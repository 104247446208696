import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { capitalized } from "../../actions/utils";
import PlaceDetailsModal from "../../components/tourism/place_details_modal";
import { Reveal } from "../../reveal";
import PlacesSkeleton from "../../components/loading/places_loading";

const Places = () => {
  const [show, setShow] = useState(false);
  const [places, setPlaces] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (place) => {
    setShow(true);
    setDetails(place);
  };

  const fetchData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/places/rmf_travellers`,
        config
      )
      .then((res) => {
        setPlaces(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-md-12 heading-section text-center ftco-animate">
          <span className="subheading">Places</span>
          <h2>Discover Places</h2>
        </div>
      </div>
      <Reveal>
        <div className="container mt-3">
          <div className="row">
            {isLoading ? (
              <>
                <PlacesSkeleton />
              </>
            ) : places.length === 0 ? (
              <div className="alert alert-info text-center" role="alert">
                <h1>
                  <i className="fa fa-globe"></i>
                </h1>
                <h3> No Places Found</h3>
              </div>
            ) : (
              places.slice(0, 6).map((place, index) => (
                <div
                  data-aos="fade-up"
                  data-aos-delay={index * 250}
                  key={index}
                  className="col-md-4 col-sm-4 col-lg-4 mb-3">
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => handleShow(place)}>
                    <img
                      style={{
                        borderRadius: "10px",
                        width: "100%",
                        height: "200px",
                      }}
                      src={place.image}
                      alt=""
                      className="img-fluid"
                    />
                    <h4 className="text-capitalize">
                      <b>{place.place_name}</b> <br />
                    </h4>
                  </a>
                  <h6 className="text-capitalize">
                    <small className="text-info text-center">
                      <i className="fa fa-map-marker"></i>{" "}
                      <span>{place.place_type}</span>,{" "}
                      {place.address.substring(0, 25)}
                    </small>
                  </h6>
                  <a className="text-capitalize" href={`${place.facebook_url}`}>
                    <i className="fa fa-facebook"></i> Facebook
                  </a>
                  <blockquote>
                    <small>
                      {capitalized(place.about_place.substring(0, 150))}
                      ...{" "}
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => handleShow(place)}
                        className="btn btn-sm btn-info text-white">
                        Read More
                      </a>
                    </small>
                  </blockquote>
                  {/* modal here */}
                  <PlaceDetailsModal
                    show={show}
                    handleClose={handleClose}
                    place={details === null ? place : details}
                  />
                </div>
              ))
            )}
          </div>
        </div>
        <center>
          <motion.a
            href={`/zm/tour_places`}
            className="btn btn-primary mt-3 mb-3"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9, x: "-5px", y: "5px" }}>
            See All <i className="fa fa-arrow-right"></i>
          </motion.a>
        </center>
      </Reveal>
    </>
  );
};

export default Places;
