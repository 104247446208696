import React from "react";
import axios from "axios";
import { ICarImages, ICarRental } from "../redux/types/car_rental.type";
import HireCarModal from "./widgets/hire_car_modal";
import { useGetCarDetailsQuery } from "../redux/slices/car_hire_slice";
import { capitalized } from "../utils/utils";
import carImage from "../assets/slider-img/slider-1.jpg";
import RouteDetails from "../components/buses/Reservations/route_details";

function CarDetails() {
  const [cars, setCars] = React.useState<ICarRental[]>([]);
  const [image, setImage] = React.useState<any>("");
  const [page, setpage] = React.useState(1);

  const queryParams = new URLSearchParams(window.location.search);
  const car_id = queryParams.get("car_id");

  const fetchData = async () => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/cars/v1/intercity_travellers/car_rental/rmf_travellers/?page=${page}`
        )
        .then((res) => {
          setCars(res.data);
        });
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchData();
  }, [page]);

  const {
    data: car,
    isLoading: isFetching,
    error,
  }: ICarRental | any = useGetCarDetailsQuery(car_id);

  if (isFetching) {
    return (
      <div
        style={{ marginTop: "20%", marginBottom: "20%" }}
        className="text-center">
        <i className="fa fa-spinner fa-spin fa-5x text-danger"></i>
      </div>
    );
  }
  if (error) {
    return (
      <>
        <section
          className="hero-wrap hero-wrap-2 js-fullheight"
          style={{
            backgroundImage: `url(${carImage})`,
            height: "fit-content",
          }}>
          <div
            style={{
              height: "fit-content",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
            }}
            className="overlay"></div>
          <div className="container">
            <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-center">
              <div className="col-md-9 ftco-animate pb-5 text-center">
                <p className="breadcrumbs">
                  <span className="mr-2">
                    <a href="/">
                      Home <i className="fa fa-chevron-right"></i>
                    </a>
                  </span>{" "}
                  <span className="mr-2">
                    <a href="/zm/blogs">
                      Car Hire <i className="fa fa-chevron-right"></i>
                    </a>
                  </span>{" "}
                  <span>
                    Details <i className="fa fa-chevron-right"></i>
                  </span>
                </p>
                <h1 className="mb-0 bread">Car Hire Details</h1>
              </div>
            </div>
          </div>
          {/* <a className="mouse smoothscroll" onClick={scrowDown}>
          <div className="mouse-icon">
            <span className="mouse-wheel"></span>
          </div>
        </a> */}
        </section>
        <div
          style={{ marginTop: "5%", marginBottom: "20%" }}
          className="text-center alert alert-danger"
          role="alert">
          <h4>AN Error Ocurred</h4>
        </div>
      </>
    );
  }

  const handleImageChange = (event: any) => {
    setImage(event);
  };

  return (
    <>
    <div className="demo-wrap">
      <img
        className="demo-bg"
        src={`${
          image === "" ? car?.car_images[0]?.image : image
        }`}
        style={{height: 'fit-content'}}
        alt=""
      />
      <div className="demo-content">
      <div className="container">
        <div className="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
          <div className="col-md-9 ftco-animate pb-5 text-center">
            <p className="breadcrumbs">
              <span className="mr-2">
                <a href="/">
                  Home <i className="fa fa-chevron-right"></i>
                </a>
              </span>{" "}
              <span>
                Car Rentals <i className="fa fa-chevron-right"></i>
              </span> Car Hire Details
            </p>
            
            <h1 className="mb-0 bread text-capitalize">{car?.car_model}</h1>
          </div>
        </div>
      </div>
      </div>
    </div>
      <div className="page blog-list-page blog-single-page right-sidebar mt-5">
        <div className="wrap mt-5">
          <div className="wrap_float">
            <div className="page_body">
              <div className="row">
                <div className="left_content col-md-10">
                  <div className="blog_single-head">
                    <div
                      className="blog_single-head_top"
                      style={{
                        backgroundImage: `url(${
                          image === "" ? car?.car_images[0]?.image : image
                        })`,
                      }}>
                      
                      <div
                        style={{ width: "fit-content" }}
                        className="car_tags mb-2">
                          <div className="text-white badges bg-lightgreen">
                        STATUS:{" "}
                        {car?.is_available ? (
                          <span className="badges bg-lightgreen text-white">
                            AVAILABLE
                          </span>
                        ) : (
                          <span className="badges bg-lightred text-white">
                            NOT AVAILABLE
                          </span>
                        )}
                      </div>
                        <div className="car_tag red text-uppercase">
                          <span style={{ fontSize: "12px" }}>
                           Local Rental Price ZMW {car?.local_price?.toLocaleString()} Per Day
                          </span>
                        </div>
                        <div className="car_tag blue mt-2 text-uppercase">
                          <span style={{ fontSize: "12px" }}>
                           Outside Town Rental Price ZMW {car?.outside_town?.toLocaleString()} Per Day
                          </span>
                        </div>
                        <div style={{ width: "fit-content" }} className="car_tag green mt-3 text-center text-uppercase">
                          <a
                            className="text-white"
                            href={`/zm/company_cars?company_id=${car?.user.id}`}>
                            {car?.user.company_name}
                          </a>
                        </div>
                      </div>
                      <h1 className="title text-capitalize">{car?.car_model}</h1>
                    </div>
                  </div>
                  <div className="row">
                    {car?.car_images?.map((item: ICarImages, index: number) => {
                      return (
                        <div
                          onClick={() => handleImageChange(item?.image)}
                          style={{ cursor: "pointer" }}
                          key={index}
                          className="col-md-3 col-sm-2">
                          <img
                            style={{ width: "100%", height: "80%" }}
                            className="rounded"
                            src={`${item?.image}`}
                            alt={car?.car_name}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className="comments-block js-section" id="reviews">
                    <div className="comments">
                      <div className="comment_item">
                        <div className="comment_item_top">
                          <h3 className="mb-3">
                            <b>Car Description</b>
                          </h3>
                          <p className="description">
                            <blockquote>
                              {capitalized(car?.description)}
                            </blockquote>
                          </p>
                          <div className="card p-3">
                            <h5 className="card-header mb-3">
                              <b>Car Accessories</b>
                            </h5>
                            <div className="row">
                              <div className="col-md-6">
                                <li>
                                  <i className="fa fa-group"></i>{" "}
                                  <b>{car?.seats} Seats</b>{" "}
                                </li>
                                <li>
                                  <i className="fa fa-music"></i>{" "}
                                  <b className="text-uppercase">{car?.music_types}</b>{" "}
                                </li>
                                <li>
                                  <i className="fa fa-fire"></i>{" "}
                                  <b>Air Conditioning</b>{" "}
                                </li>
                                <li>
                                  <i className="fa fa-car"></i>{" "}
                                  <b className="text-capitalize">
                                    {car?.gear_type}
                                  </b>{" "}
                                </li>
                                <li>
                                  <i className="fa fa-car"></i>{" "}
                                  <b className="text-capitalize">
                                    Fuel Gas Tank: {car?.fuel_litres}L
                                  </b>{" "}
                                </li>
                                <li>
                                  <i className="fa fa-dashboard"></i>{" "}
                                  <b className="text-capitalize">
                                    Speedometer: {car?.speedometer} Mph
                                  </b>{" "}
                                </li>
                              </div>
                            </div>
                            <div className="row mt-5">
                              {/* <div className="col-md-4">
                                <a
                                  href="mailto:info@intercitytravellers.com"
                                  className="btn btn-info">
                                  <i className="fa fa-comment"></i> Send Enquiry
                                </a>
                              </div> */}
                              {/* CAR HIRE MODAL HERE */}
                              {/* <HireCarModal car={car} /> */}
                              <RouteDetails />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right_content car_sidebar col-md-3">
                  <div className="_block">
                    <h4 className="block_title">Other Rides</h4>
                    <div className="popular">
                      {cars
                        .filter((data) => data.slug !== car_id)
                        .map((item: ICarRental, index: number) => (
                          <a
                            key={index}
                            href={`/zm/car_details/?car_id=${item.slug}`}
                            className="item">
                            <div className="item_top">
                              <div
                                className="img"
                                style={{
                                  backgroundImage: `url(${process.env.REACT_APP_API_URL}/${item.car_images[0]?.image})`,
                                }}></div>
                              <h5 className="_title text-capitalize">
                                {item.car_model}
                              </h5>
                              <a
                                className="btn btn-sm btn-info"
                                href={`/zm/car_details/?car_id=${item.slug}`}>
                                <i className="fa fa-arrow-right"></i> Details
                              </a>
                            </div>
                          </a>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="overlay" id="overlay"></div>
    </>
  );
}

export default CarDetails;
