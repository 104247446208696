import React from "react";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import { DragAndDrop } from "../../widgets/DragNdrop";
import httpRoomServices from "../services/http.room.services";

const EditAddRoomImages = ({ roomId, images, fetchData }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [files, setFiles] = React.useState([]);

  function removeFile(idx) {
    const newArr = [...files];
    newArr.splice(idx, 1);
    setFiles([]);
    setFiles(newArr);
  }

  const uploadRoomImages = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    const bodyFormData = new FormData();
    files.forEach((image, index) => {
      bodyFormData.append("room", roomId);
      bodyFormData.append("image", image);
      console.log(JSON.stringify(files));
      try {
        httpRoomServices
          .submitRoomImage(bodyFormData)
          .then((response) => {
            if (response.status === 201) {
              setIsSubmitting(false);
              // setFiles([]);
              fetchData();
              toast.success(`Room Updated successfully ${index + 1}`);
            } else {
              setIsSubmitting(false);
              toast.error(response.data.message);
            }
          })
          .catch((error) => {
            setIsSubmitting(false);
            console.error(error);
            toast.error(error.message);
          });
      } catch (error) {
        setIsSubmitting(false);
        console.error(error);
        toast.error(error.message);
      }
    });
  };
  return (
    <div className="mb-5">
      {images.length === 0 ? (
        <div className="alert alert-info text-center" role="alert">
          <i className="fa fa-image"></i>
          <h2>No Images Found</h2>
        </div>
      ) : (
        <div></div>
      )}
      <div>
        <Form onSubmit={uploadRoomImages} encType="multipart/form-data">
          <div className="row mb-5">
            {images.map((file, index) => {
              return (
                <div key={index} className="col-md-2 shadow-xl image-full">
                  <img
                    src={`${file.image}`}
                    style={{
                      width: "100%",
                      height: "140px",
                    }}
                    className="img-responsive rounded"
                    alt="room"
                  />
                  <div className="card-actions justify-end mt-2">
                    <button
                      onClick={() => removeFile(index)}
                      className="btn btn-danger">
                      <i className="fa fa-trash-o"></i>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          <DragAndDrop
            heading={"Add Room Image Gallery"}
            onChange={(e) => {
              if (e.target.files && e.target.files[0]) {
                for (let i = 0; i < e.target.files["length"]; i++) {
                  setFiles((prevState) => [...prevState, e.target.files[i]]);
                }
              }
            }}
            setFiles={setFiles}
            files={files}
          />
          <div className="text-center mt-3 justify-center">
            <button
              type="submit"
              className="btn btn-info"
              disabled={isSubmitting}>
              {isSubmitting ? (
                <span>Loading...</span>
              ) : (
                <span>Update Room Images</span>
              )}
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default EditAddRoomImages;
