import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDeleteUserMutation } from "../../redux/slices/authApiSlice";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { resetUserProfile } from "../../redux/features/profile/profile";
import { useAppDispatch } from "../../hooks/redux-hooks";
import { logout as setLogout } from "../../redux/features/authSlice";

export type DataFormFields = {
  current_password: string;
};

function DeleteAccountModal() {
    const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [deleteAccount, { isLoading }] = useDeleteUserMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DataFormFields>();

  const onSubmit = handleSubmit(async (data) => {
    deleteAccount({ current_password: data.current_password })
      .unwrap()
      .then((res) => {
        reset();
        setShow(false);
        dispatch(setLogout());
        dispatch(resetUserProfile());
        toast.success("Your account has been deleted Successfully");
        window.location.href = "/";
      })
      .catch((err) => {
        toast.error("Error: " + err.data["detail"]);
      });
  });

  return (
    <>
      <button onClick={handleShow} className="btn btn-danger">
        Close My Account
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 className="text-center mb-5">
            Do you really want to delete your account?
            <p>This process cannot be undone.</p>
            </h6>
            <form onSubmit={onSubmit}>
              <div className="col-sm-12 col-md-12">
                <div className="form-group">
                  <label htmlFor="current_password"> Current Password <small className="text-danger">*</small>
                  </label>
                  <input
                    id="current_password"
                    disabled={isLoading}
                    {...register("current_password", { required: true })}
                    className={`form-control ${
                      errors.current_password ? "border-danger" : ""
                    }`}
                    name="current_password"
                    type="password"
                    placeholder="Enter password to delete your account"
                  />
                  {errors.current_password && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button disabled={isLoading} variant="primary" type="submit">
                  {isLoading && <Spinner size="sm" />}{" "}
                  <i className="fa fa-trash"></i> Delete
                </Button>
              </Modal.Footer>
            </form>
          
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteAccountModal;
