import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";

function Seats({ takenSeats, allSeats }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [seats, setSeats] = useState(60);
  const [selected, setSelected] = useState(null);

  useEffect(() => {}, []);

  return (
    <>
      <div className="col-md-2 pull-right">
        <button type="button" className="btn btn-primary" onClick={handleShow}>
          Choose Seat
        </button>
      </div>

      <Modal
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
        scrollable={true}
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Choose Seat{" "}
            <div className="col-md-12">
              <div className="mt-2 row">
                <div className="col-md-4">
                  <div className="btn btn-success btn-xs mb-2">Available</div>
                </div>
                <div className="col-md-4">
                  <div className="btn btn-primary btn-xs mb-2">Choosen</div>
                </div>
                <div className="col-md-4">
                  <div className="btn btn-danger btn-xs mb-2">Booked</div>
                </div>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ButtonGroup aria-label="Basic example">
            <div className="row">
              {Array.from(Array(seats), (e, i) => {
                let taken = i + 1;
                let variant = "success";
                let choosen = "primary";
                return (
                  <div key={i} className="col-md-2 mb-3">
                    <Button
                      onClick={() => setSelected(taken)}
                      variant={taken === selected ? choosen : variant}
                    >
                      Seat {taken}
                    </Button>
                  </div>
                );
              })}
            </div>
          </ButtonGroup>
          <div className="modal-footer">
            <div className="row">
              <div className="col-md-4">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
              <div className="col-md-8">
                <button type="button" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Seats;
