import React, { useEffect } from "react";
import axios from "axios";

import OwlCarousel from "react-owl-carousel";
import ReactHtmlParser from "html-react-parser";
import { Helmet } from "react-helmet";
import { urlToken, styles } from "../../helper";
import { motion } from "framer-motion";
import { Reveal } from "../../reveal";
import { capitalized, options } from "../../actions/utils";
import WaterFalls from "./waterfalls";
import DetailsModal from "./details_modal";
import Slider from "../carousel/carousel";
import { tourSlides } from "../carousel/carousel_data";
import ProvinceSkeleton from "../loading/province_loading";
import HolidaySkeleton from "../loading/holiday_loading";

function Tourism() {
  const [isLoadingRivers, setisLoadingRivers] = React.useState(true);
  const [isLoadingWaterfalls, setisLoadingWaterfalls] = React.useState(true);
  const [isLoading, setisLoading] = React.useState(true);
  const [province, setprovince] = React.useState([]);
  const [rivers, setRivers] = React.useState([]);
  const [waterfalls, setWaterFalls] = React.useState([]);

  const [show, setShow] = React.useState(false);
  const [details, setDetails] = React.useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (water) => {
    setShow(true);
    setDetails(water);
    console.log(`OPENING BOOTSTRAP MODAL ${show}`);
  };

  const fetchData = async () => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/home/rmf_travellers`
        )
        .then((res) => {
          setprovince(res.data);
          setisLoading(false);
        });
    } catch (error) {
      setisLoading(false);
    }
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/view_lake_river_watefalls/lakes_rivers/rmf_travellers`
      )
      .then((response) => {
        setRivers(response.data);
        setisLoadingRivers(false);
      })
      .catch((error) => {
        console.error("there was an error", error.message);
        setisLoadingRivers(false);
      });

    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/view_lake_river_watefalls/waterfalls/rmf_travellers`
      )
      .then((response) => {
        setWaterFalls(response.data);
        setisLoadingWaterfalls(false);
      })
      .catch((error) => {
        console.error("there was an error", error.message);
        setisLoadingWaterfalls(false);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Intercity Travellers |Tourism Page</title>
        <meta property="og:title" content="Tourism" />
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content="https://www.intercitytravellers.com/tourism"
        />
        <meta property="og:site_name" content="intericty travellers" />
        <meta
          property="og:description"
          content="Zambia The land of the legendary African walking safari, Victoria Falls, the wild Zambezi River,abundant wildlife,and raw wilderness,all in one friendly country.
            Blessed with awe-inspiring natural wonders, an abundance of wildlife, huge water bodies and vast open spaces, Zambia offers unforgettable holidays exploring the real Africa.
            Acknowledged as one of"
        />
      </Helmet>
      {/* <section className="hero-wrap hero-wrap-2 js-fullheight tourism">
        <div className="overlay"></div>
        <div class="container">
          <div class="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
            <div class="col-md-9 ftco-animate pb-5 text-center">
              <p class="breadcrumbs">
                <span class="mr-2">
                  <a href="/">
                    Home <i class="fa fa-chevron-right"></i>
                  </a>
                </span>{" "}
                <span>
                  Zambia Tourism <i class="fa fa-chevron-right"></i>
                </span>
              </p>
              <h1 class="mb-0 bread">Zambia Tourism</h1>
              <h3 className="text-white">Explore With Us</h3>
            </div>
          </div>
        </div>
      </section> */}
      <Slider slides={tourSlides} />
      <Reveal>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 heading-section text-center ftco-animate">
              <span className="subheading">Zambia</span>
              <h2>Beyond Beauty</h2>
            </div>
          </div>
          <div className="card m-3 p-5 shadow border-0 rounded-3">
            Land of the Legendary African walking Safari, home of the Victoria
            Falls, the wild Zambezi River, breath taking lakes and wetlands, a
            profusion of birds, abundant wildlife, and raw, pulsating
            wilderness, all in one friendly country. The country lies in the
            Heart of Southern Africa, 10 to 18 degrees south of the equator and
            is bordered by eight countries.
            {/* <a href="#">Learn More</a> */}
          </div>
          {isLoadingWaterfalls ? (
            <HolidaySkeleton options={options} />
          ) : waterfalls.length === 0 ? (
            <div className="alert alert-info text-center" role="alert">
              <h1>
                <i className="fa fa-globe"></i>
              </h1>
              <h3> No Waterfalls Found</h3>
            </div>
          ) : (
            <WaterFalls waterFall={waterfalls} />
          )}
          <section>
            <h1 className="mb-3 mt-3">
              <b>
                Discover <br /> Zambian Rivers
              </b>
            </h1>
            <Reveal>
              <div className="row">
                <div className="col-md-12">
                  {isLoadingRivers ? (
                    <HolidaySkeleton options={options} />
                  ) : rivers.length === 0 ? (
                    <div className="alert alert-info text-center" role="alert">
                      <h1>
                        <i className="fa fa-globe"></i>
                      </h1>
                      <h3> No Rivers Found</h3>
                    </div>
                  ) : (
                    <div className="carousel-destination ftco-animate">
                      <OwlCarousel {...options} className="owl-theme">
                        {rivers.map((item, index) => (
                          <>
                            <div
                              data-aos="fade-up"
                              data-aos-delay={index * 250}
                              key={index}
                              className="item">
                              <div className="project-destination">
                                <a
                                  onClick={() => handleShow(item)}
                                  className="img"
                                  style={{
                                    backgroundImage: `url(${item.image})`,
                                    cursor: "pointer",
                                  }}>
                                  <div className="text text-uppercase">
                                    <span className="text-uppercase">
                                      {item.placeName}
                                    </span>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </>
                        ))}
                      </OwlCarousel>
                    </div>
                  )}
                </div>
              </div>
            </Reveal>
          </section>
          <DetailsModal
            data={details === null ? [] : details}
            show={show}
            handleClose={handleClose}
          />
          <h1 className="mt-5">
            <b>Provinces</b>
          </h1>
          <div className="row ">
            {isLoading ? (
              <ProvinceSkeleton />
            ) : province.length === 0 ? (
              <div className="alert alert-info text-center" role="alert">
                <h1>
                  <i className="fa fa-globe"></i>
                </h1>
                <h3> No Provinces Found</h3>
              </div>
            ) : (
              province.map((prov, index) => (
                <div
                  key={index}
                  data-aos="fade-up"
                  data-aos-delay={index * 250}
                  className="col-md-4 col-xl-4 mb-3 mt-5 tourism-container display-img">
                  <a
                    href={`/zm/province/?token=${urlToken}&slug=${prov.slug}&province=${prov.id}`}>
                    <img
                      className="img-fluid tour-img"
                      src={prov.thumbnail}
                      alt={prov.province_name}
                      style={{
                        width: "100%",
                        height: "200px",
                        borderRadius: "10px",
                      }}
                    />
                  </a>

                  <div>
                    <h5 className="text-capitalize mt-2">
                      <b>{prov.province_name}</b>
                    </h5>
                    <small>
                      {ReactHtmlParser(
                        capitalized(prov.about_province.substring(0, 150))
                      )}{" "}
                      <motion.a
                        href={`/zm/province/?token=${urlToken}&slug=${prov.slug}&province=${prov.id}`}
                        style={styles}
                        className="pull-right"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9, x: "-5px", y: "5px" }}>
                        Explore
                      </motion.a>
                    </small>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </Reveal>
    </>
  );
}

export default Tourism;
