import { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import profile from "../../../../images/user.png";
import { Container } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";

function AddEmployee({ uid, departments }) {
  let profileRef = useRef(null);
  const [show, setShow] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [image, setImage] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    department: null,
    employeeName: "",
    email: "",
    address: "",
    phoneNumber: "",
    position: "",
    salary: "",
  });

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handleImage = (e) => {
    setImage(e.target.files[0]);
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setFormSubmitting(true);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      let form_data = new FormData();
      form_data.append("commuter", uid);
      form_data.append("department", formData.department);
      form_data.append("employeeName", formData.employeeName);
      form_data.append("email", formData.email);
      form_data.append("address", formData.address);
      form_data.append("position", formData.position);
      form_data.append("salary", formData.salary);
      form_data.append("phoneNumber", formData.phoneNumber);
      form_data.append("image", image);

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/create_employee/rmf_travellers`,
          form_data,
          config
        )
        .then((res) => {
          console.log(res.data);
          Toast.fire({
            icon: "success",
            title: "Employee Created Successfully",
          });

          setTimeout(() => {
            window.location.reload();
            setFormSubmitting(false);
          }, 2000);
        })
        .catch((er) => {
          console.log(er.message);
          Toast.fire({
            icon: "error",
            title: `Error: ${er.message}`,
          });

          setFormSubmitting(false);
        });
    }

    setValidated(true);
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <i className="fa fa-plus-circle"></i> Add Employee
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="xl"
        scrollable
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Container>
              <Row>
                <Form.Group as={Col} md="3">
                  <div className="leftHook">
                    <div
                      className="imageCon"
                      style={{
                        backgroundImage: `url(${profile})`,
                      }}
                    />
                    <input
                      type="file"
                      name="image"
                      style={{ display: "none" }}
                      ref={(e) => (profileRef = e)}
                      onChange={handleImage}
                    />

                    <div className="point" onClick={() => profileRef.click()}>
                      Profile Picture <i className="fa fa-upload"></i>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group as={Col} md="9" className="mb-3">
                  <Row>
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Employee Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="employeeName"
                        onChange={(e) => onChange(e)}
                        placeholder="Employee Name e.g John Doe"
                        required
                        disabled={formSubmitting}
                      />
                      <Form.Control.Feedback type="invalid">
                        Employee Name is required
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom04">
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        onChange={(e) => onChange(e)}
                        placeholder="E-mail"
                        required
                        disabled={formSubmitting}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Email.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom04">
                      <Form.Label>Employee Address</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => onChange(e)}
                        name="address"
                        placeholder="Employee address"
                        required
                        disabled={formSubmitting}
                      />
                      <Form.Control.Feedback type="invalid">
                        Address is required
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom05">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type="text"
                        name="phoneNumber"
                        onChange={(e) => onChange(e)}
                        placeholder="Phone Number"
                        required
                        disabled={formSubmitting}
                      />
                      <Form.Control.Feedback type="invalid">
                        Phone Number is required
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom05">
                      <Form.Label>Position</Form.Label>
                      <Form.Control
                        type="text"
                        name="position"
                        onChange={(e) => onChange(e)}
                        placeholder="Position"
                        required
                        disabled={formSubmitting}
                      />
                      <Form.Control.Feedback type="invalid">
                        Position is required
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom05">
                      <Form.Label>Salary</Form.Label>
                      <Form.Control
                        type="number"
                        name="salary"
                        onChange={(e) => onChange(e)}
                        min={1}
                        placeholder="Salary"
                        required
                        disabled={formSubmitting}
                      />
                      <Form.Control.Feedback type="invalid">
                        Salary is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                </Form.Group>
                <Form.Group controlId="validationCustom03">
                  <Form.Label>Department</Form.Label>
                  <Form.Select
                    name="department"
                    className="text-capitalize"
                    onChange={(e) => onChange(e)}
                    size="lg"
                    required
                    disabled={formSubmitting}
                  >
                    <option selected disabled>
                      Choose Department
                    </option>
                    {departments.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.departmentName}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please provide station name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Container>
            <Modal.Footer>
              <Button
                disabled={formSubmitting}
                variant="secondary"
                onClick={handleClose}
              >
                Close
              </Button>
              <Button disabled={formSubmitting} type="submit" variant="primary">
                {formSubmitting && (
                  <span className="wc-editable">
                    <i className="fa fa-refresh fa-spin" /> Submitting ...
                  </span>
                )}
                {!formSubmitting && <span className="wc-editable">Submit</span>}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddEmployee;
