import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import $ from "jquery";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { DragAndDrop } from "../../widgets/DragNdrop";
import httpDashboardServices from "../services/http.branch.services";

function AddBranch({ hotel, uid }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [generalFacilities, setGeneralFacilities] = useState([]);
  const [facility, setFacility] = useState(null);

  let tagInput = useRef();

  const handleFacility = (e) => {
    setFacility(e.target.value);
  };

  const removeTag = (i) => {
    const newTags = [...generalFacilities];
    newTags.splice(i, 1);
    setGeneralFacilities(newTags);
  };

  const inputKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      if (
        generalFacilities.find((tag) => tag.toLowerCase() === val.toLowerCase())
      ) {
        return;
      }
      setGeneralFacilities([...generalFacilities, val]);
      tagInput.value = null;
    }
  };
  /**
   * Stop enter submitting the form.
   * @param keyEvent Event triggered when the user presses a key.
   */
  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }
  const [files, setFiles] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const uploadBranchImages = (branch) => {
    const bodyFormData = new FormData();
    files.map(async (image) => {
      bodyFormData.append("branch", branch);
      bodyFormData.append("image", image);
      try {
        httpDashboardServices
          .submitBrancheImage(bodyFormData)
          .then((response) => {
            if (response.status === 201) {
              // reset();
              // window.location.reload();
              // toast.success(response.data.message);
            } else {
              // toast.error(response.data.message);
            }
          })
          .catch((error) => {
            console.error(error);
            // toast.error(error.message);
          });
      } catch (error) {}
    });
  };
  const postData = async (data) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    // e.preventDefault();
    setFormSubmitting(true);
    httpDashboardServices
      .createBranches(data, generalFacilities, uid, hotel)
      .then((response) => {
        uploadBranchImages(response.data.id);
        if (response.statusCode === 200) {
          Toast.fire({
            icon: "success",
            title: "Branch added Succesfully",
          });
        }
      })
      .catch((error) => {
        console.log(`Failed to add branch ${error}`);
        
        setFormSubmitting(false);
        Toast.fire({
          icon: "error",
          title: "An error occurred try again later!",
        });
      });
  };
  $(document).ready(function () {
    $("#province").on("change", function () {
      var provinceID = $(this).val();
      if (provinceID) {
        $.ajax({
          url: `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/province-districts/${provinceID}/rmf_travellers`,
          type: "GET",
          dataType: "json",
          success: function (data) {
            if (data) {
              $("#district").empty();
              $("#district").focus();
              $("#district").append(
                '<option disabled selected value=""> -- Select City -- </option>'
              );
              $.each(data, function (key, value) {
                $('select[name="district"]').append(
                  '<option value="' +
                    value.id +
                    '">' +
                    value.district_name +
                    "</option>"
                );
              });
            } else {
              $("#district").empty();
            }
          },
        });
      } else {
        $("#district").empty();
      }
    });
  });
  return (
    <>
      <p className="pull-right mt-5">
        <button
          style={{ cursor: "pointer" }}
          onClick={handleShow}
          className="btn btn-primary">
          Add Branch
        </button>
      </p>
      <Modal
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
        scrollable={true}
        show={show}
        onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Branch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            encType="multipart/form-data"
            onKeyDown={onKeyDown}
            onSubmit={handleSubmit(postData)}>
            <div className="row">
              <div className="form-group col-md-6">
                <label>State</label>
                <input
                  type="text"
                  disabled={formSubmitting}
                  {...register("state", { required: true })}
                  className="form-control"
                  name="state"
                  placeholder="State"
                />
                {errors.state && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
              <div className="form-group col-md-6">
                <label>City</label>
                <input
                  type="text"
                  disabled={formSubmitting}
                  {...register("city", { required: true })}
                  className="form-control"
                  name="city"
                  placeholder="City"
                />
                {errors.district && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
              <DragAndDrop
                heading={"Branches Image Gallery"}
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    for (let i = 0; i < e.target.files["length"]; i++) {
                      setFiles((prevState) => [
                        ...prevState,
                        e.target.files[i],
                      ]);
                    }
                  }
                }}
                setFiles={setFiles}
                files={files}
              />

              <div className="col-md-6 col-xs-12">
                <div className="form-group">
                  <label>
                    <span className="wc-editable">
                      <h3 style={{ fontSize: "18px" }}>Physical Address</h3>
                    </span>
                  </label>

                  <div className="form-group mb-3">
                    <input
                      type="text"
                      disabled={formSubmitting}
                      {...register("address", { required: true })}
                      className="form-control"
                      name="address"
                      placeholder="Physical Address"
                    />
                  </div>
                  {errors.address && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="form-group">
                  <label>
                    <span className="wc-editable">
                      <h3 style={{ fontSize: "18px" }}>Branch Type</h3>
                    </span>
                  </label>

                  <div className="form-group mb-3">
                    <select
                      {...register("branchType", {
                        required: true,
                      })}
                      disabled={formSubmitting}
                      className="form-control"
                      name="branchType"
                      id="branchType">
                      <option value="">Choose branch type</option>
                      <option value="motel">Motel</option>
                      <option value="apartment">Apartment</option>
                      <option value="lodge">Lodge</option>
                      <option value="hotel">Hotel</option>
                      <option value="guest_house">Guest House</option>
                    </select>
                  </div>
                  {errors.branchType && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
              </div>
              {/* checkin checkout time*/}
              <div className="col-md-6 col-xs-12">
                <div className="form-group">
                  <label>
                    <span className="wc-editable">
                      <h3 style={{ fontSize: "18px" }}>Checkin Time</h3>
                    </span>
                  </label>

                  <div className="form-group mb-3">
                    <input
                      type="time"
                      disabled={formSubmitting}
                      {...register("checkinTime", {
                        required: true,
                        min: 0,
                      })}
                      className="form-control"
                      name="checkinTime"
                      placeholder="Checkin Time"
                    />
                  </div>
                  {errors.checkinTime && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="form-group">
                  <label>
                    <span className="wc-editable">
                      <h3 style={{ fontSize: "18px" }}>Checkout Time</h3>
                    </span>
                  </label>

                  <div className="form-group mb-3">
                    <input
                      type="time"
                      disabled={formSubmitting}
                      {...register("checkoutTime", {
                        required: true,
                        min: 0,
                      })}
                      className="form-control"
                      name="checkoutTime"
                      placeholder="Checkout Time"
                    />
                  </div>
                  {errors.checkoutTime && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
              </div>
              {/* checkin checkout time */}
              <div className="col-md-6 col-xs-12">
                <div className="form-group">
                  <label>
                    <span className="wc-editable">
                      <h3 style={{ fontSize: "18px" }}>Phone Number</h3>
                    </span>
                  </label>

                  <div className="form-group mb-3">
                    <input
                      type="number"
                      disabled={formSubmitting}
                      {...register("hotel_phone", {
                        required: true,
                        min: 0,
                      })}
                      className="form-control"
                      name="hotel_phone"
                      placeholder="Phone Number"
                    />
                  </div>
                  {errors.hotel_phone && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="form-group">
                  <label>
                    <span className="wc-editable">
                      <h3 style={{ fontSize: "18px" }}>E-mail</h3>
                    </span>
                  </label>

                  <div className="form-group mb-3">
                    <input
                      type="text"
                      disabled={formSubmitting}
                      {...register("hotel_email", { required: true })}
                      className="form-control"
                      name="hotel_email"
                      placeholder="E-MAIL"
                    />
                  </div>
                  {errors.hotel_email && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
              </div>

              <Form.Group className="mb-3" controlId="validationCustom03">
                <Form.Label>General Facilities</Form.Label>
                <div className="input-tag">
                  <ul className="input-tag__tags">
                    {generalFacilities.map((tag, i) => (
                      <li key={i}>
                        {tag}
                        <button
                          type="button"
                          onClick={() => {
                            removeTag(i);
                          }}>
                          X
                        </button>
                      </li>
                    ))}
                    <li className="input-tag__tags__input">
                      <Form.Control
                        disabled={formSubmitting}
                        type="text"
                        onKeyDown={inputKeyDown}
                        onChange={(e) => handleFacility(e)}
                        name="amenities"
                        className="form-control"
                        required
                        value={facility}
                        placeholder="General Facilities, E.g swimming pool, board room etc"
                        ref={(c) => {
                          tagInput = c;
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        General Facilities is required
                      </Form.Control.Feedback>
                    </li>
                  </ul>
                </div>
              </Form.Group>
              <div className="col-md-6 col-xs-12">
                <div className="form-group">
                  <label>
                    <span className="wc-editable">
                      <h3 style={{ fontSize: "18px" }}>Branch Description</h3>
                    </span>
                  </label>

                  <div className="form-group mb-3">
                    <textarea
                      type="text"
                      disabled={formSubmitting}
                      cols="12"
                      rows="8"
                      {...register("hote_description", {
                        required: true,
                      })}
                      name="hote_description"
                      className="form-control"
                      placeholder="Branch Description"></textarea>
                  </div>
                  {errors.hote_description && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="form-group">
                  <label>
                    <span className="wc-editable">
                      <h3 style={{ fontSize: "18px" }}>
                        Room Cancellation Policy
                      </h3>
                    </span>
                  </label>

                  <div className="form-group mb-3">
                    <textarea
                      type="text"
                      disabled={formSubmitting}
                      cols="12"
                      rows="8"
                      {...register("cancellationPolicy", {
                        required: true,
                      })}
                      name="cancellationPolicy"
                      className="form-control"
                      placeholder="Room Cancellation Policy"></textarea>
                  </div>
                  {errors.hote_description && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group pull-right">
              <button
                type="submit"
                disabled={formSubmitting}
                className="btn btn-primary">
                {formSubmitting ? (
                  <span>
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px" }}
                    />{" "}
                    Submitting...
                  </span>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default AddBranch;
