import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";

const AddBus = ({ routes, provinces, fetchBuses }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({
    seat_capacity: "",
    number_place: "",
    driver: "",
    departure_day: "",
    arrivalDay: "",
    bus_role: "",
    route: "",
  });
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { uid } = useParams();

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handlePostData = async (data) => {
    // e.preventDefault();
    setErrorMessage("");
    setFormSubmitting(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    const body = JSON.stringify({
      seat_capacity: data.seat_capacity,
      number_place: data.number_place,
      commutor: uid,
      driver: data.driver,
      arrivalDay: data.arrivalDay,
      departure_day: data.departure_day,
      bus_role: data.bus_role,
      route: data.route,
    });

    await axios
      .post(`${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/add-bus/rmf_travellers`, body, config)
      .then((response) => {
        console.log(response.data);
        fetchBuses();
        setFormSubmitting(false);
        setShow(false);

        Toast.fire({
          icon: "success",
          title: "bus added Successfully",
        });
      })
      .catch((error) => {
        Toast.fire({
          icon: "error",
          title: `An Error occurred try again later! ${error.message}`,
        });
        setErrorMessage(error.message);
        setFormSubmitting(false);
      });
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <>
      <div className="col-md-3 pull-right mb-3">
        <button className="mt-3 btn btn-outline-secondary" onClick={handleShow}>
          Add New Bus
        </button>
      </div>
      <Modal
        backdrop="static"
        keyboard={false}
        scrollable
        centered
        size="xl"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Bus</Modal.Title>
        </Modal.Header>
        {errorMessage === "" ? (
          ""
        ) : (
          <div className="alert alert-warning text-center text-dark">
            {errorMessage}
          </div>
        )}
        <Modal.Body>
          <form onSubmit={handleSubmit(handlePostData)}>
            <div className="row">
              <div className="form-group col-md-12">
                <label>Choose Route</label>
                <select
                  onChange={(e) => onChange(e)}
                  name="route"
                  disabled={formSubmitting}
                  {...register("route", { required: true })}
                  className="form-control text-capitalize"
                >
                  <option value="">Choose Route</option>
                  {routes.map((route, index) => (
                    <option key={index} value={route.id}>
                      {provinces.map((pro) =>
                        pro.id === route.from_province
                          ? pro.province_name
                          : null
                      )}{" "}
                      -{" "}
                      {provinces.map((pro) =>
                        pro.id === route.to_province ? pro.province_name : null
                      )}
                    </option>
                  ))}
                </select>
                {errors.route && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
              <div className="form-group col-md-6">
                <label className="text-dark">Seat Capacity</label>
                <input
                  type="number"
                  min="0"
                  {...register("seat_capacity", { required: true })}
                  placeholder="Seat Capacity"
                  name="seat_capacity"
                  className="form-control"
                  disabled={formSubmitting}
                  onChange={(e) => onChange(e)}
                />
                {errors.seat_capacity && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
              <div className="form-group col-md-6">
                <label className="text-dark">Number Plate</label>
                <input
                  type="text"
                  disabled={formSubmitting}
                  name="number_place"
                  {...register("number_place", { required: true })}
                  placeholder="number plate"
                  className="form-control"
                  onChange={(e) => onChange(e)}
                />
                {errors.number_place && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
              <div className="form-group col-md-6">
                <label className="text-dark">Driver</label>
                <input
                  type="text"
                  name="driver"
                  placeholder="Driver"
                  disabled={formSubmitting}
                  className="form-control"
                  {...register("driver", { required: true })}
                  onChange={(e) => onChange(e)}
                />
                {errors.driver && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
              <div className="form-group col-md-6">
                <label className="text-dark">Queue Position</label>
                <input
                  {...register("bus_role", { required: true })}
                  onChange={(e) => onChange(e)}
                  disabled={formSubmitting}
                  type="text"
                  name="bus_role"
                  placeholder="Queue Position e.g first bus"
                  className="form-control"
                />
                {errors.bus_role && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
              <div className="form-group col-md-6">
                <label>Departure Date</label>
                <input
                  onChange={(e) => onChange(e)}
                  disabled={formSubmitting}
                  type="date"
                  {...register("departure_day", { required: true })}
                  name="departure_day"
                  className="form-control"
                />
                {errors.departure_date && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>

              <div className="form-group col-md-6">
                <label className="text-dark">Arrival Date</label>
                <input
                  {...register("arrivalDay", { required: true })}
                  onChange={(e) => onChange(e)}
                  disabled={formSubmitting}
                  type="date"
                  name="arrivalDay"
                  className="form-control"
                />
                {errors.arrivalDay && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>

            <div className="modal-footer">
              <div className="row">
                <div className="col-md-6 form-group">
                  <button
                    type="button"
                    className="btn btn-secondary form-control"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                </div>
                <div className="col-md-6 form-group">
                  <button
                    className="btn btn-primary form-control"
                    disabled={formSubmitting}
                  >
                    {formSubmitting && (
                      <span className="wc-editable">
                        <i className="fa fa-refresh fa-spin" /> Submitting ....
                      </span>
                    )}
                    {!formSubmitting && (
                      <span className="wc-editable">Submit</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddBus;
