import React, { useState, useEffect, useCallback } from "react";
import { FlutterWaveButton, closePaymentModal } from "flutterwave-react-v3";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import { useStream } from "react-fetch-streams";
import bg1 from "../../../images/city.jpg";
import { useParams } from "react-router-dom";
import Seats from "./seats";
import LoadingSpinner from "../../widgets/spinner";
import Footer from "../../footer";
import { Modal } from "react-bootstrap";

function TicketCheckout() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [results, setResults] = useState([]);
  const [buses, setBuses] = useState([]);
  const [commutors, setCommutors] = useState([]);
  const [flutterwaveID, setFlutterwaveID] = useState({
    flutterwave: "",
  });
  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [inputList, setInputList] = useState([]);
  const [userCommission, setUserCommission] = useState(1);
  const [paymentService, setPaymentService] = useState({
    service: "",
    account: "",
  });

  const [takenSeatData, setTakenSeatData] = useState([]);
  const onNext = useCallback(
    async (res) => {
      const data = await res.json();
      setTakenSeatData(data);
    },
    [setTakenSeatData]
  );
  useStream(`${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/get_taken_seat/rmf_travellers`, {
    onNext,
  });
  const handlePaymentService = (e) =>
    setPaymentService({ ...paymentService, [e.target.name]: e.target.value });
  const { id } = useParams();

  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    const fetchData = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/auth/users/me/`, config)
        .then((userProfile) => {
          setUser(userProfile.data);
        })
        .catch((error) => {
          //  console.log(error.message);
          if (error.message === "Request failed with status code 401") {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "error",
              title: "Session Expired, Login to continue to checkout",
            });

            setTimeout(() => {
              window.location.href = "/zm/login";
            }, 3000);
          }
        });
      //   get commutors
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/userprofile/v1/intercity_travellers/get-commuters/rmf_travellers`
        );

        setCommutors(res.data);
        setIsLoading(false);
      } catch (err) {}
      // get bus results
      try {
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/get-fetched-ticket/${id}/rmf_travellers`
          )
          .then((res) => {
            setResults(res.data);
            setIsLoading(false);
          });
      } catch (err) {}

      //   get going buses
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/get-buses/rmf_travellers`
        );
        setBuses(res.data);
        // setIsLoading(false);
      } catch (err) {}
    };
    fetchData();
  }, []);

  const [formData, setFormData] = useState({
    phone_no: "",
    amount: "",
    first_name: "",
    last_name: "",
    email: "",
    commutor: "",
    ticket_no: Math.floor(1000 + Math.random() * 1000),
    seat_no: "0",
    from_route: "",
    to_route: "",
    departure_date: "",
    bus: "",
    customer: null,
    user_commission: userCommission,
  });

  const [seatamount, setSeatAmount] = useState(formData.amount);
  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  let tickets = seatamount - formData.amount;
  let removeCommission = inputList.length - 1;
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    setSeatAmount(tickets);
    setUserCommission(1 + removeCommission);
  };
  // handle click event of the Add button
  let ticket = 2 + inputList.length;
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        ticket_no: "",
        amount: "",
        first_name: "",
        last_name: "",
        phone_no: "",
        email: "",
        seat_no: "0",
        commutor: "",
        from_route: "",
        to_route: "",
        departure_date: "",
        bus: "",
        user_commission: 0,
      },
    ]);
    setSeatAmount(formData.amount * ticket);
    setUserCommission(1 + ticket - 1);
  };
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  let commission = commutors.map((item) =>
    item.id == user.id && item.is_referaluser == true
      ? item.pending_commission + userCommission
      : null
  );

  const totalCommission = commission.reduce(function (acc, val) {
    return acc + val;
  }, 0);

  const ticket_list = [...inputList, formData];
  const handleSubmit = async (event) => {
    // event.preventDefault();
    setFormSubmitting(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    const bodyFormData = new FormData();

    ticket_list.forEach((item) => {
      bodyFormData.append("ticket_list[]", item);
    });

    const bodyCommission = new FormData();
    bodyCommission.append("pending_commission", totalCommission);

    // console.log(formData, ...inputList);
    setIsLoading(false);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/create-ticket/rmf_travellers`,
        ticket_list.length < 1 ? formData : ticket_list,
        config
      )
      .then((response) => {
        console.log(response.data["user_commission"]);
        axios
          .patch(
            `${process.env.REACT_APP_API_URL}/userprofile/v1/intercity_travellers/clear_commission/${user.id}/rmf_travellers`,
            bodyCommission,
            config
          )
          .then((response) => {
            // console.log(`commission update ${response.data}`);
          })
          .catch((error) => {
            // setErrorMessage(error.message);
            // console.error('There was an error!', error);
          });
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 9000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: `Your bus ticket has been booked Successfully go to the main station and present your ticket ${formData.ticket_no} to the bus conductor`,
        });

        setFormSubmitting(false);
      })
      .catch((error) => {
        // setTimeout(() => {
        //   window.location.href = `/ticket/${id}/passengers`;

        // }, 2000);
        setFormSubmitting(false);
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: `An error occurred, Please contact Intercity Travellers for assistence`,
        });
        console.error("There was an error!", error);
      });
  };

  function randomString(length, chars) {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  var orderId = randomString(
    10,
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
  );
  var appKey =
    "1D682C567D64488136399E6218FD3C2954EA5DC56D4785C85EFBE15A7A96A1583493B830AE2B1EE8139C5A84C0E3DEE7";
  var authKey =
    "4A818A870763B4B7C6ECE48F6096EEED3493B830AE2B1EE8139C5A84C0E3DEE7F33CBE16D88D75F06ECCF65B4F7F937787C7F9A8";
  const registerTransaction = async () => {
    setFormSubmitting(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    var reqbody = {
      app_key: appKey,
      auth_key: appKey,
      key_type: "business",
      request_id: new Date().toString(),
      order_id: orderId,
      order_details: "Ticket Payment",
      method: "mobile_money",
      amount: seatamount === "" ? formData.amount : seatamount,
      currency: "ZMW",
      account: `+26${user.phone}`,
      preauthflag: "0",
      service: "Airtel Money",
    };

    var jsonBody = JSON.stringify(reqbody);

    await axios
      .post(
        "https://samafricaonline.com/sam_pay/public/ra_register",
        jsonBody,
        config
      )
      .then((response) => {
        console.log(response.data);
        setFormSubmitting(false);
        finalizeTransaction(response.data["token"]);
      })
      .catch((error) => {
        setFormSubmitting(false);

        Swal.fire("Error", `${error.message}`, "error");
        console.error("There was an error!", error);
      });
  };

  const finalizeTransaction = async (token) => {
    setFormSubmitting(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    var finalRequestbody = {
      app_key: appKey,
      auth_key: authKey,
      key_type: "business",
      request_id: new Date().toString(),
      method: "mobile_money",
      token: token,
      otp: "",
    };

    await axios
      .post(
        "https://samafricaonline.com/sam_pay/public/ra_mmpayrequest",
        JSON.stringify(finalRequestbody),
        config
      )
      .then((response) => {
        console.log(response.data);
        setFormSubmitting(false);
        handleSubmit();
        Swal.fire(
          "Success",
          "Your Ticket has been reserved succesfully!",
          "success"
        );
      })
      .catch((error) => {
        setFormSubmitting(false);

        Swal.fire("Error", `${error.message}`, "error");
        console.error("There was an error!", error);
      });
  };

  // console.log(`====== ${flutterwaveID.flutterwave}=========`);
  const config = {
    public_key: "FLWPUBK_TEST-a9886c6a11cc9054e48958b1c86a9435-X",
    tx_ref: Date.now(),
    amount: seatamount === "" ? formData.amount : seatamount,
    currency: "ZMW",
    country: "ZM",
    payment_options: "card",
    subaccounts: [
      {
        id: flutterwaveID.flutterwave,
      },
    ],
    customer: {
      email: user.email,
      phone_number: user.phone,
      name: user.first_name + " " + user.last_name,
    },
    customizations: {
      title: "Intercity Travellers",
      description: "Ticket Payment",
      logo: "https://intercitytravellers.com/static/media/logo.fac39d8d9cc655e4948c.png",
    },
  };

  const fwConfig = {
    ...config,
    text: "Pay with Card!",
    callback: (response) => {
      console.log(response);
      if (response.status === "successful") {
        handleSubmit();
      }
      closePaymentModal(); // this will close the modal programmatically
    },
    onClose: () => {},
  };

  function TimeDifference(arrivalTime, departTime) {
    var diff = moment.duration(moment(arrivalTime).diff(moment(departTime)));
    var days = parseInt(diff.asDays()); //84

    var hours = parseInt(diff.asHours()); //2039 hours, but it gives total hours in given miliseconds which is not expacted.

    hours = hours - days * 24; // 23 hours

    var minutes = parseInt(diff.asMinutes()); //122360 minutes,but it gives total minutes in given miliseconds which is not expacted.

    minutes = minutes - (days * 24 * 60 + hours * 60);
    return `${days}days, ${hours}hrs:${minutes}m`;
  }

  return (
    <>
      <section
        className=""
        style={{
          backgroundImage: `url(${bg1})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-center">
            <div className="col-md-9 ftco-animate pb-5 text-center">
              <p className="breadcrumbs">
                <span className="mr-2">
                  <a href="/">
                    Home <i className="fa fa-chevron-right"></i>
                  </a>
                </span>{" "}
                <span className="mr-2">
                  <a> Ticket Reservation </a>
                </span>{" "}
              </p>
              <h1 className="mb-0 bread"> Ticket Reservation</h1>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Modal --> */}
      <Modal
        backdrop="static"
        keyboard={false}
        centered
        scrollable={true}
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Make Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="otp">Choose Payment Method</label>
            <select
              className="form-control"
              required
              onChange={(e) => handlePaymentService(e)}
              name="service"
            >
              <option value="Airtel Money">Airtel Money</option>
              <option value="MTN Money">MTN Money</option>
              <option value="Zampay">Zamtel Money</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="otp">Enter Phone Number</label>
            <input
              onChange={(e) => handlePaymentService(e)}
              type="text"
              name="account"
              placeholder="Phone Number"
              className="form-control"
              required
            />
          </div>
          <div className="modal-footer">
            <div className="row">
              <div className="col-md-6">
                <button
                  type="submit"
                  onClick={
                    paymentService.account === "" ? null : registerTransaction
                  }
                  className="btn btn-primary"
                  disabled={formSubmitting}
                >
                  {formSubmitting && (
                    <span className="text-white">
                      <i className="fa fa-refresh fa-spin" /> waiting...
                    </span>
                  )}
                  {!formSubmitting && <small>Pay</small>}
                </button>
              </div>
              <div className="col-md-6">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div
        style={{ background: "rgba(255, 255, 255, 0.8)" }}
        className="mainContainer"
      >
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="mt-5">
            <div className="mt-5">
              <div className="cl-pt-30 cl-pb-60">
                <div className="container">
                  <div
                    id="reservation-ticket-tab"
                    className="bs-container-wrap row step-app"
                  >
                    <div
                      id="pjBrContainer"
                      className="bs-container step-content"
                    >
                      <div className="step-tab-ticket">
                        <form
                          onSubmit={handleSubmit}
                          className="form-tickets-bus"
                        >
                          <div className="bus-tickets-area">
                            <div className="bus-ticket-top cms-e-section">
                              <b>
                                <p className="pull-left text-dark">
                                  Trip From:{" "}
                                  {results.map((res, index) =>
                                    id == res.id ? (
                                      <span
                                        key={index}
                                        className="text-capitalize text-dark ml-1"
                                      >
                                        <span className="text-success">
                                          {res.from_route}{" "}
                                        </span>
                                        to{" "}
                                        <span className="text-success">
                                          {res.to_route}
                                        </span>
                                      </span>
                                    ) : null
                                  )}
                                </p>
                              </b>
                              <input
                                type="hidden"
                                defaultValue={(formData.phone_no = user.phone)}
                                name="phone_no"
                                onChange={(e) => onChange(e)}
                              />
                              <input
                                type="hidden"
                                defaultValue={
                                  (formData.first_name = user.first_name)
                                }
                                name="first_name"
                                onChange={(e) => onChange(e)}
                              />
                              <input
                                type="hidden"
                                defaultValue={
                                  (formData.last_name = user.last_name)
                                }
                                name="last_name"
                                onChange={(e) => onChange(e)}
                              />
                              <input
                                type="hidden"
                                defaultValue={(formData.email = user.email)}
                                name="email"
                                onChange={(e) => onChange(e)}
                              />
                              <p className="pull-right">
                                <span className="text-dark">Bus: </span>
                                <span className="text-dark">
                                  {results.map((res) =>
                                    id == res.id
                                      ? commutors.map((com) =>
                                          res.commutor == com.id ? (
                                            <span
                                              key={com.id}
                                              className="text-dark text-uppercase"
                                            >
                                              <b>{com.company_name}</b>
                                              <input
                                                type="hidden"
                                                defaultValue={
                                                  (formData.commutor = com.id)
                                                }
                                                name="commutor"
                                                onChange={(e) => onChange(e)}
                                              />
                                              <input
                                                type="hidden"
                                                onChange={
                                                  (flutterwaveID.flutterwave =
                                                    com.flutterwave_id)
                                                }
                                                value={com.flutterwave_id}
                                              />
                                              {buses.map((bus) =>
                                                res.going_bus === bus.id ? (
                                                  <input
                                                    type="hidden"
                                                    value={
                                                      (formData.bus = bus.id)
                                                    }
                                                    name="bus"
                                                    onChange={(e) =>
                                                      onChange(e)
                                                    }
                                                  />
                                                ) : null
                                              )}
                                            </span>
                                          ) : null
                                        )
                                      : null
                                  )}
                                </span>
                              </p>
                            </div>
                            <div className="table-responsive">
                              <table className="table text-dark">
                                <thead>
                                  <tr>
                                    <th scope="col">
                                      <b>Bus</b>
                                    </th>
                                    <th scope="col">
                                      <b>Departure</b>
                                    </th>
                                    <th scope="col">
                                      <b>Arrival</b>
                                    </th>
                                    <th scope="col">
                                      <b>Departure Date / Time</b>
                                    </th>
                                    <th scope="col">
                                      <b>Arrival Date / Time</b>
                                    </th>
                                    <th>
                                      <b>Duration</b>
                                    </th>
                                    <th scope="col">
                                      <b>Ticket No</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {results.map((res, index) =>
                                    id == res.id ? (
                                      <tr key={index}>
                                        <td>
                                          <span className="text-capitalize">
                                            {res.from_route}
                                            <input
                                              type="hidden"
                                              name="from_route"
                                              onChange={(e) => onChange(e)}
                                              value={
                                                (formData.from_route =
                                                  res.from_route)
                                              }
                                            />
                                          </span>

                                          <p className="vertical-divider"></p>
                                          <span className="text-capitalize">
                                            {res.to_route},{" "}
                                            <b>K{res.route_price}</b>
                                            <input
                                              type="hidden"
                                              name="amount"
                                              onChange={(e) => onChange(e)}
                                              value={
                                                (formData.amount =
                                                  res.route_price)
                                              }
                                            />
                                            <input
                                              type="hidden"
                                              name="to_route"
                                              onChange={(e) => onChange(e)}
                                              value={
                                                (formData.to_route =
                                                  res.to_route)
                                              }
                                            />
                                          </span>
                                        </td>
                                        <td>
                                          <div
                                            className="bus-tickets-select form_ticket_number"
                                            data-title="Tickets"
                                          >
                                            <div className="form-group">
                                              <strong className="ticket-price">
                                                <h5 className="text-capitalize">
                                                  {res.from_route}{" "}
                                                </h5>
                                              </strong>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            className="bus-tickets-select form_ticket_number"
                                            data-title="Tickets"
                                          >
                                            <div className="form-group">
                                              <strong className="ticket-price">
                                                <h5 className="text-capitalize">
                                                  {res.to_route}{" "}
                                                </h5>
                                              </strong>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          {buses.map((bus, index) => (
                                            <div key={index}>
                                              {res.going_bus === bus.id ? (
                                                <div
                                                  className="bus-departure-date-time"
                                                  data-title="Departure time"
                                                >
                                                  <span className="bus-date text-capitalize">
                                                    {moment(
                                                      res.departure_date
                                                    ).format("MMMM Do YYYY")}
                                                    ,{" "}
                                                    {`${res.departure_time}hrs`}{" "}
                                                    - {bus.bus_role}
                                                  </span>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          ))}
                                        </td>
                                        <td>
                                          {buses.map((bus, index) => (
                                            <div key={index}>
                                              {res.going_bus === bus.id ? (
                                                <div
                                                  className="bus-departure-date-time"
                                                  data-title="Departure time"
                                                >
                                                  <span className="bus-date">
                                                    {moment(
                                                      res.arrivalDay
                                                    ).format("MMMM Do YYYY")}
                                                    {", "}
                                                    {`${res.arrival_time}hrs`}
                                                    <input
                                                      type="hidden"
                                                      name="departure_date"
                                                      onChange={(e) =>
                                                        onChange(e)
                                                      }
                                                      value={
                                                        (formData.departure_date =
                                                          res.departure_date +
                                                          " " +
                                                          bus.departure_time)
                                                      }
                                                    />
                                                  </span>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          ))}
                                        </td>
                                        <td>
                                          <b>
                                            {TimeDifference(
                                              `${res.arrivalDay} ${res.arrival_time}`,
                                              `${res.departure_date} ${res.departure_time}`
                                            )}
                                          </b>
                                        </td>
                                        <td>
                                          <div
                                            className="bus-ticket-table-td bus-ticket-table-td13 text-danger"
                                            data-title="Ticket"
                                          >
                                            # {formData.ticket_no}
                                            <input
                                              type="hidden"
                                              name="ticket_no"
                                              onChange={(e) => onChange(e)}
                                              value={formData.ticket_no}
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    ) : null
                                  )}
                                </tbody>
                              </table>
                              <Seats allSeats={60} takenSeats={takenSeatData} />
                              {/* {data.map((item, index) => item.seat_no)} */}
                              <p className="pull-right">
                                <button
                                  type="button"
                                  className="btn btn-outline-primary"
                                  onClick={handleAddClick}
                                >
                                  {" "}
                                  <i className="fa fa-plus"></i> Add Another
                                  Ticket
                                </button>
                              </p>
                              <input
                                type="hidden"
                                value={(formData.customer = user.id)}
                                name="customer"
                                onChange={(e) => onChange(e)}
                              />
                              <input
                                type="hidden"
                                value={
                                  user.is_referaluser == true
                                    ? (formData.user_commission = 1)
                                    : 0
                                }
                                name="user_commission"
                                onChange={(e) => onChange(e)}
                              />
                            </div>

                            <hr />

                            {/* <h3>{inputList.length}</h3> */}
                            {inputList.map((x, i) => {
                              return (
                                <div>
                                  <div id="tickets-details">
                                    {/* new form here */}
                                    <div className="bus-tickets-table">
                                      {results.map((res, index) =>
                                        id == res.id ? (
                                          <div key={index}>
                                            {commutors.map((com) =>
                                              res.commutor == com.id ? (
                                                <span
                                                  key={com.id}
                                                  className="text-warning text-capitalize"
                                                >
                                                  <input
                                                    type="hidden"
                                                    value={
                                                      (x.commutor = com.id)
                                                    }
                                                    name="commutor"
                                                    onChange={(e) =>
                                                      handleInputChange(e, i)
                                                    }
                                                  />
                                                </span>
                                              ) : null
                                            )}
                                            {buses.map((bus) =>
                                              res.going_bus == bus.id ? (
                                                <input
                                                  type="hidden"
                                                  value={(x.bus = bus.id)}
                                                  name="bus"
                                                  onChange={(e) =>
                                                    handleInputChange(e, i)
                                                  }
                                                />
                                              ) : null
                                            )}
                                            {buses.map((bus) => (
                                              <div key={bus.id}>
                                                {res.going_bus === bus.id ? (
                                                  <div
                                                    className="bus-departure-date-time"
                                                    data-title="Departure time"
                                                  >
                                                    <span className="bus-date">
                                                      <input
                                                        type="hidden"
                                                        name="departure_date"
                                                        onChange={(e) =>
                                                          handleInputChange(
                                                            e,
                                                            i
                                                          )
                                                        }
                                                        value={
                                                          (x.departure_date =
                                                            res.departure_date +
                                                            " " +
                                                            bus.departure_time)
                                                        }
                                                      />
                                                    </span>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            ))}
                                            <span className="text-capitalize">
                                              <input
                                                type="hidden"
                                                name="from_route"
                                                onChange={(e) =>
                                                  handleInputChange(e, i)
                                                }
                                                value={
                                                  (x.from_route =
                                                    res.from_route)
                                                }
                                              />
                                            </span>

                                            <span className="text-capitalize">
                                              <input
                                                type="hidden"
                                                name="amount"
                                                onChange={(e) =>
                                                  handleInputChange(e, i)
                                                }
                                                value={
                                                  (x.amount = res.route_price)
                                                }
                                              />
                                              <input
                                                type="hidden"
                                                name="to_route"
                                                onChange={(e) =>
                                                  handleInputChange(e, i)
                                                }
                                                value={
                                                  (x.to_route = res.to_route)
                                                }
                                              />
                                            </span>
                                          </div>
                                        ) : null
                                      )}

                                      <input
                                        type="hidden"
                                        name="customer"
                                        value={(x.customer = user.id)}
                                        onChange={(e) =>
                                          handleInputChange(e, i)
                                        }
                                      />

                                      <input
                                        type="hidden"
                                        value={
                                          user.is_referaluser == true
                                            ? (x.user_commission = 1)
                                            : 0
                                        }
                                        name="user_commission"
                                        onChange={(e) =>
                                          handleInputChange(e, i)
                                        }
                                      />

                                      <input
                                        type="hidden"
                                        name="ticket_no"
                                        onChange={(e) =>
                                          handleInputChange(e, i)
                                        }
                                        value={
                                          (x.ticket_no = formData.ticket_no)
                                        }
                                      />

                                      <div className="row">
                                        <div
                                          className="col-md-2"
                                          data-title="Ticket"
                                        >
                                          <label htmlFor="ticket_no">
                                            Ticket Number
                                          </label>{" "}
                                          <br />
                                          <span className="text-danger">
                                            # {formData.ticket_no}
                                          </span>
                                        </div>

                                        <div className="col-md-5">
                                          <div className="form-group">
                                            <label htmlFor="first_name">
                                              First Name
                                            </label>
                                            <input
                                              className="form-control"
                                              name="first_name"
                                              placeholder="Enter First Name"
                                              value={x.first_name}
                                              required
                                              onChange={(e) =>
                                                handleInputChange(e, i)
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-5">
                                          <div className="form-group">
                                            <label htmlFor="last_name">
                                              Last Name
                                            </label>
                                            <input
                                              className="form-control"
                                              name="last_name"
                                              placeholder="Enter Last Name"
                                              value={x.last_name}
                                              required
                                              onChange={(e) =>
                                                handleInputChange(e, i)
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label htmlFor="phone number">
                                              Phone Number
                                            </label>
                                            <input
                                              className="form-control"
                                              name="phone_no"
                                              placeholder="Enter Phone Number"
                                              value={x.phone_no}
                                              required
                                              onChange={(e) =>
                                                handleInputChange(e, i)
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label htmlFor="email">
                                              E-mail
                                            </label>
                                            <input
                                              className="form-control"
                                              name="email"
                                              placeholder="Enter E-mail"
                                              value={x.email}
                                              required
                                              onChange={(e) =>
                                                handleInputChange(e, i)
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <p className="pull-right mb-4">
                                        K {formData.amount}
                                        {inputList.length >= 1 && (
                                          <button
                                            className="btn btn-sm btn-primary"
                                            onClick={() => handleRemoveClick(i)}
                                          >
                                            Remove
                                          </button>
                                        )}
                                      </p>
                                    </div>

                                    <br />

                                    {/* new form end */}
                                  </div>
                                </div>
                              );
                            })}
                            <div className="cl-hr-line-dashed"></div>
                            {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}

                            <h4>
                              <input
                                required
                                id="agreement"
                                name="agreement"
                                defaultChecked
                                type="checkbox"
                              />{" "}
                              <label htmlFor="agreement">
                                <span
                                  className="wc-editable text-danger"
                                  data-pk="front_label_agree"
                                  data-type="text"
                                >
                                  {" "}
                                  I have read and accept your
                                </span>
                              </label>
                              <Link target="_blank" to={`/terms`}>
                                <span
                                  className="wc-editable"
                                  data-pk="front_label_terms_conditions"
                                  data-type="text"
                                >
                                  {" "}
                                  Terms and conditions
                                </span>
                              </Link>
                              <div className="step-tab-panel-bottom step-footer">
                                <div className="cl-hr-line-dashed"></div>

                                {seatamount === "" ? (
                                  <h3
                                    id="bsRoundtripPrice"
                                    className="total-price text-right text-dark mt-3"
                                  >
                                    Total Price:{" "}
                                    <span>K {formData.amount}</span>
                                  </h3>
                                ) : (
                                  <h3
                                    id="bsRoundtripPrice"
                                    className="total-price text-right text-dark mt-3"
                                  >
                                    Total Price: <span>K {seatamount}</span>
                                  </h3>
                                )}
                              </div>
                            </h4>

                            <br />
                          </div>
                        </form>

                        <div className="step-tab-panel-bottom step-footer pull-right">
                          {/* <!-- Button trigger modal --> */}
                          <div className=" mb-3">
                            <button
                              disabled={formSubmitting}
                              type="button"
                              className="btn btn-primary"
                              onClick={handleShow}
                            >
                              {formSubmitting ? (
                                <span>
                                  {formSubmitting && (
                                    <i className="fa fa-refresh fa-spin" />
                                  )}
                                  {formSubmitting && (
                                    <span className="wc-editable text-warning">
                                      Please wait ....
                                    </span>
                                  )}
                                </span>
                              ) : (
                                "Pay with Mobile Money"
                              )}
                            </button>
                          </div>
                          {/* <div className="col-md-6">
                              {formSubmitting ? (
                                <span>
                                  {formSubmitting && (
                                    <i className="fa fa-refresh fa-spin" />
                                  )}
                                  {formSubmitting && (
                                    <span className="wc-editable text-warning">
                                      Please wait ....
                                    </span>
                                  )}
                                </span>
                              ) : (
                                <FlutterWaveButton {...fwConfig} />
                              )}
                            </div> */}
                          {/* <p className="pull-right">
                            <img
                              src={pays}
                              alt=""
                              style={{ width: "50%", height: "70px" }}
                              className="img-fluid"
                            />
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default TicketCheckout;
