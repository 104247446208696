import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import LoadingSpinner from "../../components/widgets/spinner";
import JoditEditor from "jodit-react";
import { useAppSelector } from "../../hooks/redux-hooks";
import { toast } from "react-toastify";

const CarRentalTerms = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSubmitting, setISubmitting] = React.useState(false);
  const editor = React.useRef(null);
  const [content, setContent] = React.useState("");
  const user = useAppSelector((state) => state.auth.user);
  const fetchData = async () => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/userprofile/v1/intercity_travellers/update_user/${user?.id}/rmf_travellers`,
        config
      )
      .then((response) => {
        setContent(response.data['termsConditions'])
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setISubmitting(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    let form_data = new FormData();
    form_data.append("termsConditions", content);

    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}/userprofile/v1/intercity_travellers/update_user/${user?.id}/rmf_travellers`,
        form_data,
        config
      )
      .then((response) => {
        setISubmitting(false);
        fetchData();
        toast.success("Terms & Conditions updated Successfully");
      })
      .catch((error) => {
        setISubmitting(false);
        toast.error("An Error occurred try again later!");
      });
  };



  React.useEffect(() => {
    fetchData();
  });
  return (
    <>
      <div className=" mt-5">
        {isLoading ? (
          <div className="centerAll">
            <LoadingSpinner />
            <h1>Please wait..</h1>
          </div>
        ) : (
          <div className="p-2 p-md-3 pt-3">
            <h1 className="text-center">Terms & Conditions</h1>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <JoditEditor
                  ref={editor}
                  value={content}
                  //tabIndex={1} // tabIndex of textarea
                  onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                  onChange={(newContent) => {
                    // setContent(newContent)
                  }}
                />
              </div>
              <div className="text-center pull-right">
                <button disabled={isSubmitting} className="btn btn-warning">
                  {" "}
                  {isSubmitting ? (
                    <span>
                      <i className="fa fa-refresh fa-spin" /> Updating...
                    </span>
                  ) : (
                    "Update Terms"
                  )}{" "}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default CarRentalTerms;
