import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ReactHtmlParser from "html-react-parser";
import LoadingSpinner from "../../components/widgets/spinner";
import { urlToken } from "../../helper";
const EditDis = () => {
  const [isLoading, setisLoading] = useState(true);
  const [details, setDetails] = useState([]);
  const [districts, setDistricts] = useState([]);
  const { id, slug } = useParams();

  const fetchData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    const results = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/provinces-details/${slug}/${id}/rmf_travellers`, config
    );
    setDetails(results.data);
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/province-districts/${id}/rmf_travellers`, config
    );
    setDistricts(res.data);
    setisLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="wrapper  d-flex align-items-stretch mt-5">
        {isLoading ? (
          <center>
            <LoadingSpinner />
          </center>
        ) : (
          <div id="content" className="p-2 p-md-3 pt-3">
            <section>
              <div className="container-fluid">
                <center>
                  <div
                    className="col-md-12 order-1 order-lg-2"
                    data-aos="zoom-in"
                  >
                    <div className="about-img">
                      <img
                        style={{ width: "100%", height: "65vh" }}
                        className="img-responsive"
                        src={details.thumbnail}
                        alt=""
                      />
                    </div>
                  </div>
                </center>
                <div className="col-md-12 order-2 order-lg-1 content">
                  <h3 className="text-capitalize text-danger">
                    {details.province_name}
                  </h3>
                  <p className="font-italic">
                    {ReactHtmlParser(details.about_province)}
                  </p>
                </div>
              </div>
            </section>
            <h2 className="text-white text-center mb-5 text-capitalize">
              Districts in{" "}
              <span style={{ color: "#f1a417" }}>{details.province_name}</span>
            </h2>

            <div className="row">
              {districts.map((district) => (
                <div className="col-md-3 mb-4" key={district.id}>
                  <div className="row display-img">
                    <a
                      href={`/zm/district/${urlToken}/${district.slug}/${district.id}`}
                    >
                      <img
                        src={district.image}
                        style={{
                          height: "200px",
                          width: "100%",
                          borderRadius: "5px",
                        }}
                        className="img-responsive tour-img"
                        alt=""
                      />
                    </a>

                    <div className="">
                      <a
                        href={`/zm/district/${urlToken}/${district.slug}/${district.id}`}
                        className="text-capitalize"
                      >
                        {district.district_name}
                      </a>
                    </div>
                    <div className="">
                      {ReactHtmlParser(district.discription.substring(0, 150))}

                      <a
                        href={`/zm/district/${urlToken}/${district.slug}/${district.id}`}
                      >
                        ....<i className="fa fa-arrow-circle-right"></i> More
                      </a>
                    </div>
                    <div>
                      <a
                        className="btn btn-sm btn-info pull-right"
                        href={`/zm/intercity_travellers_admin/edit-district/${district.slug}/${district.id}`}
                      >
                        <i className="fa fa-edit"></i> Edit
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditDis;
