import http from "./http.common";

class HolidayPackageService {
  // CREATE HOLIDAY PACKAGE
  async createPackage(
    formData,
    activities,
    thumbnail,
    placeImage1,
    placeImage2,
    ageRange,
    languages
  ) {
    let form_data = new FormData();
    form_data.append("title", formData.title);
    form_data.append("currency", formData.currency);
    form_data.append("tourPrice", formData.tourPrice);
    form_data.append("tourDays", formData.tourDays);
    form_data.append("description", formData.description);
    form_data.append("country", formData.country);
    form_data.append("location", formData.location);
    form_data.append("travel_type", formData.travel_type);
    form_data.append("thumbnail", thumbnail);
    form_data.append("placeImage1", placeImage1);
    form_data.append("placeImage2", placeImage2);
    form_data.append("placeName1", formData.placeName1);
    form_data.append("placeName2", formData.placeName2);
    form_data.append("groupSize", formData.groupSize);
    form_data.append("activities", JSON.stringify([...activities]));
    form_data.append("languages", JSON.stringify([...languages]));
    form_data.append("ageRange", JSON.stringify(ageRange));
    console.log(form_data);
    return http.post(
      `/holidays/v1/intercity_travellers/create_travel/rmf_travellers`,
      form_data
    );
  }

  // SUBMIT Package IMAGES
  async submitPackageImage(body) {
    return await http.post(
      `/holidays/v1/intercity_travellers/holiday_package_image_gallery/rmf_travellers`,
      body
    );
  }
}
export default new HolidayPackageService();
