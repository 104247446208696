import React from "react";
import { Helmet } from "react-helmet";
import httpApiService from "../components/services/http.api.service";
import HeroSlider from "./hero_slider";
import CarCard from "./widgets/car_card";
import { Reveal } from "../reveal";
import { ICarBrand, ICarRental } from "../redux/types/car_rental.type";
import { useGetBrandsQuery } from "../redux/slices/car_brands_apislice";
import { carGearBoxTypes } from "../components/data/car_brands";

function AllCars() {
  const [cars, setCars] = React.useState<ICarRental[]>([]);
  const [carCompanies, carRentals] = React.useState<any[]>([]);
  const [page, setpage] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(true);
  const [number, setNumber] = React.useState(1); // No of pages
  const [dataPerPage] = React.useState(12);
  const [q, setQ] = React.useState("");
  const [filterParam, setFilterParam] = React.useState("all");
  const [searchParam] = React.useState(["gear_type", "car_model", "car_brand"]);

  const fetchData = async () => {
    try {
      httpApiService.getCarRentals().then((res) => {
        setCars(res.data);
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
    }
    // fetch Holiday Packages
    try {
      httpApiService.getCarCompanies().then((response) => {
        carRentals(response.data);
      });
    } catch (error) {}
  };
  React.useEffect(() => {
    fetchData();
  }, [page]);

  const { data: carBrands, isLoading: fetchingBrands }: ICarBrand | any =
    useGetBrandsQuery();

  // pagination
  const lastData = number * dataPerPage;
  const firstData = lastData - dataPerPage;
  const currentData = cars.slice(firstData, lastData);
  const pageNumber = [];

  for (let i = 1; i <= Math.ceil(cars.length / dataPerPage); i++) {
    pageNumber.push(i);
  }

  const ChangePage = (pageNumber: number) => {
    setNumber(pageNumber);
  };
  const data = Object.values(currentData);

  function search(list: any) {
    return list.filter((item: any) => {
      if (item.user.company_name === filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.car_model === filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.gear_type === filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.car_brand.id == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (filterParam == "all") {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }

  return (
    <>
      <Helmet>
        <title>Intercity Travellers | Car Rentals</title>
        <meta
          property="og:url"
          content="https://www.intercitytravellers.com/zm/car_rentals"
        />
        <meta
          property="og:title"
          content="Rental Cars at Intercity Travellers |Car Rentals"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta
          name="description"
          content="Rent a car in Zambia and find great deals with Intercity Travellers. Choose from a range of brand new economy and luxury cars, across our 8 stations. Book now!"
        />
        <meta
          name="description"
          content="Find the best rental prices on luxury, economy, and family rental cars with FREE amendments in over 60,000 locations worldwide, reserve online today!"
        />
        <meta
          name="keywords"
          content="car hire, cheap car hire, car rental,  rent a car, car rentals, hire car, cheap car rentals, cheap car rental, carrentals, rent car, car hire comparison, carrental, carhire, compare car hire, car rental comparison, rentalcars, rental cars"
        />
      </Helmet>
      <HeroSlider />
      {/* <div className="breadcrumbs">
        <div className="wrap">
          <div className="wrap_float">
            <a href="/">Home</a>
            <span className="separator"> / </span>
            <a href="#">All Cars</a>
          </div>
        </div>
      </div> */}
      <div className="page stories-list-page right-sidebar">
        <div className="wrap">
          <div className="wrap_float">
            <div className="page_head">
              <div className="title">Car Rentals</div>
              <div className="subtitle">
                EXPLORE THE WORLD WITH INTERCITY TRAVELLERS CAR RENTALS
              </div>
            </div>
            <div className="page_body">
              <div className="row">
                <div className="left_content col-md-9">
                  <div className="shadow border-right p-2">
                    <Reveal>
                      <div className="form-group border-0">
                        <div className="row">
                          <div className="col-md-4">
                            <h4>Search for Car to Hire</h4>
                          </div>
                          <div className="col-md-2">
                            <div className="tag-widget post-tag-container">
                              <div className="tagcloud">
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setFilterParam("all")}
                                  className="tag-cloud-link btn btn-warning">
                                  Clear
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-field">
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Search Cars for hire"
                            value={q}
                            onChange={(e) => setQ(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="tag-widget post-tag-container mb-2 mt-2">
                        <div className="tagcloud">
                          {carGearBoxTypes.map((item: any, index: number) => (
                            <a
                              style={{ cursor: "pointer" }}
                              key={index}
                              onClick={() => setFilterParam(item)}
                              className={`tag-cloud-link ${
                                item === filterParam
                                  ? "active_category text-white"
                                  : ""
                              }`}>
                              {item}
                            </a>
                          ))}
                        </div>
                        {fetchingBrands ? (
                          <center>
                            <i className="fa fa-spinner fa-spin fa-5x text-danger"></i>
                          </center>
                        ) : (
                          <div className="tagcloud">
                            {carBrands.map(
                              (category: ICarBrand, index: number) => (
                                <a
                                  style={{ cursor: "pointer" }}
                                  key={index}
                                  onClick={() =>  setFilterParam(category.id.toString())
                                  }
                                  className={`tag-cloud-link ${
                                    category.id.toString() === filterParam
                                      ? "active_category text-white"
                                      : ""
                                  }`}>
                                  {category.brand_name}
                                  <small
                                    style={{
                                      background: "red",
                                      color: "white",
                                      marginLeft: "10px",
                                      paddingRight: "2px",
                                      paddingLeft: "2px",
                                      borderRadius: "50%",
                                    }}>
                                    {category.carmodel_count}
                                  </small>
                                </a>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </Reveal>
                  </div>
                  <div className="stories_list mt-4">
                    {isLoading ? (
                      <center>
                        <i className="fa fa-spinner fa-spin fa-5x text-danger"></i>
                      </center>
                    ) : search(data).length === 0 ? (
                      <center>
                        <div className="alert alert-warning custom-length text-center mt-5">
                          <i className="fa fa-car fa-5x"></i>
                          <h4>No Cars Found</h4>
                        </div>
                      </center>
                    ) : (
                      search(data).map((car: any, index: number) => (
                        <CarCard item={car} key={index} />
                      ))
                    )}
                  </div>
                  {/* <div className="pagination">
                    <nav
                      aria-label="..."
                      className=" d-flex unlock-item  justify-content-center mt-5 mb-5">
                      <button
                        className="btn btn-success"
                        onClick={() => setpage(page + 1)}>
                        <i
                          className="fa fa-chevron-circle-down"
                          aria-hidden="true"></i>
                        <span></span> Load More ....
                      </button>
                    </nav>
                    <a className="arrow prev"></a>
                        <ul>
                            <li><a href="#" className="active">1</a></li>
                            <li><a href="#">2</a></li>
                            <li><a href="#">3</a></li>
                            <li><a href="#">4</a></li>
                        </ul>
                        <a className="arrow next"></a>
                  </div> */}
                </div>
                <div className="right_content car_sidebar col-md-3">
                  <div className="_block">
                    <div className="block_title">Car Hire Companies</div>
                    <div className="popular">
                      {carCompanies.map((item, index) => (
                        <a
                          key={index}
                          href={`/zm/company_cars?company_id=${item.id}`}
                          className="item">
                          <div className="item_top">
                            <div
                              className="img"
                              style={{
                                backgroundImage: `url(${item.company_logo})`,
                              }}></div>
                            <div className="_title text-capitalize">
                              {item.company_name}
                            </div>
                            <a
                              className="btn btn-sm btn-info"
                              href={`/zm/company_cars?company_id=${item.id}`}>
                              Details <i className="fa fa-arrow-right"></i>
                            </a>
                          </div>
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllCars;
