import { MotionStyle } from "framer-motion";

export const buttonStyles: MotionStyle = {
  background: "blue",
  textAlign: "center",
  borderRadius: 30,
  width: 150,
  padding: "5px 10px",
  margin: "auto",
  color: "#fff",
  outline: "none",
  border: "none",
  cursor: "pointer",
};
