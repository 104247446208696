import React, { useState } from "react";
import PlaceDetailsModal from "../../tourism/place_details_modal";
import { capitalized } from "../../../actions/utils";

const RoutePlaces = ({ places }) => {
  const [details, setDetails] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setDetails(null);
  };
  const handleShow = (commuter) => {
    setDetails(commuter);
    setShow(true);
  };
  return (
    <>
      <div className="mt-3">
        <div className="row">
          {places.length === 0 ? (
            <div className="alert alert-info text-center">
              <h4>
                <b>Couldn't find any places</b>
              </h4>
            </div>
          ) : (
            places.map((place, index) => (
              <div key={index} className="col-md-6 col-sm-6 col-lg-6 mb-3">
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => handleShow(place)}
                >
                  <img
                    style={{
                      borderRadius: "10px",
                      width: "100%",
                      height: "200px",
                    }}
                    src={place.image}
                    alt={place.place_name}
                    className="img-fluid"
                  />
                  <h6 className="text-capitalize">
                    <b>{place.place_name}</b> <br />
                  </h6>
                </a>
                <blockquote>
                  <small>
                    {capitalized(place.about_place.substring(0, 150))}
                    ...{" "}
                    <a
                      onClick={() => handleShow(place)}
                      className="btn btn-sm btn-primary text-white pull-right"
                    >
                      Read More
                    </a>
                  </small>
                </blockquote>
                {/* modal here */}
                <PlaceDetailsModal
                  show={show}
                  handleClose={handleClose}
                  place={details === null ? place : details}
                />
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};
export default RoutePlaces;
