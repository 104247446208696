import axios from "axios";
import moment, { weekdays } from "moment";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Charts from "./chart";

const BusDetails = () => {
  const [bus, setBus] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [amount, setAmount] = useState(0);
  const [diff, setDiff] = useState(null);
  const today = new Date();
  const weeklyData = new Date(new Date().setMonth(today.getMonth() - 1));
  const lastMonth = new Date(new Date().setMonth(today.getMonth() - 1));
  const prevMonth = new Date(new Date().setMonth(today.getMonth() - 2));

  const { uid, busid } = useParams();
  const fetchData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    //get company buses
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/get_bus_byid/${busid}/rmf_travellers`,
          config
        )
        .then((res) => {
          setBus(res.data);
          setIsLoading(false);
          console.log(`BUS DATA ${res.data.number_place}`);
        });
    } catch (error) {
      // alert(error.message);
    }
    //get provinces
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/home/rmf_travellers`, config)
        .then((res) => {
          setProvinces(res.data);
          // setIsLoading(false);
        });
    } catch (error) {}
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/bus-routes/${uid}/rmf_travellers`, config)
        .then((res) => {
          setRoutes(res.data);
          // setIsLoading(false);
        });
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
    // const interval = setInterval(() => {
    //   console.log("This will run every second!");
    // }, 1000);
    // return () => clearInterval(interval);
    const fetchStatics = async () => {
      // const lastMonthQuery = query(
      //   collection(db, data.query),
      //   where("timeStamp", "<=", today),
      //   where("timeStamp", ">", lastMonth)
      // );
      // const prevMonthQuery = query(
      //   collection(db, data.query),
      //   where("timeStamp", "<=", lastMonth),
      //   where("timeStamp", ">", prevMonth)
      // );
      // const lastMonthData = await getDocs(lastMonthQuery);
      // const prevMonthData = await getDocs(prevMonthQuery);
      // setAmount(lastMonthData.docs.length);
      // setDiff(
      //   ((lastMonthData.docs.length - prevMonthData.docs.length) / prevMonthData.docs.length) *
      //     100
      // );
    };
  }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="py-5 mt-5">
          {isLoading ? (
            <center>
              <Spinner />
            </center>
          ) : (
            <div className="row">
              <div className="col-md-6">
                <h1 className="text-center mb-3">Bus Details</h1>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <h4>Number Plate</h4>
                    <h6>{bus.number_place}</h6>
                  </div>
                  <div className="col-md-6 mb-3">
                    <h4>Drivers Name</h4>
                    <h6 className="text-capitalize">{bus.driver}</h6>
                  </div>
                  <div className="col-md-6 mb-3">
                    <h4>Route</h4>
                    <h6 className="text-capitalize">
                      {routes.map((route) =>
                        route.id === bus.route
                          ? provinces.map((pro) =>
                              pro.id === route.from_province
                                ? pro.province_name
                                : null
                            )
                          : null
                      )}{" "}
                      -{" "}
                      {routes.map((route) =>
                        route.id === bus.route
                          ? provinces.map((pro) =>
                              pro.id === route.to_province
                                ? pro.province_name
                                : null
                            )
                          : null
                      )}
                    </h6>
                  </div>
                  <div className="col-md-6 mb-3">
                    <h4>Queue Position</h4>
                    <h6 className="text-capitalize">{bus.bus_role}</h6>
                  </div>
                  <div className="col-md-6">
                    <h4>Current Departure Date</h4>
                    <h6>
                      {moment(bus.departure_day).format("MMMM Do, YYYY")}{" "}
                    </h6>
                  </div>
                  <div className="col-md-6">
                    <h4>Current Arrival Date</h4>
                    <h6>{moment(bus.arrivalDay).format("MMMM Do, YYYY")}</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <h1 className="text-center mb-3">Revenue</h1>
                <div className="row">
                  <div className="col-md-6">
                    <h4>Daily Revenue</h4>
                    <h6>ZMW{amount}</h6>
                    <Charts aspect={3 / 1} title="Spending ( Last 6 Months)" />
                  </div>
                  <div className="col-md-6">
                    <h4>Weekly Revenue</h4>
                    <h6>ZMW{amount}</h6>
                    <Charts aspect={3 / 1} title="Spending ( Last 6 Months)" />
                  </div>
                  <div className="col-md-6">
                    <h4>Monthly Revenue</h4>
                    <h6>ZMW{amount}</h6>
                    <Charts aspect={3 / 1} title="Spending ( Last 6 Months)" />
                  </div>
                  <div className="col-md-6">
                    <h4>Yearly Revenue</h4>
                    <h6>ZMW{amount}</h6>
                    <Charts aspect={3 / 1} title="Spending ( Last 6 Months)" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default BusDetails;
