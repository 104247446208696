import React, { useState } from "react";
import axios from "axios";
import { Reveal } from "../../reveal";
import { useAppSelector } from "../../hooks/redux-hooks";

function PostBlogComment({ id, fetchBlogs }) {
  const [isSubmitting, setisSubmitting] = useState(false);
  const user = useAppSelector((state) => state.auth.user)


  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    blog_comment: "",
  });

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setisSubmitting(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    let form_data = new FormData();
    form_data.append("full_name", formData.full_name);
    form_data.append("email", formData.email);
    form_data.append("blog_comment", formData.blog_comment);
    form_data.append("blog", id);
    form_data.append("user", user.id);

    axios
      .post(`${process.env.REACT_APP_API_URL}/blog/v1/intercity_travellers/comment/rmf_travellers`, form_data, config)
      .then((response) => {
        setisSubmitting(false);
        fetchBlogs();
        e.target.reset();
        setInterval(() => {}, 200);
      })
      .catch((error) => {
        setisSubmitting(false);
        console.error("There was an error!", error);
      });
  };

  return (
    <>
      <Reveal>
        <form onSubmit={handleSubmit} className="p-5 bg-light">
          <div className="row">
            <div className="form-group col-md-6">
              <label htmlFor="name">Full Name</label>
              <input
                type="text"
                required
                disabled={isSubmitting}
                name="full_name"
                onChange={(e) => onChange(e)}
                className="form-control"
                placeholder="Your Name"
                id="name"
              />
            </div>

            <div className="form-group col-md-6">
              <label htmlFor="email">E-mail</label>
              <input
                type="email"
                required
                disabled={isSubmitting}
                name="email"
                onChange={(e) => onChange(e)}
                className="form-control"
                placeholder="Email"
                id="email"
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="message">Comment</label>
            <textarea
              name="blog_comment"
              required
              disabled={isSubmitting}
              id="message"
              cols="30"
              rows="10"
              onChange={(e) => onChange(e)}
              placeholder="Comment"
              className="form-control"
            ></textarea>
          </div>

          <div className="form-group pull-right">
            
            <button className="btn py-3 px-4 btn-primary" type="submit"
              disabled={isSubmitting}>
                {isSubmitting && <i className="fa fa-refresh fa-spin"></i>} Post Comment
              </button>
          </div>
        </form>
      </Reveal>
    </>
  );
}

export default PostBlogComment;
