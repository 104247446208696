import axios from "axios";
import React from "react";
import { ICarRental } from "../../redux/types/car_rental.type";

const HomePageCars: React.FC = () => {
  const [cars, setCars] = React.useState<ICarRental[]>([]);
  const [isOnLine, setisOnLine] = React.useState<boolean>()

  function poopityScoop() {
    window.ononline = (event) => {
      setisOnLine(true);
      console.log("Back Online");
    };

    window.onoffline = (event) => {
      setisOnLine(false);
      console.log("Connection Lost");
    };
  }

  const fetchData = async () => {
    // view cars
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/cars/v1/intercity_travellers/car_rental/rmf_travellers/`
        )
        .then((res) => {
          setCars(res.data);
        });
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchData();
    poopityScoop();
    console.log(
      navigator.onLine === true ? setisOnLine(true) : setisOnLine(false)
    );
  }, [isOnLine]);

  if(cars.length === 0){
    return (
      <div>
      </div>
    )
  }



  const capitalized = (word: string) => {
    if (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }

    return "";
  };

  return (
    <>
      <div className="blog container">
        <div className="wrap">
          <div className="wrap_float">
            <div className="title_wrap">
              <h2 className="title">Intercity Travellers Car Rentals</h2>
              <p className="subtitle">Choose a Car to Drive Today</p>
              <div className="controls">
                <a href="/zm/car_rentals/" className="a">
                  All Cars <i className="fa fa-arrow-right"></i>
                </a>
              </div>
            </div>
            <div className="section_content">
              {cars.slice(0, 6).map((car, index) => (
                <a
                  data-aos="fade-up"
                  data-aos-delay={index * 250}
                  key={index}
                  href={`/zm/car_details/?car_id=${car.slug}`}
                  className="blog_item mb-3">
                  <div
                    className="blog_item_top"
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_API_URL}/${car.car_images[0]?.image})`,
                    }}>
                    <div className="sq_parent">
                      <div className="sq_wrap">
                        <div className="sq_content">
                        <div className="car_tags">
                        <div style={{width: 'fit-content'}} className="car_tag red text-uppercase">
                        <span style={{fontSize: '12px'}}>
                             Local Price ZMW {car?.local_price?.toLocaleString()} Per Day
                          </span>
                        </div>
                        <div style={{width: 'fit-content'}} className="car_tag blue mt-2 text-uppercase">
                          <span style={{fontSize: '12px'}}>
                             Outside Town Price ZMW {car?.outside_town?.toLocaleString()} Per Day
                          </span>
                        </div>
                        <div style={{width: 'fit-content'}} className="car_tag green mt-3 text-center text-uppercase">
                          <a className="text-white" href={`/zm/company_cars?company_id=${car?.user.id}`}>{car?.user.company_name}</a>
                        </div>
                      </div>
                          <h3 className="_title text-capitalize">
                            {car.car_model}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="shadow js-shadow"></div>
                  </div>
                  <div className="blog_item_bottom">
                    <p className="text">
                      {capitalized(car.description.substring(0, 150))}...{" "}
                      <a className="btn btn-sm btn-info" href={`/zm/car_details/?car_id=${car.slug}`}>
                        <i className="fa fa-arrow-right"></i> Read More
                      </a>
                    </p>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePageCars;
