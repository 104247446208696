import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { Reveal } from "../../reveal";
import { useForm } from "react-hook-form";
import { Rating } from "react-simple-star-rating";
import httpApiService from "../services/http.api.service";

const tooltipArray = [
  "Terrible",
  "Terrible+",
  "Bad",
  "Bad+",
  "Average",
  "Average+",
  "Great",
  "Great+",
  "Awesome",
  "Awesome+",
];
const fillColorArray = [
  "#f17a45",
  "#f17a45",
  "#f19745",
  "#f19745",
  "#f1a545",
  "#f1a545",
  "#f1b345",
  "#f1b345",
  "#f1d045",
  "#f1d045",
];

const PostReview = ({ travelId, fetchData }) => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [rating, setRating] = useState(1);

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const handlePostReview = async (data) => {
    setFormSubmitting(true);

    httpApiService
      .postHolidayPackageReview(data, rating, travelId)
      .then((response) => {
        reset();
        Swal.fire("Successful", "Review Posted Successfully", "success");
        fetchData();
        setFormSubmitting(false);
      })
      .catch((error) => {
        setFormSubmitting(false);
        Swal.fire("Error", "An Error occurred try again later!", "error");
        // console.log(formData);
        console.error("There was an error!", error);
      });
  };

  return (
    <>
      <Reveal>
        <form onSubmit={handleSubmit(handlePostReview)}>
          <div className="center">
            <Rating
              onClick={handleRating}
              transition
              allowFraction
              showTooltip
              tooltipArray={tooltipArray}
              fillColorArray={fillColorArray}
              /* Available Props */
            />

            <div className="form-group mt-4">
              <textarea
                className="form-control"
                name="review"
                rows={8}
                {...register("review", { required: true })}
                placeholder="Write Review"></textarea>
              {errors.review && (
                <p className="text-danger">Review is required</p>
              )}
            </div>
          </div>
          <div className=" pull-right">
            <button
              type="submit"
              style={{ borderRadius: "10px" }}
              className="btn btn-primary"
              disabled={formSubmitting}>
              {formSubmitting && <i className="fa fa-refresh fa-spin" />}
              {formSubmitting && (
                <span className="wc-editable text-white">Posting ....</span>
              )}
              {!formSubmitting && (
                <span className="wc-editable text-white">
                  <i className="fa fa-comment"></i> Post Review
                </span>
              )}
            </button>
          </div>
        </form>
      </Reveal>
    </>
  );
};
export default PostReview;
