import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as Home from "./components/pages";
import Layout from "./hocs/Layout";
import * as Page from "./accounts/admin/pages";
import IntercityLayout from "./accounts/admin/layout/intercityLayout";
import PageNotFound from "./components/404/404";
import * as auth from "./auth";
import * as hotel from "./accounts/hotelDashboard/pages";
import * as bus from "./accounts/commuter";
import * as holiday from "./accounts/holiday/pages";
import * as customer from "./accounts/customer/pages";
import * as reservation from "./components/buses/pages";
import * as cars from "./cars";
import * as carDashboard from "./accounts/cars_dashboard";
import AOS from "aos";
import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.css";
import { Flip, ToastContainer } from "react-toastify";
import CompanyProfile from "./accounts/company_profile";
import DefaultLayout from "./auth/defaultLayout";
import ProtectedLayout from "./auth/protectedLayout";
import CarMainLayout from './accounts/cars_dashboard/layout/car_main_layout';

const App = () => {
  React.useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
      offset: 100,
    });
    AOS.refresh();
  }, []);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        limit={1}
        transition={Flip}
      />

      <BrowserRouter>
        <Routes>
          <Route path="*" element={<PageNotFound />} />

          <Route path='/zm/auth' element={<DefaultLayout />}>
            <Route path="/zm/auth/login" element={<auth.LoginPage />} />
            <Route path="/zm/auth/register" element={<auth.RegisterUser />} />
            <Route path="/zm/auth/reset-password/:token" element={<auth.ResetPassword />}/>
            <Route path="/zm/auth/password/reset/confirm/:uid/:token" element={<auth.ResetPasswordConfirm />} />
            <Route path="/zm/auth/authenticating" element={<auth.UserRedirect />} />
          </Route>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home.HomePage />} />
            {/* <Route path="/zm/authenticating" element={<auth.UserRedirect />} /> */}
            <Route path="/zm/get_started" element={<Home.GetStarted />} />
            
            <Route path="/zm/contact-us" element={<Home.ContactUs />} />
            <Route path="/zm/about-us" element={<Home.AboutUs />} />
            <Route path="/zm/tour_places" element={<Home.AllPlaces />} />
            <Route path="/zm/bus-ticket-reservation" element={<reservation.SearchBus />} />
            <Route path="/zm/ticket/:token/:id/passengers" element={<reservation.TicketCheckout />} />
            <Route path="/zm/reservations/" element={<reservation.Reservations />} />
            <Route path="/zm/holiday-destinations" element={<Home.HolidayDestinations />} />
            <Route path="/zm/destination-details/" element={<Home.DestinationDetails />} />
            <Route path="/zm/holiday-details/:slug/:id/:token/" element={<Home.HolidayDetails />} />
            <Route path="/zm/hotels-lodges" element={<Home.Hotels />} />
            <Route path="/zm/hotel-details/" element={<Home.HotelDetails />} />
            <Route path="/zm/room_details/" element={<Home.RoomDetails />} />
            <Route path="/zm/blogs" element={<Home.Blogs />} />
            <Route path="/zm/blog-details/" element={<Home.BlogDetails />} />
            <Route path="/zm/zambia-tourism" element={<Home.Tourism />} />
            <Route path="/zm/province/" element={<Home.ProvinceDetails />} />
            <Route path="/terms" element={<Home.TermsConditions />} />
            <Route path="/zm/privacy-policy" element={<Home.PrivacyPolice />} />
            <Route path="/zm/district/" element={<Home.DistrictDetails />} />
            <Route path="/zm/car_rentals/" element={<cars.AllCars />} />
            <Route path="/zm/car_details/" element={<cars.CarDetails />} />
            <Route path="/zm/company_cars" element={<cars.CompanyCars />} />
          </Route>
          
          {/* ADMIN ROUTES STARTS */}
          {/* <Route
              path="/zm/intercity_travellers_admin"
              element={(props: any) => <AuthController {...props}></AuthController>}
            /> */}

          <Route path="/zm/intercity_travellers_admin" element={<IntercityLayout />}>
            <Route index element={<Page.Admin />} />
            <Route path="/zm/intercity_travellers_admin/edit-district/:slug/:id" element={<Page.EditDistrict />}/>
            <Route path="/zm/intercity_travellers_admin/blogs" element={<Page.MaBlogs />} />
            <Route path="/zm/intercity_travellers_admin/edit_blog/:token/:id/:slug" element={<Page.EditmaBlogs />}/>
            <Route path="/zm/intercity_travellers_admin/pro/:slug/:id" element={<Page.EditDis />}/>
            <Route path="/zm/intercity_travellers_admin/edit/:slug/:id" element={<Page.EditProvince />}/>
            <Route path="/zm/intercity_travellers_admin/terms/:id" element={<Page.MaTerms />}/>
            <Route path="/zm/intercity_travellers_admin/privacy/:id" element={<Page.MaPrivacy />}/>
            <Route path="/zm/intercity_travellers_admin/commutors" element={<Page.Commuters />}/>
            <Route path="/zm/intercity_travellers_admin/hotels" element={<Page.Hotels />}/>
            <Route path="/zm/intercity_travellers_admin/registration_requests" element={<Page.RegistrationRequests />}/>
            <Route path="/zm/intercity_travellers_admin/commutor-routes" element={<Page.CommutorRoutes />}/>
            <Route path="/zm/intercity_travellers_admin/places" element={<Page.MaPlaces />}/>
            <Route path="/zm/intercity_travellers_admin/referals/:id" element={<Page.MaReferals />}/>
            <Route path="/zm/intercity_travellers_admin/edit_account/:uid" element={<Page.editCompanies />}/>
            <Route path="/zm/intercity_travellers_admin/register_referal_account" element={<Page.registerReferal />} />
            <Route path="/zm/intercity_travellers_admin/referal_accounts" element={<Page.ReferalAccounts />}/>
            <Route path="/zm/intercity_travellers_admin/view_account_details/:uid" element={<Page.ReferalUserDetails />}/>
            <Route path="/zm/intercity_travellers_admin/cars" element={<Page.CarHireCompanies />}/>
          </Route>
          {/* CAR HIRE DASHBOARD ROUTES */}
          <Route path="/zm/car_dashboard" element={<CarMainLayout />}>
            <Route index element={<carDashboard.CarHomePage />} />
            <Route path="/zm/car_dashboard/cars" element={<carDashboard.CarSection />}/>
            <Route path="/zm/car_dashboard/edit_car/" element={<carDashboard.EditCar />}/>
            <Route path="/zm/car_dashboard/customer_bookings" element={<carDashboard.CarReservation />}/>
            <Route path="/zm/car_dashboard/customers" element={<carDashboard.Customers />}/>
            <Route path="/zm/car_dashboard/customer_profile/:token" element={<carDashboard.CustomerProfile />}/>
            <Route path="/zm/car_dashboard/profile/" element={<carDashboard.CompanyProfile />}/>
            <Route path="/zm/car_dashboard/privacy_policy/:token/:uid" element={<carDashboard.CarRentalPrivacy />}/>
            <Route path="/zm/car_dashboard/terms_of_service/:token/:uid" element={<carDashboard.CarRentalTerms />}/>
          </Route>
          {/* start hotel routes */}
          <Route element={<ProtectedLayout />}>
            <Route path="/zm/hotel_dashboard" element={<hotel.HotelMainLayout />}>
              <Route index element={<hotel.HotelDashboard />} />
              <Route path="/zm/hotel_dashboard/hotel_branches" element={<hotel.HotelBranches />}/>
              <Route path="/zm/hotel_dashboard/view_hotels" element={<hotel.HotelBranches />}/>
              <Route path="/zm/hotel_dashboard/hotel_rooms" element={<hotel.Branch />}/>
              <Route path="/zm/hotel_dashboard/privacy_policy" element={<hotel.HotelLodgePrivacyPolicy />}/>
              <Route path="/zm/hotel_dashboard/terms_of_service" element={<hotel.HotelLodgeTermsConditions />}/>
              <Route path="/zm/hotel_dashboard/view_hotel_rooms" element={<hotel.GetHotelRooms />}/>
              <Route path="/zm/hotel_dashboard/edit_hotel" element={<hotel.EditBranch />}/>
              <Route path="/zm/hotel_dashboard/edit_room" element={<hotel.EditBranchRooms />}/>
              <Route path="/zm/hotel_dashboard/hotel_reservations" element={<hotel.HotelReservations />}/>
              <Route path="/zm/hotel_dashboard/customers" element={<hotel.Customers />}/>
              <Route path="/zm/hotel_dashboard/customer_profile" element={<hotel.CustomerProfile />}/>
              <Route path="/zm/hotel_dashboard/profile" element={<CompanyProfile />}/>
            </Route>
          </Route>

          {/* end hotel routes */}
          {/* start commuters routes */}
          <Route path="/zm/print_customer_tickets/:token/:uid" element={<bus.PrintTickets />}/>
          <Route path="/zm/dashboard" element={<bus.MainLayout />}>
            <Route index element={<bus.Dashboard />} />
            <Route path="/zm/dashboard/buses/:token/:uid" element={<bus.Buses />}/>
            <Route path="/zm/dashboard/bus_details/:token/:uid/:busid" element={<bus.BusDetails />}/>
            <Route path="/zm/dashboard/stations/:token/:uid" element={<bus.Stations />}/>
            <Route path="/zm/dashboard/routes/:token/:uid" element={<bus.BusRoutes />}/>
            <Route path="/zm/dashboard/profile/" element={<bus.CompanyProfile />}/>
            <Route path="/zm/dashboard/busfares/:token/:uid" element={<bus.BusFares />}/>
            <Route path="/zm/dashboard/customer-tickets/:token/:uid" element={<bus.CustomerTickets />}/>
            <Route path="/zm/dashboard/cancelled-tickets/:token/:uid" element={<bus.CancelledTickets />}/>
            {/* <Route path="/zm/dashboard/edit_bus/:token/:id/:uid" element={<bus.EditBus />}/>
            <Route path="/zm/dashboard/edit_bus_fare/:token/:id/:uid" element={<bus.EditBusFares />}/> */}
            <Route path="/zm/dashboard/ticket_details/:token/:ticket_no/:uid" element={<bus.TicketDetails />}/>
            <Route path="/zm/dashboard/employees/:token/:uid" element={<bus.Employees />}/>
            <Route path="/zm/dashboard/employee_details/:token/:uid" element={<bus.EmployeeDetails />}/>
          </Route>
          {/* end commuters routes */}
          {/* HOLIDAY DASHBOARD ROUTES STARTS */}
          <Route path="/zm/holiday_dashboard" element={<holiday.HolidayLayout />}>
            <Route index element={<holiday.HolidayDashboard />} />
            <Route path="/zm/holiday_dashboard/countries/" element={<holiday.HolidayCountries />}/>
            <Route path="/zm/holiday_dashboard/holiday_packages/" element={<holiday.HolidayPackages />}/>
          </Route>
          {/* HOLIDAY DASHBOARD ROUTES ENDS */}
          {/* CUSTOMER ROUTES STARTS */}
          <Route path="/zm/profile" element={<customer.UserMainLayout />}>
            <Route index element={<customer.CustomerHomePage />} />
            <Route path="/zm/profile/rooms" element={<customer.UserRooms />}/>
            <Route path="/zm/profile/places" element={<customer.Places />}/>
            <Route path="/zm/profile/cars" element={<customer.CarRentals />}/>
            <Route path="/zm/profile/delete_account" element={<Home.DeleteAccount />} />
          </Route>
          {/* CUSTOMER ROUTES ENDS */}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
