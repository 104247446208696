import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import LoadingSpinner from "../../components/widgets/spinner";
import PostBlog from "./post_blog";
import { urlToken } from "../../helper";

function MaBlogs() {
  const [isLoading, setIsLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);

  const fetchBlogs = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
      Accept: "application/json",
    }
    try {
      setIsLoading(true);
      
      await axios.get(
        `${process.env.REACT_APP_API_URL}/blog/v1/admin_blogs/`,
          headers
      ).then((response)=> {
        setBlogs(response.data);
      setIsLoading(false);
      });
      
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      
    }
    
  };
  useEffect(() => {
    fetchBlogs();

  }, []);
  return (
    <div className="py-4">
      <h1 className="text-center mb-4">it's a PWIKU</h1>

      <PostBlog fetchBlogs={fetchBlogs} />
      {isLoading ? (
        <>
          <LoadingSpinner />
        </>
      ) : (
        <div>
          <div className="container">
            <div className="row">
              {blogs.map((blog, index) => (
                <div key={index} className="col-md-4 mb-3 display-img">
                  <img
                    style={{
                      width: "100%",
                      height: "250px",
                      borderRadius: "5px",
                    }}
                    src={`${process.env.REACT_APP_API_URL}/${blog.image}`}
                    className="tour-img"
                    alt={blog.blog_title}
                  />
                  <h6 className="text-capitalize">
                    <i className="fa fa-user"></i> By {blog.blog_author},
                    <i className="fa fa-watch"></i>
                    {blog.created_aat
                      ? moment(blog.created_aat).format("MMMM Do YYYY H:m a")
                      : ""}
                  </h6>
                  <h5>
                    <span className="text-info text-capitalize">
                      {" "}
                      {blog.blog_title}{" "}
                    </span>
                  </h5>
                  <i>
                    {blog.blog_body.substring(0, 150)}
                    <Link
                      to={`/zm/blog-details/${urlToken}/${blog.id}/${blog.slug}`}
                    >
                      {" "}
                      <i className="fa fa-arrow-right"></i> Continue Reading
                    </Link>
                  </i>
                  <div className="row">
                    <div className="col-md-6 pull-right">
                      <Link
                        to={`/zm/intercity_travellers_admin/edit_blog/${urlToken}/${blog.id}/${blog.slug}`}
                        className="btn btn-sm btn-outline-info"
                      >
                        <i className="fa fa-pencil"></i> Edit
                      </Link>
                    </div>
                    {/* <div className="col-md-6">
                                          <button className="btn btn-sm btn-outline-danger"><i className="fa fa-trash"></i> Delete</button>
                                      </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MaBlogs;
