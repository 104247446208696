import React from "react";
import { ICarRental } from "../../redux/types/car_rental.type";

interface ICarCard {
  item: ICarRental;
  is_company?: boolean;
}

const CarCard: React.FC<ICarCard> = ({ item, is_company }) => {
  const capitalized = (word: string) => {
    if (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }

    return "";
  };
  return (
    <>
      <a
        href={`/zm/car_details/?car_id=${item.slug}`}
        className="story_item"
        style={{
          backgroundImage: `url(${
            !is_company
              ? `${process.env.REACT_APP_API_URL}/${item.car_images[0]?.image}`
              : item.car_images[0]?.image
          })`,
        }}>
        <div className="item_wrap">
          <div className="_content">
            <div className="flag_wrap">
              <div style={{width: 'fit-content'}} className="tags">
                <div style={{width: 'fit-content'}} className="tag bg-info px-2 text-uppercase text-white rounded">
                  <a
                    className="text-white"
                    href={`/zm/company_cars?company_id=${item.user.id}`}>
                   <i className="fa fa-car"></i> {item.user.company_name}
                  </a>
                </div>
                {/* <div className="tag bg-warning mt-5 text-uppercase text-white rounded">
                  <b>{item.car_model}</b>
                </div> */}
              </div>
              {/* <div
                style={{ width: "fit-content" }}
                className="tags text-center">
                <div className="tag bg-danger rounded mt-2 p-1 text-white red text-uppercase">
                  <span style={{ fontSize: "12px" }}>
                    ZMW {item.local_price?.toLocaleString()} Per Day
                  </span>
                </div>
              </div> */}
            </div>
            <h6 className="country text-capitalize">{item.car_model}</h6>
            <p className="text">
              {capitalized(item.description.substring(0, 150))}
            </p>
          </div>
        </div>
        <div className="shadow js-shadow"></div>
      </a>
    </>
  );
};

export default CarCard;
