import axios from "axios";
import http from "./http.common";

class ApiService {
  async getCarRentals() {
    return await http.get(
      `/cars/v1/intercity_travellers/car_rental/rmf_travellers/`
    );
  }

  async getCarCompanies() {
    return await http.get(
      `/userprofile/v1/intercity_travellers/get-car-companies/rmf_travellers`
    );
  }

  async getHolidayPackages() {
    return await http.get(
      `/holidays/v1/intercity_travellers/view_travels/rmf_travellers`
    );
  }
  async getCountry(country) {
    return await http.get(
      `/api/v1/intercity_travellers/view_holiday_country/${country}/rmf_travellers`
    );
  }

  // GET DESTINATIONS BY COUNTRY
  async getCountryDestinations(country) {
    return await http.get(
      `/holidays/v1/intercity_travellers/view_destinations/${country}/rmf_travellers`
    );
  }

  async getCountries() {
    return await http.get(
      `/api/v1/intercity_travellers/view_holiday_countries/rmf_travellers`
    );
  }

  // FETCH BLOGS
  async fetchBlogs() {
    return await http.get(`/blog/v1/intercity_travellers/blogs/rmf_travellers`);
  }

  // FETCH PROVINCES
  async fetchProvinces() {
    return await http.get(
      `/api/v1/intercity_travellers/home/rmf_travellers`
    );
  }

  // POST HOLIDAY PACKAGE  REVIEW
  async postHolidayPackageReview(data, rating, travelId) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    let form_data = new FormData();
    // form_data.append("user", user);
    form_data.append("review", data.review);
    form_data.append("travel", travelId);
    form_data.append("rating", rating);
    return await http.post(
      `/holidays/v1/intercity_travellers/create_review/rmf_travellers`,
      form_data,
      config
    );
  }
}

export default new ApiService();
