import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../components/widgets/spinner";

class ReferalAccounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubmitting: false,
      isSubmitting: false,
      isLoading: true,
      company: [],
    };
  }
  componentDidMount = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    await axios
      .get(`${process.env.REACT_APP_API_URL}/userprofile/v1/intercity_travellers/get-commuters/rmf_travellers`, config)
      .then((res) => {
        const company = res.data;
        this.setState({
          company,
          isLoading: false,
        });
      });
  };

  render() {
    return (
      <>
          <div className="pull-right mt-3 mb-4">
            <Link
              to="/zm/intercity_travellers_admin/register_referal_account"
              className="btn btn-info"
            >
              Create Referal Account
            </Link>
          </div>
          <div id="content" className="p-2 p-md-3 pt-3">
            <h1 className="text-center">Referal Accounts</h1>
            <div className="">
              {this.state.isLoading ? (
                <LoadingSpinner />
              ) : this.state.company.length === 0 ? (
                <div className="alert alert-danger text-capitalize">
                  No Referal Account created
                </div>
              ) : (
                <div className="table-responsive">
                  <table class="table table-hover text-dark">
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>E-mail</th>
                        <th>Phone Number</th>
                        <th>Commission</th>
                        {/* <th>Date Created</th> */}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.company.map((res, index) =>
                        res.is_referaluser === true ? (
                          <tr key={index}>
                            <td className="text-capitalize">
                              {res.first_name}
                            </td>
                            <td className="text-capitalize">{res.last_name}</td>
                            <td>{res.email}</td>
                            <td>{res.phone}</td>
                            <td>K{res.pending_commission}</td>
                            {/* <td>
                          {moment(res.created_at).format(
                            "MMMM Do YYYY H:m a"
                          )}
                        </td> */}
                            <td>
                              <a
                                href={`/zm/intercity_travellers_admin/view_account_details/${res.id}`}
                              >
                                <i className="fa fa-pencil"></i> View Account
                              </a>
                            </td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
      </>
    );
  }
}

export default ReferalAccounts;
