import React from "react";
import { ICarRental } from "../../../../redux/types/car_rental.type";
import CreateCar from "./create_car";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import CarDetailsModal from "./car_details_modal";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { useGetAdminCarsQuery } from "../../../../redux/slices/admin_hire_slice";

function CarSection() {
  const [page, setPage] = React.useState<number>(1);
  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [details, setDetails] = React.useState<ICarRental | null>(null);
  // DETAILS MODAL
  const handleShow = (item: ICarRental) => {
    setDetails(item);
    setShowDetailsModal(true);
  };
  const handleCloseDetailsModel = () => {
    setShowDetailsModal(false);
  };
  const user = useAppSelector((state) => state.auth.user);
  const {
    data: cars,
    isLoading,
    error,
  }: ICarRental | any = useGetAdminCarsQuery({
    company: user?.id,
  });

  if (isLoading) {
    return (
      <div style={{ marginTop: "10%" }} className="text-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <div className="ImageSection container">
        <CreateCar user_id={user?.id} />
        <h2 className="mt-3 text-center mb-4">Car Rentals</h2>
        <div className="row">
          {error ? (
            <center>
              <div
                className="alert alert-danger text-center mt-5 custom-length"
                role="alert">
                An error occurred while loading the application
              </div>
            </center>
          ) : cars.length === 0 ? (
            <center>
              <div className="alert alert-info custom-length" role="alert">
                <i className="fa fa-car fa-5x"></i>
                <h1>No Cars Found</h1>
              </div>
            </center>
          ) : (
            cars.map((car: ICarRental, index: number) => (
              <div key={index} className="col-md-4 mb-3 shadow">
                <div className="img">
                  <img
                    className="img-fluid rounded"
                    src={`${car.car_images[0]?.image}`}
                    alt=""
                  />
                </div>
                <div className="about-info">
                  <h6>
                    <span className="text-capitalize"> {car.car_model} </span>
                  </h6>
                  <p className="about-text">
                    {car.description.substring(0, 100)}...
                  </p>
                  <div className="row">
                    <div className="col-md-4">
                      <p>Seats:</p>
                      <p>Music:</p>
                      <p>Brand:</p>
                    </div>
                    <div className="col-md-8">
                      <p className="text-capitalize">{car.seats} seats</p>
                      <p>{car.music_type}</p>
                      <p className="text-uppercase">
                        {car.car_brand.brand_name}
                      </p>
                    </div>
                  </div>
                  <div className="pull-right mb-3">
                    <button
                      onClick={() => handleShow(car)}
                      className="btn btn-sm btn-info">
                      <i className="fa fa-eye"></i> Details
                    </button>
                    <a
                      href={`/zm/car_dashboard/edit_car/?car_id=${car.id}`}
                      className="btn btn-sm btn-primary">
                      <i className="fa fa-pencil"></i> Edit
                    </a>
                  </div>
                </div>
                <CarDetailsModal
                  show={showDetailsModal}
                  item={details === null ? car : details}
                  handleClose={handleCloseDetailsModel}
                />
              </div>
            ))
          )}
        </div>
      </div>
      {/* <div className="text-center col-md-4">
        <nav aria-label="..." className="justify-content-center mt-5 mb-5">
          <button className="btn btn-success" onClick={() => setPage(page + 1)}>
            <i className="fa fa-chevron-circle-down" aria-hidden="true"></i>
            <span></span> Load More ....
          </button>
        </nav>
      </div> */}
    </>
  );
}

export default CarSection;
