import http from "./http.common";

class DashbordRoomServices {
  // CREATE BRANCH
  async createRoom(
    formData: any,
    breakFast: any,
    lunch: any,
    supper: any,
    gallery: any[],
    roomNumbers: any[],
    amenities: any[],
    uid: any,
    branch: any
  ) {
    let form_data = new FormData();
    form_data.append("user", uid);
    form_data.append("hotel", branch);
    form_data.append("room_name", formData.room_type);
    form_data.append("room_occupation", formData.room_occupation);
    form_data.append("room_images", JSON.stringify([...gallery]));
    form_data.append("room_numbers", JSON.stringify([...roomNumbers]));
    form_data.append("amenities", JSON.stringify([...amenities]));
    form_data.append("room_price", formData.room_price);
    form_data.append("room_description", formData.room_description);
    form_data.append("room_type", formData.room_type);
    form_data.append("includeBreakFast", breakFast);
    form_data.append("includeLunch", lunch);
    form_data.append("includeSupper", supper);
    form_data.append("breakFastPrice", formData.breakFastPrice ?? 0);
    form_data.append("lunchPrice", formData.lunchPrice ?? 0);
    form_data.append("supperPrice", formData.supperPrice ?? 0);
    return await http.post(
      `/hotels/v1/intercity_travellers/create_room/rmf_travellers`,
      form_data
    );
  }

  // SUBMIT BRANCH IMAGES
  async submitRoomImage(body: any) {
    return await http.post(
      `/hotels/v1/intercity_travellers/room_images/rmf_travellers`,
      body
    );
  }

  // delete branch
  async deleteRoom(id: number) {
    return await http.delete(`/api/v1/peza/admin/bondi/${id}/`);
  }

  async updateRoom(
    formData: any,
    details: any,
    amenities: any,
    breakFast: any,
    lunch: any,
    supper: any,
    room: any
  ) {
    let form_data = new FormData();
    form_data.append("room_name", formData.room_type);
    form_data.append("room_occupation", formData.room_occupation);

    form_data.append(
      "amenities",
      JSON.stringify([...details.amenities].concat([...amenities]))
    );
    form_data.append("room_price", formData.room_price);
    form_data.append("room_description", formData.room_description);
    form_data.append("room_type", formData.room_type);
    form_data.append("includeBreakFast", breakFast);
    form_data.append("includeLunch", lunch);
    form_data.append("includeSupper", supper);
    form_data.append("breakFastPrice", formData.breakFastPrice);
    form_data.append("lunchPrice", formData.lunchPrice);
    form_data.append("supperPrice", formData.supperPrice);
    return await http.patch(
      `/hotels/v1/intercity_travellers/edit_room/${room}/rmf_travellers`
    );
  }

  async getRoom(room: number) {
    return await http.get(
      `/hotels/v1/intercity_travellers/edit_room/${room}/rmf_travellers`
    );
  }

  // UPDATE ROOM STATUS
  async updateRoomStatus(body: any, room: number) {
    const data = {
      roomReserved: body,
    };
    return await http.patch(
      `/hotels/v1/intercity_travellers/edit_room/${room}/rmf_travellers`,
      data
    );
  }

  async fetchBranchRooms(branch: any) {
    return await http.get(
      `/hotels/v1/intercity_travellers/hotel_rooms/${branch}/rmf_travellers`
    );
  }
  // FETCH BRANCH DETAILS
  async fetchBranchDetails(branch: number) {
    return await http.get(
      `/hotels/v1/intercity_travellers/edit_hotel/${branch}/rmf_travellers`
    );
  }
  // ADD UPDATE ROOM NUMBERS
  async upateAddRoomNumber(formData: any) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    // console.log(formData);
    return await http.post(
      `/hotels/v1/intercity_travellers/room_numbers/rmf_travellers/`,
      formData,
      config
    );
  }
}

export default new DashbordRoomServices();
