import React, { useState } from "react";
import { connect } from "react-redux";
import { signup } from "../../actions/auth";
import { errorHandler } from "../../helper";
import axios from "axios";
import Swal from "sweetalert2";

function RegisterReferalAccount() {
  const [accountCreated, setAccountCreated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    company_name: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    is_referaluser: true,
    password: "",
    re_password: "",
  });

  const {
    company_name,
    first_name,
    last_name,
    email,
    phone,
    is_referaluser,
    password,
    re_password,
  } = formData;

  const [passwordError, setPasswordError] = useState({
    password: "",
    re_password: "",
  });
  const onChange = (e) =>
    setFormData(
      { ...formData, [e.target.name]: e.target.value },
      validateInput(e)
    );

  const validateInput = (e) => {
    let { name, value } = e.target;
    setPasswordError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "email":
          if (!value) {
            stateObj[name] = "Please enter Email.";
          }
          break;

        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (formData.re_password && value !== formData.re_password) {
            stateObj["re_password"] =
              "Password and Confirm Password does not match.";
          } else {
            stateObj["re_password"] = formData.re_password
              ? ""
              : error.re_password;
          }
          break;

        case "re_password":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (formData.password && value !== formData.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      first_name,
      last_name,
      email,
      phone,
      is_referaluser,
      password,
      re_password,
    });

    try {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/auth/users/`, body, config)
        .then((res) => {
          console.log(res.data);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Accounted Created Successfully",
          });
        })
        .catch((er) => {
          console.log(er.message);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: errorHandler(er),
          });
        });
    } catch (err) {
      console.log(err.message);
    }
    setAccountCreated(true);
    setLoading(false);
  };

  const continueWithGoogle = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/o/google-oauth2/?redirect_uri=${process.env.REACT_APP_API_URL}/google`
      );

      window.location.replace(res.data.authorization_url);
    } catch (err) {}
  };

  const continueWithFacebook = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/o/facebook/?redirect_uri=${process.env.REACT_APP_API_URL}/facebook`
      );

      window.location.replace(res.data.authorization_url);
    } catch (err) {}
  };

  // if (isAuthenticated) {
  //     return <Redirect to='/admin_reuben_i4cus' />
  // }
  // if (accountCreated) {
  //     return <Redirect to='/login' />
  // }

  return (
    <>
      <div className="container py-5 text-center">
        <div className="inner p-2 p-md-3 pt-3">
          <h4 className="mt-5">Create Referal User</h4>
          {error && (
            <div className="alert alert-warning">
              <i className="fa fa-warning"></i> {error}
            </div>
          )}
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="form-group input-container">
              <input
                className="form-control input-field"
                type="text"
                placeholder="Company Name*"
                name="company_name"
                value={company_name}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="form-group input-container">
              <input
                className="form-control input-field"
                type="text"
                placeholder="First Name*"
                name="first_name"
                value={first_name}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="form-group input-container">
              <input
                className="form-control input-field"
                type="text"
                placeholder="Last Name*"
                name="last_name"
                value={last_name}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="form-group input-container">
              <input
                className="form-control input-field"
                type="email"
                placeholder="Email*"
                name="email"
                value={email}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="form-group input-container">
              <input
                className="form-control input-field"
                type="number"
                placeholder="Phone Number *"
                name="phone"
                value={phone}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <input
              className="form-control input-field"
              type="hidden"
              placeholder="is Commutor"
              name="is_referaluser"
              value={is_referaluser}
              onChange={(e) => onChange(e)}
            />
            <div className="form-group input-container">
              <input
                className="form-control input-field"
                type="password"
                placeholder="Password*"
                name="password"
                value={password}
                onChange={(e) => onChange(e)}
                minLength="6"
                required
                onBlur={validateInput}
              />
              {passwordError.password && (
                <div className="alert alert-warning">
                  <i className="text-danger fa fa-info"></i>
                  {passwordError.password}
                </div>
              )}
            </div>
            <div className="form-group input-container">
              <input
                className="form-control input-field"
                type="password"
                placeholder="Confirm Password*"
                name="re_password"
                value={re_password}
                onChange={(e) => onChange(e)}
                minLength="6"
                required
                onBlur={validateInput}
              />
              {passwordError.re_password && (
                <div className="alert alert-warning">
                  <i className="text-danger fa fa-info"></i>{" "}
                  {passwordError.re_password}
                </div>
              )}
            </div>

            <div className="form-group mt-3 pull-right">
              <button
                disabled={loading}
                className="btn btn-primary"
                type="submit"
              >
                {loading ? (
                  <center>
                    <i className="fa fa-refresh fa-spin"></i> Registering
                    Account....
                  </center>
                ) : (
                  "Register User"
                )}
              </button>
            </div>
          </form>
          {/* <div className='row'>
                <div className='col-md-6'>
                    <button className='btn btn-danger mt-3' onClick={continueWithGoogle}>
                <i className='fa fa-google'></i> Continue With Google
                    </button>
                </div>
                <div className='col-md-6'>
                    
            <button className='btn btn-primary mt-3' onClick={continueWithFacebook}>
            <i className='fa fa-facebook'></i> Continue With Facebook
            </button>
                </div>
            </div> */}
          {/* <p className='mt-3'>
                Already have an account? <Link to='/login'>Sign In</Link>
            </p> */}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { signup })(RegisterReferalAccount);
