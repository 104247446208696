import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../navigation/Sidebar";
import TopNav from "../navigation/top_nav";

const IntercityLayout = () => {
  return (
    <>
      <div className="main-wrapper">
          <TopNav />
          <Sidebar />
          <div className="page-wrapper">
            <Outlet />
        </div>
      </div>
    </>
  );
};

export default IntercityLayout;
