import React from "react";
import HomeSlider from "../carousel/home_carousel";
import { homeSlides } from "../carousel/carousel_data";
const HeroSection: React.FC = () => {
  return (
    <>
      <HomeSlider slides={homeSlides} />
    </>
  );
};
export default HeroSection;
