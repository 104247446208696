import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import AddEmployee from "./add_employee";
import AddDepartment from "./add_department";
import moment from "moment";
import { useParams } from "react-router-dom";
import axios from "axios";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import LoadingSpinner from "../../../../components/widgets/spinner";

const Employees = () => {
  const [departments, setDepartments] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [number, setNumber] = useState(1); // No of pages
  const [employeesPerPage] = useState(50);
  const [q, setQ] = useState("");
  const [searchParam] = useState(["employeeName", "phoneNumber", "email"]);
  const [filterParam, setFilterParam] = useState(["All"]);
  const { uid } = useParams();

  const fetchData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    //get company buses
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/get_departments/${uid}/rmf_travellers`,
          config
        )
        .then((res) => {
          setDepartments(res.data);
          setIsLoading(false);
        });
    } catch (error) {
      // alert(error.message);
    }
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/get_employees/${uid}/rmf_travellers`,
          config
        )
        .then((res) => {
          setEmployees(res.data);
          // setIsLoading(false);
        });
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, []);

  // pagination
  const lastEmployee = number * employeesPerPage;
  const firstEmployee = lastEmployee - employeesPerPage;
  const currentEmployees = employees.slice(firstEmployee, lastEmployee);
  const pageNumber = [];

  for (let i = 1; i <= Math.ceil(employees.length / employeesPerPage); i++) {
    pageNumber.push(i);
  }

  const ChangePage = (pageNumber) => {
    setNumber(pageNumber);
  };
  const data = Object.values(currentEmployees);

  function search(employees) {
    return employees.filter((item) => {
      if (item.department == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }

  return (
    <>
      <div className="py-5 container-fluid">
        <h1 className="mt-5 text-center">Employees</h1>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-4">
              <AddEmployee departments={departments} uid={uid} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-4 pull-right">
              <AddDepartment uid={uid} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="shadow border-0 rounded-3 p-3">
              <h3>Employees</h3>
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <h6>Search:</h6>

                    <input
                      type="text"
                      className="form-control bg-transparent "
                      placeholder="search...."
                      value={q}
                      onChange={(e) => setQ(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3 form-group">
                    <h6>Filter By Department:</h6>
                    <select
                      onChange={(e) => {
                        setFilterParam(e.target.value);
                      }}
                      name="department"
                      required
                      className="form-control"
                      aria-label="Filter Employee by Department"
                    >
                      <option value="All">All</option>
                      {departments.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.departmentName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/*	Error Message*/}
              </div>
              <div className="table-responsive">
                {isLoading ? (
                  <LoadingSpinner />
                ) : employees.length === 0 ? (
                  <div className="alert alert-danger text-center">
                    No Employees
                  </div>
                ) : (
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Phone Number</th>
                        <th>Department</th>
                        <th>Position</th>
                        <th>Salary</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {search(data).map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.employeeName}</td>
                          <td>{item.phoneNumber}</td>
                          <td>
                            {departments.map((data) =>
                              data.id === item.department
                                ? data.departmentName
                                : ""
                            )}
                          </td>
                          <td>{item.position}</td>
                          <td>ZMW {item.salary}</td>
                          <td>
                            <button className="btn btn-sm btn-info">
                              Details
                            </button>
                          </td>
                          <td>
                            <button className="btn btn-sm btn-primary">
                              <i className="fa fa-edit"></i> Edit
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </div>
              <div className="pull-right">
                {data.length < 50 ? null : (
                  <ButtonToolbar aria-label="Toolbar with button groups">
                    <ButtonGroup className="me-2" aria-label="First group">
                      <Button
                        disabled={number === 1}
                        onClick={() => setNumber(number - 1)}
                      >
                        <i className="fa fa-chevron-left"></i>
                      </Button>
                    </ButtonGroup>
                    <ButtonGroup className="me-2" aria-label="Second group">
                      {pageNumber.map((item, index) => {
                        return (
                          <Button
                            className={`text-center btn btn-info text-white ${
                              number === item ? "active" : "btn btn-info"
                            }`}
                            key={index}
                            onClick={() => ChangePage(item)}
                          >
                            {item}
                          </Button>
                        );
                      })}
                    </ButtonGroup>
                    <ButtonGroup aria-label="Third group">
                      <Button onClick={() => setNumber(number + 1)}>
                        {" "}
                        <i className="fa fa-chevron-right"></i>
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="shadow border-0 rounded-3 p-3">
              <h2>Departments</h2>
              {isLoading ? (
                <LoadingSpinner />
              ) : departments.length === 0 ? (
                <div className="alert alert-danger text-center">
                  No Departments
                </div>
              ) : (
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Department Name</th>
                      <th>Created on</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {departments.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <small>{item.departmentName}</small>
                        </td>
                        <td>
                          {moment(item.created_at).format("MMMM Do, YYYY")}
                        </td>
                        <td>
                          <button className="btn btn-sm btn-primary">
                            <i className="fa fa-edit"></i> Edit
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Employees;
