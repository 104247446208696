import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import AddBus from "./add_bus";
import EditBus from "./editBus";
import LoadingSpinner from "../../../components/widgets/spinner";
import moment from "moment";
import { urlToken } from "../../../helper";

const Buses = () => {
  const [buses, setBuses] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { uid } = useParams();

  const fetchData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    //get company buses
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/buses/${uid}/rmf_travellers`, config)
        .then((res) => {
          setBuses(res.data);
          setIsLoading(false);
        });
    } catch (error) {
      // alert(error.message);
    }
    //get provinces
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/home/rmf_travellers`, config)
        .then((res) => {
          setProvinces(res.data);
          // setIsLoading(false);
        });
    } catch (error) {}
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/bus-routes/${uid}/rmf_travellers`, config)
        .then((res) => {
          setRoutes(res.data);
          // setIsLoading(false);
        });
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="py-5 mt-4">
            <h3 className="text-capitalize">my buses</h3>

            <AddBus
              routes={routes}
              provinces={provinces}
              fetchBuses={fetchData}
            />

            {/* <!-- loading buses table --> */}
            <div className="table-responsive my-4">
              {isLoading ? (
                <LoadingSpinner />
              ) : buses.length === 0 ? (
                <div className="alert alert-danger text-center">
                  There are no Buses
                </div>
              ) : (
                <table
                  id="example"
                  className="table table-hover table-striped table-lg"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Route</th>
                      <th>Number Plate</th>
                      <th>Seat Capacity</th>
                      <th>Driver</th>
                      <th>Departure Date</th>
                      <th>Arrival Date</th>
                      <th>Queue</th>
                      <th colSpan="2">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {buses.map((bus, index) => (
                      <tr key={index}>
                        <td className="col-auto">
                          <p className="font-bold ms-3 mb-0">{index + 1}</p>
                        </td>
                        <td className="col-auto">
                          <p className="mb-0 text-capitalize">
                            {routes.map((route) =>
                              route.id === bus.route
                                ? provinces.map((pro) =>
                                    pro.id === route.from_province
                                      ? pro.province_name
                                      : null
                                  )
                                : null
                            )}{" "}
                            -{" "}
                            {routes.map((route) =>
                              route.id === bus.route
                                ? provinces.map((pro) =>
                                    pro.id === route.to_province
                                      ? pro.province_name
                                      : null
                                  )
                                : null
                            )}
                          </p>
                        </td>
                        <td className="col-auto text-uppercase">
                          <p className=" mb-0">{bus.number_place}</p>
                        </td>
                        <td className="col-auto">
                          <p className="mb-0">{bus.seat_capacity} seats</p>
                        </td>
                        <td className="col-auto text-capitalize">
                          <p className="mb-0">{bus.driver}</p>
                        </td>
                        <td className="col-auto text-capitalize">
                          <p className=" mb-0">
                            {moment(bus.departure_day).format("MMMM Do, YYYY")}{" "}
                          </p>
                        </td>
                        <td className="col-auto text-capitalize">
                          <p className=" mb-0">
                            {" "}
                            {moment(bus.arrivalDay).format("MMMM Do, YYYY")}
                          </p>
                        </td>
                        <td className="col-auto text-capitalize">
                          <p className="mb-0">{bus.bus_role}</p>
                        </td>
                        <td>
                          <EditBus
                            bus={bus}
                            routes={routes}
                            provinces={provinces}
                          />
                        </td>
                        <td>
                          <a
                            className="btn btn-sm btn-primary"
                            href={`/zm/dashboard/bus_details/${urlToken}/${uid}/${bus.id}`}
                          >
                            <i className="fa fa-info-circle"></i> Details
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
          {/* <!-- end of loading buses table --> */}
        </div>
      </div>
    </>
  );
};

export default Buses;
