import axios from "axios";
import React, { useRef, useState } from "react";
import bg1 from "../../images/contact.jpg";
import WeAre from "../widgets/weAre";
import { Spinner } from "react-bootstrap";

const ContactUs = () => {
  const [sendMessage, setSendMessage] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [isSending, setisSending] = useState(false);
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    subject: "",
    message: "",
  });

  const ref = useRef();

  const { full_name, email, subject, message } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    setisSending(true);
    setSendMessage("");
    seterrorMessage("");
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ full_name, email, subject, message });

    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/send-email/rmf_travellers`,
          body,
          config
        )
        .then((res) => {
          e.target.reset();
          setSendMessage("Your Message has been sent, Thank you!");
          setisSending(false);
        })
        .catch((error) => {
          seterrorMessage("An error occurred, Message not Sent");
          console.log(error.message);
          // console.log(body);
          setisSending(false);
        });
    } catch (err) {}
  };
  return (
    <>
      <section
        className="hero-wrap hero-wrap-2 js-fullheight"
        style={{ backgroundImage: `url(${bg1}`, height: "fit-content" }}>
        <div style={{ height: "fit-content" }} className="overlay"></div>
        <div class="container">
          <div class="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
            <div class="col-md-9 ftco-animate pb-5 text-center">
              <p class="breadcrumbs">
                <span class="mr-2">
                  <a href="/">
                    Home <i class="fa fa-chevron-right"></i>
                  </a>
                </span>{" "}
                <span>
                  Contact Us <i class="fa fa-chevron-right"></i>
                </span>
              </p>
              <h1 class="mb-0 bread">Contact Us</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-section ftco-no-pb contact-section mb-4">
        <div className="container">
          <div className="row d-flex contact-info">
            <div className="col-md-4 d-flex">
              <div className="align-self-stretch box p-4 text-center">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="fa fa-map-marker"></span>
                </div>
                <h3 className="mb-2">Address</h3>
                <p>
                  Plot No. 13 Nchenja Road Off Great East road north mead lusaka
                </p>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="align-self-stretch box p-4 text-center">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="fa fa-phone"></span>
                </div>
                <h3 className="mb-2">Contact Number</h3>
                <p>
                  <a href="tel://+260971228773">+ 260971228773</a>
                </p>
                <p>
                  <a href="tel://+260974516215">+ 260974516215</a>
                </p>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="align-self-stretch box p-4 text-center">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="fa fa-paper-plane"></span>
                </div>
                <h3 className="mb-2">Email Address</h3>
                <p>
                  <a href="mailto:intercitytraveller01@gmail.com">
                    intercitytraveller01@gmail.com
                  </a>
                </p>
                <p>
                  <a href="mailto:reumaifab@gmail.com">reumaifab@gmail.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-section contact-section ftco-no-pt">
        <div className="container">
          <div className="row block-9">
            <div className="col-md-6 order-md-last">
              {errorMessage && (
                <div className="alert alert-danger">{errorMessage}</div>
              )}
              {sendMessage && (
                <div className="alert alert-success">{sendMessage}</div>
              )}
              <form
                onSubmit={(e) => onSubmit(e)}
                className="bg-light p-5 contact-form">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Name"
                        value={full_name}
                        onChange={(e) => onChange(e)}
                        name="full_name"
                        required="Full Name is required"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Email"
                        value={email}
                        onChange={(e) => onChange(e)}
                        name="email"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Subject"
                    value={subject}
                    onChange={(e) => onChange(e)}
                    name="subject"
                    required
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    placeholder="Your Message"
                    value={message}
                    onChange={(e) => onChange(e)}
                    name="message"
                    require
                    id=""
                    cols="30"
                    rows="7"></textarea>
                </div>
                <div className="form-group pull-right">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={isSending}>
                    {isSending ? (
                      <span>
                        <Spinner size="sm" /> sending...
                      </span>
                    ) : (
                      "Send Message"
                    )}
                  </button>
                </div>
              </form>
            </div>

            <div className="col-md-6 d-flex">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3846.1515742180973!2d28.281280214850355!3d-15.422368389283397!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1940f3b10b1681ff%3A0x455f28611e3c458d!2sIndeco%20House!5e0!3m2!1sen!2szm!4v1688751514591!5m2!1sen!2szm"
                width="600"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </section>
      <WeAre />
    </>
  );
};

export default ContactUs;
