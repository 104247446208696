import React, { useState, useEffect } from "react";
import httpBranchServices from "../../services/http.branch.services";
import { TotalAmount } from "../../../utils/add_numbers";

// interface IReport{
//   branch: IBranch,
//   groupBy: string;
// }

function DailyReports({ branch }) {
  const [dailyReport, setDailyReport] = useState([]);
  const [branchData, setBranchData] = useState([]);

  const fetchReports = () => {
    try {
      httpBranchServices
        .getReports(branch.id, "day")
        .then((reports) => {
          setDailyReport(reports.data.daily_report);
          setBranchData(reports.data.details);
        })
        .catch((error) => {});
    } catch (error) {}
  };

  useEffect(() => {
    fetchReports();
  }, []);

  return (
    <>
      <div className="mb-3 text-center text-white">
        <div className="card bg-info">
          <div className="card-body">
            <div
              className="rounded shadow"
              style={{
                background: "#00162b",
                width: "80px",
                height: "40px",
                float: "right",
                margin: "auto",
                display: "flex",
              }}>
              <h6 className="text-white" style={{ margin: "auto" }}>
                Sales {Number(dailyReport[0]?.count).toLocaleString()}
              </h6>
            </div>
            <i className="fa fa-calendar fa-3x"></i>
            <h4 className="text-white text-capitalize mt-3">daily bookings</h4>
            <h5 className="text-white">
              ZMW <TotalAmount data={dailyReport} />{" "}
            </h5>
          </div>
        </div>
      </div>
    </>
  );
}

export default DailyReports;
