import { apiSlice } from "../services/apiSlice";
import { ICarBooking } from "../types/car_booking.type";

export const userAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserCarRentals: builder.query<ICarBooking[], any>({
      query: ({client, status}) => `/cars/v1/intercity_travellers/car_booking/rmf_travellers/?status=${status}&client_id=${client}`,
      transformResponse: (res: ICarBooking[]) =>
        res.sort((a, b) => b.id - a.id),
      providesTags: ["ICarBooking"],
      // keepUnusedDataFor: 5,
    }),
  }),
});
export const {
  useGetUserCarRentalsQuery,
} = userAPISlice;
