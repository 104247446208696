import React from "react";
import GalleryModal from "./galleryModal";
import axios from "axios";
import { useParams } from "react-router-dom";


export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class ImageGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentIndex: null, imgUrls: []};
    this.closeModal = this.closeModal.bind(this);
    this.findNext = this.findNext.bind(this);
    this.findPrev = this.findPrev.bind(this);
    this.renderImageContent = this.renderImageContent.bind(this);
  }

  async fetchImages() {
    await axios.get(`${process.env.REACT_APP_API_URL}/${this.props.imgUrl}/${this.props.branch}/rmf_travellers`).then((response) => {
      this.setState({
        imgUrls: response.data,
      })
    })
  }

  componentDidMount() {
    this.fetchImages()
  }
  renderImageContent(src, index) {
    return (
      <div onClick={(e) => this.openModal(e, index)}>
        <img className="rounded" src={src.image} key={src} />
      </div>
    ) 
  }
  openModal(e, index) {
    this.setState ({ currentIndex: index });
  }
  closeModal(e) {
    if (e != undefined) {
      e.preventDefault();
    }
    this.setState ({ currentIndex: null });
  }
  findPrev(e) {
    if (e != undefined) {
      e.preventDefault();
    }
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex -1
    }));
  }
  findNext(e) {
    if (e != undefined) {
      e.preventDefault();
    }
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex + 1
    }));
  }
  render() {
    return (
      <div className="gallery-container">
        <h1>Gallery</h1>
        <div className="gallery-grid">
          {this.state.imgUrls.map(this.renderImageContent)}
        </div>
        <GalleryModal
          closeModal={this.closeModal} 
          findPrev={this.findPrev} 
          findNext={this.findNext} 
          hasPrev={this.state.currentIndex > 0} 
          hasNext={this.state.currentIndex + 1 < this.state.imgUrls.length} 
          src={this.state.imgUrls[this.state.currentIndex]} 
        />
      </div>
    )
  }
}

export default withRouter(ImageGallery);