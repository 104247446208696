import React from "react";
import { options } from "../../actions/utils";
import { Reveal } from "../../reveal";
import OwlCarousel from "react-owl-carousel";
import DetailsModal from "./details_modal";

function WaterFalls({ waterFall }) {
  const [show, setShow] = React.useState(false);
  const [details, setDetails] = React.useState(null);

  const handleWaterFallClose = () => setShow(false);
  const handleWaterFallShow = (water) => {
    setShow(true);
    setDetails(water);
  };
  return (
    <>
      <section>
        <h1 className="mb-3 mt-3 ">
          <b>
            Discover <br /> Zambian Waterfalls
          </b>
        </h1>
        <Reveal>
          <div className="row">
            <div className="col-md-12">
              <div className="carousel-destination ftco-animate">
                <OwlCarousel {...options} className="owl-theme">
                  {waterFall.map((item, index) => (
                    <>
                      <div
                        data-aos="fade-up"
                        data-aos-delay={index * 250}
                        key={index}
                        className="item">
                        <div className="project-destination">
                          <a
                          href="#"
                            onClick={() => handleWaterFallShow(item)}
                            className="img"
                            style={{
                              backgroundImage: `url(${item.image})`,
                              cursor: "pointer",
                            }}>
                            <div className="text text-uppercase">
                              <span className="text-uppercase">
                                {item.placeName}
                              </span>
                            </div>
                          </a>
                        </div>
                      </div>
                    </>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </Reveal>
      </section>
      <DetailsModal
        data={details === null ? [] : details}
        show={show}
        handleClose={handleWaterFallClose}
      />
    </>
  );
}

export default WaterFalls;
