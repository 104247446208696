import React, { useEffect, useState } from "react";
import axios from "axios";

import moment from "moment";

import AddCommuterRoute from "./add_commuter_route";
import UpdateRoute from "./edit_commuter_route";
import LoadingSpinner from "../../components/widgets/spinner";

function CommutorRoutes() {
  const [isLoading, setIsLoading] = useState(false);

  const [editItem, setEditItem] = useState(null);

  const [showEdit, setShowEdit] = useState(false);
  const handleEditClose = () => setShowEdit(false);
  const handleShowEdit = (item) => {
    setShowEdit(true);
    setEditItem(item);
  };

  const [provinces, setProvinces] = useState([]);
  const [company, setCompany] = useState([]);
  const [routes, setRoutes] = useState([]);
  const fetchData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/home/rmf_travellers`, config)
      .then((res) => {
        setProvinces(res.data);
      });

    await axios
      .get(`${process.env.REACT_APP_API_URL}/userprofile/v1/intercity_travellers/get-commuters/rmf_travellers`, config)
      .then((res) => {
        setCompany(res.data);
      });

    await axios
      .get(`${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/all-routes/rmf_travellers`, config)
      .then((res) => {
        setRoutes(res.data);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <section>
        <h1 className="text-center">Commuter Routes</h1>
        <div className="wrapperalign-items-stretch">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <div id="content" className="p-2 p-md-3 pt-3">
              <div className="form-group text-center mt-5 col-md-6 pull-right">
                <AddCommuterRoute
                  fetchRoutes={fetchData}
                  company={company}
                  provinces={provinces}
                />
              </div>

              <div className="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Company Name</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Date Created</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {routes.map((res, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="text-capitalize">
                          {company.map((com) =>
                            res.commutor === com.id ? com.company_name : null
                          )}
                          <br />
                        </td>
                        <td className="text-capitalize">
                          {provinces.map((pro) =>
                            pro.id === res.from_province
                              ? pro.province_name
                              : null
                          )}
                        </td>
                        <td className="text-capitalize">
                          {provinces.map((pro) =>
                            pro.id === res.to_province
                              ? pro.province_name
                              : null
                          )}
                        </td>
                        <td>
                          {moment(res.created_at).format(
                            "MMMM Do YYYY HH:MM a"
                          )}
                        </td>
                        <td>
                          <UpdateRoute
                            show={showEdit}
                            handleShow={() => handleShowEdit(res)}
                            handleClose={handleEditClose}
                            provinces={provinces}
                            details={editItem === null ? res : editItem}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default CommutorRoutes;
