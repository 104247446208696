const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
    <div className={isDanger ? "countdown danger" : "countdown"}>
      <h2>{value}</h2>
      <h1>{type}</h1>
    </div>
  );
};

export default DateTimeDisplay;
