import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { Reveal } from "../../../reveal";
import axios from "axios";
import { urlToken } from "../../../helper";
import HolidaySkeleton from "../../loading/holiday_loading";

const PlacesCard = () => {
  const [isLoading, setisLoading] = useState(true);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/view_holiday_countries/rmf_travellers`
          )
          .then((res) => {
            setCountries(res.data);
            setisLoading(false);
          });
      } catch (error) {
        setisLoading(false);
      }
    };
    fetchCountries();
  }, []);
  //Owl Carousel Settings
  const options = {
    loop: true,
    center: true,
    items: 3,
    margin: 10,
    autoplay: true,
    dots: true,
    autoplayTimeout: 4500,
    smartSpeed: 450,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <>
      <Reveal>
        <section className="ftco-section img ftco-select-destination">
          <div className="container">
            <div className="row justify-content-center pb-4">
              <div className="col-md-12 heading-section text-center ftco-animate">
                <span className="subheading">
                  Intercity Travellers Destinations
                </span>
                <h2 className="mb-4">Select Your Destination</h2>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {isLoading ? (
                <>
                  <HolidaySkeleton options={options} />
                </>
              ) : countries.length === 0 ? (
                <div className="alert alert-info text-center" role="alert">
                  <h1>
                    <i className="fa fa-globe"></i>
                  </h1>
                  <h3> No Holiday Destinations Found</h3>
                </div>
              ) : (
                <div className="col-md-12">
                  <div className="carousel-destination ftco-animate">
                    <OwlCarousel {...options} className="owl-theme">
                      {countries.map((item, index) => (
                        <div
                          data-aos="fade-up"
                          data-aos-delay={index * 250}
                          key={index}
                          className="item">
                          <div className="project-destination">
                            <a
                              href={`/zm/destination-details/?token=${urlToken}&country_id=${item.id}&slug=${item.slug}`}
                              className="img"
                              style={{ backgroundImage: `url(${item.image})` }}>
                              <div className="text">
                                <h3 className="text-uppercase">
                                  {item.countryName}
                                </h3>
                                <span>{item.travels_count} Tours</span>
                              </div>
                            </a>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </Reveal>
    </>
  );
};
export default PlacesCard;
