import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import LoadingSpinner from "../../../components/widgets/spinner";
import { DragAndDrop } from "../../widgets/DragNdrop";
import httpBranchServices from "../services/http.branch.services";
import { useForm } from "react-hook-form";

function EditBranch() {
  const [isLoading, setIsLoading] = useState(true);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [generalFacilities, setGeneralFacilities] = useState([]);
  const [facility, setFacility] = useState(null);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [details, setDetails] = useState(null);

  let tagInput = useRef();

  const handleFacility = (e) => {
    setFacility(e.target.value);
  };

  const removeTag = (i) => {
    const newTags = [...generalFacilities];
    newTags.splice(i, 1);
    setGeneralFacilities(newTags);
  };

  const inputKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      if (
        generalFacilities.find((tag) => tag.toLowerCase() === val.toLowerCase())
      ) {
        return;
      }
      setGeneralFacilities([...generalFacilities, val]);
      tagInput.value = null;
    } else if (e.key === "Backspace" && !val) {
      removeTag(generalFacilities.length - 1);
    }
  };
  /**
   * Stop enter submitting the form.
   * @param keyEvent Event triggered when the user presses a key.
   */
  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const [files, setFiles] = useState([]);

  const { token, id, uid } = useParams();

  const fetchData = async () => {
    httpBranchServices.fetchSingleBranch(id).then((res) => {
      setDetails(res.data);
      setIsLoading(false);
    });
    //get provinces
    try {
      httpBranchServices.getProvinces().then((res) => {
        setProvinces(res.data);
      });
    } catch (error) {}

    try {
      httpBranchServices.getDistricts().then((res) => {
        setDistricts(res.data);
      });
    } catch (error) {}
    // fetch branch IMAGES
    try {
      httpBranchServices.fetchBranchImages(id).then((res) => {
        // setFiles(res.data);
      });
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleUpdate = async (data) => {
    setFormSubmitting(true);
    httpBranchServices
      .updateBranch(data, generalFacilities.concat(details.amenities), uid, id)
      .then((response) => {
        if (response.status === 200) {
          Toast.fire({
            icon: "success",
            title: "Branch Updated Successfully",
          });
          setFormSubmitting(false);
        }
      })
      .catch((error) => {
        setFormSubmitting(false);
        Toast.fire({
          icon: "error",
          title: `Error: ${error.message}`,
        });
      });
  };
  $(document).ready(function () {
    $("#province").on("change", function () {
      var provinceID = $(this).val();
      if (provinceID) {
        $.ajax({
          url: `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/province-districts/${provinceID}/rmf_travellers`,
          type: "GET",
          dataType: "json",
          success: function (data) {
            if (data) {
              $("#district").empty();
              $("#district").focus();
              $("#district").append(
                '<option disabled selected value=""> -- Select City -- </option>'
              );
              $.each(data, function (key, value) {
                $('select[name="district"]').append(
                  '<option value="' +
                    value.id +
                    '">' +
                    value.district_name +
                    "</option>"
                );
              });
            } else {
              $("#district").empty();
            }
          },
        });
      } else {
        $("#district").empty();
      }
    });
  });
  return (
    <>
      <div className="content">
        <div className="py-4">
          <h3 className="text-center mb-5">Edit Branch</h3>
          {isLoading ? (
            <div className="centerAll">
              <LoadingSpinner />
            </div>
          ) : (
            <form onKeyDown={onKeyDown} onSubmit={handleSubmit(handleUpdate)}>
              <div className="row">
                <div className="form-group col-md-4">
                  <label>Province</label>
                  <select
                    disabled={formSubmitting}
                    id="province"
                    name="province"
                    {...register("province", { required: true })}
                    className="form-control text-capitalize">
                    <option selected value={details.province.id}>
                      Current:{" "}
                      {details.province.province_name}
                    </option>
                    {provinces.map((province, index) => (
                      <option key={index} value={province.id}>
                        {province.province_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-4">
                  <label>City</label>
                  <select
                    disabled={formSubmitting}
                    id="district"
                    name="district"
                    {...register("district", { required: true })}
                    className="form-control text-capitalize">
                    <option value={details.district.id}>
                      Current:{" "}
                      {details.district.district_name}
                    </option>
                  </select>
                </div>

                <div className="col-md-4 col-xs-12">
                  <div className="form-group">
                    <label>
                      <span className="wc-editable">
                        <h3 style={{ fontSize: "18px" }}>Physical Address</h3>
                      </span>
                    </label>

                    <div className="form-group mb-3">
                      <input
                        type="text"
                        disabled={formSubmitting}
                        className="form-control"
                        {...register("address", { required: true })}
                        name="address"
                        defaultValue={details.address}
                        placeholder="Physical Address"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-xs-12">
                  <div className="form-group">
                    <label>
                      <span className="wc-editable">
                        <h3 style={{ fontSize: "18px" }}>Branch Type</h3>
                      </span>
                    </label>

                    <div className="form-group mb-3">
                      <select
                        disabled={formSubmitting}
                        className="form-control"
                        {...register("branchType", {
                          required: true,
                        })}
                        name="branchType"
                        id="branchType">
                        <option value={details.branchType}>
                          {details.branchType}
                        </option>
                        <option value="motel">Motel</option>
                        <option value="apartment">Apartment</option>
                        <option value="lodge">Lodge</option>
                        <option value="hotel">Hotel</option>
                        <option value="guest_house">Guest House</option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* checkin checkout time*/}
                <div className="col-md-4 col-xs-12">
                  <div className="form-group">
                    <label>
                      <span className="wc-editable">
                        <h3 style={{ fontSize: "18px" }}>Checkin Time</h3>
                      </span>
                    </label>

                    <div className="form-group mb-3">
                      <input
                        type="time"
                        disabled={formSubmitting}
                        className="form-control"
                        {...register("checkinTime", {
                          required: true,
                          min: 0,
                        })}
                        name="checkinTime"
                        defaultValue={details.checkinTime}
                        placeholder="Checkin Time"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-xs-12">
                  <div className="form-group">
                    <label>
                      <span className="wc-editable">
                        <h3 style={{ fontSize: "18px" }}>Checkout Time</h3>
                      </span>
                    </label>

                    <div className="form-group mb-3">
                      <input
                        type="time"
                        disabled={formSubmitting}
                        className="form-control"
                        {...register("checkoutTime", {
                          required: true,
                          min: 0,
                        })}
                        name="checkoutTime"
                        defaultValue={details.checkoutTime}
                        placeholder="Checkout Time"
                      />
                    </div>
                  </div>
                </div>
                {/* checkin checkout time */}
                <div className="col-md-6 col-xs-12">
                  <div className="form-group">
                    <label>
                      <span className="wc-editable">
                        <h3 style={{ fontSize: "18px" }}>Phone Number</h3>
                      </span>
                    </label>

                    <div className="form-group mb-3">
                      <input
                        type="number"
                        disabled={formSubmitting}
                        className="form-control"
                        {...register("hotel_phone", {
                          required: true,
                          min: 0,
                        })}
                        name="hotel_phone"
                        defaultValue={details.hotel_phone}
                        placeholder="Phone Number"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xs-12">
                  <div className="form-group">
                    <label>
                      <span className="wc-editable">
                        <h3 style={{ fontSize: "18px" }}>E-mail</h3>
                      </span>
                    </label>

                    <div className="form-group mb-3">
                      <input
                        type="text"
                        disabled={formSubmitting}
                        className="form-control"
                        {...register("hotel_email", { required: true })}
                        name="hotel_email"
                        defaultValue={details.hotel_email}
                        placeholder="E-MAIL"
                      />
                    </div>
                  </div>
                </div>

                <Form.Group className="mb-3" controlId="validationCustom03">
                  <Form.Label>General Facilities</Form.Label>
                  <div className="input-tag">
                    <ul className="input-tag__tags">
                      {details.amenities.map((tag, i) => (
                        <li key={i}>
                          {tag}
                          <button
                            type="button"
                            onClick={() => {
                              removeTag(i);
                            }}>
                            X
                          </button>
                        </li>
                      ))}
                      {generalFacilities.map((tag, i) => (
                        <li key={i}>
                          {tag}
                          <button
                            type="button"
                            onClick={() => {
                              removeTag(i);
                            }}>
                            X
                          </button>
                        </li>
                      ))}
                      <li className="input-tag__tags__input">
                        <Form.Control
                          disabled={formSubmitting}
                          type="text"
                          onKeyDown={inputKeyDown}
                          onChange={(e) => handleFacility(e)}
                          name="amenities"
                          className="form-control"
                          value={facility}
                          placeholder="General Facilities, E.g swimming pool, board room etc"
                          ref={(c) => {
                            tagInput = c;
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          General Facilities is required
                        </Form.Control.Feedback>
                      </li>
                    </ul>
                  </div>
                </Form.Group>
                <div className="col-md-6 col-xs-12">
                  <div className="form-group">
                    <label>
                      <span className="wc-editable">
                        <h3 style={{ fontSize: "18px" }}>Branch Description</h3>
                      </span>
                    </label>

                    <div className="form-group mb-3">
                      <textarea
                        type="text"
                        disabled={formSubmitting}
                        cols="12"
                        rows="8"
                        {...register("hote_description", {
                          required: true,
                        })}
                        name="hote_description"
                        className="form-control"
                        defaultValue={details.hote_description}
                        placeholder="Branch Description"></textarea>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xs-12">
                  <div className="form-group">
                    <label>
                      <span className="wc-editable">
                        <h3 style={{ fontSize: "18px" }}>
                          Room Cancellation Policy
                        </h3>
                      </span>
                    </label>

                    <div className="form-group mb-3">
                      <textarea
                        type="text"
                        disabled={formSubmitting}
                        cols="12"
                        rows="8"
                        {...register("cancellationPolicy", {
                          required: true,
                        })}
                        name="cancellationPolicy"
                        className="form-control"
                        defaultValue={details.cancellationPolicy}
                        placeholder="Room Cancellation Policy"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <DragAndDrop
                  heading={"Edit Branches Image Gallery"}
                  onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      for (let i = 0; i < e.target.files["length"]; i++) {
                        setFiles((prevState) => [
                          ...prevState,
                          e.target.files[i],
                        ]);
                      }
                    }
                  }}
                  setFiles={setFiles}
                  files={files}
                />
              </div>
              <div className="form-group pull-right mt-4">
                <button type="submit" className="btn btn-primary">
                  {formSubmitting ? (
                    <span>
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                      />{" "}
                      Updating...
                    </span>
                  ) : (
                    "Update Branch"
                  )}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
}

export default EditBranch;
