import React from "react";
import { Link } from "react-router-dom";
import emailIcon from "../assets/img/icons/mail.svg";
import { useLogin } from "../hooks";
import { urlToken } from "../helper";

const LoginPage: React.FC = () => {
  const [view, setView] = React.useState<boolean>(false);
  const { email, password, isLoading, onChange, onSubmit } = useLogin();

  return (
    <div className="account-page">
      <div className="main-wrapper">
        <div className="account-content">
          <div className="login-wrapper">
            <div className="login-content">
              <div className="login-userset">
                <center>
                  <div className="login-logo">
                    <img src={require("../assets/img/logo.png")} alt="img" />
                  </div>
                </center>
                <div className="login-userheading">
                  <h3>Sign In</h3>
                  <h4>Please login to your account</h4>
                </div>

                <form onSubmit={onSubmit}>
                  <div className="form-login">
                    <label>Email</label>
                    <div className="form-addons">
                      <input
                        type="email"
                        onChange={onChange}
                        name="email"
                        value={email}
                        required
                        disabled={isLoading}
                        placeholder="Enter your email address"
                      />
                      <img src={emailIcon} alt="img" />
                    </div>
                  </div>
                  <div className="form-login">
                    <label>Password</label>
                    <div className="pass-group">
                      <input
                        type={view ? "text" : "password"}
                        className="pass-input"
                        name="password"
                        onChange={onChange}
                        value={password}
                        required
                        disabled={isLoading}
                        placeholder="Enter your password"
                      />
                      <span
                        onClick={() => setView(() => !view)}
                        className={view ? "fa toggle-password fa-eye": "fa toggle-password fa-eye-slash"}></span>
                    </div>
                  </div>
                  <div className="form-login">
                    <div className="alreadyuser">
                      <h4>
                        <Link
                          to={`/zm/auth/reset-password/${urlToken}`}
                          className="hover-a">
                          Forgot Password?
                        </Link>
                      </h4>
                    </div>
                  </div>
                  <div className="form-login">
                    <button
                      type="submit"
                      className="btn btn-login"
                      disabled={isLoading}>
                      {isLoading && (
                        <div className="spinner-border spinner-border-sm text-danger"></div>
                      )}{" "}
                      Sign In
                    </button>
                  </div>
                </form>
                <div className="signinform text-center">
                  <h4>
                    Don’t have an account?
                    <Link to="/zm/auth/register" className="hover-a">
                      Sign Up
                    </Link>
                  </h4>
                </div>
                {/* <div className="form-setlogin">
                  <h4>Or sign up with</h4>
                </div> */}
                {/* <div className="form-sociallink">
                  <ul>
                    <li>
                      <a href="javascript:void(0);">
                        <img
                          src={require("../assets/img/icons/google.png")}
                          className="me-2"
                          alt="google"
                        />
                        Sign Up using Google
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <img
                          src={require("../assets/img/icons/facebook.png")}
                          className="me-2"
                          alt="google"
                        />
                        Sign Up using Facebook
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="login-img shadow">
              <img src={require("../assets/img/auth.jpg")} alt="img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
