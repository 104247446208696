import Axios from "axios";
import { tokenName } from "./actions/authController";
import { ME_URL, REFERESH_URL } from "./urls";

export const LastUserChat = "lastUserChat";

export const axiosHandler = ({
  method = "",
  url = "",
  data = {},
  extra = null,
}) => {
  let methodType = method.toUpperCase();
  if (
    ["GET", "POST", "PATCH", "PUT", "DELETE"].includes(methodType) ||
    {}.toString(data) !== "[object Object]"
  ) {
    let axiosProps = { method: methodType, url, data };

    if (extra) {
      axiosProps.headers = { ...axiosProps.headers, ...extra };
    }
    return Axios(axiosProps);
  } else {
    alert(`method ${methodType} is not accepted or data is not an object`);
  }
};

export const errorHandler = (err, defaulted = false) => {
  if (defaulted) {
    return "Ops!, an error occurred.";
  }

  let messageString = "";
  if (!err.response) {
    messageString += "Network error! check your network and try again";
  } else {
    let data = err.response.data.results;
    if (!err.response.data.results) {
      data = err.response.data;
    }
    messageString = loopObj(data);
  }
  return messageString.replace(/{|}|'|\[|\]/g, "");
};

const loopObj = (obj) => {
  let agg = "";
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      agg += `${typeof obj[key] === "object" ? loopObj(obj[key]) : obj[key]}`;
    }
  }
  return agg;
};

export const getToken = async (props) => {
  let token = localStorage.getItem(tokenName);
  const userProfile = await axiosHandler({
    method: "get",
    url: ME_URL,
    token: token.access,
  }).catch((e) => null);
  if (userProfile) {
    return token.access;
  } else {
    const getNewAccess = await axiosHandler({
      method: "post",
      url: REFERESH_URL,
      data: {
        refresh: token.refresh,
      },
    }).catch((e) => null);
    if (getNewAccess) {
      localStorage.setItem(tokenName, JSON.stringify(getNewAccess.data));
      return getNewAccess.data.access;
    }
  }
};

export const randomString = (length, chars) => {
  var result = "";
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

export const urlToken = randomString(
  100,
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
);
export const styles = {
  background: "blue",
  textAlign: "center",
  borderRadius: 30,
  width: 150,
  padding: "5px 10px",
  margin: "auto",
  color: "#fff",
  outline: "none",
  border: "none",
  cursor: "pointer",
};
