import React, { useState, useEffect } from "react";

import axios from "axios";
import AddTravels from "./add_tour";
import LoadingSpinner from "../../../components/widgets/spinner";

function HolidayPackages() {
  const [travels, setTravel] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    const config = {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
      }
  };
    const fetchData = async () => {
      try {
        await axios
          .get(`${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/view_holiday_countries/rmf_travellers`, config)
          .then((res) => {
            setCountries(res.data);
            setisLoading(false);
          });
      } catch (error) {}
      try {
        await axios
          .get(`${process.env.REACT_APP_API_URL}/holidays/v1/intercity_travellers/view_travels/rmf_travellers`, config)
          .then((res) => {
            setTravel(res.data);
          });
      } catch (error) {}
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="mt-5 px-4 py-5">
        <div className="mt-5">
          <AddTravels countries={countries} />
        </div>
        {isLoading ? (
          <center>
            <LoadingSpinner />
          </center>
        ) : travels.length === 0 ? (
          <div className="mt-5 alert alert-danger text-center">
            No Holiday Packages
          </div>
        ) : (
          <section>
            {travels.map((item, index) => {
              return (
                <div className="mt-5" key={index}>
                  <div className="image-data">
                    <img src={item.image1} alt="" />
                    <ul className="hover-items">
                      <li>
                        <a
                          className="btn btn-sm btn-info"
                          href={`/edit/${item.id}/travel`}
                        >
                          <i className="fa fa-pencil"></i> Edit
                        </a>
                        {/* <a className="btn btn-sm btn-outline-danger"  href={`${item.id}/delete`}><i className="fa fa-trash"></i> Delete</a> */}
                      </li>
                    </ul>
                  </div>
                  <h5 className="text-capitalize">
                    {item.title},
                    <small className="text-success">{item.country}</small>
                  </h5>

                  <p>{item.description.substring(0, 160)}... </p>
                  <div className="pull-right">
                    <a
                      href={`/edit/${item.id}/travel`}
                      className="btn btn-outline-warning"
                    >
                      <i className="fa fa-pencil"></i> Edit
                    </a>
                  </div>
                </div>
              );
            })}
          </section>
        )}
      </div>
    </>
  );
}

export default HolidayPackages;
