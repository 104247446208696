export const carBrands = [
    "toyota",
    "bmw",
    "benz",
    "honda",
    "subaru",
    "jeep",
    "hyundai",
    "ford",
    "chevrolet",
    "suzuki",
    "mitsubish",
    "audi",
    "nissan",
    "lexus",
    "volvo",
    "jaguar",
    "mazda"
];

export const carGearBoxTypes = [
    "automatic",
    "manual"
]