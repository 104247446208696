import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "../../../hooks/redux-hooks";

const SidebarNav = () => {
  const location = useLocation();
  return (
    <>
      <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul
              className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start"
              id="menu">
              <li id="active-sidebar">
                <a href="/" className="text-truncate">
                  <i className="fs-5 bi-house"></i>
                  <span className="ms-2">Home</span>
                </a>
              </li>
              <li className={`${location.pathname === "/zm/profile" ? "active" : ""}`}>
                <Link
                  to={`/zm/profile`}>
                  <i className="fs-5 bi-speedometer2"></i>
                  <span className="ms-2">Dashboard</span>{" "}
                </Link>
              </li>
              <li className={`${location.pathname === "/zm/profile/rooms" ? "active" : ""}`}>
                <Link
                  to={`/zm/profile/rooms`}
                  className="nav-link text-truncate">
                  <i className="fa fa-hotel"></i>
                  <span className="ms-2">Rooms</span>
                </Link>
              </li>
              <li className={`${location.pathname === "/zm/profile/cars" ? "active" : ""}`}>
                <Link
                  to={`/zm/profile/cars`}
                  className="nav-link text-truncate">
                  <i className="fa fa-car"></i>
                  <span className="ms-2">Cars</span>
                </Link>
              </li>
              <li className={`${location.pathname === "/zm/profile/places" ? "active" : ""}`}>
                <Link
                  to={`/zm/profile/places`}
                  className="nav-link text-truncate">
                  <i className="fs-5 bi-grid"></i>
                  <span className="ms-2">Places</span>
                </Link>
              </li>
              <li className={`${location.pathname === "/zm/profile/delete_account" ? "active" : ""}`}>
                <Link
                  to={`/zm/profile/delete_account`}
                  className="nav-link text-truncate">
                  <i className="fs-5 bi-trash"></i>
                  <span className="ms-2">Delete Account</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default SidebarNav;
