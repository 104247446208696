import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function CertificateView({ certificate, handleClose, show }) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title>Certificate Of Incorporation </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src={certificate}
            style={{ width: "100%", height: "100vh" }}
          ></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CertificateView;
