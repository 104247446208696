import React from "react";
import { Outlet } from "react-router-dom";
import SidebarNav from "../admin/SideNav";

const HolidayLayout = () => {
  return (
    <>
      <SidebarNav />
      <div className="px-3" id="main-content">
        <Outlet />
      </div>
    </>
  );
};

export default HolidayLayout;
