import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useRef } from "react";
import Modal from "react-bootstrap/Modal";

function ConfirmBooking({ reservationID, setErrorMessage }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [confirming, setConfirming] = useState(false);
  const [formData, setFormData] = useState({
    status: "",
  });

  const ref = useRef(null);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handleSubmit = async (e) => {
    setConfirming(true);
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    let form_data = new FormData();
    form_data.append("status", formData.status);

    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}/hotels/v1/intercity_travellers/update_reservation/${reservationID}/rmf_travellers`,
        form_data,
        config
      )
      .then((response) => {
        setErrorMessage(response.data["status"]);
        // console.log(response.data["status"]);
        ref.current.value = "";
        formData.status = "";
        setTimeout(() => {
          // this.props.history.push(`/admin-dashboard`)
          setShow(false);
          setConfirming(false);
        }, 2000);

        Toast.fire({
          icon: "success",
          title: "Reservation Confirmed Successful!",
        });
      })
      .catch((error) => {
        setConfirming(false);

        Toast.fire({
          icon: "error",
          title: "An Error occurred try again later!",
        });

        // setErrorMessage(error.message);
        // console.error('There was an error!', error);
      });
  };
  return (
    <>
      <button
        onClick={handleShow}
        data-bs-toggle="modal"
        data-bs-target="#confirmBookModal"
        className="btn btn-secondary border-0 btn-sm text-white"
      >
        <i className="fa fa-pencil-square"></i>
      </button>
      <Modal
        backdrop="static"
        keyboard={false}
        centered
        scrollable={true}
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm / Reject Reservation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label>Select field</label>
              <select
                onChange={(e) => onChange(e)}
                name="status"
                className="form-control"
                required
                ref={ref}
                disabled={confirming}
              >
                <option value="" disabled selected>
                  choose an option
                </option>
                <option value="confirmed">Confirm Reservation</option>
                <option value="rejected">Reject Reservation</option>
              </select>
            </div>

            <div className="mb-3 pull-right">
              <button className="btn btn-secondary">
                {confirming ? (
                  <div>
                    <i className="fa fa-refresh fa-spin"></i> Confirming...
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ConfirmBooking;
