import React from "react";
import moment from "moment";
import { Reveal } from "../../reveal";
import { urlToken } from "../../helper";

function RelatedBlogs({ related }) {
  return (
    <>
      <Reveal>
        {related.map((item, index) => (
          <div
            data-aos="fade-up"
            data-aos-delay={index * 250}
            key={index}
            className="block-21 mb-4 d-flex">
            <a
              href={`/zm/blog-details/?token=${urlToken}&blog_id=${item.id}&slug=${item.slug}`}
              className="blog-img mr-4"
              style={{
                backgroundImage: `url(${item.image})`,
                borderRadius: "5px",
              }}></a>
            <div className="text">
              <h3 className="heading">
                <a
                  href={`/zm/blog-details/?token=${urlToken}&blog_id=${item.id}&slug=${item.slug}`}>
                  {item.blog_title}
                </a>
              </h3>
              <div className="meta">
                <div>
                  <a
                    href={`/zm/blog-details/?token=${urlToken}&blog_id=${item.id}&slug=${item.slug}`}>
                    <span className="fa fa-calendar"></span>{" "}
                    {moment(item.created_at).format("MMMM Do YYYY")}
                  </a>
                </div>
                <div className="text-capitalize">
                  <a
                    href={`/zm/blog-details/?token=${urlToken}&blog_id=${item.id}&slug=${item.slug}`}>
                    <span className="fa fa-user"></span> {item.blog_author}
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Reveal>
    </>
  );
}

export default RelatedBlogs;
