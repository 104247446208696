import React from "react";
import { Modal } from "react-bootstrap";

const CommuterDetails = ({ show, handleClose, commutors, res }) => {
  return (
    <>
      <Modal
        backdrop="static"
        keyboard={false}
        centered
        scrollable={true}
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {commutors.map((coms) => (
              <span key={coms.id} className="text-capitalize ">
                {res.commutor === coms.id ? coms.company_name : ""}
              </span>
            ))}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {commutors.map((coms, index) =>
            res.commutor == coms.id ? (
              <span key={index}>
                {/* <img style={{ width: '80px', height: '80px' }} src={coms.profile_picture} className="menu-img" alt="" /> */}
                <p>
                  <i className="fa fa-envelope"></i> E-mail:{" "}
                  {res.commutor === coms.id ? (
                    <a href={`mailto:${coms.email}`}>{coms.email}</a>
                  ) : (
                    ""
                  )}
                </p>
                <p>
                  <i className="fa fa-phone"></i> Phone:{" "}
                  {res.commutor === coms.id ? coms.phone : ""}
                </p>
                <p>
                  <i class="fa fa-map-marker"></i> Address:{" "}
                  {res.commutor === coms.id ? coms.address : ""}
                </p>
                <p>
                  {res.commutor === coms.id ? (
                    <a href={`https://www.facebook.com/${coms.facebook_url}`}>
                      <i className="fa fa-facebook"></i> Facebook
                    </a>
                  ) : (
                    ""
                  )}
                </p>
              </span>
            ) : (
              ""
            )
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CommuterDetails;
