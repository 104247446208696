import React from "react";
import { styles, urlToken } from "../../../helper";
import { motion } from "framer-motion";
import LoadingSpinner from "../../widgets/spinner";
import { Rating } from "react-simple-star-rating";

function BranchCard({ data, isLoading }) {
  return (
    <>
      {isLoading ? (
        <center>
          <LoadingSpinner />
        </center>
      ) : data.length === 0 ? (
        <div className="text-center shadow" role="alert">
          <i style={{ fontSize: "70px" }} className="fa fa-bed"></i>
          <h1>Nothing Found</h1>
        </div>
      ) : (
        data.map((item, index) => {
          return (
            <motion.div
              transition={{ delay: 0.1 * index }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              whileHover={{ scale: 1.1 }}
              key={index}
              class="col-md-4 ftco-animate">
              <div class="project-wrap hotel">
                <a
                  href={`/zm/hotel-details/?name=${item.hotel_name}&branch_id=${item.id}&token=${urlToken}&undefined=${item.user.id}`}
                  class="img"
                  style={{ backgroundImage: `url(${item.branch_images[0].image})` }}></a>
                <div class="text p-4">
                  <h3 className="capitalize">
                    <a
                      className="capitalize"
                      href={`/zm/hotel-details/?name=${item.hotel_name}&branch_id=${item.id}&token=${urlToken}&undefined=${item.user.id}`}>
                      {item.hotel_name}
                    </a>
                  </h3>
                  <p class="location text-capitalize">
                    <span class="fa fa-map-marker"></span>{" "}
                    {item.address.substring(0, 30)}
                  </p>
                  <span className="mb-5">
                    <Rating
                      size={22}
                      initialValue={item.rating_avg}
                      transition
                      allowFraction
                      showTooltip
                      tooltipDefaultText={`${item.rating_avg}`}
                      allowTitleTag={false}
                      readonly
                      tooltipStyle={{
                        paddingLeft: "2px",
                        paddingRight: "2px",
                        paddingBottom: "0px",
                        paddingTop: "0px",
                      }}
                      /* Available Props */
                    />
                  </span>

                  <motion.a
                    href={`/zm/hotel-details/?name=${item.hotel_name}&branch_id=${item.id}&token=${urlToken}&undefined=${item.user.id}`}
                    style={styles}
                    className="pull-right mt-3"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9, x: "-5px", y: "5px" }}>
                    Details
                  </motion.a>
                </div>
              </div>
            </motion.div>
          );
        })
      )}
    </>
  );
}

export default BranchCard;
