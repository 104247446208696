import React, { useState, useEffect } from "react";
import httpBranchServices from "../services/http.branch.services";
import { TotalAmount } from "../../utils/add_numbers";
import DailyReports from "./reports/daily_report";
import WeeklyReports from "./reports/weekly_report";
import MonthlyReports from "./reports/monthly_report";
import YearlyReports from "./reports/yearly_report";
import ExampleChart from "../widgets/area_chart";

function BranchStatics({ branch }) {
  const [dailyReport, setDailyReport] = useState([]);
  const [weeklyReport, setWeeklyReport] = useState([]);
  const [monthlyReport, setMonthlyReport] = useState([]);
  const [yearlyReport, setYearlyReport] = useState([]);
  const [branchData, setBranchData] = useState([]);

  const fetchReports = () => {
    try {
      httpBranchServices
        .getReports(branch.id, "day")
        .then((reports) => {
          setDailyReport(reports.data.daily_report);
          setWeeklyReport(reports.data.weekly_report);
          setMonthlyReport(reports.data.monthly_report);
          setYearlyReport(reports.data.yearly_report);
          setBranchData(reports.data.details);
        })
        .catch((error) => {});
    } catch (error) {}
  };

  useEffect(() => {
    fetchReports();
  }, []);

  const handleMonthChange = (e) => {
    console.log(e.target.value);
  };

  return (
    <>
      <h1 className="text-center">Branch Statistics</h1>
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="filter">Filter</label>
            <input type="day" className="form-control" />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="filter">Filter By Month</label>
            <input
              onChange={handleMonthChange}
              type="month"
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="filter">Filter</label>
            <select
              name="filter_statics"
              id="filter_statics"
              className="form-control">
              <option value="">Filter</option>
              <option value="january">January</option>
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          {/* DAILY REPORT */}
          <DailyReports branch={branch} />
        </div>
        <div className="col-md-3 col-sm-2">
          {/* WEEKLY REPORT */}
          <WeeklyReports branch={branch} />
        </div>
        <div className=" col-md-3 col-sm-2">
          {/* MONTHLY */}
          <MonthlyReports branch={branch} />
        </div>
        <div className="col-md-3">
          {/* YEARLY REPORT */}
          <YearlyReports branch={branch} />
        </div>
      </div>
      <ExampleChart />
    </>
  );
}

export default BranchStatics;
