import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import bg1 from "../../images/launch.jpg";
import appleStore from "../../images/app-store.png";
import CountDownTimer from "../countdown/countdown";

function LaunchingSoon({ show, handleClose }) {
  const THREE_DAYS_IN_MS = 20 * 24 * 60 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();

  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;
  const [targetDate, setTargetDate] = useState(
    new Date(dateTimeAfterThreeDays)
  );
  return (
    <>
      <Modal
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        scrollable={true}
        show={show}
        onHide={handleClose}
      >
        <Modal.Body>
          <section
            style={{
              backgroundImage: `url(${bg1})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="overlay"></div>
            <div className="container">
              <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-center">
                <div className="col-md-9 ftco-animate pb-5 text-center">
                  <p className="breadcrumbs">
                    <h1>Launching Soon</h1>
                  </p>
                  <img
                    className="text-warning"
                    src={appleStore}
                    style={{ width: "20%" }}
                    alt=""
                    srcset=""
                  />
                  <CountDownTimer targetDate={targetDate} />
                  <div className="pull-right">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleClose}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default LaunchingSoon;
