import { apiSlice } from "../services/apiSlice";
import { ICarCompany, ICarImages, ICarRental } from "../types/car_rental.type";

export const carHireAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateBooking: builder.mutation<ICarRental, Partial<ICarRental>& Pick<ICarRental, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `/cars/v1/intercity_travellers/car_booking/rmf_travellers/${id}/`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["ICarRental"],
      transformResponse: (response: { car: ICarRental }) =>
        response.car,
    }),
    getCarDetails: builder.query<ICarRental, any>({
      query: (carid) => ({
        url: `/cars/v1/intercity_travellers/get_car_details/rmf_travellers/${carid}`,
      }),
      providesTags: ["ICarRental"],
    }),
    getCars: builder.query<ICarRental[], any>({
      query: ({company}) => `/cars/v1/intercity_travellers/company_cars/rmf_travellers/?company_id=${company}`,
      transformResponse: (res: ICarRental[]) =>
        res.sort((a, b) => b.id - a.id),
      providesTags: ["ICarRental"],
      // keepUnusedDataFor: 5,
    }),
    getCompany: builder.query<ICarCompany, any>({
      query: ({company}) => `/userprofile/v1/intercity_travellers/get_car_hire/${company}/rmf_travellers`,
      providesTags: ["ICarCompany"],
      // keepUnusedDataFor: 5,
    }),
    getCarImages: builder.query<ICarImages[], any>({
      query: (carid) => `/cars/v1/intercity_travellers/car_image_gallery/rmf_travellers/${carid}`,
      transformResponse: (res: ICarImages[]) =>
        res.sort((a, b) => b.id - a.id),
      providesTags: ["ICarImages"],
      // keepUnusedDataFor: 5,
    }),
    deleteBooking: builder.mutation<ICarRental, number>({
      query: (id) => ({
        url: `/cars/v1/intercity_travellers/car_booking/rmf_travellers/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["ICarRental"],
    }),
  }),
});
export const {
useDeleteBookingMutation,
useGetCarDetailsQuery,
useGetCarsQuery,
useGetCompanyQuery,
useGetCarImagesQuery,
useUpdateBookingMutation
} = carHireAPISlice;
