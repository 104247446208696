import React, { useRef, useEffect } from "react";
import axios from "axios";
import ReactHtmlParser from "html-react-parser";
import LoadingSpinner from "../widgets/spinner";
import bg1 from "../../images/blog.jpg";

function TermsConditions() {
  const [terms, setTerms] = React.useState([]);
  const [isLoading, setisLoading] = React.useState(true);
  const scrollRef = useRef(null);
  const scrowDown = () => scrollRef.current.scrollIntoView();

  useEffect(() => {
    const fetchTerms = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/get-terms/rmf_travellers`)
        .then((response) => {
          setTerms(response.data);
          setisLoading(false);
        })
        .catch((error) => {
          console.error("there was an error", error.message);
        });
    };
    fetchTerms();
  }, []);

  return (
    <>
      <section
        className="hero-wrap hero-wrap-2 js-fullheight"
        style={{ backgroundImage: `url(${bg1})` }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-center">
            <div className="col-md-9 ftco-animate pb-5 text-center">
              <p className="breadcrumbs">
                <span className="mr-2">
                  <a href="/">
                    Home <i className="fa fa-chevron-right"></i>
                  </a>
                </span>{" "}
                <span>Terms and Conditions</span>
              </p>
              <h1 className="mb-0 bread">Terms and Conditions</h1>
            </div>
          </div>
        </div>
        <a className="mouse smoothscroll" onClick={scrowDown}>
          <div className="mouse-icon">
            <span className="mouse-wheel"></span>
          </div>
        </a>
      </section>
      <h1
        className="text-center text-danger mt-5 mb-5"
        style={{ textDecoration: "underline" }}
      >
        Terms And Conditions
      </h1>
      <div ref={scrollRef} className="container">
        <p
          style={{
            letterSpacing: "1px",
            textDecoration: "none",
            listStyleType: "none",
          }}
          className="text-dark"
        >
          {isLoading ? (
            <div className="centerAll">
              <LoadingSpinner />
            </div>
          ) : (
            <small className="text-dark">
              {terms.map((res) => (
                <span>{ReactHtmlParser(res.body)}</span>
              ))}
            </small>
          )}
        </p>
      </div>
    </>
  );
}

export default TermsConditions;
