import React from "react";
import { IRoomNumber } from "../../../redux/types/room_numbers.type";
import { useGetRoomNumbersQuery } from "../../../redux/slices/room_numbers_slice";
import LoadingSpinner from "../../../components/widgets/spinner";
import UpdateRoomNumber from "./edit_room_number";

const RoomNumberWidget: React.FC = () => {
  const {
    data: roomNumbers,
    isLoading,
    error,
  }: IRoomNumber | any = useGetRoomNumbersQuery();

  if (isLoading) {
    return (
      <center>
        <LoadingSpinner />
      </center>
    );
  }
  if (error) {
    return (
      <div className="text-center alert alert-danger" role="alert">
        <h4>{error.status}</h4>
      </div>
    );
  }
  return (
    <>
      <div className="card mb-0">
        <div className="card-body">
          <h4 className="card-title">Rooms</h4>
          <div className="table-responsive dataview">
            <table className="table datatable">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Room #</th>
                  <th>Room</th>
                  <th>Reserved</th>
                  <th>Cleaning</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {roomNumbers.map((item: IRoomNumber, index: number) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.roomNumber}</td>
                      <td className="text-capitalize">{item.room.room_type}</td>
                      <td
                        className={
                          item.roomReserved === true
                            ? "badges bg-lightred text-white"
                            : "badges bg-lightgreen text-white"
                        }>
                        {item.roomReserved === true ? "Reserved" : "Available"}
                      </td>
                      <td>
                        {item.underClearning === true
                          ? "Cleaning"
                          : "Cleaned"}
                      </td>
                      <td>
                        <UpdateRoomNumber roomData={item} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoomNumberWidget;
