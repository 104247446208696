import React, { useState, useEffect } from "react";

import axios from "axios";
import AddTravels from "./add_tour";
import LoadingSpinner from "../../../components/widgets/spinner";
import AddCountryModal from "./countries/add_country";

function HolidayCountries() {
  const [countries, setCountries] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    
    const fetchData = async () => {
      const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
      try {
        await axios
          .get(`${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/view_holiday_countries/rmf_travellers`, config)
          .then((res) => {
            setCountries(res.data);
            setisLoading(false);
          });
      } catch (error) {}
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="mt-5 px-3 py-3">
        <div className="mt-5 mb-5">
          <AddCountryModal />
        </div>
        {isLoading ? (
          <center>
            <LoadingSpinner />
          </center>
        ) : countries.length === 0 ? (
          <div className="mt-5 alert alert-danger text-center">
            Nothing to Display yet
          </div>
        ) : (
          <section>
            <div className="carousel-destination ftco-animate">
              <div className="row">
                {countries.map((item, index) => (
                  <div key={index} className="item col-md-4 mb-3">
                    <div className="project-destination">
                      <a
                        href={`/zm/destination-details/?country_id=${item.id}&slug=${item.slug}`}
                        className="img"
                        style={{ backgroundImage: `url(${item.image})` }}
                      >
                        <div className="text">
                          <h3 className="text-uppercase">{item.countryName}</h3>
                          <span>
                            <button className="btn btn-sm btn-primary">
                              <i className="fa fa-pencil"></i> Edit
                            </button>
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        )}
      </div>
    </>
  );
}

export default HolidayCountries;
