import axios from "axios";
import React, { useEffect, useState } from "react";
import moment from "moment";
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import { useParams } from "react-router-dom";
import LoadingSpinner from "../../../components/widgets/spinner";

const CustomerTickets = () => {
  const [number, setNumber] = useState(1); // No of pages
  const [ticketsPerPage] = useState(20);
  const [buses, setBuses] = useState([]);
  const [commuters, setCommuters] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [departureDate, setDepartureDate] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [q, setQ] = useState("");
  const [searchParam] = useState([
    "first_name",
    "last_name",
    "email",
    "ticket_no",
    "status",
  ]);
  const [filterParam, setFilterParam] = useState(["All"]);

  const { uid } = useParams();

  const fetchData = async () => {
    let status = "confirmed";
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/get_tickets/${uid}/${status}/rmf_travellers`
        )
        .then((res) => {
          setTickets(res.data);
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
      setMessage(error.message);
    }

    //  get commuter buses
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/buses/${uid}/rmf_travellers`)
        .then((res) => {
          setBuses(res.data);
          setIsLoading(false);
        });
    } catch (error) {}

    // get departure date
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/bus_time/${uid}/rmf_travellers`)
        .then((res) => {
          setDepartureDate(res.data);
          setIsLoading(false);
        });
    } catch (error) {}

    // get commuters
    // get users
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/userprofile/v1/intercity_travellers/get-commuters/rmf_travellers`)
        .then((users) => {
          setCommuters(users.data);
          setIsLoading(false);
        });
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  // pagination
  const lastTicket = number * ticketsPerPage;
  const firstTicket = lastTicket - ticketsPerPage;
  const currentTickets = tickets.slice(firstTicket, lastTicket);
  const pageNumber = [];

  for (let i = 1; i <= Math.ceil(tickets.length / ticketsPerPage); i++) {
    pageNumber.push(i);
  }

  const ChangePage = (pageNumber) => {
    setNumber(pageNumber);
  };
  const data = Object.values(currentTickets);

  function search(tickets) {
    return tickets.filter((item) => {
      if (item.departure_date == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.bus == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.status == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (filterParam == "All") {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }

  let ticketAmount = data.map((item) => parseInt(item.amount));

  let total_amount = ticketAmount.reduce(function (acc, val) {
    return acc + val;
  }, 0);
  function randomString(length, chars) {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  var token = randomString(100, `${localStorage.getItem("access")}`);
  return (
    <>
      <div className="py-4">
        <div className="main-content on-print gubu mt-5">
          <div className="col-lg-12 mt-4 mt-lg-0">
            {/* <!-- summary analytics --> */}
            <div className="row my-3 hide-on-print">
              <div className="col-md-6 mb-3">
                <div className="shadow border-0 rounded-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col mt-0">
                        <h6 className="card-title text-muted">
                          Confirmed Tickets
                        </h6>
                      </div>

                      <div className="col-auto">
                        <div className="stat text-primary">
                          <i
                            className="bi bi-ticket-detailed-fill"
                            style={{
                              fontSize: "2rem",
                              color: "rgb(192, 52, 52)",
                            }}
                          ></i>
                        </div>
                      </div>
                    </div>
                    <h5 className="mt-1 mb-3">{data.length}</h5>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mb-3">
                <div className="shadow border-0 rounded-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col mt-0">
                        <h6 className="card-title text-muted">
                          Confirmed Tickets Revenue
                        </h6>
                      </div>

                      <div className="col-auto">
                        <div className="stat text-primary">
                          <i
                            className="bi bi-cash-coin"
                            style={{
                              fontSize: "2rem",
                              color: "rgb(7, 145, 7)",
                            }}
                          ></i>
                        </div>
                      </div>
                    </div>
                    <h5 className="mt-1 mb-3">ZMW {total_amount}</h5>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- end of summary analytics --> */}
            <div className="row">
              <div className="col-md-12">
                <div className="container p-4 shadow rounded-3 hide-on-print">
                  <div className="row">
                    <div className="col-md-3">
                      <h6>Search:</h6>

                      <input
                        type="text"
                        className="form-control bg-transparent "
                        placeholder="search...."
                        value={q}
                        onChange={(e) => setQ(e.target.value)}
                      />
                    </div>
                    <div className="col-md-3 form-group">
                      <h6>Filter By Bus:</h6>
                      <select
                        onChange={(e) => {
                          setFilterParam(e.target.value);
                        }}
                        name="bus"
                        required
                        className="form-control"
                        aria-label="Filter Tickets By Bus"
                      >
                        <option value="All">All</option>
                        {buses.map((bus, index) => (
                          <option key={index} value={bus.id}>
                            {bus.driver} - {bus.number_place}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-3 form-group">
                      <h6>Filter By Date:</h6>
                      <select
                        onChange={(e) => {
                          setFilterParam(e.target.value);
                        }}
                        name="departure_date"
                        required
                        className="form-control"
                        aria-label="Filter Tickets By Departure Date/Time"
                      >
                        <option value="All">All</option>
                        {departureDate.map((item, index) => (
                          <option key={index} value={item.departure_date}>
                            {moment(item.departure_date).format(
                              "MMMM Do YYYY, H:m"
                            )}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-3 form-group">
                      <h6>Filter By Status:</h6>
                      <select
                        onChange={(e) => {
                          setFilterParam(e.target.value);
                        }}
                        name="status"
                        required
                        className="form-control"
                        aria-label="Filter Tickets By Departure Date/Time"
                      >
                        <option value="All">All</option>
                        <option value="pending">Pending Tickets</option>
                        <option value="confirmed">Confirmed Tickets</option>
                        <option value="cancelled">Cancelled Tickets</option>
                      </select>
                    </div>
                  </div>
                  {/*	Error Message*/}
                  {message && (
                    <p className="text-primary text-center">{message}</p>
                  )}
                </div>
                <div className="table-responsive">
                  <h3 class="text-capitalize mt-4 mb-4">Booked tickets</h3>
                  {isLoading ? (
                    <LoadingSpinner />
                  ) : tickets.length === 0 ? (
                    <div className="alert alert-danger text-center">
                      There are no Tickets
                    </div>
                  ) : (
                    <table
                      id="tickets example"
                      className="table table-striped data-table table-lg"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Ticket #</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>N.O.K</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Bus</th>
                          <th>Date of Departure</th>
                          <th>Paid</th>
                          <th className="hide-on-print">Status</th>
                          {/* <th colSpan="2">Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {search(data).map((ticket, index) => (
                          <tr className="text-dark bg-white" key={index}>
                            <td className="col-auto">
                              <small className="font-bold mb-0">
                                {index + 1}
                              </small>
                            </td>
                            <td className="col-auto">
                              <small className="font-bold mb-0">
                                {ticket.ticket_no}
                              </small>
                            </td>
                            <td className="col-auto">
                              <small className="font-bold mb-0 text-capitalize">
                                {ticket.first_name === null
                                  ? commuters.map((prof) =>
                                      prof.id === ticket.customer
                                        ? prof.first_name
                                        : null
                                    )
                                  : ticket.first_name}{" "}
                                {ticket.last_name === null
                                  ? commuters.map((prof) =>
                                      prof.id === ticket.customer
                                        ? prof.last_name
                                        : null
                                    )
                                  : ticket.last_name}
                              </small>
                            </td>
                            <td className="col-auto">
                              <small className="mb-0">
                                {ticket.phone_no === null
                                  ? commuters.map((prof) =>
                                      prof.id === ticket.customer
                                        ? prof.phone
                                        : null
                                    )
                                  : ticket.phone_no}
                              </small>
                            </td>
                            <td className="col-auto">
                              <small className="mb-0">
                                {ticket.phone_no === null
                                  ? commuters.map((prof) =>
                                      prof.id === ticket.customer
                                        ? prof.nof
                                        : null
                                    )
                                  : ticket.phone_no}
                              </small>
                            </td>
                            <td className="col-auto">
                              <small className="mb-0 text-capitalize">
                                {ticket.from_route}
                              </small>
                            </td>
                            <td className="col-auto">
                              <small className="mb-0 text-capitalize">
                                {ticket.to_route}
                              </small>
                            </td>
                            <td className="col-auto">
                              <small className=" mb-0">
                                {buses.map((buz) =>
                                  buz.id === ticket.bus
                                    ? buz.number_place
                                    : null
                                )}
                              </small>
                            </td>
                            <td className="col-auto">
                              <small className="mb-0">
                                {moment(ticket.departure_date).format(
                                  "Do MMMM, YYYY, HH:mm a"
                                )}
                              </small>
                            </td>
                            <td className="col-auto">
                              <small className="mb-0">K {ticket.amount}</small>
                            </td>
                            <td className="col-auto hide-on-print">
                              <small className="mb-0">{ticket.status}</small>
                            </td>
                            {/* <td className="col-auto">
                                                 <Link to={`ticket_details/${ticket.ticket_no}`}>View Details</Link>
                                             </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
                <p className="pull-right">
                  <a
                    href={`print_customer_tickets/${token}/${uid}`}
                    className="btn btn-primary hide-on-print"
                  >
                    <i className="fa fa-print"></i> Go to print Page
                  </a>
                  <div></div>
                </p>
                <div className="container pull-right">
                  {data.length < 50 ? null : (
                    <div className="pagination">
                      <div className="page-item">
                        <button
                          disabled={number === 1}
                          className="text-center btn-primary py-0"
                          onClick={() => setNumber(number - 1)}
                        >
                          <i className="fa fa-chevron-left"></i>
                        </button>
                      </div>

                      {pageNumber.map((Elem, index) => {
                        return (
                          <div key={index} className="page-item">
                            <button
                              className={`text-center text-white ${
                                number === Elem ? "active" : ""
                              } btn-outline-dark`}
                              onClick={() => ChangePage(Elem)}
                            >
                              {Elem}
                            </button>
                          </div>
                        );
                      })}
                      <div className="page-item">
                        <button
                          className="text-center btn-primary"
                          onClick={() => setNumber(number + 1)}
                        >
                          <i className="fa fa-chevron-right"></i>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerTickets;
