import React, { useState, useEffect } from "react";

import axios from "axios";
import AddTravels from "./add_tour";
import LoadingSpinner from "../../../components/widgets/spinner";

function HolidayDashboard() {
  const [travels, setTravel] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    const fetchData = async () => {
      try {
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/view_holiday_countries/rmf_travellers`,
            config
          )
          .then((res) => {
            setCountries(res.data);
            setisLoading(false);
          });
      } catch (error) {}
      try {
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/holidays/v1/intercity_travellers/view_travels/rmf_travellers`,
            config
          )
          .then((res) => {
            setTravel(res.data);
          });
      } catch (error) {}
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="mt-5">
        <AddTravels countries={countries} />
      </div>
      <div className="container">
        {isLoading ? (
          <center>
            <LoadingSpinner />
          </center>
        ) : travels.length === 0 ? (
          <div className="mt-5 alert alert-danger text-center">
            No Holiday Packages
          </div>
        ) : (
          <div className="row">
            {travels.map((item, index) => {
              return (
                <div className="mt-5 col-md-4" key={index}>
                  <div className="image-data">
                    <img
                      style={{ width: "100%", height: "250px" }}
                      className="rounded"
                      src={item.thumbnail}
                      alt=""
                    />
                  </div>
                  <h5 className="text-capitalize">
                    {item.title},
                    <small className="text-success"> {item.countryName}</small>
                  </h5>

                  <p>{item.description.substring(0, 130)}... </p>
                  <div className="pull-right">
                    <a
                      href={`/edit/${item.id}/travel`}
                      className="btn btn-outline-warning">
                      <i className="fa fa-pencil"></i> Edit
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}

export default HolidayDashboard;
