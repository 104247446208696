import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { UPDATE_URL } from "../../../urls";
import Swal from "sweetalert2";
import Offcanvas from "react-bootstrap/Offcanvas";
import Popover from "react-bootstrap/Popover";
import Overlay from "react-bootstrap/Overlay";
import { logout } from "../../../actions/auth";
import { Spinner } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { ProfileModal } from "../../homeComponents";

function SideBar(props, { logout, isAuthenticated }) {
  const [showProfile, setShowProfile] = useState(false);
  const [profileClosable, setProfileClosable] = useState(true);
  const [userdetail, setUserDetail] = useState(null);
  const [profileDetails, setProfileDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [show, setShow] = useState(false);

  //open pop over
  const [showPop, setShowPop] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event) => {
    setShowPop(!showPop);
    setTarget(event.target);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const SessionExpired = () => {
    return Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Session Expired!",
      footer: '<a href="/zm/login">Login to Continue</a>',
      allowOutsideClick: false,
      showConfirmButton: false,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };

      try {
        await axios
          .get(`${process.env.REACT_APP_API_URL}/auth/users/me/`, config)
          .then((res) => {
            setProfileDetails(res.data);
            setIsLoading(false);
          })
          .catch((err) => {
            if (err.message === "Request failed with status code 401") {
              SessionExpired();
            }
            console.log("could not load data => ", err.message);
          });
      } catch (error) {}
      // get user profile
      try {
        await axios.get(`${UPDATE_URL}/rmf_travellers`, config).then((res) => {
          setUserDetail(res.data);
        });
      } catch (error) {}
    };

    fetchData();
    if (profileDetails !== userdetail) {
      setUserDetail(userdetail);
      if (!profileDetails.n_r_c) {
        setShowProfile(false);
        setProfileClosable(false);
      }
    }

    // if (profileDetails.is_commutor === true) {
    //   window.location.href = `/zm/dashboard/?uid=${userdetail.id}`;
    // }
    // if (profileDetails.is_customer === true) {
    //   window.location.href = `/zm/profile/?uid=${userdetail.id}`;
    // }
  }, []);

  const logout_user = () => {
    logout();
    isAuthenticated = false;
    setRedirect(true);
  };

  const toggleSideBar = () => {
    const sideBar = document.getElementById("left-menu");
    if (sideBar.classList.contains("close")) {
      sideBar.classList.remove("close");
    } else {
      sideBar.classList.add("close");
    }
  };

  if (!userdetail) {
    return (
      <>
        <Spinner size="sm" />
      </>
    );
  }

  return (
    <>
      <ProfileModal
        {...props}
        close={() => setShowProfile(false)}
        userDetail={userdetail}
        user_id={profileDetails.id}
        visible={showProfile}
        closable={profileClosable}
        setClosable={() => setProfileClosable(true)}
      />
      <div id="warraper">
        <div id="logo">
          <a href="/">
            <span>Intercity Holiday Packages</span>
          </a>
        </div>
        {/* <span id="show-label-menu">
          
        </span> */}
        <div id="left-menu">
          <center>
            <div className="profile mt-3">
              <img
                src={`https://nregsmp.org/eService/images/User.png`}
                alt="profile"
              />
              <h4 className="text-capitalize">Administrator</h4>
              <h6>admin@gmail.com</h6>

              <button
                onClick={logout_user}
                className="btn btn-sm btn-outline-warning">
                <i className="bi bi-box-arrow-in-left"></i> Log out
              </button>
            </div>
          </center>
          <ul>
            <li>
              <Link to={`/zm/holiday_dashboard/?uid=1`}>
                <i className="fa fa-dashboard"></i>
                <span>Dashboard</span>
              </Link>
            </li>
            <hr />
            <li>
              <Link to={`/zm/holiday_dashboard/countries/?uid=1`}>
                <i className="fa fa-flag"></i>
                <span>Countries</span>
              </Link>
            </li>
            <li>
              <Link to={`/zm/holiday_dashboard/holiday_packages/?uid=1`}>
                <i className="fa fa-compass"></i>
                <span>Holiday Packages</span>
              </Link>
            </li>
          </ul>
        </div>
        <div id="top-header">
          <i
            onClick={toggleSideBar}
            id="toggle-menu"
            style={{ cursor: "pointer" }}
            className="fa fa-list"></i>
          <span onClick={handleClick} className="d-inline-block user">
            <i className="fa fa-user"></i>
          </span>
          <div ref={ref}>
            <Overlay
              show={showPop}
              target={target}
              placement="bottom"
              container={ref}
              containerPadding={20}>
              <Popover id="popover-contained">
                <Popover.Header as="h4" className="text-capitalize">
                  john doe
                </Popover.Header>
                <Popover.Body>
                  <div
                    onClick={handleShow}
                    style={{ cursor: "pointer" }}
                    className="mb-3">
                    <strong>
                      <i className="fa fa-user"></i> My Profile
                    </strong>
                  </div>
                  <a href="#!" style={{ cursor: "pointer" }}>
                    <strong>
                      <i className="bi bi-box-arrow-right"></i>
                    </strong>{" "}
                    Logout
                  </a>
                </Popover.Body>
              </Popover>
            </Overlay>
          </div>

          <i className="icon-speech float-right"></i>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, { logout })(SideBar);
