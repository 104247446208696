const sidebarNav = [
  {
    link: "/zm/dashboard/routes",
    section: "routes",
    icon: <i className="fa fa-road"></i>,
    text: "Routes",
  },
  {
    link: "/zm/dashboard/stations",
    section: "stations",
    icon: <i className="fa fa-bus"></i>,
    text: "Stations",
  },
  {
    link: "/zm/dashboard/buses",
    section: "buses",
    icon: <i className="fa fa-car"></i>,
    text: "Buses",
  },

  {
    link: "/zm/dashboard/busfares",
    section: "busfares",
    icon: <i className="fa fa-bus"></i>,
    text: "Busfares",
  },
  {
    link: "/zm/dashboard/customer-tickets",
    section: "customer tickets",
    icon: <i className="fa fa-ticket"></i>,
    text: "Customer Tickets",
  },
  {
    link: "/zm/dashboard/employees",
    section: "Employees",
    icon: <i className="fa fa-users"></i>,
    text: "Employees",
  },
  // {
  //   link: "/zm/dashboard/cancelled-tickets",
  //   section: "cancelled tickets",
  //   icon: <i className="fa fa-ticket"></i>,
  //   text: "Cancelled Tickets",
  // },
];

export default sidebarNav;
