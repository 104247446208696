import React, { useEffect, useState } from "react";

import appleStore from "../../images/app-store.png";
import google from "../../images/google-play.png";
import { Reveal } from "../../reveal";
import ToggleSwitch from "../../theme/theme";
import LaunchingSoon from "../widgets/launching_soon";

const Footer = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isDark, setIsDark] = useState(true);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
      setIsDark(true);
    } else {
      setTheme("light");
      setIsDark(false);
    }
  };
  useEffect(() => {
    localStorage.setItem("theme", theme);
    document.body.className = theme;
  }, [theme, isDark]);

  return (
    <>
      <Reveal>
        <footer className="ftco-footer bg-bottom ftco-no-pt">
          <div className="container text-white">
            <div className="row">
              <div className="col-md pt-3">
                <div className="ftco-footer-widget pt-md-3 mb-4">
                  <h2 className="ftco-heading-2">About <span className="text-danger">Intercity Travellers</span></h2>
                  <p>
                    Intercity Travellers is Zambia's best online bus search
                    engine which provides the best Real-Time, Cloud Based,
                    Integrated Bus Ticketing and Hotel Management System
                    developed by REUMAIFAB TECH...{" "}
                    <a href="/zm/about-us">read more</a>
                  </p>
                  <ul className="ftco-footer-social list-unstyled float-md-left float-lft">
                    {/* <li className="ftco-animate">
                      <a href="#">
                        <span className="fa fa-twitter"></span>
                      </a>
                    </li> */}
                    <li className="ftco-animate">
                      <a
                        target="_blank"
                        href="https://www.facebook.com/profile.php?id=100066576531150"
                      >
                        <span className="fa fa-facebook"></span>
                      </a>
                    </li>
                    <li className="ftco-animate">
                      <a target="_blank" href="https://www.instagram.com/inter_citytravelers?utm_source=qr&igshid=MzNlNGNkZWQ4Mg==">
                        <span className="fa fa-instagram"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md pt-3 border-left">
                <div className="ftco-footer-widget pt-md-3 mb-4 ml-md-5">
                  <h2 className="ftco-heading-2">Information</h2>
                  <ul className="list-unstyled">
                    <li>
                      <a href="/terms" className="py-2 d-block">
                        Terms & Conditions
                      </a>
                    </li>

                    <li>
                      <a href="/zm/privacy-policy" className="py-2 d-block">
                        Privacy and Policy
                      </a>
                    </li>
                    <li>
                      <a href="/zm/contact-us" className="py-2 d-block">
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <a href="/zm/about-us" className="py-2 d-block">
                        About Us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md pt-3 border-left">
                <div className="ftco-footer-widget pt-md-3 mb-4">
                  <h2 className="ftco-heading-2">Experience</h2>
                  <ul className="list-unstyled">
                    <li>
                      <a href="/zm/zambia-tourism" className="py-2 d-block">
                        Tourism
                      </a>
                    </li>
                    <li>
                      <a
                        href="/zm/holiday-destinations"
                        className="py-2 d-block"
                      >
                        Holiday Destinations
                      </a>
                    </li>

                    <li>
                      <a
                        href="/zm/bus-ticket-reservation"
                        className="py-2 d-block"
                      >
                        Bus Ticket Reservation
                      </a>
                    </li>
                    {/* <li>
                      <a href="/zm/hotels-lodges" className="py-2 d-block">
                        Hotels and Lodges
                      </a>
                    </li> */}

                    <div className="row">
                      <div className="col-md-6">
                        <a
                          target="_blank"
                          href="https://play.google.com/store/apps/details?id=com.reumaifab.intercitytravellers"
                          className="py-2 d-block"
                        >
                          <img
                            className="text-warning"
                            src={google}
                            width={100}
                            alt=""
                            srcset=""
                          />
                        </a>
                      </div>
                      <div className="col-md-6">
                        {/* <a
                          onClick={handleShow}
                          style={{ cursor: "pointer" }}
                          className="py-2 d-block"
                        >
                          <img
                            className="text-warning"
                            src={appleStore}
                            width={100}
                            alt=""
                            srcset=""
                          />
                        </a> */}
                      </div>
                    </div>
                    {/* <center>
                      <ToggleSwitch
                        id="switch"
                        checked={isDark}
                        onChange={toggleTheme}
                      />
                    </center> */}
                  </ul>
                </div>
              </div>
              <div className="col-md pt-3 border-left">
                <div className="ftco-footer-widget pt-md-3 mb-4">
                  <h2 className="ftco-heading-2">Have a Questions?</h2>
                  <div className="block-23 mb-3">
                    <ul>
                      <li>
                        <span className="icon fa fa-map-marker"></span>
                        <span className="text">
                          Plot No. 13 Nchenja Road Off Great East road north
                          mead lusaka
                        </span>
                      </li>
                      <li>
                        <a href="tel:+260971228773">
                          <span className="icon fa fa-phone"></span>
                          <span className="text">+260971228773</span>
                        </a>
                      </li>
                      <li>
                        <a href="tel:+260974516215">
                          <span className="icon fa fa-phone"></span>
                          <span className="text">+260974516215</span>
                        </a>
                      </li>
                      <li>
                        <a href="mailto:intercitytraveller01@gmail.com">
                          <span className="icon fa fa-envelope"></span>
                          <span className="text">
                            intercitytraveller01@gmail.com
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <LaunchingSoon show={show} handleClose={handleClose} />
            <div className="col-md-12 text-center">
              <p>
                Copyright &copy;
                {new Date().getFullYear()} All rights reserved | Intercity
                Travellers{" "}
                <i className="fa fa-heart text-danger" aria-hidden="true"></i>{" "}
                by{" "}
                <a
                  className="text-danger"
                  href="https://reumaifab.com"
                  target="_blank"
                >
                  Reumaifab
                </a>
              </p>
            </div>
          </div>
        </footer>
      </Reveal>
    </>
  );
};
export default Footer;
