import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { useRetrieveUserQuery } from "../../redux/slices/authApiSlice";
import { User } from "../../redux/types/user";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { resetUserProfile } from "../../redux/features/profile/profile";
import { useLogoutMutation } from "../../redux/slices/authApiSlice";
import { logout as setLogout } from "../../redux/features/authSlice";
import { toast } from "react-toastify";

const Navigation = () => {
  const [isOnLine, setisOnLine] = React.useState<boolean>()

  function poopityScoop() {
    window.ononline = (event) => {
      setisOnLine(true);
      console.log("Back Online");
    };

    window.onoffline = (event) => {
      setisOnLine(false);
      console.log("Connection Lost");
    };
  }
  const location = useLocation();
  const token = useAppSelector((state) => state.auth.token);
  const dispatch = useAppDispatch();
  const [logout] = useLogoutMutation();
  const {
    data: user,
    isLoading,
    isSuccess,
    error,
  }: User | any = useRetrieveUserQuery();

  const handleLogout = () => {
    dispatch(setLogout());
    dispatch(resetUserProfile());
    // logout(undefined)
    //   .unwrap()
    //   .then(() => {
    //     dispatch(setLogout());
    //     dispatch(resetUserProfile());
    //   });
  };
  React.useEffect(() => {
    poopityScoop();
    console.log(
      navigator.onLine === true ? setisOnLine(true) : setisOnLine(false)
    );
  }, [isOnLine, token]);

  const guestLinks = () => (
    <Fragment>
      <li
        className={`nav-item ${
          location.pathname === "/zm/auth/login" ? "active" : ""
        }`}>
        <Link to="/zm/auth/login" className="nav-link">
          Login
        </Link>
      </li>
      <li
        className={`nav-item ${
          location.pathname === "/zm/auth/register" ? "active" : ""
        }`}>
        <Link to="/zm/auth/register" className="nav-link">
          Register
        </Link>
      </li>
    </Fragment>
  );

  const authLinks = () => (
    <Fragment>
      {user?.user_type === "COMMUTER" ? (
        <li
          className={`nav-item ${
            location.pathname === "/zm/dashboard/" ? "active" : ""
          }`}>
          <a
            className="nav-link scrollto"
            href={`/zm/dashboard/?uid=${user?.id}`}>
            Dashboard
          </a>
        </li>
      ) : user?.user_type === "HOTEL" ? (
        <li
          className={`nav-item ${
            location.pathname === "/zm/hotel_dashboard/" ? "active" : ""
          }`}>
          <a
            className="nav-link scrollto"
            href={`/zm/hotel_dashboard/?uid=${user?.id}`}>
            Dashboard
          </a>
        </li>
      ) : user?.user_type === "CARHIRE" ? (
        <li
          className={`nav-item ${
            location.pathname === "/zm/car_dashboard/" ? "active" : ""
          }`}>
          <a
            className="nav-link scrollto"
            href={`/zm/car_dashboard/?uid=${user?.id}`}>
            Dashboard
          </a>
        </li>
      ) : user?.user_type === "ADMIN" ? (
        <li
          className={`nav-item ${
            location.pathname === "/zm/intercity_travellers_admin/"
              ? "active"
              : ""
          }`}>
          <a
            className="nav-link scrollto"
            href={`/zm/intercity_travellers_admin/?uid=${user?.id}`}>
            Admin
          </a>
        </li>
      ) : (
        <li
          className={`nav-item ${
            location.pathname === "/zm/profile/" ? "active" : ""
          }`}>
          <a
            className="nav-link scrollto"
            href={`/zm/profile/?uid=${user?.id}`}>
            Profile
          </a>
        </li>
      )}

      <li className={`nav-item`}>
        <a className="nav-link" href="#!" onClick={handleLogout}>
          Logout
        </a>
      </li>
    </Fragment>
  );
  return (
    <>
    {/* {isLoading ? toast.success("Connected") : toast.error("No Internet Connection")} */}
      <nav
        className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light"
        id="ftco-navbar">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            Intercity<span>Travellers</span>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#ftco-nav"
            aria-controls="ftco-nav"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="oi oi-menu"></span> <i className="bi bi-list"></i>
          </button>

          <div className="collapse navbar-collapse" id="ftco-nav">
            <ul className="navbar-nav ml-auto">
              <li
                className={`nav-item ${
                  location.pathname === "/" ? "active" : ""
                }`}>
                <a href="/" className="nav-link">
                  Home
                </a>
              </li>
              <li
                className={`nav-item ${
                  location.pathname === "/zm/bus-ticket-reservation"
                    ? "active"
                    : ""
                }`}>
                <a href="/zm/bus-ticket-reservation" className="nav-link">
                  Bus Tickets
                </a>
              </li>
              {/* <li className={`nav-item ${
            location.pathname === "/" ? "active" : ""
          }`}>
                <a href="/zm/hotels-lodges" className="nav-link">
                  Hotels / Lodges
                </a>
              </li> */}
              <li
                className={`nav-item ${
                  location.pathname === "/zm/holiday-destinations"
                    ? "active"
                    : ""
                }`}>
                <a href="/zm/holiday-destinations" className="nav-link">
                  Holiday Destinations
                </a>
              </li>
              <li
                className={`nav-item ${
                  location.pathname === "/zm/car_rentals/" ? "active" : ""
                }`}>
                <a href="/zm/car_rentals/" className="nav-link">
                  Care Hire
                </a>
              </li>
              <li
                className={`nav-item ${
                  location.pathname === "/zm/zambia-tourism" ? "active" : ""
                }`}>
                <a href="/zm/zambia-tourism" className="nav-link">
                  Zambia
                </a>
              </li>

              <li
                className={`nav-item ${
                  location.pathname === "/zm/blogs" ? "active" : ""
                }`}>
                <a href="/zm/blogs" className="nav-link">
                  Blog
                </a>
              </li>
              <li
                className={`nav-item ${
                  location.pathname === "/zm/about-us" ? "active" : ""
                }`}>
                <a href="/zm/about-us" className="nav-link">
                  About
                </a>
              </li>
              <li
                className={`nav-item ${
                  location.pathname === "/zm/contact-us" ? "active" : ""
                }`}>
                <a href="/zm/contact-us" className="nav-link">
                  Contact
                </a>
              </li>

              {token ? authLinks() : guestLinks()}
              <li className="nav-item mt-3">
                <a href="/zm/get_started" className="btn btn-sm btn-primary">
                  Get Started
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
