import React, { useState, useEffect } from "react";
import axios from "axios";

import { Carousel } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AddBranch from "./branches/add_branch";
import LoadingSpinner from "../../components/widgets/spinner";
import BranchDetails from "./show_branch_details";
import { capitalized } from "../../actions/utils";
import { urlToken } from "../../helper";
import { useAppSelector } from "../../hooks/redux-hooks";

function HotelBranches() {
  const user = useAppSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(true);
  const [hotels, setHotels] = useState([]);
  const [showBranchDetails, setShowBranchDetailsModal] = useState(false);
  const [branch, setBranch] = useState(null);
  const handleShowBranchDetails = (branch: any) => {
    setShowBranchDetailsModal(true);
    setBranch(branch);
  };
  const handleCloseBranchDetails = () => {
    setShowBranchDetailsModal(false);
    // setBranch(null);
  };

  const fetchData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/hotels/v1/intercity_travellers/get_user_hotels/${user?.id}/rmf_travellers`,
        config
      )
      .then((res) => {
        setHotels(res.data);
        setIsLoading(false);
      });

  };

  useEffect(() => {
    fetchData();
  }, []);

  const queryParams = new URLSearchParams(window.location.search);
  const hotel = queryParams.get("hotel");

  return (
    <>
      <div className="mainContainer">
        {/* add Branch */}
        <section>
          <div className="wrapper align-items-stretch">
            <div id="content" className="p-2 p-md-3 pt-3">
              <div className="mt-5 mb-5">
                <AddBranch hotel={hotel} uid={user?.id} />
              </div>
              <div className="text-center mt-5">
                <h1 className="text-center text-dark mt-5">Branches </h1>
              </div>
              <div className="container-fluid mt-5">
                {isLoading ? (
                  <>
                    <LoadingSpinner />
                  </>
                ) : hotels.length === 0 ? (
                  <div className="alert alert-danger text-center mt-5">
                    You haven't added any Branch
                  </div>
                ) : (
                  <div className="row">
                    {hotels.map((hotel: any, index) => (
                      <div key={index} className="col-md-4 col-sm-4 mb-3">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => handleShowBranchDetails(hotel)}>
                          <Carousel fade indicators={false} controls={false}>
                            {hotel.branch_images.map((item: any, idx: number) => {
                              return (
                                <Carousel.Item key={idx}>
                                  <img
                                    className="img-responsive"
                                    src={item.image}
                                    style={{
                                      borderRadius: "10px",
                                      width: "100%",
                                      height: "250px",
                                    }}
                                    alt={hotel.hotel_name}
                                  />
                                </Carousel.Item>
                              );
                            })}
                          </Carousel>

                          <h6 className="text-capitalize">
                            {hotel.hotel_name} <br />
                            <small className="text-info text-center">
                              <span className="text-dark"></span>
                              {hotel.state},{" "}
                              {hotel.city}
                            </small>
                          </h6>
                        </a>
                        {/* <a className="text-capitalize" href={`https://facebook.com/${hotel.facebook_url}`}> <i className="fa fa-facebook"></i> Facebook</a> */}
                        <blockquote>
                          <i className="text-dark">
                            {capitalized(
                              hotel.hote_description.substring(0, 150)
                            )}
                            ...{" "}
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => handleShowBranchDetails(hotel)}
                              className="text-danger">
                              Read More
                            </a>
                          </i>
                        </blockquote>
                        <div className="row">
                          <div className="col-md-6">
                            <a
                              className="btn btn-sm btn-info"
                              href={`/zm/hotel_dashboard/hotel_rooms/?branch=${hotel.id}`}>
                              Branch Details{" "}
                              <i className="fa fa-arrow-right"></i>
                            </a>
                          </div>
                          <div className="col-md-6">
                            <a
                              className="btn btn-sm btn-primary"
                              href={`/zm/hotel_dashboard/edit_hotel/?branch=${hotel.id}`}>
                              <i className="fa fa-edit"></i> Edit
                            </a>
                          </div>
                        </div>

                        {/* view place details modal */}
                        <BranchDetails
                          branch={branch === null ? hotel : branch}
                          show={showBranchDetails}
                          handleClose={handleCloseBranchDetails}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default HotelBranches;
