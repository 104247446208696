import { motion } from "framer-motion";
import { styles, urlToken } from "../../helper";
import { Reveal } from "../../reveal";

const Branches = ({ branches }) => {
  return (
    <>
      <Reveal>
        {branches.length === 0 ? (
          <div className="text-center mt-5 mb-5">
            <i className="fa fa-bed fa-5x"></i>
          </div>
        ) : (
          <div class="section" id="section-team">
            <div class="row">
              {branches.map((item, index) => (
                <div
                  data-aos="fade-up"
                  data-aos-delay={index * 250}
                  key={index}
                  class="col-md-6 col-lg-4">
                  <div class="block-2">
                    <div class="flipper">
                      <div
                        class="front"
                        style={{ backgroundImage: `url(${item.image1})` }}>
                        <div class="box text-danger">
                          <h1 className="text-capitalize text-danger">
                            {item.hotel_name}
                          </h1>
                          <p className="text-danger text-capitalize">
                            {item.address}
                          </p>
                        </div>
                      </div>
                      <div class="back">
                        {/* <!-- back content --> */}
                        <blockquote>
                          <p>
                            &ldquo;{item.hote_description.substring(0, 150)}
                            &rdquo;
                          </p>
                          <br />
                        </blockquote>

                        <div class="author d-flex">
                          <div class="name align-self-center text-capitalize">
                            <i className="fa fa-bed"></i>
                            {item.hotel_name}
                            <br /> <i className="fa fa-explore"></i>
                            <b className="text-capitalize">
                              {item.district.district_name}

                              {" Branch"}
                            </b>
                            <span class="position">
                              <i className="fa fa-map-marker"></i>
                              {item.address}
                            </span>
                          </div>
                          <motion.a
                            href={`/zm/hotel-details/?name=${item.hotel_name}&branch_id=${item.id}&token=${urlToken}&undefined=${item.user.id}`}
                            style={styles}
                            className="pull-right"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9, x: "-5px", y: "5px" }}>
                            Details
                          </motion.a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- .flip-container --> */}
                </div>
              ))}
            </div>
          </div>
        )}
      </Reveal>
    </>
  );
};

export default Branches;
