import { apiSlice } from "../services/apiSlice";
import { IUserProfile, User } from "../types/user";

export const userAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateUser: builder.mutation<User, Partial<User>& Pick<User, 'id'>>({
      query: ({ id, ...patch }) =>({
          url: `/userprofile/v1/intercity_travellers/user_profile/rmf_travellers/${id}/`,
          method: "PATCH",
          body: patch,
      }),
      invalidatesTags: ["User"],
      transformResponse: (response: { data: User }) => response.data,
      transformErrorResponse: (
        response: { status: string | number },
      ) => response.status,
      
    }),
    getUser: builder.query<User, number>({
      query: (id) => ({
          url: `/auth/users/me/`,
      }),
      transformErrorResponse: (
        response: { status: string | number },
      ) => response.status,
      providesTags: (result, error, id) => [{ type: 'User', id }],
    }),
    deleteUser: builder.mutation<User, Pick<User, 'id'>>({
      query:(id) =>({
          url: `/userprofile/v1/intercity_travellers/user_profile/rmf_travellers/${id}/`,
          method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'User', id: arg.id }],
      transformErrorResponse: (
        response: { status: string | number },
      ) => response.status,
      
    }),
    // USER PROFILE
    // CREATE PROFILE
    createUserProfile: builder.mutation<IUserProfile, User>({
      query({...patient}) {
        console.log("CREATE PROFILE" + patient);
        return {
          url: "/userprofile/v1/intercity_travellers/user_profile/rmf_travellers/",
          method: "POST",
          body: patient,
        };
      },
      invalidatesTags: ['IUserProfile'],
      transformResponse: (result: { patient: IUserProfile }) => result.patient,
      transformErrorResponse: (
        response: { status: string | number },
      ) => response.status,
    }),
    // UPDATE PROFILE
    updateProfile: builder.mutation<IUserProfile, Partial<IUserProfile>& Pick<IUserProfile, 'id'>>({
      query({id, ...patch}){
        console.log("UPDATE PROFILE" + patch);
          return {
            url: `/userprofile/v1/intercity_travellers/user_profile/rmf_travellers/${id}/`,
            method: "PATCH",
            body: patch,
          };
      },
      invalidatesTags: (result, error, arg) => [{ type: 'IUserProfile', id: arg.id }],
      transformResponse: (response: { patient: IUserProfile }) => response.patient,
      transformErrorResponse: (
        response: { status: string | number },
      ) => response.status,
    }),
    // CHANGE PASSWORD
  updateUserPassword: builder.mutation<User, Partial<User>& Pick<User, 'id'>>({
    query: ({ id, ...patch }) =>({
        url: `/userprofile/v1/intercity_travellers/update_user_password/rmf_travellers`,
        method: "PATCH",
        body: patch,
    }),
    invalidatesTags: ["User"]    
  }),
  }),
  
});
export const {
  useCreateUserProfileMutation,
  useDeleteUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,  
  useUpdateProfileMutation,
  useUpdateUserPasswordMutation
} = userAPI;
