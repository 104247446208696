import { apiSlice } from "../services/apiSlice";
import { IBranch } from "../types/hotel_branch.type";

export const branchAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createBranch: builder.mutation<
      IBranch,
      Partial<IBranch>
    >({
      query(data) {
        return {
          url: `/hotels/v1/intercity_travellers/create_hotel/rmf_travellers`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["IBranch"],
      transformResponse: (result: { branch: IBranch }) =>
        result.branch,
    }),
    updateBranch: builder.mutation<
      IBranch,
      { id: string; branch: IBranch }
    >({
      query: ({ id, branch }) => ({
        url: `/hotels/v1/intercity_travellers/edit_hotel/${id}/rmf_travellers`,
        method: "PATCH",
        body: branch,
      }),
      invalidatesTags: ["IBranch"],
      transformResponse: (response: { branch: IBranch }) =>
        response.branch,
    }),
    getBranch: builder.query<IBranch, any>({
      query: (id) => ({
        url: `/hotels/v1/intercity_travellers/edit_hotel/${id}/rmf_travellers`,
      }),
      providesTags: ["IBranch"],
    }),
    getBranches: builder.query<IBranch[], number>({
      query: (uid) => `/hotels/v1/intercity_travellers/get_user_hotels/${uid}/rmf_travellers`,
      transformResponse: (res: IBranch[]) =>
        res.sort((a, b) => b.id - a.id),
      providesTags: ["IBranch"],
      // keepUnusedDataFor: 5,
    }),
    deleteBranch: builder.mutation<IBranch, number>({
      query: (id) => ({
        url: `/hotels/v1/intercity_travellers/room_reservations/rmf_travellers/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["IBranch"],
    }),
  }),
});
export const {
  useCreateBranchMutation,
  useDeleteBranchMutation,
  useGetBranchQuery,
  useGetBranchesQuery,
  useUpdateBranchMutation,
} = branchAPI;
