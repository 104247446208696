import React, { useEffect, useRef } from "react";
import { Carousel, Spinner } from "react-bootstrap";
import axios from "axios";
import RoomCard from "./room_card";
import { useAnimate, usePresence } from "framer-motion";
import Branches from "./branches";
import LoadingSpinner from "../widgets/spinner";
import { capitalized } from "../../actions/utils";
import { Reveal } from "../../reveal";
import ImageGallery from "../widgets/gallery/gallery";
import user from "../../images/user.png";
import moment from "moment";
import { Rating } from "react-simple-star-rating";

const HotelDetails = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const branch_id = queryParams.get("branch_id");
  const hotelID = queryParams.get("undefined");
  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();
  const [rooms, setRooms] = React.useState([]);
  const [hotel, setHotel] = React.useState([]);
  const [branches, setBranches] = React.useState([]);
  const [districts, setDistricts] = React.useState([]);
  const [searching, setSearching] = React.useState(true);
  const [isLoading, setisLoading] = React.useState(true);
  const scrollRef = useRef(null);
  const scrowDown = () => scrollRef.current.scrollIntoView();

  const ImgUrl = "hotels/v1/intercity_travellers/retrieve_branch_images";
  useEffect(() => {
    const fetchData = async () => {
      try {
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/districts/rmf_travellers`
          )
          .then((res) => {
            setDistricts(res.data);
            setSearching(false);
          });
      } catch (error) {}
      try {
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/hotels/v1/intercity_travellers/hotel_details/${branch_id}/rmf_travellers`
          )
          .then((response) => {
            setHotel(response.data);
            setisLoading(false);
          });
      } catch (error) {
        setisLoading(false);
        console.log(error.message);
      }
      // get rooms
      try {
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/hotels/v1/intercity_travellers/hotel_rooms/${branch_id}/rmf_travellers`
          )
          .then((response) => {
            setRooms(response.data);
            setisLoading(false);
          });
      } catch (error) {
        setisLoading(false);
        console.log(error.message);
      }
      // get rooms
      try {
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/hotels/v1/intercity_travellers/get_user_hotels/${hotelID}/rmf_travellers`
          )
          .then((response) => {
            setBranches(response.data);
            setisLoading(false);
          });
      } catch (error) {
        setisLoading(false);
        console.log(error.message);
      }
    };
    fetchData();
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [0, 1] },
          { duration: 0.5, delay: 0.2 }
        );
      };
      enterAnimation();
    } else {
      const exitAnimation = async () => {
        await animate(
          scope.current,
          { opacity: [0, 1] },
          { duration: 0.5, delay: 0.2 }
        );
        safeToRemove();
      };
      exitAnimation();
    }
  }, []);
  return (
    <>
      <section
        className="site-hero overlay"
        style={{ backgroundImage: `url(${hotel.image2})` }}
        data-stellar-background-ratio="0.5"
        id="section-home">
        {searching ? (
          <center>
            <LoadingSpinner />
          </center>
        ) : (
          <div className="container">
            <div className="row site-hero-inner justify-content-center align-items-center">
              <div className="col-md-10 text-center" data-aos="fade-up">
                <h1 className="heading text-capitalize">{hotel.hotel_name}</h1>
                <h3 className="subheading text-capitalize text-white">
                  <b>
                    <span className="text-warning">
                      {districts.map((item) =>
                        item.id === hotel.district ? item.district_name : ""
                      )}
                    </span>{" "}
                    Branch
                  </b>
                </h3>
              </div>
            </div>
          </div>
        )}

        <a className="mouse smoothscroll" onClick={scrowDown}>
          <div className="mouse-icon">
            <span className="mouse-wheel"></span>
          </div>
        </a>
      </section>
      {/* <!-- END section --> */}

      {isLoading ? (
        <center>
          <Spinner />
        </center>
      ) : (
        <>
          <section ref={scrollRef} className="py-5 bg-light" id="section-about">
            <div className="container">
              <Reveal>
                <div className="row align-items-center">
                  <div
                    className="col-md-12 col-lg-7 ml-auto order-lg-2 position-relative mb-5"
                    data-aos="fade-up">
                    {/* carusel down */}
                    <Carousel fade indicators={false} controls={false}>
                      <Carousel.Item className="hotel-carousel">
                        <img
                          className="d-block w-100 img-fluid rounded"
                          src={hotel.image1}
                          alt={hotel.hotel_name}
                        />
                      </Carousel.Item>
                      <Carousel.Item className="hotel-carousel">
                        <img
                          className="d-block w-100 img-fluid rounded"
                          src={hotel.image2}
                          alt={hotel.hotel_name}
                        />
                      </Carousel.Item>
                    </Carousel>
                  </div>
                  <div
                    className="col-md-12 col-lg-4 order-lg-1"
                    data-aos="fade-up">
                    <h2 className="heading mb-4">Hey there!</h2>
                    <p className="mb-5">
                      {capitalized(hotel.hote_description)}
                    </p>
                    {/* <p>
                      <a
                        href="https://vimeo.com/channels/staffpicks/93951774"
                        data-fancybox
                        className="btn btn-primary text-white py-2 mr-3 text-uppercase letter-spacing-1"
                      >
                        Watch the video
                      </a>
                    </p> */}
                  </div>
                </div>
              </Reveal>
              <h1 className="mt-4">General Facilities</h1>
              <hr className="facilities" />
              <Reveal>
                <div className="row">
                  {hotel.amenities.map((item, index) => (
                    <div key={index} className="col-md-4 text-capitalize">
                      <i className="fa fa-check-square text-success"></i> {item}
                    </div>
                  ))}
                </div>
              </Reveal>
            </div>
          </section>
          <h2 className="text-capitalize text-center">
            <b>Our Rooms</b>
            <hr className="ourRooms" />
          </h2>
          <RoomCard room={rooms} />
          <section className="py-5 bg-light">
            <div className="container">
              <ImageGallery branch={branch_id} imgUrl={ImgUrl} />
              <section>
                <div className="mt-3 mb-5">
                  <h3
                    className="mb-3"
                    style={{ fontSize: "20px", fontWeight: "bold" }}>
                    {hotel.branch_reviews?.length === 0 ? (
                      <div></div>
                    ) : (
                      <span>
                        <h3>{hotel.branch_reviews?.length} Reviews</h3>
                        <h1>
                          <Rating
                            size={50}
                            initialValue={hotel.rating_avg}
                            transition
                            allowFraction
                            showTooltip
                            tooltipDefaultText={`${hotel.rating_avg}/5`}
                            allowTitleTag={false}
                            readonly
                            tooltipStyle={{
                              paddingLeft: "2px",
                              paddingRight: "2px",
                              paddingBottom: "0px",
                              paddingTop: "0px",
                            }}
                            /* Available Props */
                          />
                        </h1>
                      </span>
                    )}
                  </h3>

                  <ul className="comment-list">
                    {hotel.branch_reviews?.length < 1 ? (
                      <div className="alert alert-info text-center">
                        <i className="fa fa-comments fa-5x"></i>
                        <p>No Reviews</p>
                      </div>
                    ) : (
                      hotel.branch_reviews?.map((item, index) => (
                        <li key={index} className="comment">
                          <div className="vcard bio">
                            <img src={user} alt="Image placeholder" />
                          </div>
                          <div className="comment-body">
                            <h3 className="text-capitalize">
                              {item.user?.first_name} {item.user?.last_name}{" "}
                              <Rating
                                size={18}
                                initialValue={item.rating}
                                transition
                                allowFraction
                                showTooltip
                                tooltipDefaultText={`${item.rating}`}
                                allowTitleTag={false}
                                readonly
                                tooltipStyle={{
                                  paddingLeft: "3px",
                                  paddingRight: "3px",
                                  paddingBottom: "0px",
                                  paddingTop: "0px",
                                }}
                                /* Available Props */
                              />
                            </h3>
                            <div className="">
                              {moment(item.created_at).format("MMMM Do YYYY")}
                            </div>
                            <p>
                              <blockquote>
                                {capitalized(item.review)}
                              </blockquote>
                            </p>
                            {/* <p>
                        <a href="#" className="reply">
                          Reply
                        </a>
                      </p> */}
                          </div>
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              </section>
              <h2 className="text-capitalize">
                More From{" "}
                <span className="text-warning text-uppercase">
                  <b className="text-uppercase">{hotel.hotel_name}</b>
                </span>
              </h2>
              <Branches
                branches={branches.filter((item) => item.id != hotel.id)}
                districts={districts}
              />
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default HotelDetails;
