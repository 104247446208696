import { apiSlice } from "../services/apiSlice";
import { ICarBrand } from "../types/car_rental.type";


export const carBrands = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createBrand: builder.mutation<
      ICarBrand,
      Partial<ICarBrand>
    >({
      query(data) {
        return {
          url: `/cars/v1/intercity_travellers/car_brands/rmf_travellers/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["ICarBrand"],
      transformResponse: (result: { branch: ICarBrand }) =>
        result.branch,
    }),
    updateBrand: builder.mutation<
      ICarBrand,
      { id: string; branch: ICarBrand }
    >({
      query: ({ id, branch }) => ({
        url: `/cars/v1/intercity_travellers/car_brands/rmf_travellers/${id}`,
        method: "PATCH",
        body: branch,
      }),
      invalidatesTags: ["ICarBrand"],
      transformResponse: (response: { branch: ICarBrand }) =>
        response.branch,
    }),
    getBrand: builder.query<ICarBrand, any>({
      query: (id) => ({
        url: `/cars/v1/intercity_travellers/car_brands/rmf_travellers/${id}`,
      }),
      providesTags: ["ICarBrand"],
    }),
    getBrands: builder.query<ICarBrand[], void>({
      query: (uid) => `/cars/v1/intercity_travellers/car_brands/rmf_travellers/`,
      transformResponse: (res: ICarBrand[]) =>
        res.sort((a, b) => b.id - a.id),
      providesTags: ["ICarBrand"],
      // keepUnusedDataFor: 5,
    }),
    deleteBrand: builder.mutation<ICarBrand, number>({
      query: (id) => ({
        url: `/cars/v1/intercity_travellers/car_brands/rmf_travellers/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ICarBrand"],
    }),
  }),
});
export const {
  useCreateBrandMutation,
  useDeleteBrandMutation,
  useGetBrandQuery,
  useGetBrandsQuery,
  useUpdateBrandMutation,
} = carBrands;
