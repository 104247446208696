import axios from "axios";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";

function MaReferals(props) {
  const [referals, setReferals] = useState([]);
  const [formsubmitting, setFormSubmitting] = useState(false);

  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    const id = props.match.params.id;
    const FetchData = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/get_referal/${id}/rmf_travellers`, config)
        .then((res) => {
          setReferals(res.data);
        })
        .catch((error) => {
          console.log(error.message);
        });
    };
    FetchData();
  }, []);

  const [formData, setFormData] = useState({
    referal: referals.referal,
  });

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const referal_id = props.match.params.id;

  const updateReferal = (e) => {
    e.preventDefault();
    setFormSubmitting(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    let form_data = new FormData();
    form_data.append("referal", formData.referal);

    // console.log(formData);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/update_referal/${referal_id}/rmf_travellers`,
        form_data,
        config
      )
      .then((response) => {
        console.log(response.data);
        Swal.fire(
          "Successful",
          "Referal Documentation has been updated Successful....",
          "success"
        );
        setFormSubmitting(false);
      })
      .catch((error) => {
        setFormSubmitting(false);
        Swal.fire("Error", "An Error occurred try again later!", "error");
        console.error("There was an error!", error);
      });
  };

  return (
    <>
      <div className="py-4 d-flex align-items-stretch">
        <div id="content" className="p-2 p-md-3 pt-3 mt-5">
          <h4 className="text-center">Referals</h4>

          <form onSubmit={updateReferal}>
            <div className="form-group">
              <label htmlFor="referals">About Referals</label>
              <textarea
                name="referal"
                id="refer"
                cols="30"
                onChange={onChange}
                className="form-control"
                defaultValue={referals.referal}
                rows="10"
              ></textarea>
              {/* <Editor
                            initialValue={referals.referal}
                            onEditorChange={onChange}
                            /> */}
            </div>

            <div className="form-group pull-right mb-5">
              <button
                type="submit"
                disabled={formsubmitting}
                className="btn btn-success"
              >
                {formsubmitting ? (
                  <center>
                    <Spinner />
                  </center>
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default MaReferals;
