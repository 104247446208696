import React, { useState, useEffect } from "react";
import edit from "../../images/edit.png";
import axios from "axios";
import Swal from "sweetalert2";
import { Modal, Spinner } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import ChangePassword from "../widgets/change_password";

export const UserAvatar = (props) => {
  return (
    <div className={`userAvatar ${props.isV2 ? "version2" : ""}`}>
      <div
        className="imageCon"
        style={{
          backgroundImage: `url("${props.profilePicture}")`,
        }}
      />
      <div className="contents">
        <div className="name">{props.name} </div>
        {!props.noStatus && <div className="subContent">{props.email}</div>}
      </div>
    </div>
  );
};

let profileRef;

export const ProfileModal = (props) => {
  const [validated, setValidated] = useState(false);
  // console.log(props);
  const [profileData, setProfileData] = useState({
    ...props.profileDetails,
    user_id: props.profileDetails.id,
  });
  const [submitted, setSubmitted] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [pp, setPP] = useState(
    props.profileDetails.profile_picture
      ? props.profileDetails.profile_picture
      : ""
  );

  // const { dispatch } = useContext(stores);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const submit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}/userprofile/v1/intercity_travellers/update/${props.user_id}/rmf_travellers`,
        profileData,
        config
      )
      .then((profile) => {
        setProfileData(profile.data);
        props.setClosable();
        setSubmitted(false);

        Toast.fire({
          icon: "success",
          title: "Profile Updated Succesfully",
        });
      })
      .catch((e) => {
        setSubmitted(false);

        Toast.fire({
          icon: "error",
          title: "An Error occurred, Profile Not Updated!",
        });
      });

    // const url =
    // UPDATE_URL +
    // `${props.profileDetails.n_r_c ? `/${props.profileDetails.id}` : ""}`;
    // const method = props.profileDetails.n_r_c ? "patch" : "post";

    // const profile = await axiosHandler({
    //   method,
    //   url,
    //   data: profileData,
    // }).catch(e => {
    //   Swal.fire('Error', 'An Error occurred, Profile Not Updated!', 'error')
    // });
    // setSubmitted(false);
    // if (profile) {
    //   Swal.fire('Successful', 'Profile Updated Succesfully!', 'success')
    //   props.setClosable();
    //   dispatch({ type: userDetailAction, payload: profile.data });
    // }
  };

  const onChange = (e) => {
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (props.visible) {
      setProfileData({
        ...props.profileDetails,
        user_id: props.profileDetails.id,
      });
      setPP(
        props.profileDetails.profile_picture
          ? props.profileDetails.profile_picture
          : ""
      );
    }
  }, [props.visible]);

  const handleOnChange = async (e) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let data = new FormData();
    data.append("profile_picture", e.target.files[0]);
    setUploading(true);
    const result = await axios.patch(
      `${process.env.REACT_APP_API_URL}/userprofile/v1/intercity_travellers/update/${props.user_id}/rmf_travellers`,
      data,
      config
    );
    setUploading(false);
    if (result) {
      setPP(result.data.profile_picture);
      setProfileData({ ...profileData });
    }
  };

  return (
    <>
      <Modal
        backdrop="static"
        keyboard={false}
        centered
        scrollable={true}
        size="xl"
        show={props.visible}
        onHide={props.close}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Form.Group as={Col} md="3">
                <div className="leftHook">
                  <div
                    className="imageCon"
                    style={{
                      backgroundImage: `url(${pp})`,
                    }}
                  />
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={(e) => (profileRef = e)}
                    onChange={handleOnChange}
                  />
                  {!props.view && (
                    <>
                      {uploading ? (
                        <div className="point">Loading...</div>
                      ) : (
                        <div
                          className="point"
                          onClick={() => profileRef.click()}
                        >
                          Change Picture
                          <img alt="" src={edit} />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </Form.Group>
              <Form.Group as={Col} md="5">
                <form onSubmit={submit}>
                  <label>Email </label>

                  <input
                    name="email"
                    className="form-control"
                    value={profileData.email}
                    disabled
                    required
                  />

                  <label> First Name</label>

                  <input
                    name="first_name"
                    className="form-control"
                    value={profileData.first_name}
                    onChange={onChange}
                    disabled={props.view}
                    required
                  />

                  <label>Last Name</label>

                  <input
                    name="last_name"
                    className="form-control"
                    value={profileData.last_name}
                    onChange={onChange}
                    disabled={props.view}
                    required
                  />

                  <label>Phone Number </label>

                  <input
                    type="number"
                    className="form-control"
                    min="0"
                    name="phone"
                    value={profileData.user_profile?.phone_number}
                    onChange={onChange}
                    disabled={props.view}
                    required
                  />

                  <label>Next Of Kine (N.O.K) Phone Number </label>

                  <input
                    type="number"
                    className="form-control"
                    min="0"
                    name="nof"
                    value={profileData.user_profile?.nof}
                    onChange={onChange}
                    disabled={props.view}
                    required
                  />

                  <label>National Registration Card(N.R.C)</label>

                  <input
                    type="text"
                    className="form-control"
                    name="n_r_c"
                    value={profileData.user_profile?.n_r_c}
                    onChange={onChange}
                    disabled={props.view}
                    required
                  />

                  <label>Address</label>
                  <input
                    type="text"
                    name="address"
                    className="form-control"
                    value={profileData.user_profile?.address}
                    onChange={onChange}
                    disabled={props.view}
                    required
                  />
                  {!props.view && (
                    <button
                      className="btn btn-primary"
                      type="submit"
                      disabled={submitted}
                    >
                      {submitted ? (
                        <center>
                          <Spinner />
                        </center>
                      ) : (
                        "Update"
                      )}
                    </button>
                  )}
                </form>
              </Form.Group>
              <Form.Group as={Col} md="4">
              <ChangePassword />
              </Form.Group>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};
