import { Rating } from "react-simple-star-rating";
import { motion } from "framer-motion";
import { styles, urlToken } from "../../helper";
import { Reveal } from "../../reveal";

const RoomCard = ({ room }) => {
  return (
    <>
      <Reveal>
        <section class="ftco-section">
          <div class="container">
            <div class="row">
              {room.map((item, index) => (
                <motion.div
                  data-aos="fade-up"
                  data-aos-delay={index * 250}
                  transition={{ delay: 0.1 * index }}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  whileHover={{ scale: 1.1 }}
                  key={index}
                  class="col-md-4 ftco-animate">
                  <div class="project-wrap hotel">
                    <a
                      href={`/zm/room_details/?room=${item.id}&hotel=${item.hotel}&token=${urlToken}`}
                      class="img"
                      style={{
                        backgroundImage: `url(${item.room_images[0].image})`,
                      }}>
                      <span class="price">ZMW {item.room_price}/Per Night</span>
                    </a>
                    <div class="text p-4">
                      <Rating
                        size={22}
                        initialValue={item.rating_avg}
                        transition
                        allowFraction
                        showTooltip
                        tooltipDefaultText={`${item.rating_avg} / 5`}
                        allowTitleTag={false}
                        readonly
                        tooltipStyle={{
                          paddingLeft: "3px",
                          paddingRight: "3px",
                          paddingBottom: "0px",
                          paddingTop: "0px",
                        }}
                        /* Available Props */
                      />
                      <h3 className="text-capitalize">
                        <a
                          href={`/zm/room_details/?room=${item.id}&hotel=${item.hotel}&token=${urlToken}`}>
                          {item.room_name}
                        </a>
                      </h3>

                      <ul>
                        <li>
                          <span class="flaticon-king-size"></span>
                          sleeps {item.room_occupation} People
                        </li>
                      </ul>
                      <motion.a
                        href={`/zm/room_details/?room=${item.id}&hotel=${item.hotel}&token=${urlToken}`}
                        style={styles}
                        className="pull-right"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9, x: "-5px", y: "5px" }}>
                        Book Now
                      </motion.a>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>
      </Reveal>
    </>
  );
};
export default RoomCard;
