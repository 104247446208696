import { apiSlice } from "../services/apiSlice";
import { IRoomReservation } from "../types/room_reservation.type";

export const roomReservationAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createReservation: builder.mutation<
      IRoomReservation,
      Partial<IRoomReservation>
    >({
      query(reservation) {
        return {
          url: `/hotels/v1/intercity_travellers/room_reservations/rmf_travellers`,
          method: "POST",
          body: reservation,
        };
      },
      invalidatesTags: ["IRoomReservation"],
      transformResponse: (result: { department: IRoomReservation }) =>
        result.department,
    }),
    updateReservation: builder.mutation<
      IRoomReservation,
      { id: string; department: IRoomReservation }
    >({
      query: ({ id, department }) => ({
        url: `/hotels/v1/intercity_travellers/room_reservations/rmf_travellers/${id}/`,
        method: "PATCH",
        body: department,
      }),
      invalidatesTags: ["IRoomReservation"],
      transformResponse: (response: { department: IRoomReservation }) =>
        response.department,
    }),
    getReservation: builder.query<IRoomReservation, number>({
      query: (id) => ({
        url: `/hotels/v1/intercity_travellers/room_reservations/rmf_travellers/${id}/`,
      }),
      providesTags: ["IRoomReservation"],
    }),
    getReservations: builder.query<IRoomReservation[], any>({
      query: ({hotel_owner, status}) => `/hotels/v1/intercity_travellers/room_reservations/rmf_travellers/?status=${status}&hotel_owner=${hotel_owner}`,
      transformResponse: (res: IRoomReservation[]) =>
        res.sort((a, b) => b.id - a.id),
      providesTags: ["IRoomReservation"],
      // keepUnusedDataFor: 5,
    }),
    getCustomerReservations: builder.query<IRoomReservation[], any>({
      query: ({hotel_owner}) => `/hotels/v1/intercity_travellers/hotel_customer_reservations/rmf_travellers/`,
      transformResponse: (res: IRoomReservation[]) =>
        res.sort((a, b) => b.id - a.id),
      providesTags: ["IRoomReservation"],
      // keepUnusedDataFor: 5,
    }),
    getCustomerReservation: builder.query<IRoomReservation, any>({
      query: ({customer}) => ({
        url: `/hotels/v1/intercity_travellers/update_reservation/${customer}/rmf_travellers`,
      }),
      providesTags: ["IRoomReservation"],
    }),
    deleteReservation: builder.mutation<IRoomReservation, number>({
      query: (id) => ({
        url: `/hotels/v1/intercity_travellers/room_reservations/rmf_travellers/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["IRoomReservation"],
    }),
  }),
});
export const {
  useCreateReservationMutation,
  useDeleteReservationMutation,
  useGetReservationQuery,
  useGetReservationsQuery,
  useGetCustomerReservationsQuery,
  useGetCustomerReservationQuery,
  useUpdateReservationMutation,
} = roomReservationAPI;
