import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const PlacesSkeleton = () => {
  return (
    <>
      <SkeletonTheme baseColor="#f3f3f3" highlightColor="#ecebeb">
        <div className="row">
          {Array(6)
            .fill()
            .map((item, index) => (
              <div className="col-md-4 mb-3 mr" key={index}>
                <div className="card-image">
                  <Skeleton height={200} width={`100%`} />
                </div>
                <h4 className="card-title">
                  <Skeleton height={30} width={`100%`} />
                </h4>
                <div className="card-description__left">
                  <Skeleton height={20} width={`80%`} />

                  <span>
                    <Skeleton height={20} width={`40%`} />
                  </span>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    <Skeleton height={60} width={`100%`} />
                  </div>
                  <div className="col-md-3 mt-3">
                    <Skeleton height={40} width={`100%`} />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </SkeletonTheme>
    </>
  );
};

export default PlacesSkeleton;
