import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { urlToken } from "../../../helper";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Calendar from "../../calendar/calendar";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const { user } = useSelector((state) => state.auth);
  const [number, setNumber] = useState(1); // No of pages
  const [ticketsPerPage] = useState(50);
  const [buses, setBuses] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [departureDate, setDepartureDate] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [q, setQ] = useState("");
  const [searchParam] = useState([
    "first_name",
    "last_name",
    "email",
    "ticket_no",
  ]);
  const [filterParam, setFilterParam] = useState(["All"]);

  const queryParams = new URLSearchParams(window.location.search);
  const uid = queryParams.get("uid");

  const fetchData = async () => {
    //  get commuter buses
    try {
      // console.log(`==== printing user ${uid } ======`);
      await axios
        .get(`${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/buses/${uid}/rmf_travellers`)
        .then((res) => {
          setBuses(res.data);
          // setIsLoading(false);
        });
    } catch (error) {}

    //  get tickets
    let status = "pending";
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/get_tickets/${uid}/${status}/rmf_travellers`
        )
        .then((res) => {
          setTickets(res.data);
          setIsLoading(false);
        });
    } catch (error) {}

    // get departure date
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/bus_time/${uid}/rmf_travellers`)
        .then((res) => {
          setDepartureDate(res.data);
          // setIsLoading(false);
        });
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
    console.log(`CURRENT LGGED IN USER ${user?.first_name}`);
  }, []);

  // pagination
  const lastTicket = number * ticketsPerPage;
  const firstTicket = lastTicket - ticketsPerPage;
  const currentTickets = tickets.slice(firstTicket, lastTicket);
  const pageNumber = [];

  for (let i = 1; i <= Math.ceil(tickets.length / ticketsPerPage); i++) {
    pageNumber.push(i);
  }

  const ChangePage = (pageNumber) => {
    setNumber(pageNumber);
  };
  const data = Object.values(currentTickets);

  function search(tickets) {
    return tickets.filter((item) => {
      if (item.departure_date == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.bus == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (filterParam == "All") {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }

  let ticketAmount = data.map((item) => parseInt(item.amount));

  const total_amount = ticketAmount.reduce(function (acc, val) {
    return acc + val;
  }, 0);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col px-4 py-5">
            <h3 className="text-capitalize mt-4">dashboard</h3>
            {/* <!-- summary analytics --> */}
            <div className="row my-3">
              <div className="col-md-4 mb-3">
                <div className="shadow border-0 rounded-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col mt-0">
                        <h6 className="card-title text-muted">
                          Unconfirmed Tickets
                        </h6>
                      </div>

                      <div className="col-auto">
                        <div className="stat text-primary">
                          <i
                            className="bi bi-ticket-detailed-fill"
                            style={{
                              fontSize: "2rem",
                              color: "rgb(192, 52, 52)",
                            }}
                          ></i>
                        </div>
                      </div>
                    </div>
                    <h5 className="mt-1 mb-3">{data.length}</h5>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-3">
                <div className="shadow border-0 rounded-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col mt-0">
                        <h6 className="card-title text-muted">
                          Unconfirmed Tickets Revenue
                        </h6>
                      </div>

                      <div className="col-auto">
                        <div className="stat text-primary">
                          <i
                            className="bi bi-cash-coin"
                            style={{
                              fontSize: "2rem",
                              color: "rgb(7, 145, 7)",
                            }}
                          ></i>
                        </div>
                      </div>
                    </div>
                    <h5 className="mt-1 mb-3">
                      ZMW {total_amount.toLocaleString()}
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mb-3">
                <div className="shadow border-0 rounded-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col mt-0">
                        <h6 className="card-title text-muted text-capitalize">
                          Buses
                        </h6>
                      </div>

                      <div className="col-auto">
                        <div className="stat text-primary">
                          <i
                            className="bi bi-truck-front-fill"
                            style={{
                              fontSize: "2rem",
                              color: "orangered",
                            }}
                          ></i>
                        </div>
                      </div>
                    </div>
                    <h5 className="mt-1 mb-3">{buses.length}</h5>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- end of summary analytics --> */}

            {/* <!-- loading buses table --> */}
            {/* <div className="table-responsive">
                      <h5 className="text-muted text-capitalize pb-2">
                        currently queued buses
                      </h5>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Plate number</th>
                            <th>Number of seats</th>
                            <th>Number of seats sold</th>
                            <th>Route</th>
                            <th>Date/time of departure</th>
                          </tr>
                        </thead>

                        {isLoading ? (
                          <Loader />
                        ) : (
                          <tbody>
                            {busfares.map((fare, index) =>
                              fare.departure_date < dDate ? null : (
                                <tr key={index}>
                                  <td className="text-uppercase">
                                    {buses.map((item) =>
                                      item.id === fare.going_bus
                                        ? item.number_place
                                        : null
                                    )}
                                  </td>
                                  <td>
                                    {buses.map((item) =>
                                      item.id === fare.going_bus
                                        ? item.seat_capacity
                                        : null
                                    )}
                                  </td>
                                  <td>
                                    {buses.map((item) =>
                                      item.id === fare.going_bus
                                        ? item.seat_capacity - tickets.length
                                        : null
                                    )}
                                  </td>
                                  <td className="text-capitalize">
                                    {routes.map((item) =>
                                      item.id === fare.bus_route
                                        ? provinces.map((pro) =>
                                            pro.id === item.from_province
                                              ? pro.province_name
                                              : null
                                          )
                                        : null
                                    )}{" "}
                                    -{" "}
                                    {routes.map((item) =>
                                      item.id === fare.bus_route
                                        ? provinces.map((pro) =>
                                            pro.id === item.to_province
                                              ? pro.province_name
                                              : null
                                          )
                                        : null
                                    )}
                                  </td>
                                  <td>
                                    {moment(fare.departure_date).format(
                                      "YYYY, Do MMMM,"
                                    )}{" "}
                                    {onroad.map((item) =>
                                      item.id === fare.going_bus
                                        ? item.departure_time
                                        : null
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        )}
                      </table>
                    </div> */}
            {/* <!-- end of loading buses table --> */}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="mb-5">
          <div className="container shadow rounded-3 hide-on-print">
            <div className="row">
              <div className="col-md-6">
                <h6>Search:</h6>

                <input
                  type="text"
                  className="form-control bg-transparent "
                  placeholder="search...."
                  value={q}
                  onChange={(e) => setQ(e.target.value)}
                />
              </div>
              <div className="col-md-3 form-group">
                <h6>Filter By Bus:</h6>
                <select
                  onChange={(e) => {
                    setFilterParam(e.target.value);
                  }}
                  name="bus"
                  required
                  className="form-control"
                  aria-label="Filter Tickets By Bus"
                >
                  <option value="All">All</option>
                  {buses.map((bus, index) => (
                    <option key={index} value={bus.id}>
                      {bus.driver} - {bus.number_place}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3 form-group">
                <h6>Filter By Date:</h6>
                <select
                  onChange={(e) => {
                    setFilterParam(e.target.value);
                  }}
                  name="departure_date"
                  required
                  className="form-control"
                  aria-label="Filter Tickets By Departure Date/Time"
                >
                  <option value="All">All</option>
                  {departureDate.map((item, index) => (
                    <option key={index} value={item.departure_date}>
                      {moment(item.departure_date).format("MMMM Do YYYY, H:m")}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/*	Error Message*/}
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="shadow border-0 rounded-3 mt-5">
                <h5 className="ml-3">Pending Tickets</h5>
                <div className="card-body">
                  <div className="table-responsive">
                    {isLoading ? (
                      <center>
                        <i
                          className="fa fa-spinner fa-spin text-center"
                          style={{ fontSize: "50px", color: "red" }}
                        ></i>
                      </center>
                    ) : tickets.length === 0 ? (
                      <div className="alert alert-danger text-center">
                        There are no Tickets
                      </div>
                    ) : (
                      <table
                        id="example"
                        className="table table-hover table-striped table-lg"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Ticket</th>
                            <th>Name</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Bus</th>
                            <th>Date of Departure</th>
                            <th>Paid</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {search(data).map((ticket, index) => (
                            <tr key={index}>
                              <td>
                                <small className="font-bold mb-0">
                                  {index + 1}
                                </small>
                              </td>
                              <td>
                                <small className="font-bold mb-0">
                                  {ticket.ticket_no}
                                </small>
                              </td>
                              <td>
                                <small className="font-bold mb-0 text-capitalize">
                                  {ticket.first_name} {ticket.last_name}
                                </small>
                              </td>
                              <td>
                                <small className="mb-0 text-capitalize">
                                  {ticket.from_route}
                                </small>
                              </td>
                              <td>
                                <small className="mb-0 text-capitalize">
                                  {ticket.to_route}
                                </small>
                              </td>
                              <td>
                                <small className="mb-0 text-uppercase">
                                  {buses.map((bus) =>
                                    bus.id === ticket.bus
                                      ? bus.number_place
                                      : null
                                  )}
                                </small>
                              </td>
                              <td>
                                <small className=" mb-0">
                                  {moment(ticket.departure_date).format(
                                    "YYYY Do MMMM, HH:mm a"
                                  )}
                                </small>
                              </td>
                              <td>
                                <small className="mb-0">
                                  K {ticket.amount}
                                </small>
                              </td>

                              <td>
                                <a
                                  className="btn btn-sm btn-primary"
                                  href={`/zm/dashboard/ticket_details/${urlToken}/${ticket.ticket_no}/${uid}`}
                                >
                                  Details
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}

                    <div className="pull-right">
                      {data.length < 50 ? null : (
                        <ButtonToolbar aria-label="Toolbar with button groups">
                          <ButtonGroup
                            className="me-2"
                            aria-label="First group"
                          >
                            <Button
                              disabled={number === 1}
                              onClick={() => setNumber(number - 1)}
                            >
                              <i className="fa fa-chevron-left"></i>
                            </Button>
                          </ButtonGroup>
                          <ButtonGroup
                            className="me-2"
                            aria-label="Second group"
                          >
                            {pageNumber.map((item, index) => {
                              return (
                                <Button
                                  className={`text-center btn btn-info text-white ${
                                    number === item ? "active" : "btn btn-info"
                                  }`}
                                  key={index}
                                  onClick={() => ChangePage(item)}
                                >
                                  {item}
                                </Button>
                              );
                            })}
                          </ButtonGroup>
                          <ButtonGroup aria-label="Third group">
                            <Button onClick={() => setNumber(number + 1)}>
                              {" "}
                              <i className="fa fa-chevron-right"></i>
                            </Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="shadow border-0 rounded-3 mt-5">
                <h5 className="ml-3">BUSES</h5>
                <div className="table-responsive my-4">
                  {isLoading ? (
                    <center>
                      <i
                        className="fa fa-spinner fa-spin text-center"
                        style={{ fontSize: "50px", color: "red" }}
                      ></i>
                    </center>
                  ) : buses.length === 0 ? (
                    <div className="alert alert-danger text-center">
                      There are no Buses
                    </div>
                  ) : (
                    <table
                      id="example"
                      className="table table-striped table-hover table-lg"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Bus</th>
                          <th>Driver</th>
                          <th colSpan="2">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {buses.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <p className="font-bold ms-3 mb-0">{index + 1}</p>
                            </td>
                            <td>
                              <small>{item.number_place}</small>
                            </td>
                            <td className="text-capitalize">
                              <small>{item.driver}</small>
                            </td>

                            <td>
                              <a
                                className="btn btn-sm btn-info"
                                href={`/zm/dashboard/bus_details/${urlToken}/${uid}/${item.id}`}
                              >
                                Details
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                  <div className="text-center">
                    <a
                      className="btn btn-sm btn-primary"
                      href={`/zm/dashboard/buses/${urlToken}/${uid}`}
                    >
                      View All
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Calendar />
    </>
  );
};

export default Dashboard;
