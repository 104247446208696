import React from "react";
import { Carousel, Modal } from "react-bootstrap";
import { capitalized } from "../../actions/utils";

const ShowRoomDetails = ({ room, show, handleClose }) => {
  return (
    <>
      <Modal
        {...room}
        backdrop="static"
        keyboard={false}
        centered
        scrollable={true}
        size="xl"
        show={show}
        onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <b className="text-capitalize">{room.room_name}</b>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <Carousel fade indicators={false} controls={true}>
                {room.room_images?.map((item, index) => {
                  return (
                    <Carousel.Item key={index}>
                      <img
                        className="img-responsive"
                        src={item.image}
                        style={{
                          borderRadius: "10px",
                          width: "100%",
                          height: "400px",
                        }}
                        alt={room.room_name}
                      />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
            <div className="col-md-6">
              <h2 className="text-capitalize text-center">{room.room_name}</h2>
              <div className="col-md-12">
                  <h5>
                    <i className="fa fa-bed text-success"></i> Rooms:{" "}
                  </h5>
                  <small className="text-capitalize">
                    <div className="row">
                      {room.room?.map((item, index) => (
                        <div key={index} className="col-md-4">
                          <li>Number: {item.roomNumber}</li>
                          <li>Reserved: {item.roomReserved === true ? "Yes" : "No"}</li>
                          <li>Cleaning: {item.underClearning === true ? "Yes" : "No"}</li>
                        </div>
                      ))}
                    </div>
                  </small>
                </div>
                <div className="col-md-9">
                  <h5>
                    <i className="fa fa-map text-success"></i> Amenities:{" "}
                  </h5>
                  <small className="text-capitalize">
                    <div className="row">
                      {room.amenities.map((item, index) => (
                        <div key={index} className="col-md-4">
                          <li>{item}</li>
                        </div>
                      ))}
                    </div>
                  </small>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <h6>
                    <i className="fa fa-bed text-success"></i> Sleeps:{" "}
                    {room.room_occupation}{" "}
                    {room.room_occupation === 1 ? "Person" : "People"}
                  </h6>
                </div>
                <div className="col-md-6">
                  <h6>
                    <i className="fa fa-money text-success"></i> Room Price: ZMW
                    {room.room_price}
                  </h6>
                </div>
              </div>
              <small>{capitalized(room.room_description)}</small>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShowRoomDetails;
