import React from "react";

function RelatedRooms() {
  return (
    <div className="container">
      <h1>Related Rooms</h1>
    </div>
  );
}

export default RelatedRooms;
