import React, { useEffect } from "react";
import axios from "axios";
import ReactHtmlParser from "html-react-parser";
import { Helmet } from "react-helmet";
import LoadingSpinner from "../widgets/spinner";
import { urlToken } from "../../helper";
import { Reveal } from "../../reveal";

function ProvinceDetails() {
  const queryParams = new URLSearchParams(window.location.search);
  const province = queryParams.get("province");
  const slug = queryParams.get("slug");
  const [details, setDetails] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const fetchData = async () => {
    // GET PROVINCE DETAILS
    try {
     await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/provinces-details/${slug}/${province}/rmf_travellers`
        )
        .then((res) => {
          setDetails(res.data);
          setIsLoading(false);
        });
    } catch (error) {}
  };
  useEffect(() => {
    
    fetchData();
  }, []);

  const capitalized = (word) => {
    if (word) {
      return word.charAt(0).toUpperCase() + word.slice([1]);
    }

    return "";
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Intercity Travellers | Provinces Page</title>
        <meta property="og:title" content="Provinces" />
        <meta property="og:type" content="article" />

        <meta
          name="keywords"
          content="lusaka province, western province, southern province, north-western province, nothern province, muchinga province, luapula province, eastern province, copperbelt province, central province"
        />
      </Helmet>
      <main>
        <section
          className="hero-wrap hero-wrap-2 js-fullheight"
          style={{ backgroundImage: `url(${details.thumbnail})`, height: 'fit-content' }}>
          <div style={{height: 'fit-content'}} className="overlay"></div>
          <div class="container">
            <div class="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
              <div class="col-md-9 ftco-animate pb-5 text-center">
                <p class="breadcrumbs">
                  <span class="mr-2">
                    <a href="/">
                      Home <i class="fa fa-chevron-right"></i>
                    </a>
                  </span>{" "}
                  <span className="text-capitalize">
                    {details.province_name} <i class="fa fa-chevron-right"></i>
                  </span>
                </p>
                <h1 class="mb-0 bread text-capitalize">
                  {details.province_name}
                </h1>
                <h3 className="text-white text-capitalize">
                  {details.country}
                </h3>
              </div>
            </div>
          </div>
        </section>
        <section className="inner-page">
          <div className="container-fluid">
            <section id="about" className="about">
              <div className="container-fluid">
                <h1 className="text-capitalize">
                  <b>{details.province_name}</b>
                </h1>
                <blockquote>
                  <p className="font-italic">
                    {ReactHtmlParser(capitalized(details.about_province))}
                  </p>
                </blockquote>
              </div>
            </section>
          </div>
        </section>

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Reveal>
            <section id="menu" className="menu">
              <div className="container-fluid" data-aos="fade-up">
                <h2 className="text-center mb-5 text-capitalize">
                  Districts in {details.province_name}
                </h2>

                <div className="row" data-aos="fade-up" data-aos-delay="200">
                  {details.province_districts.map((district, index) => (
                    <div
                      data-aos="fade-up"
                      data-aos-delay={index * 250}
                      className="col-md-3 mb-4 display-img"
                      key={index}>
                      <a
                        href={`/zm/district/?token=${urlToken}&slug=${district.slug}&district=${district.id}`}>
                        <img
                          src={district.image}
                          style={{
                            height: "200px",
                            width: "100%",
                            borderRadius: "5px",
                          }}
                          className="img-responsive tour-img"
                          alt=""
                        />
                      </a>

                      <div className="mt-3">
                        <a
                          href={`/zm/district/?token=${urlToken}&slug=${district.slug}&district=${district.id}`}
                          className="text-capitalize">
                          <h5>{district.district_name}</h5>
                        </a>
                      </div>
                      <div className="">
                        {ReactHtmlParser(
                          district.discription.substring(0, 150)
                        )}
                        ...
                        <br />
                        <a
                          className="btn btn-sm btn-info pull-right"
                          href={`/zm/district/?token=${urlToken}&slug=${district.slug}&district=${district.id}`}>
                          <i className="fa fa-arrow-circle-right"></i> More
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </Reveal>
        )}
      </main>
    </>
  );
}

export default ProvinceDetails;
