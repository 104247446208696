import React, { useEffect } from "react";
import DestinationsCard from "../widgets/destinations_card";
import { Reveal } from "../../reveal";
import HolidaySlider from "./carousel";
import { holidaySlides } from "../carousel/carousel_data";
import httpApiService from "../services/http.api.service";

const HolidayDestinations = () => {
  const [holidays, setHolidays] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [number, setNumber] = React.useState(1); // No of pages
  const [holidayPerPage] = React.useState(8);
  const [q, setQ] = React.useState("");
  const [searchParam] = React.useState(["country", "title", "countryName"]);
  const [filterParam, setFilterParam] = React.useState(["all"]);
  const [isLoading, setisLoading] = React.useState(true);

  const fetchData = async () => {
    // fetch Countries
    try {
      httpApiService.getCountries().then((response) => {
        setCountries(response.data);
      });
    } catch (error) {}
    // fetch Holiday Packages
    try {
      httpApiService.getHolidayPackages().then((response) => {
        setHolidays(response.data);
        setisLoading(false);
      });
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);
  // pagination
  const lastHoliday = number * holidayPerPage;
  const firstHoliday = lastHoliday - holidayPerPage;
  const currentHoliday = holidays.slice(firstHoliday, lastHoliday);
  const pageNumber = [];

  for (let i = 1; i <= Math.ceil(holidays.length / holidayPerPage); i++) {
    pageNumber.push(i);
  }

  const ChangePage = (pageNumber) => {
    setNumber(pageNumber);
  };
  const data = Object.values(currentHoliday);

  function search(list) {
    return list.filter((item) => {
      if (item.title == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.country == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (filterParam == "all") {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }

  return (
    <>
      <HolidaySlider slides={holidaySlides} />
      <Reveal>
        <section class="ftco-section ftco-no-pb">
          <div class="container">
            <div class="search-wrap-1 ftco-animate">
              <form class="search-property-1">
                <div class="row no-gutters">
                  <div class="col-l-12 col-md-8 d-flex">
                    <div class="form-group p-4 border-0">
                      <label htmlFor="#">Destination</label>
                      <div class="form-field">
                        <div class="icon">
                          <span class="fa fa-search"></span>
                        </div>
                        <input
                          type="search"
                          class="form-control"
                          placeholder="Search Destinations"
                          value={q}
                          onChange={(e) => setQ(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg d-flex">
                    <div className="form-group p-4">
                      <label htmlFor="#">Filter</label>
                      <div className="form-field">
                        <div className="select-wrap">
                          <div className="icon">
                            <span className="fa fa-chevron-down"></span>
                          </div>
                          <select
                            name="country"
                            id=""
                            className="form-control text-capitalize"
                            onChange={(e) => setFilterParam(e.target.value)}>
                            <option value="all">Filter By:</option>
                            {countries.map((item, index) => (
                              <option
                                className="text-capitalize"
                                key={index}
                                value={item.id}>
                                {item.countryName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </Reveal>
      {/* place holiday card here */}

      <>
        <DestinationsCard data={search(data)} isLoading={isLoading} />
        <section className="ftco-section">
          <div className="container">
            {search(data).length < 8 ? (
              <div></div>
            ) : (
              <div className="row">
                <div className="col text-center">
                  <div className="block-27">
                    <ul>
                      <li>
                        <a
                          style={{ cursor: "pointer" }}
                          disabled={number === 1}
                          onClick={() => setNumber(number - 1)}>
                          &lt;
                        </a>
                      </li>
                      {pageNumber.map((page, index) => (
                        <li
                          key={index}
                          className={`${number === page ? "active" : ""}`}>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => ChangePage(page)}>
                            <span>{page}</span>
                          </a>
                        </li>
                      ))}

                      <li>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => setNumber(number + 1)}>
                          &gt;
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </>
    </>
  );
};
export default HolidayDestinations;
