import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import axios from "axios";
import Swal from "sweetalert2";

function AddDepartment({ uid }) {
  const [show, setShow] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    departmentName: "",
  });

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setFormSubmitting(true);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      const body = JSON.stringify({
        commuter: uid,
        departmentName: formData.departmentName,
      });
      try {
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/create_department/rmf_travellers`,
            body,
            config
          )
          .then((res) => {
            console.log(res.data);
            Toast.fire({
              icon: "success",
              title: "Department Created Successfully",
            });

            setTimeout(() => {
              window.location.reload();
              setFormSubmitting(true);
            }, 2000);
          })
          .catch((er) => {
            console.log(er.message);
            Toast.fire({
              icon: "error",
              title: `Error: ${er.message}`,
            });

            setFormSubmitting(true);
          });
      } catch (err) {
        console.log(err.message);
        setFormSubmitting(true);
      }
    }

    setValidated(true);
  };

  return (
    <>
      <Button variant="info" onClick={handleShow}>
        <i className="fa fa-plus-circle"></i> Add Department
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Department</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group controlId="validationCustom03">
                <Form.Label>Department Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Department Name"
                  onChange={(e) => onChange(e)}
                  disabled={formSubmitting}
                  name="departmentName"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Department Name is required
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Modal.Footer>
              <Button
                type="button"
                variant="secondary"
                disabled={formSubmitting}
                onClick={handleClose}
              >
                Close
              </Button>
              <Button disabled={formSubmitting} type="submit" variant="primary">
                {formSubmitting && (
                  <span className="wc-editable">
                    <i className="fa fa-refresh fa-spin" /> Submitting ...
                  </span>
                )}
                {!formSubmitting && <span className="wc-editable">Submit</span>}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddDepartment;
