import React, { Component } from "react";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import Swal from "sweetalert2";

import { useParams } from "react-router-dom";
import LoadingSpinner from "../../components/widgets/spinner";

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class MaTerms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
      isLoading: true,
      formSubmitting: false,
      isSubmitting: false,
      terms: [],
    };
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.input = React.createRef();
  }

  handleEditorChange(content) {
    this.setState({ content });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      isSubmitting: true,
      formSubmitting: true,
    });
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    let form_data = new FormData();
    form_data.append("body", this.state.content);

    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/update_terms/${this.props.match.params.id}/rmf_travellers`,
        form_data,
        config
      )
      .then((response) => {
        // console.log(response.data)
        Swal.fire(
          "Successful",
          "Submitted was Successful....Redirecting!",
          "success"
        );
        setTimeout(() => {
          window.location.reload();
          this.setState({
            isSubmitting: false,
          });
        }, 2000);
      })
      .catch((error) => {
        this.setState({
          isSubmitting: false,
        });
        Swal.fire("Error", "An Error occurred try again later!", "error");
        console.error("There was an error!", error);
      });
  }

  async componentDidMount() {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/update_terms/${this.props.match.params.id}/rmf_travellers`, config
      )
      .then((response) => {
        this.setState({
          terms: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.error("there was an error", error.message);
      });
  }

  render() {
    return (
      <>
        <div className="wrapper mt-5 align-items-stretch">
          {this.state.isLoading ? (
            <div className="centerAll">
              <LoadingSpinner /> loading content...{" "}
            </div>
          ) : (
            <div className="p-2 p-md-3 pt-3">
              <h1 className="text-center">Terms And Conditions</h1>
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <Editor
                    ref={this.input}
                    init={{
                      height: 500,
                    }}
                    value={this.state.terms.body}
                    onEditorChange={this.handleEditorChange}
                  />
                </div>
                <div className="mb-5 mt-4 pull-right">
                  <button type="submit" className="btn btn-lg btn-info">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </>
    );
  }
}
export default withRouter(MaTerms);
