import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddRoom from "../rooms/add_room";
import LoadingSpinner from "../../../components/widgets/spinner";
import ShowRoomDetails from "../show_room_details";
import RoomCard from "../rooms/room_card";
import httpRoomServices from "../services/http.room.services";
import BranchStatics from "./statics";
import { IBranch } from "../../../redux/types/hotel_branch.type";
import { useGetBranchQuery } from "../../../redux/slices/branchAPISlice";
import { IRoom } from "../../../redux/types/hotel_room.type";
import BranchCharts from "../widgets/branch_charts";
import { dailyData, monthlyData } from "../widgets/data_charts";
import DailyCharts from "./reports/daily_chart";
import { useAppSelector } from "../../../hooks/redux-hooks";

function Branch() {
  const queryParams = new URLSearchParams(window.location.search);
  const branch = queryParams.get("branch");
  const user = useAppSelector((state) => state.auth.user);
  const [rooms, setRooms] = useState([]);
  const [showRoomDetailsModal, setShowRoomDetailsModal] = useState(false);
  const [roomDetails, setRoomDetails] = useState<IRoom | null>(null);
  // ROOM DETAILS MODAL
  const handleShowRoomDetailsModal = (room: IRoom) => {
    setRoomDetails(room);
    setShowRoomDetailsModal(true);
  };
  const handleCloseRoomDetailsModel = () => {
    setShowRoomDetailsModal(false);
  };

  const fetchData = async () => {
    try {
      httpRoomServices.fetchBranchRooms(branch).then((response) => {
        setRooms(response.data);
      });
    } catch (error: any) {
      console.log(`HOTELS ROOMS ERROR ${error.message}`);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const {
    data: details,
    isLoading,
    error,
  }: IBranch | any = useGetBranchQuery(branch);

  if (isLoading) {
    return (
      <center>
        <LoadingSpinner />
      </center>
    );
  }
  if (error) {
    return (
      <div className="text-center alert alert-danger" role="alert">
        <h4>{error.status}</h4>
      </div>
    );
  }
  

  return (
    <>
      <div className="content">
        <div>
          {/* add modal */}
          <AddRoom uid={user?.id} branch={branch} />
        </div>
        <h2 className="text-center mt-5 text-capitalize">
          <span style={{ color: "#f1a417" }}>{details.hotel_name}</span>
        </h2>
        <h6 className="text-capitalize mb-5 text-center ">
          <i className="fa fa-map-marker text-success"></i> Location:{" "}
          {details.province?.province_name}, {details.district?.district_name}
        </h6>
        <div className="mt-5 mb-5">
          <BranchStatics branch={details} />
          <div className="row">
            <div className="col-md-6">
              <h3>Daily</h3>
              <DailyCharts />
            </div>
            <div className="col-md-6">
              <h3>Weekly</h3>
              <BranchCharts width={600} height={300} data={dailyData} />
            </div>
          </div>
          <div className="col-md-12">
            <h3>Monthly</h3>
            <BranchCharts width={1000} height={300} data={monthlyData} />
          </div>
        </div>
        {rooms.length === 0 ? (
          <div className="alert alert-danger text-center">
            You haven't added any Rooms in this branch
          </div>
        ) : (
          <>
            <h3>Branch Rooms</h3>
            <div className="row">
              {rooms.map((room, index) => {
                return (
                  <div className="col-md-4 col-sm-2 mb-4" key={index}>
                    <RoomCard
                      room={room}
                      uid={user?.id}
                      handleShowRoomDetailsModal={handleShowRoomDetailsModal}
                    />

                    {/* view place details modal */}
                    <ShowRoomDetails
                      show={showRoomDetailsModal}
                      room={roomDetails === null ? room : roomDetails}
                      handleClose={handleCloseRoomDetailsModel}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Branch;
