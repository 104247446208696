import React, { useState, useEffect } from "react";
import axios from "axios";
import { Carousel } from "react-bootstrap";
import ReactHtmlParser from "html-react-parser";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../components/widgets/spinner";
import ShowRoomDetails from "./show_room_details";
import { useAppSelector } from "../../hooks/redux-hooks";

function GetHotelRooms() {
  const user = useAppSelector((state) => state.auth.user);
  const [rooms, setRooms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [roomDetails, setRoomDetails] = useState(null);

  const handleClose = () => {
    setShow(false);
    // setRoomDetails(null);
  };

  const handleShow = (room: any) => {
    setRoomDetails(room);
    setShow(true);
  };

  const fetchData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/hotels/v1/intercity_travellers/get_room_details/${user?.id}/rmf_travellers`,
      config
    );
    setRooms(res.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="wrapper align-items-stretch">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div id="content" className="p-2 p-md-3 pt-3">
            <h2 className="text-center mt-5 mb-5">Rooms</h2>
            <div className="row">
              {rooms.length === 0 ? (
                <div className="alert alert-danger text-center">
                  You haven't added any Rooms, go to the branch section and add
                  a branch then add the rooms to that branch
                </div>
              ) : (
                rooms.map((room: any, index) => (
                  <div className="col-md-3 mb-4" key={index}>
                    <div className="row">
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => handleShow(room)}>
                        <Carousel fade indicators={false} controls={false}>
                          {room.room_images.map((item: any, idx: number) => {
                            return <Carousel.Item key={idx}>
                            <img
                              className="img-responsive"
                              src={item.image}
                              style={{
                                height: "200px",
                                width: "100%",
                                borderRadius: "5px",
                              }}
                              alt={room.room_name}
                            />
                          </Carousel.Item>
                          })}
                        </Carousel>
                      </a>

                      <div className="text-capitalize">
                        <h3>{room.room_name}</h3>
                      </div>
                      <div className="">
                        {ReactHtmlParser(
                          room.room_description.substring(0, 150)
                        )}

                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => handleShow(room)}
                          className="text-danger">
                          ...<i className="fa fa-arrow-circle-right"></i> More
                        </a>
                      </div>
                    </div>
                    {/* view place details modal */}
                    <ShowRoomDetails
                      room={roomDetails === null ? room : roomDetails}
                      show={show}
                      handleClose={handleClose}
                    />
                  </div>
                ))
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default GetHotelRooms;
