import { Outlet } from "react-router-dom";
import Navigation from "../components/navigation";
import { connect } from "react-redux";
import Footer from "../components/footer";

const Layout = () => {

  return (
    <>
      <Navigation />
      <Outlet />
      <Footer />
    </>
  );
};

export default connect(null, {})(Layout);
