import React, { useEffect } from "react";
import axios from "axios";
import ReactHtmlParser from "html-react-parser";
import LoadingSpinner from "../widgets/spinner";
import { Reveal } from "../../reveal";
import PlaceDetailsModal from "./place_details_modal";

function DistrictDetails() {
  const queryParams = new URLSearchParams(window.location.search);
  const district = queryParams.get("district");
  const slug = queryParams.get("slug");
  const [show, setShow] = React.useState(false);
  const [details, setDetails] = React.useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (place) => {
    setShow(true);
    setDetails(place);
  };
  const [districts, setDistricts] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  useEffect(() => {
    const fetchData = async () => {
      // GET DISTRICTS
      try {
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/district-details/${slug}/${district}/rmf_travellers`
          )
          .then((res) => {
            setDistricts(res.data);
            setIsLoading(false);
          });
      } catch (error) {}
    };
    fetchData();
  }, []);

  const capitalized = (word) => {
    if (word) {
      return word.charAt(0).toUpperCase() + word.slice([1]);
    }

    return "";
  };

  return (
    <>
      <section
        className="hero-wrap hero-wrap-2 js-fullheight"
        style={{ backgroundImage: `url(${districts.image})`, height: 'fit-content' }}>
        <div style={{height: 'fit-content'}} className="overlay"></div>
        <div class="container">
          <div class="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
            <div class="col-md-9 ftco-animate pb-5 text-center">
              <p class="breadcrumbs">
                <span class="mr-2">
                  <a href="/">
                    Home <i class="fa fa-chevron-right"></i>
                  </a>
                </span>{" "}
                <span className="text-capitalize">
                  {districts.district_name} <i class="fa fa-chevron-right"></i>
                </span>
              </p>
              <h1 class="mb-0 bread text-capitalize">
                {districts.district_name}
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="about">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 shadow">
              <div className="about-img">
                <img
                  style={{ width: "100%", height: "50%" }}
                  src={districts.image}
                  alt={district.district_name}
                  className="rounded"
                />
              </div>
            </div>
            <div className="col-md-7 content">
              <h1 className="text-capitalize">{districts.district_name}</h1>
              <p className="font-italic">
                {ReactHtmlParser(capitalized(districts.discription))}
              </p>
            </div>
          </div>
        </div>
      </section>

      {isLoading ? (
        <LoadingSpinner />
      ) : districts.district_places.length < 1 ? null : (
        <Reveal>
           <h2 className="text-dark text-center text-capitalize">
          Places in{" "}
          <span style={{ color: "#f1a417" }}>{districts.district_name}</span>
        </h2>
          <div className="container-fluid mt-5">
            <div className="row">
              {districts.district_places.map((place, index) => (
                <div
                  data-aos="fade-up"
                  data-aos-delay={index * 250}
                  className="col-sm-2 col-md-4 col-lg-3 display-img"
                  key={index}>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => handleShow(place)}>
                    <img
                      src={place.image}
                      style={{
                        width: "100%",
                        height: "200px",
                        borderRadius: "5px",
                      }}
                      className="menu-img tour-img"
                      alt={place.place_name}
                    />
                  </a>
                  <div className="mb-2 text-capitalize">
                    <h4>{place.place_name}</h4>
                    {place.address.substring(0, 30)}...,{" "}
                    <span className="text-info text-capitalize">
                      <b className="text-capitalize">{place.place_type}</b>
                    </span>{" "}
                    <br />
                    <a
                      className="text-capitalize"
                      href={`${place.facebook_url}`}>
                      {" "}
                      <i className="fa fa-facebook"></i> Facebook
                    </a>
                    <br />
                    {/* <a className="text-capitalize" href={`https://${place.website_url}`}> <i className="fa fa-global"></i> Website: www.reumaifab.com</a> */}
                  </div>
                  <div className="menu-ingredients">
                    <blockquote>
                      <i>
                        {place.about_place.substring(0, 150)}...
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => handleShow(place)}
                          className="btn btn-sm btn-info text-white">
                          Read More
                        </a>
                      </i>
                    </blockquote>
                  </div>
                  {/* MODAL HERE */}
                  <PlaceDetailsModal
                    place={details === null ? place : details}
                    show={show}
                    handleClose={handleClose}
                  />
                </div>
              ))}
            </div>
          </div>
        </Reveal>
      )}
    </>
  );
}

export default DistrictDetails;
