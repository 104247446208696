import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import LoadingSpinner from "../../components/widgets/spinner";
import { useAppSelector } from "../../hooks/redux-hooks";

const UserRooms = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [rooms, setRooms] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  const fetchRooms = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    // get user rooms
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/hotels/v1/intercity_travellers/get_booking/${user?.id}/rmf_travellers`,
          config
        )
        .then((response) => {
          setRooms(response.data);
          setisLoading(false);
        });
    } catch (error) {
      setisLoading(false);
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchRooms();
  }, []);

  return (
    <>
      <div className="container pt-5">
        <h1 className="text-center mt-5 mb-5">Rooms</h1>
        <div className="row">
          {isLoading ? (
            <LoadingSpinner />
          ) : rooms.length === 0 ? (
            <center>
              <h3 className="text-bold text-capitalize">
                You haven't booked any rooms
              </h3>
              <i style={{ fontSize: "50px" }} className="fa fa-bed"></i>
            </center>
          ) : (
            rooms.map((room, index) => (
              <div key={index} className="col-sm-4 mb-4">
                <div className="card shadow border-0 rounded-3">
                  <div className="card-body">
                    <img
                      style={{ height: "200px", width: "100%" }}
                      className="rounded"
                      src={room.image}
                      alt={room.room_name}
                    />
                    <h3 className="card-title text-bold text-capitalize text-center">
                      {room.hotel.hotel_name}
                    </h3>
                    <ul>
                      <li className="text-bold text-capitalize">
                        {room.room_type} ({room.room_number} K{room.room_price}){" "}
                      </li>
                      <li className="text-bold text-capitalize">
                        <i className="fa fa-map-marker"></i>{" "}
                        {room.room_location}
                      </li>
                      <li>
                        <i className="fa fa-calendar"></i>{" "}
                        {moment(room.date_checkin).format("dd, MMM do YYYY")} -{" "}
                        {moment(room.date_checkout).format("dd, MMM do YYYY")}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default UserRooms;
