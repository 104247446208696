import React, { Component } from "react";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../components/widgets/spinner";

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class HotelLodgeTermsConditions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
      isLoading: true,

      isSubmitting: false,
      privacy: null,
    };
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEditorChange(content) {
    this.setState({ content });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      isSubmitting: true,
    });
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    let form_data = new FormData();
    form_data.append("privacyPolicy", this.state.content);

    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}/userprofile/v1/intercity_travellers/update_user/${this.props.match.params.uid}/rmf_travellers`,
        form_data,
        config
      )
      .then((response) => {
        // console.log(response.data)
        Swal.fire(
          "Successful",
          "Terms & Conditions Updated Successfully",
          "success"
        );
        setTimeout(() => {
          this.setState({
            isSubmitting: false,
          });
        }, 2000);
      })
      .catch((error) => {
        this.setState({
          isSubmitting: false,
        });
        Swal.fire("Error", "An Error occurred try again later!", "error");
        console.error("There was an error!", error);
      });
  };

  async componentDidMount() {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/userprofile/v1/intercity_travellers/update_user/${this.props.match.params.uid}/rmf_travellers`
      )
      .then((response) => {
        console.log(`privacy policy ${response.data}`);
        this.setState({
          privacy: response.data,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.error("there was an error", error.message);
      });
  }
  render() {
    return (
      <>
        <div className="wrapper mt-5">
          {this.state.isLoading ? (
            <div className="centerAll">
              <LoadingSpinner /> loading content...{" "}
            </div>
          ) : (
            <div id="content" className="p-2 p-md-3 pt-3">
              <h1 className="text-center">Terms & Conditions</h1>
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <Editor
                    init={{
                      height: 500,
                    }}
                    value={this.state.privacy.termsConditions}
                    onEditorChange={this.handleEditorChange}
                  />
                </div>
                <div className="text-center pull-right">
                  <button
                    disabled={this.state.isSubmitting}
                    className="btn btn-warning"
                  >
                    {" "}
                    {this.state.isSubmitting ? (
                      <span>
                        <i className="fa fa-refresh fa-spin" /> Updating...
                      </span>
                    ) : (
                      "Update"
                    )}{" "}
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </>
    );
  }
}
export default withRouter(HotelLodgeTermsConditions);
