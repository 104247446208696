import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import { Modal } from "react-bootstrap";

const EditBusFares = ({
  routes,
  buses,
  busfares,
  provinces,
  uid,
  stations,
  show,
  handleClose,
}) => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    ...busfares,
  });
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitting(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    const body = {
      bus_route: formData.bus_route,
      going_bus: formData.going_bus,
      commutor: uid,
      from_route: formData.from_route,
      to_route: formData.to_route,
      route_price: formData.route_price,
      departure_date: formData.departure_date,
      departure_time: formData.departure_time,
      arrival_time: formData.arrival_time,
      station: formData.station,
    };
    // console.log(body);
    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/update_bus_fare/${busfares.id}/rmf_travellers`,
        body,
        config
      )
      .then((response) => {
        // console.log(response.data)
        Toast.fire({
          icon: "success",
          title: "BusFare Updated Successful....Redirecting",
        });
        setTimeout(() => {
          window.location.reload();

          setFormSubmitting(false);
        }, 2000);
      })
      .catch((error) => {
        setFormSubmitting(false);
        Toast.fire({
          icon: "error",
          title: `${error.message}`,
        });
      });
  };

  $(document).ready(function () {
    $("#from_province").on("change", function () {
      var provinceID = $(this).val();
      if (provinceID) {
        $.ajax({
          url:
            `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/province-districts/${provinceID}/rmf_travellers`,
          type: "GET",
          dataType: "json",
          success: function (data) {
            if (data) {
              $("#from_route").empty();
              $("#district").focus();
              $("#from_route").append(
                '<option disabled selected value=""> -- Select The City Where you are coming from -- </option>'
              );
              $.each(data, function (key, value) {
                $('select[name="from_route"]').append(
                  '<option value="' +
                    value.district_name +
                    '">' +
                    value.district_name +
                    "</option>"
                );
              });
            } else {
              $("#from_route").empty();
            }
          },
        });
      } else {
        $("#from_route").empty();
      }
    });
  });

  $(document).ready(function () {
    $("#to_province").on("change", function () {
      var provinceID = $(this).val();
      if (provinceID) {
        $.ajax({
          url:
            `${process.env.REACT_APP_API_URL}/api/province-districts/` +
            provinceID,
          type: "GET",
          dataType: "json",
          success: function (data) {
            if (data) {
              $("#to_route").empty();
              $("#district").focus();
              $("#to_route").append(
                '<option disabled selected value=""> -- Select Bus Stop Point -- </option>'
              );
              $.each(data, function (key, value) {
                $('select[name="to_route"]').append(
                  '<option value="' +
                    value.district_name +
                    '">' +
                    value.district_name +
                    "</option>"
                );
              });
            } else {
              $("#to_route").empty();
            }
          },
        });
      } else {
        $("#to_route").empty();
      }
    });
  });
  return (
    <Modal
      backdrop="static"
      keyboard={false}
      scrollable
      centered
      size="xl"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Bus Fare</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="form-group col-md-6">
              <label>Select Bus</label>
              <select
                onChange={(e) => onChange(e)}
                disabled={formSubmitting}
                required
                name="going_bus"
                className="form-control"
              >
                <option value={busfares.going_bus}>
                  {" "}
                  {buses.map((item, index) =>
                    busfares.going_bus === item.id ? (
                      <div key={index}>
                        {" "}
                        {item.bus_role} -{" "}
                        <div>
                          {item.driver} - {item.number_place}
                        </div>
                      </div>
                    ) : null
                  )}{" "}
                </option>

                {buses.map((busroad, index) => (
                  <option key={index} value={busroad.id}>
                    {busroad.bus_role} - {busroad.departure_time}{" "}
                    <div>
                      {busroad.driver} - {busroad.number_place}
                    </div>
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group col-md-6">
              <label>Choose Station</label>
              <select
                onChange={(e) => onChange(e)}
                name="station"
                disabled={formSubmitting}
                className="form-control text-capitalize"
              >
                {stations.map((station, index) =>
                  busfares.station === station.id ? (
                    <option selected key={index} value={busfares.station}>
                      Current:{" "}
                      {provinces.map((pro) =>
                        pro.id === station.province ? pro.province_name : null
                      )}{" "}
                      - {station.stationName}
                    </option>
                  ) : null
                )}
                <option disabled>Change Bus Station</option>
                {stations.map((station, index) => (
                  <option key={index} value={station.id}>
                    {provinces.map((pro) =>
                      pro.id === station.province ? pro.province_name : null
                    )}{" "}
                    - {station.stationName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <label>From Province</label>
              <select
                onChange={(e) => onChange(e)}
                id="from_province"
                name="from_province"
                className="form-control text-capitalize"
                disabled={formSubmitting}
              >
                <option selected disabled>
                  Change Bus Boarding Point
                </option>
                {routes.map((route, index) => (
                  <option key={index} value={route.from_province}>
                    {provinces.map((pro) =>
                      pro.id === route.from_province ? pro.province_name : null
                    )}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group col-md-6">
              <label>To Province</label>
              <select
                onChange={(e) => onChange(e)}
                id="to_province"
                name="to_province"
                className="form-control text-capitalize"
                disabled={formSubmitting}
              >
                <option selected disabled>
                  Change Bus Boarding Destination
                </option>
                {routes.map((route, index) => (
                  <option key={index} value={route.to_province}>
                    {provinces.map((pro) =>
                      pro.id === route.to_province ? pro.province_name : null
                    )}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <label>Source</label>
              <select
                disabled={formSubmitting}
                onChange={(e) => onChange(e)}
                required
                id="from_route"
                name="from_route"
                className="form-control text-capitalize"
              >
                <option selected>{busfares.from_route}</option>
              </select>
            </div>
            <div className="form-group col-md-6">
              <label>Destination</label>
              <select
                disabled={formSubmitting}
                onChange={(e) => onChange(e)}
                required
                id="to_route"
                name="to_route"
                className="form-control text-capitalize"
              >
                <option selected>{busfares.to_route}</option>
              </select>
            </div>

            <div className="form-group col-md-6">
              <label>Departure Date</label>
              <input
                onChange={(e) => onChange(e)}
                defaultValue={busfares.departure_date}
                required
                disabled={formSubmitting}
                type="date"
                name="departure_date"
                className="form-control"
              />
            </div>
            <div className="form-group col-md-6">
              <label>Departure Time</label>
              <input
                onChange={(e) => onChange(e)}
                defaultValue={busfares.departure_time}
                required
                disabled={formSubmitting}
                type="time"
                name="departure_time"
                className="form-control"
              />
            </div>
            <div className="form-group col-md-6">
              <label className="text-dark">Estimated Arrival Time</label>
              <input
                onChange={(e) => onChange(e)}
                defaultValue={busfares.arrival_time}
                required
                disabled={formSubmitting}
                type="time"
                name="arrival_time"
                className="form-control"
              />
            </div>
            <div className="form-group col-md-6">
              <label>Price</label>
              <input
                type="number"
                min="1"
                required
                disabled={formSubmitting}
                onChange={(e) => onChange(e)}
                defaultValue={busfares.route_price}
                name="route_price"
                placeholder="Route Price"
                className="form-control"
              />
            </div>
          </div>

          <div className="modal-footer">
            <div className="row">
              <div className="col-sm-6 col-md-6">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={formSubmitting}
                >
                  {formSubmitting && (
                    <span className="wc-editable">
                      <i className="fa fa-refresh fa-spin" /> Updating ....
                    </span>
                  )}
                  {!formSubmitting && (
                    <span className="wc-editable">Update</span>
                  )}
                </button>
              </div>
              <div className="col-sm-6 col-md-6">
                <button
                  type="button"
                  disabled={formSubmitting}
                  className="btn btn-secondary"
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditBusFares;
