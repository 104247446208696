import React, { useState, useEffect } from "react";
import edit from "../images/edit.png";
import axios from "axios";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Spinner } from "react-bootstrap";

export const UserMain = (props) => {
  let _count = 0;
  if (props.count) {
    if (parseInt(props.count) > 0) {
      _count = props.count;
    }
  }
  return (
    <div
      className={`flex align-center justify-between userMain ${
        props.clickable ? "clickable" : ""
      }`}
      onClick={() => props.clickable && props.onClick()}
    >
      <UserAvatar
        isV2
        name={props.company_name}
        profilePicture={props.profile_picture}
        email={props.email}
      />
      {_count > 0 && <div className="counter">{props.count}</div>}
    </div>
  );
};

export const UserAvatar = (props) => {
  return (
    <div className={`userAvatar ${props.isV2 ? "version2" : ""}`}>
      <div
        className="imageCon"
        style={{
          backgroundImage: `url("${props.profile_picture}")`,
        }}
      />
      <div className="contents">
        <div className="name">{props.company_name} </div>
      </div>
    </div>
  );
};

let profileRef;

export const ProfileModal = (props) => {
  const [validated, setValidated] = useState(false);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const [profileData, setProfileData] = useState({
    user_id: props.userDetail.id,
    ...props.userDetail,
  });
  const [submitted, setSubmitted] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [pp, setPP] = useState(
    props.userDetail.profile_picture ? props.userDetail.profile_picture : ""
  );

  const submit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}/userprofile/v1/intercity_travellers/update/${props.user_id}/rmf_travellers`,
        profileData,
        config
      )
      .then((profile) => {
        setProfileData(profile.data);
        props.setClosable();
        setSubmitted(false);

        Toast.fire({
          icon: "success",
          title: "Company Profile Updated Succesfully",
        });
      })
      .catch((e) => {
        setSubmitted(false);

        Toast.fire({
          icon: "error",
          title: "An Error occurred, Company Profile Not Updated!",
        });
      });
    // to be touched again

    // const url =
    //   UPDATE_URL +
    //   `${profileDetails.company_name ? `/${profileDetails.id}` : ""}`;
    // const method = profileDetails.company_name ? "PATCH" : "POST";
    // const profile = await axiosHandler({
    //   method,
    //   url,
    //   data: profileData,

    // }).catch(e => {
    //   Swal.fire('Error', 'An Error occurred, Profile Not Updated!', 'error')
    // });
    // setSubmitted(false);
    // if (profile) {
    //   Swal.fire('Successful', 'Profile Updated Succesfully!', 'success')
    //   props.setClosable();
    //   setProfileDetails(profile.data)
    // }
  };

  const onChange = (e) => {
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (props.visible) {
      setProfileData({
        ...props.userDetail,
        user_id: props.userDetail.id,
      });
      setPP(
        `https://intercitytravellers.com${props.userDetail.profile_picture}`
          ? `https://intercitytravellers.com${props.userDetail.profile_picture}`
          : `https://intercitytravellers.com${props.userDetail.profile_picture}`
      );
    }
  }, [props.visible]);

  const handleOnChange = async (e) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    let data = new FormData();
    data.append("profile_picure", e.target.files[0]);
    setUploading(true);
    const result = await axios.patch(
      `${process.env.REACT_APP_API_URL}/userprofile/v1/intercity_travellers/update/${props.user_id}/rmf_travellers`,
      data,
      config
    );
    setUploading(false);
    if (result) {
      setPP(result.data.profile_picture);
      setProfileData({ ...profileData });
    }
  };

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      centered
      scrollable={true}
      size="xl"
      show={props.visible}
      onHide={props.close}
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Company Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Form.Group as={Col} md="3">
              <div className="leftHook">
                <div
                  className="imageCon"
                  style={{
                    backgroundImage: `url(${pp})`,
                  }}
                />
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={(e) => (profileRef = e)}
                  onChange={handleOnChange}
                />
                {!props.view && (
                  <>
                    {uploading ? (
                      <div className="point">Loading...</div>
                    ) : (
                      <div className="point" onClick={() => profileRef.click()}>
                        Change Picture
                        <img alt="" src={edit} />
                      </div>
                    )}
                  </>
                )}
              </div>
            </Form.Group>
            <Form.Group as={Col} md="5">
              <form onSubmit={submit}>
                <div className="form-group">
                  <label>Company Name</label>
                  <input
                    name="company_name"
                    className="form-control"
                    value={profileData.company_name}
                    onChange={onChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Registration Number </label>

                  <input
                    name="registration_number"
                    className="form-control"
                    placeholder="Registration number"
                    value={profileData.registration_number}
                    onChange={onChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>

                  <input
                    className="form-control"
                    name="email"
                    value={profileData.email}
                    disabled
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Phone</label>

                  <input
                    name="phone"
                    placeholder="phone number"
                    className="form-control"
                    value={profileData.phone}
                    onChange={onChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Tax Clearance Number</label>

                  <input
                    name="tax_clearance_number"
                    className="form-control"
                    placeholder="Tax clearance number"
                    value={profileData.tax_clearance_number}
                    onChange={onChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Bank Account Number </label>

                  <input
                    name="bank_account_no"
                    value={profileData.bank_account_no}
                    className="form-control"
                    placeholder="bank account number"
                    onChange={onChange}
                    disabled={props.view}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Facebook Page Name</label>

                  <input
                    name="facebook_url"
                    value={profileData.facebook_url}
                    className="form-control"
                    placeholder="facebook page name"
                    onChange={onChange}
                    disabled={props.view}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Company Address</label>

                  <input
                    name="address"
                    value={profileData.address}
                    className="form-control"
                    placeholder="Company Address"
                    onChange={onChange}
                    disabled={props.view}
                    required
                  />
                </div>
                {!props.view && (
                  <button
                    className="btn btn-info"
                    type="submit"
                    disabled={submitted}
                  >
                    {submitted ? (
                      <center>
                        <Spinner /> updating...
                      </center>
                    ) : (
                      "Update"
                    )}
                  </button>
                )}
              </form>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group controlId="validationCustom03">
                  <Form.Label>Current Password</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Current Password"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Current Password is required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="validationCustom04">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="New Password"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    New Password is required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="validationCustom05">
                  <Form.Label>Confirm New Password</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Retype New Password"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Confirm New Password is required
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mt-3">
                  <Button type="submit">Update Password</Button>
                </Form.Group>
              </Form>
            </Form.Group>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
