import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { logout } from "../../../actions/auth";
import { Spinner } from "react-bootstrap";
import { User } from "../../../redux/types/user";
import { AuthState } from "../../../redux/features/authSlice";

const AdminSibar = () => {
  const [refers, setRefers] = React.useState<any[]>([]);
  const [terms, setTerms] = React.useState<any[]>([]);
  const [privacy, setPrivacy] = React.useState([]);
  const [userdetail, setUserDetail] = React.useState<User>();
  const [isLoading, setIsLoading] = React.useState(true);

  const SessionExpired = () => {
    return Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Session Expired!",
      footer: '<a href="/zm/auth/login">Login to Continue</a>',
      allowOutsideClick: false,
      showConfirmButton: false,
    });
  };


  const FetchData = async () => {
    
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
      Accept: "application/json",
    },
  };
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/auth/users/me/`, config)
        .then((res) => {
          // console.log(`====TOKEN ${localStorage.getItem("access")}`);
          setUserDetail(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            SessionExpired();
          }
          console.log("could not load data => ", err.message);
        });
    } catch (error) {}
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/referals/rmf_travellers`
        )
        .then((res) => {
          setRefers(res.data);
          // console.log(res.data);
        });
    } catch (error: any) {
      console.log(error.message);
    }
    // get terms
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/get-terms/rmf_travellers`
        )
        .then((res) => {
          setTerms(res.data);
          // console.log(res.data);
        });
    } catch (error: any) {
      console.log(error.message);
    }

    // get privacy policy
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/privacy/rmf_travellers`
        )
        .then((res) => {
          setPrivacy(res.data);
          // console.log(res.data);
        });
    } catch (error: any) {
      console.log(error.message);
    }
  };
  React.useEffect(() => {
    
    FetchData();
   
  }, []);

  if (!userdetail) {
    return <Spinner size="sm" />;
  }

  return (
    <>
      <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul
              className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start"
              id="menu">
              <li>
                <a href="/" >
                  <i className="fa fa-home mr-2"></i> Site
                </a>
              </li>
              <li>
                <a
                  
                  href={`/zm/intercity_travellers_admin/?uid=${userdetail.id}`}>
                  <i className="fs-5 bi-speedometer2 mr-2"></i> Dashboard
                </a>
              </li>

              {/* <li className="submenu">
                            <Link  to="/hotels"> <i className="fa fa-hotel"></i> Hotels</Link>
                        </li> */}
              <li className="submenu">
                <a
                  
                  href="/zm/intercity_travellers_admin/blogs">
                  {" "}
                  <i className="fas fa-blog mr-2"></i> Blogs
                </a>
              </li>

              <li className="submenu">
                <a
                  
                  href="/zm/intercity_travellers_admin/commutors">
                  {" "}
                  <i className="fa fa-bus mr-2"></i> Commutors
                </a>
              </li>
              <li className="submenu">
                <a
                  
                  href="/zm/intercity_travellers_admin/commutor-routes">
                  <i className="fa fa-road"></i> Commutor Routes
                </a>
              </li>
              <li className="submenu">
                <a
                  
                  href="/zm/intercity_travellers_admin/hotels">
                  <i className="fa fa-bed mr-2"></i> Hotels
                </a>
              </li>
              <li className="submenu">
                <a
                  
                  href="/zm/intercity_travellers_admin/cars">
                  <i className="fa fa-car mr-2"></i> Cars
                </a>
              </li>
              <li className="submenu">
                <a
                  
                  href="/zm/intercity_travellers_admin/registration_requests">
                  <i className="fa fa-bed mr-2"></i> Registration Requests
                </a>
              </li>
              <li className="submenu">
                <a
                  
                  href="/zm/intercity_travellers_admin/referal_accounts">
                  {" "}
                  <i className="fa fa-users mr-2"></i> Referal Accounts
                </a>
              </li>
              <li className="submenu">
                <a
                  
                  href="/zm/intercity_travellers_admin/places">
                  {" "}
                  <i className="fa fa-home mr-2"></i> Places
                </a>
              </li>

              {refers.map((refer, index) => (
                <li key={index} className="submenu">
                  <a
                    
                    href={`/zm/intercity_travellers_admin/referals/${refer.id}`}>
                    {" "}
                    <i className="fa fa-book mr-2"></i> Referals
                  </a>
                </li>
              ))}
              {terms.map((item, index) => (
                <li key={index} className="submenu">
                  <a
                    
                    href={`/zm/intercity_travellers_admin/terms/${item.id}`}>
                    {" "}
                    <i className="fa fa-book-open mr-2"></i> Terms / Conditions
                  </a>
                </li>
              ))}
              {privacy.map((item: any, index) => (
                <li key={index} className="submenu">
                  <a
                    
                    href={`/zm/intercity_travellers_admin/privacy/${item.id}`}>
                    {" "}
                    <i className="fa fa-book mr-2"></i> Privacy Policy
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: AuthState) => ({
  isAuthenticated: state.isAuthenticated,
});

export default connect(mapStateToProps, { logout })(AdminSibar);
