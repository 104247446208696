import React, { Component } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";

import { useParams } from "react-router-dom";
export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}
class EditProvince extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      thumbnail: null,
      province_name: "",
      about_province: "",
      country: "",
      image_preview: "",
      province_id: "",
      isLoading: true,
      province: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.input = React.createRef();
  }

  handleEditorChange(about_province) {
    this.setState({ about_province });
  }

  handleImage(e) {
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    this.setState({
      image_preview: image_as_base64,
      thumbnail: e.target.files[0],
    });
  }

  async componentDidMount() {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    const province_id = this.props.match.params.id;
    const slug = this.props.match.params.slug;
    const results = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/provinces-details/${slug}/${province_id}/rmf_travellers`,config
    );
    this.setState({
      province: results.data,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      isSubmitting: true,
    });
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    let form_data = new FormData();
    form_data.append("thumbnail", this.state.thumbnail);
    form_data.append("province_name", this.state.province_name);
    form_data.append("about_province", this.state.about_province);
    form_data.append("country", this.state.country);

    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/update-province/${this.props.match.params.id}/rmf_travellers`,
        form_data,
        config
      )
      .then((response) => {
        // console.log(response.data)
        Swal.fire(
          "Successful",
          "Province updated Successfully....Redirecting!",
          "success"
        );

        setTimeout(() => {
          window.location.href = `/zm/intercity_travellers_admin/?uid=1`;
          this.setState({
            isSubmitting: false,
          });
        }, 2000);
      })
      .catch((error) => {
        this.setState({
          isSubmitting: false,
        });
        Swal.fire("Error", "An Error occurred try again later!", "error");
        console.error("There was an error!", error);
      });
  }

  render() {
    return (
      <>
        <div className="py-4 align-items-stretch">
          <div id="content" className="p-2 p-md-3 pt-3">
            <h4 className="text-center mt-5 mb-5">Edit Provinces</h4>
            <div className="mt-5">
              <form onSubmit={this.handleSubmit}>
                <div className="container">
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label className="text-dark">Province Name</label>

                      <input
                        type="text"
                        required
                        placeholder="Province Name"
                        name="province_name"
                        className="form-control"
                        onChange={(e) =>
                          (this.state.province_name = e.target.value)
                        }
                        defaultValue={
                          (this.state.province_name =
                            this.state.province.province_name)
                        }
                        ref={this.input}
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label className="text-dark">Image</label>
                      <br />
                      {/* <img
                            width="150"
                            height="100"
                            src={this.state.image_preview}
                            alt="image_preview"
                          /> */}
                      <input
                        type="file"
                        name="thumbnail"
                        placeholder="image"
                        className="form-control"
                        //  value={province.thumbnail}
                        onChange={this.handleImage}
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label className="text-dark">Country</label>

                      <input
                        type="text"
                        required
                        defaultValue={
                          (this.state.country = this.state.province.country)
                        }
                        onChange={(e) => (this.state.country = e.target.value)}
                        name="country"
                        placeholder="Country"
                        className="form-control"
                        ref={this.input}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <Editor
                      init={{
                        height: 500,
                      }}
                      ref={this.input}
                      initialValue={this.state.province.about_province}
                      onEditorChange={this.handleEditorChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div>
                    <p className="pull-right">
                      <button
                        className="btn btn-primary form-control"
                        disabled={this.state.isSubmitting}
                      >
                        {this.state.isSubmitting && (
                          <span className="wc-editable">
                            {" "}
                            <i className="fa fa-refresh fa-spin" /> Updating
                            ....
                          </span>
                        )}
                        {!this.state.isSubmitting && (
                          <span className="wc-editable">
                            <i className="fa fa-upload" />
                            Update
                          </span>
                        )}
                      </button>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(EditProvince);
