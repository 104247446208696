import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";

const EditBus = ({ bus, routes, provinces }) => {
  const [formSubmiting, setFormSubmiting] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
      Accept: "application/json",
    },
  };

  const [formData, setFormData] = useState({
    ...bus,
  });

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const updateBuse = async (data) => {
    // e.preventDefault();
    setFormSubmiting(true);

    const body = {
      seat_capacity: data.seat_capacity,
      number_place: data.number_place,
      driver: data.driver,
      bus_role: data.bus_role,
      route: data.route,
      departure_day: data.departure_day,
      arrivalDay: data.arrivalDay,
    };
    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/update-bus/${bus.id}/rmf_travellers`,
        body,
        config
      )
      .then((response) => {
        console.log(response.data);
        setTimeout(() => {
          window.location.reload();
          setFormSubmiting(false);
        }, 2000);

        Toast.fire({
          icon: "success",
          title: "Bus Updated Successful....Redirecting",
        });
      })
      .catch((error) => {
        setFormSubmiting(false);
        Toast.fire({
          icon: "error",
          title: `An Error occurred try again later!${error.message}`,
        });
      });
  };

  return (
    <>
      <div
        className="text-center btn btn-sm btn-info"
        style={{
          cursor: "pointer",
        }}
        onClick={handleShow}>
        <i className="fa fa-edit"></i> Edit
      </div>
      <Modal
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
        show={show}
        onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Bus</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(updateBuse)}>
            <div className="row">
              <div className="form-group col-md-12">
                <label>Change Route Route</label>
                <select
                  onChange={(e) => onChange(e)}
                  name="route"
                  disabled={formSubmiting}
                  {...register("route", { required: true })}
                  className="form-control text-capitalize">
                  <option defaultValue={formData.route}>
                    {routes.map((item) =>
                      item.id === formData.route
                        ? provinces.map((pro) =>
                            pro.id === item.from_province
                              ? pro.province_name
                              : ""
                          )
                        : null
                    )}{" "}
                    -{" "}
                    {routes.map((item) =>
                      item.id === formData.route
                        ? provinces.map((pro) =>
                            pro.id === item.to_province ? pro.province_name : ""
                          )
                        : null
                    )}
                  </option>
                  {routes.map((route, index) => (
                    <option key={index} value={route.id}>
                      {provinces.map((pro) =>
                        pro.id === route.from_province
                          ? pro.province_name
                          : null
                      )}{" "}
                      -{" "}
                      {provinces.map((pro) =>
                        pro.id === route.to_province ? pro.province_name : null
                      )}
                    </option>
                  ))}
                </select>
                {errors.route && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
              <div className="form-group col-md-6">
                <label className="text-dark">Seat Capacity</label>
                <input
                  type="number"
                  min="0"
                  {...register("seat_capacity", { required: true })}
                  placeholder="Seat Capacity"
                  name="seat_capacity"
                  className="form-control"
                  disabled={formSubmiting}
                  defaultValue={formData.seat_capacity}
                  onChange={(e) => onChange(e)}
                />
                {errors.seat_capacity && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
              <div className="form-group col-md-6">
                <label className="text-dark">Number Plate</label>
                <input
                  type="text"
                  disabled={formSubmiting}
                  name="number_place"
                  defaultValue={formData.number_place}
                  {...register("number_place", { required: true })}
                  placeholder="number plate"
                  className="form-control"
                  onChange={(e) => onChange(e)}
                />
                {errors.number_place && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
              <div className="form-group col-md-6">
                <label className="text-dark">Driver</label>
                <input
                  type="text"
                  name="driver"
                  placeholder="Driver"
                  defaultValue={formData.driver}
                  disabled={formSubmiting}
                  className="form-control"
                  {...register("driver", { required: true })}
                  onChange={(e) => onChange(e)}
                />
                {errors.driver && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
              <div className="form-group col-md-6">
                <label className="text-dark">Queue Position</label>
                <input
                  {...register("bus_role", { required: true })}
                  onChange={(e) => onChange(e)}
                  disabled={formSubmiting}
                  type="text"
                  defaultValue={formData.bus_role}
                  name="bus_role"
                  placeholder="Queue Position e.g first bus"
                  className="form-control"
                />
                {errors.bus_role && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
              <div className="form-group col-md-6">
                <label>Departure Date</label>
                <input
                  onChange={(e) => onChange(e)}
                  disabled={formSubmiting}
                  type="date"
                  {...register("departure_day", { required: true })}
                  defaultValue={formData.departure_day}
                  name="departure_day"
                  className="form-control"
                />
                {errors.departure_day && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>

              <div className="form-group col-md-6">
                <label className="text-dark">Arrival Date</label>
                <input
                  {...register("arrivalDay", { required: true })}
                  onChange={(e) => onChange(e)}
                  disabled={formSubmiting}
                  defaultValue={formData.arrivalDay}
                  type="date"
                  name="arrivalDay"
                  className="form-control"
                />
                {errors.arrivalDay && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>

            <Modal.Footer>
              <div className="row">
                <div>
                  <button
                    type="submit"
                    className="btn btn-primary form-control"
                    disabled={formSubmiting}>
                    {formSubmiting && (
                      <span className="wc-editable">
                        {" "}
                        <Spinner size="sm" /> Updating ....
                      </span>
                    )}
                    {!formSubmiting && (
                      <span className="wc-editable">
                        <i className="fa fa-upload"></i> Update
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditBus;
