import { format, parseISO, subDays } from "date-fns";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";


const DailyCharts = () => {
    const dailyData = [];
    for (let num = 7; num >= 0; num--) {
      dailyData.push({
        date: subDays(new Date(), num).toISOString().substr(0, 10),
        amount: 5 + Math.random(),
        profit: 1,
      });
    }
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={dailyData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          tickFormatter={(str) => {
            const date = parseISO(str);
            return format(date, "MMM, d");
          }}
        />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="amount" fill="#8884d8" /> {/* Sales Bar in Purple */}
        <Bar dataKey="profit" fill="#FFA500" /> {/* Profit Bar in Orange */}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default DailyCharts;
