import React, { useRef } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Swal from "sweetalert2";
import { DragAndDrop } from "../../widgets/DragNdrop";
import { useForm } from "react-hook-form";
import httpHolidaypackagesService from "../services/http.holidaypackages.service";

function AddTravels({ countries }) {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [ageRange, setAgeRange] = useState({
    fromAge: "",
    toAge: "",
  });
  const [languages, setLanguages] = useState([]);
  const [language, setLanguage] = useState(null);
  const [formData, setFormData] = useState({
    placeImage1: null,
    placeImage2: null,
  });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [files, setFiles] = useState([]);

  const onChange = (e) => {
    setAgeRange({
      ...ageRange,
      [e.target.name]: e.target.value,
    });
  };

  const handleLanguages = (e) => {
    setLanguage(e.target.value);
  };

  let langaugeRef = useRef();

  const removeLanguageTag = (i) => {
    const newTags = [...languages];
    newTags.splice(i, 1);
    setLanguages(newTags);
  };

  /**
   * Stop enter submitting the form.
   * @param keyEvent Event triggered when the user presses a key.
   */
  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const inputLanguageKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      if (languages.find((tag) => tag.toLowerCase() === val.toLowerCase())) {
        return;
      }
      setLanguages([...languages, val]);
      langaugeRef.value = null;
    }
  };

  const [activitiesInputList, setActivitiesInputList] = useState([
    {
      currency: "",
      activityName: "",
      amount: "",
    },
  ]);
  // handle Activity input change
  const handleActivityInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...activitiesInputList];
    list[index][name] = value;
    setActivitiesInputList(list);
  };

  // handle click event of the Remove button
  const handleActivityRemoveClick = (index) => {
    const list = [...activitiesInputList];
    list.splice(index, 1);
    setActivitiesInputList(list);
  };
  // handle click event of the Add button
  const handleAddActivityClick = () => {
    setActivitiesInputList([
      ...activitiesInputList,
      {
        currency: "",
        activityName: "",
        amount: "",
      },
    ]);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const uploadPackageImages = (packageID) => {
    const bodyFormData = new FormData();
    files.forEach(async (image) => {
      bodyFormData.append("package", packageID);
      bodyFormData.append("image", image);
      try {
        httpHolidaypackagesService
          .submitPackageImage(bodyFormData)
          .then((response) => {
            if (response.status === 201) {
              // reset();
              // window.location.reload();
              // toast.success(response.data.message);
            } else {
              // toast.error(response.data.message);
            }
          })
          .catch((error) => {
            console.error(error);
            // toast.error(error.message);
          });
      } catch (error) {}
    });
  };
  // add travel
  const handleFormSubmit = async (data) => {
    setFormSubmitting(true);
    // console.log(data);
    httpHolidaypackagesService
      .createPackage(
        data,
        activitiesInputList,
        files[0],
        formData.placeImage1,
        formData.placeImage2,
        ageRange,
        languages
      )
      .then((response) => {
        uploadPackageImages(response.data.id);
        Swal.fire("Successful", "Travel Added Successfully", "success");
        setFormSubmitting(false);
      })
      .catch((error) => {
        setFormSubmitting(false);
        Swal.fire("Error", `An Error occurred\n${error.message}`, "error");
      });
  };

  return (
    <>
      <div className="col-md-2 mt-5">
        <button
          onClick={handleShow}
          className="btn btn-sm btn-outline-primary mt-5">
          <i className="fa fa-plus"></i> Add Tour
        </button>
      </div>
      <Modal
        centered
        scrollable
        size="xl"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add Tour Package</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onKeyDown={onKeyDown}
            id="paypal_donate_form_onetime_recurring"
            onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label htmlFor="form_name">
                        Country <small className="text-danger">*</small>
                      </label>
                      <select
                        className="form-control text-capitalize mb-2"
                        name="country"
                        {...register("country", { required: true })}
                        disabled={formSubmitting}>
                        <option value="">Choose Country</option>
                        {countries.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.countryName}
                          </option>
                        ))}
                      </select>
                      {errors.country && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <label htmlFor="form_name">
                        Package Title <small className="text-danger">*</small>
                      </label>
                      <input
                        {...register("title", { required: true })}
                        disabled={formSubmitting}
                        id="title"
                        name="title"
                        className="form-control mb-2"
                        type="text"
                        placeholder="Package title"
                      />
                      {errors.title && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <label htmlFor="form_name">
                        Tours Duration <small className="text-danger">*</small>
                      </label>
                      <input
                        {...register("tourDays", { required: true })}
                        disabled={formSubmitting}
                        id="title"
                        name="tourDays"
                        className="form-control mb-2"
                        type="text"
                        placeholder="Tour Duration E.G 3 night, 4 days or 2hrs"
                      />
                      {errors.tourDays && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                  </div>
                  <Form.Group
                    as={Col}
                    md="4"
                    className="mb-3"
                    controlId="validationCustom05">
                    <Form.Label>Select Currency</Form.Label>
                    <InputGroup className="mb-3">
                      <select
                        {...register("currency", { required: true })}
                        disabled={formSubmitting}
                        name="currency"
                        className="form-control"
                        id="ConsultantSelect">
                        <option value="">Select Currency</option>
                        <option value="ZMW">Zambian Currency: ZMW</option>
                        <option value="USD">American Currency: USD</option>
                      </select>
                      <input
                        {...register("tourPrice", { required: true })}
                        disabled={formSubmitting}
                        id="title"
                        name="tourPrice"
                        className="form-control mb-2"
                        type="text"
                        placeholder="Tour Package Price"
                      />
                    </InputGroup>
                    {errors.tourPrice && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </Form.Group>

                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <label htmlFor="form_email">
                        Location <small className="text-danger">*</small>
                      </label>
                      <input
                        {...register("location", { required: true })}
                        id="form_email"
                        disabled={formSubmitting}
                        name="location"
                        className="form-control required mb-2"
                        type="text"
                        placeholder="Location e.g https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2519.700327595181!2d-0.13858868403737226!3d50.836714467337785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487585104ec1fe1b%3A0x28b5349b15ce0c4d!2sLondon%20Road%20(Brighton)%20Train%20Station%20-%20Southern%20Railway!5e0!3m2!1sen!2suk!4v1611468671158!5m2!1sen!2suk"
                      />
                      {errors.location && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label htmlFor="form_name">
                        Tour Type <small className="text-danger">*</small>
                      </label>
                      <select
                        name="travel_type"
                        className="form-control text-capitalize mb-2"
                        {...register("travel_type", { required: true })}
                        disabled={formSubmitting}>
                        <option value="">Choose Package Type</option>
                        <option value="local">Local Holiday Package</option>
                        <option value="internation">
                          International Holiday Package
                        </option>
                      </select>
                      {errors.travel_type && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor="form_name">
                        Maximum group size for this package
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        {...register("groupSize", { required: true })}
                        disabled={formSubmitting}
                        id="title"
                        name="groupSize"
                        className="form-control mb-2"
                        type="number"
                        placeholder="Group Size E.g 4"
                      />
                      {errors.groupSize && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="ageRamge">Pack Age Range</label>
                      <div class="input-group mb-3">
                        <div className="form-group">
                          <input
                            type="number"
                            {...register("fromAge", {
                              required: true,
                              onChange: (e) => onChange(e),
                            })}
                            name="fromAge"
                            class="form-control"
                            placeholder="From Age"
                            aria-label="From Age"
                          />
                          {errors.fromAge && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                        </div>
                        <div className="form-group">
                          <input
                            type="number"
                            {...register("toAge", {
                              required: true,
                              onChange: (e) => onChange(e),
                            })}
                            name="toAge"
                            class="form-control"
                            placeholder="To Age"
                            aria-label="To Age"
                          />
                          {errors.toAge && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Form.Group
                    as={Col}
                    md="12"
                    className="mb-3"
                    controlId="validationCustom03">
                    <Form.Label>Languages</Form.Label>
                    <div className="input-tag">
                      <ul className="input-tag__tags">
                        {languages.map((tag, i) => (
                          <li key={i}>
                            {tag}
                            <button
                              type="button"
                              onClick={() => {
                                removeLanguageTag(i);
                              }}>
                              X
                            </button>
                          </li>
                        ))}
                        <li className="input-tag__tags__input">
                          <Form.Control
                            disabled={formSubmitting}
                            type="text"
                            onKeyDown={inputLanguageKeyDown}
                            {...register("languages", {
                              required: false,
                              onChange: (e) => handleLanguages(e),
                            })}
                            name="languages"
                            className="form-control"
                            value={language}
                            placeholder="Languages, E.g. English, French, Bemba, Nyanja etc"
                            ref={(c) => {
                              langaugeRef = c;
                            }}
                          />
                        </li>
                      </ul>
                    </div>
                    {errors.languages && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </Form.Group>

                  <h2 className="text-center mb-3">Places To Visit</h2>
                  <div className="col-sm-6 col-md-3">
                    <div className="form-group">
                      <label htmlFor="form_email">
                        Place Name 1 <small className="text-danger">*</small>
                      </label>
                      <input
                        {...register("placeName1", { required: true })}
                        id="form_email"
                        disabled={formSubmitting}
                        name="placeName1"
                        className="form-control required mb-2"
                        type="text"
                        placeholder="Place Name"
                      />
                      {errors.placeName1 && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="form-group">
                      <label htmlFor="form_email">
                        Place Name 2 <small className="text-danger">*</small>
                      </label>
                      <input
                        {...register("placeName2", { required: true })}
                        id="form_email"
                        name="placeName2"
                        className="form-control required mb-2"
                        disabled={formSubmitting}
                        type="text"
                        placeholder="Place Name"
                      />
                      {errors.placeName2 && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="form-group">
                      <label htmlFor="form_email">
                        Place Image 1 <small className="text-danger">*</small>
                      </label>
                      <input
                        id="placeImage1"
                        {...register("placeImage1", {
                          required: true,
                          onChange: (e) =>
                            (formData.placeImage1 = e.target.files[0]),
                        })}
                        name="placeImage1"
                        className="form-control required mb-2"
                        disabled={formSubmitting}
                        type="file"
                        accept="image/*"
                        placeholder="place Image"
                      />
                      {errors.placeImage1 && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="form-group">
                      <label htmlFor="form_email">
                        Place Image 2 <small className="text-danger">*</small>
                      </label>
                      <input
                        {...register("placeImage2", {
                          required: true,
                          onChange: (e) =>
                            (formData.placeImage2 = e.target.files[0]),
                        })}
                        id="placeImage2"
                        name="placeImage2"
                        className="form-control required mb-2"
                        type="file"
                        accept="image/*"
                        disabled={formSubmitting}
                        placeholder="place Image"
                      />
                      {errors.placeImage2 && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                  </div>
                  <div className="text-center">
                    <h2>Extra Activities</h2>
                  </div>
                  <div>
                    <Form.Group
                      as={Col}
                      md="6"
                      className="mb-3"
                      controlId="validationCustom05">
                      <button
                        type="button"
                        onClick={handleAddActivityClick}
                        className="btn btn-sm btn-success">
                        <i className="bi bi-plus-circle"></i> Add Activity
                      </button>
                    </Form.Group>
                    <Row>
                      {activitiesInputList.map((x, i) => {
                        return (
                          <>
                            <Form.Group as={Col} md="6" className="mb-3">
                              <Form.Label>Activity Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="activityName"
                                value={x.activityName}
                                onChange={(e) =>
                                  handleActivityInputChange(e, i)
                                }
                                placeholder="Activity Name"
                                disabled={formSubmitting}
                              />
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              md="6"
                              className="mb-3"
                              controlId="validationCustom05">
                              <Form.Label>Select Currency</Form.Label>
                              <InputGroup className="mb-3">
                                <Form.Select
                                  onChange={(e) =>
                                    handleActivityInputChange(e, i)
                                  }
                                  name="currency"
                                  id="ConsultantSelect">
                                  <option value="">Select Currency</option>
                                  <option value="ZMW">
                                    Zambian Currency: ZMW
                                  </option>
                                  <option value="USD">
                                    American Currency: USD
                                  </option>
                                </Form.Select>
                                <Form.Control
                                  type="number"
                                  name="amount"
                                  value={x.amount}
                                  onChange={(e) =>
                                    handleActivityInputChange(e, i)
                                  }
                                  disabled={formSubmitting}
                                  placeholder="Amount"
                                />
                              </InputGroup>
                            </Form.Group>
                            <p className="pull-right mb-4">
                              <button
                                type="button"
                                className="btn btn-sm btn-warning"
                                onClick={() => handleActivityRemoveClick(i)}>
                                <i className="bi bi-trash text-danger"></i>{" "}
                                Remove Activity
                              </button>
                            </p>
                          </>
                        );
                      })}
                    </Row>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label htmlFor="form_name">
                        Package Description{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <textarea
                        rows="8"
                        cols="5"
                        {...register("description", { required: true })}
                        disabled={formSubmitting}
                        id="description"
                        name="description"
                        className="form-control mb-2"
                        placeholder="Package Description"></textarea>
                      {errors.description && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                    <DragAndDrop
                      heading={"Package Image Gallery"}
                      onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                          for (let i = 0; i < e.target.files["length"]; i++) {
                            setFiles((prevState) => [
                              ...prevState,
                              e.target.files[i],
                            ]);
                          }
                        }
                      }}
                      setFiles={setFiles}
                      files={files}
                    />
                  </div>
                </div>
              </div>

              <Modal.Footer>
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group">
                      <a onClick={handleClose} className="btn btn-warning">
                        Close
                      </a>
                    </div>
                  </div>
                  <div className="col-sm-7 col-md-7 pull-right">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={formSubmitting}>
                      {formSubmitting && (
                        <span className="wc-editable ">
                          <i className="fa fa-refresh fa-spin" /> submitting ...
                        </span>
                      )}
                      {!formSubmitting && (
                        <span className="wc-editable ">
                          <i className="fa fa-upload"></i> submit
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddTravels;
