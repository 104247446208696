import { apiSlice } from "../services/apiSlice";
import { IRoomNumber } from "../types/room_numbers.type";

export const roomNumberAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createRoomNumber: builder.mutation<
      IRoomNumber,
      Partial<IRoomNumber>
    >({
      query(data) {
        return {
          url: `/hotels/v1/intercity_travellers/room_numbers/rmf_travellers`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["IRoomNumber"],
      transformResponse: (result: { room: IRoomNumber }) =>
        result.room,
    }),
    updateRoomNumber: builder.mutation<
      IRoomNumber,
      { id: any; room: IRoomNumber }
    >({
      query: ({ id, room }) => ({
        url: `/hotels/v1/intercity_travellers/room_numbers/rmf_travellers/${id}/`,
        method: "PATCH",
        body: room,
      }),
      invalidatesTags: ["IRoomNumber"],
      transformResponse: (response: { room: IRoomNumber }) =>
        response.room,
    }),
    getRoomNumber: builder.query<IRoomNumber, number>({
      query: (id) => ({
        url: `/hotels/v1/intercity_travellers/room_numbers/rmf_travellers/${id}/`,
      }),
      providesTags: ["IRoomNumber"],
    }),
    getRoomNumbers: builder.query<IRoomNumber[], void>({
      query: () => `/hotels/v1/intercity_travellers/room_numbers/rmf_travellers/`,
      transformResponse: (res: IRoomNumber[]) =>
        res.sort((a, b) => b.id - a.id),
        providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'IRoomNumber', id })) : ['IRoomNumber'],
      // keepUnusedDataFor: 5,
    }),
    deleteRoomNumber: builder.mutation<IRoomNumber, number>({
      query: (id) => ({
        url: `/hotels/v1/intercity_travellers/room_numbers/rmf_travellers/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["IRoomNumber"],
    }),
  }),
});
export const {
  useCreateRoomNumberMutation,
  useDeleteRoomNumberMutation,
  useGetRoomNumberQuery,
  useGetRoomNumbersQuery,
  useUpdateRoomNumberMutation,
} = roomNumberAPI;
