import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import LoadingSpinner from "../../../components/widgets/spinner";
import CertificateView from "./certificate_view";
import RequestDetails from "./request_details";

function RegistrationRequests() {
  const [requests, setRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [certificate, setCertificate] = useState(null);
  const [requestDetails, setRequestDetails] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [show, setShow] = useState(false);
  const handleCloseDetails = () => {
    setRequestDetails(null);
    setShowDetails(false);
  };

  const handleShowDetails = (item) => {
    setRequestDetails(item);
    setShowDetails(true);
  };

  const handleClose = () => {
    setCertificate(null);
    setShow(false);
  };
  const handleShow = (item) => {
    setCertificate(item);
    setShow(true);
  };
  const fetchData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/view_requests/rmf_travellers`, config)
      .then((res) => {
        setRequests(res.data);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="wrapper mt-5 align-items-stretch">
        <div id="content" className="p-2 p-md-3 pt-3">
          <h3 className="text-center mt-4 mb-4">
            Lodge/Hotel Registration Requests
          </h3>

          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="table-responsive">
              <table class="table table-hover text-dark">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Company Name</th>
                    <th>E-mail</th>
                    <th>Phone Number</th>
                    <th>Certificate Of Incorporation</th>
                    <th>Status</th>
                    <th>Date Created</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {requests.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="text-capitalize">{item.companyName}</td>
                      <td>{item.email}</td>
                      <td>{item.phoneNumber}</td>
                      <td>
                        <button
                          onClick={() => handleShow(item.cerfificate)}
                          className="btn btn-sm btn-info"
                        >
                          View Certificate Of Incorporation
                        </button>
                      </td>
                      <td className="text-capitalize">{item.status}</td>
                      <td>
                        {moment(item.created_at).format("MMMM Do YYYY H:m a")}
                      </td>
                      <td>
                        <button
                          onClick={() => handleShowDetails(item)}
                          className="btn btn-sm btn-primary"
                        >
                          <i className="fa fa-eye"></i> Details
                        </button>
                      </td>
                      <CertificateView
                        certificate={
                          certificate === null ? item.cerfificate : certificate
                        }
                        handleClose={handleClose}
                        show={show}
                      />
                      <RequestDetails
                        request={
                          requestDetails === null ? item : requestDetails
                        }
                        handleClose={handleCloseDetails}
                        show={showDetails}
                        setShow={setShowDetails}
                        fetchRequests={fetchData}
                      />
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default RegistrationRequests;
