import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/css/spinner.css";
import "./styles/css/gallery.css";
import "./styles/scss/style.scss";
// custom styles import
import "./styles/css/dashboard.css";
import "./styles/css/flaticon.css";
import "./styles/css/style.css";
import "./styles/css/custom.css";
import "./styles/css/auth.css";
import "./styles/css/404.css";
import "./styles/css/slider.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-loading-skeleton/dist/skeleton.css";
// car styles
import "./styles/css/cars.css";
import "./styles/css/hero_car_slider.css";

// import "./assets/styles/css/bootstrap.min.css";
import "./assets/styles/css/animate.css";
import "./assets/styles/css/dataTables.bootstrap4.min.css";
import "./assets/styles/css/style.css";
// custom styles import end
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
