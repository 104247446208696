import React from "react";
import edit from "../../assets/img/icons/edit-set.svg";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useAppSelector } from "../../hooks/redux-hooks";
import {
  useGetUserQuery,
  useUpdateUserMutation,
  useCreateUserProfileMutation,
  useUpdateProfileMutation,
} from "../../redux/slices/usersApiSlice";
import { User } from "../../redux/types/user";
import ChangePassword from "../widgets/change_password";

const CompanyProfile: React.FC = () => {
  const profileData = useAppSelector((state) => state.auth.user);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const {
    data: userData,
    isLoading: gettingUser,
    isSuccess: gettingUserSuccess,
    isFetching,
    error,
  }: User | any = useGetUserQuery(Number(profileData?.id));
  const [updateUser, { isSuccess }] = useUpdateUserMutation();
  const [createProfile, { isLoading }] = useCreateUserProfileMutation();
  const [updateProfile, { isUninitialized }] = useUpdateProfileMutation();

  if (gettingUser || isFetching) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <i className="fa fa-spinner fa-spin fa-5x text-danger"></i>
      </div>
    );
  }

  if (gettingUserSuccess) {
    localStorage.setItem("userInfo", JSON.stringify(userData));
  }

  if (error) {
    console.log(error.status);
    return (
      <div
        style={{ marginTop: "50%" }}
        className="alert alert-danger mt-5 text-center"
        role="alert">
        {error}
      </div>
    );
  }

  const onSubmit = (data: any) => {
    if (userData.user_profile === null) {
      createProfile({
        user_profile: Number(profileData?.id),
        ...data,
      })
        .unwrap()
        .then((res) => {
          reset();
          toast.success("Details Updated Successfully");
        })
        .catch((err) => {
          toast.error("Failed to Update:" + err.error);
        });
    } else {
      updateProfile({ id: userData.user_profile?.id, ...data })
        .unwrap()
        .then((res) => {
          reset();
          toast.success("Details Updated Successfully");
        })
        .catch((err) => {
          toast.error("Failed to Update:" + err.error);
        });
    }
    updateUser({ id: Number(profileData?.id), ...data })
      .unwrap()
      .then((res) => {
        reset();
        toast.success("Details Updated Successfully");
      })
      .catch((err) => {
        toast.error("Failed to add:" + err.error);
      });
  };

  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Profile</h4>
            <h6>Company Profile</h6>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="profile-set">
              <div className="profile-head"></div>
              <div className="profile-top">
                <div className="profile-content">
                  <div className="profile-contentimg">
                    <img
                      src={
                        userData.user_profile?.profile_picture === undefined
                          ? "https://intercitytravellers.com/media/profilepic/user.png"
                          : userData.user_profile?.profile_picture
                      }
                      alt="img"
                      id="blah"
                    />
                    <div className="profileupload">
                      <input type="file" id="imgInp" />
                      <a href="javascript:void(0);">
                        <img src={edit} alt="img" />
                      </a>
                    </div>
                  </div>
                  <div className="profile-contentname">
                    <h2 className="text-capitalize">
                      {userData.first_name} {userData.last_name}
                    </h2>
                    <small>{userData.email}</small>
                    <h4>Updates Your Photo and Personal Details.</h4>
                  </div>
                </div>
              </div>
            </div>
            {/* EDIT FORM */}
            <Container>
              <Form.Group>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <h3>Personal Details</h3>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label>First Name</label>
                      <input
                        className={`form-control text-capitalize ${
                          errors.first_name ? "border-danger" : ""
                        }`}
                        value={userData?.first_name}
                        {...register("first_name", { required: true })}
                        disabled={isLoading}
                      />
                      {errors.first_name && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label>Last Name</label>
                      <input
                        className={`form-control text-capitalize ${
                          errors.last_name ? "border-danger" : ""
                        }`}
                        value={userData?.last_name}
                        {...register("last_name", { required: true })}
                        disabled={isLoading}
                      />
                      {errors.last_name && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="age">Next of kin</label>
                      <input
                        type="number"
                        value={userData.user_profile?.nof}
                        placeholder="Next of kin"
                        disabled={isLoading}
                        {...register("nof", { required: true })}
                        className={`form-control ${
                          errors.nof ? "border-danger" : ""
                        }`}
                        name="nof"
                        id="age"
                      />
                      {errors.nof && (
                        <p className="text-danger">Next of Kin is required.</p>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="phone">NRC</label>
                      <input
                        type="number"
                        value={userData.user_profile?.n_r_c}
                        disabled={isLoading}
                        placeholder="NRC"
                        {...register("n_r_c", { required: true })}
                        className={`form-control ${
                          errors.n_r_c ? "border-danger" : ""
                        }`}
                        name="n_r_c"
                        id="phone"
                      />
                      {errors.n_r_c && (
                        <p className="text-danger">NRC is required.</p>
                      )}
                    </div>
                    <div className="form-group col-md-12">
                      <label htmlFor="gender">Gender</label>
                      <select
                        disabled={isLoading}
                        {...register("gender", { required: true })}
                        className={`form-control ${
                          errors.gender ? "border-danger" : ""
                        }`}
                        name="gender"
                        id="gender">
                        <option defaultValue={userData.user_profile?.gender}>
                          {userData.user_profile?.gender}
                        </option>
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                      </select>
                      {errors.gender && (
                        <p className="text-danger">Gender is required.</p>
                      )}
                    </div>
                  </div>
                  <h3>Company Details</h3>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label>Company Name</label>
                      <input
                        className={`form-control text-capitalize ${
                          errors.company_name ? "border-danger" : ""
                        }`}
                        value={userData?.company_name}
                        {...register("company_name", { required: true })}
                        disabled={isLoading}
                      />
                      {errors.company_name && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label>Registration Number </label>
                      <input
                        className={`form-control`}
                        placeholder="Registration number"
                        value={userData?.user_profile?.registration_number}
                        disabled
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label>Email</label>
                      <input
                        className={`form-control`}
                        name="email"
                        value={userData?.email}
                        disabled
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label>Phone</label>
                      <input
                        placeholder="phone number"
                        className={`form-control text-capitalize ${
                          errors.phone_number ? "border-danger" : ""
                        }`}
                        value={userData?.user_profile?.phone_number}
                        {...register("phone_number", { required: true })}
                        disabled={isLoading}
                      />
                      {errors.phone_number && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label>Tax Clearance Number</label>
                      <input
                        className={`form-control text-capitalize ${
                          errors.tax_clearance_number ? "border-danger" : ""
                        }`}
                        placeholder="Tax clearance number"
                        value={userData?.user_profile?.registration_number}
                        {...register("tax_clearance_number", {
                          required: true,
                        })}
                        disabled={isLoading}
                      />
                      {errors.tax_clearance_number && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label>Facebook Page Name</label>
                      <input
                        value={userData?.user_profile?.facebook_url}
                        {...register("facebook_url", { required: false })}
                        disabled={isLoading}
                        className={`form-control ${
                          errors.facebook_url ? "border-danger" : ""
                        }`}
                        placeholder="facebook page name"
                      />
                      {errors.facebook_url && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                    <div className="form-group col-md-12">
                      <label>Company Address</label>
                      <input
                        value={userData?.user_profile?.address}
                        {...register("address", { required: true })}
                        className={`form-control ${
                          errors.address ? "border-danger" : ""
                        }`}
                        placeholder="Company Address"
                      />
                      {errors.address && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                  </div>
                  <h3>Transaction Accounts</h3>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label>Bank Name</label>
                      <input
                        value={userData?.user_profile?.bank_name}
                        {...register("bank_name", { required: true })}
                        className={`form-control ${
                          errors.bank_name ? "border-danger" : ""
                        }`}
                        placeholder="Bank Name"
                      />
                      {errors.bank_name && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label>Bank Account</label>
                      <input
                        value={userData?.user_profile?.bank_account_no}
                        {...register("bank_account_no", { required: true })}
                        className={`form-control ${
                          errors.bank_account_no ? "border-danger" : ""
                        }`}
                        placeholder="Company Bank Account Number"
                      />
                      {errors.bank_account_no && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label>Moble Money Network</label>
                      <select
                        className={`form-control ${
                          errors.mobile_money_network ? "border-danger" : ""
                        }`}
                        value={userData?.user_profile?.mobile_money_network}
                        {...register("mobile_money_network", {
                          required: true,
                        })}
                        name="mobile_money_network"
                        id="">
                          <option value="">Select Network</option>
                          <option value="airtel">Airtel</option>
                          <option value="mtn">MTN</option>
                          <option value="zamtel">Zamtel</option>
                        </select>

                      {errors.mobile_money_network && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label>Mobile Money Account</label>
                      <input
                        value={
                          userData?.user_profile?.mobile_money_account_number
                        }
                        {...register("mobile_money_account_number", {
                          required: true,
                        })}
                        className={`form-control ${
                          errors.mobile_money_account_number
                            ? "border-danger"
                            : ""
                        }`}
                        placeholder="Mobile Money Number"
                      />
                      {errors.mobile_money_account_number && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                  </div>
                  <div className="ms-auto text-center">
                    <button type="submit" className="btn btn-submit me-2">
                      {isLoading && (
                        <div className="spinner-border spinner-border-sm text-danger"></div>
                      )}{" "}
                      Update Account
                    </button>
                  </div>
                </form>
              </Form.Group>

              <ChangePassword />
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyProfile;
