import React from "react";
import moment from "moment";
import ConfirmBooking from "./confirm_booking";
import LoadingSpinner from "../../components/widgets/spinner";
import WelcomeDisplay from "./widgets/welcome_display";
import BranchDisplay from "./widgets/branch_display";
import { IRoomReservation } from "../../redux/types/room_reservation.type";
import { useGetReservationsQuery } from "../../redux/slices/room_reservation_slice";
import Pagination from "../../components/widgets/pagination";
import RoomNumberWidget from "./widgets/room_numbers_widget";
import Calendar from "../calendar/calendar";

const HotelDashboard = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const uid = queryParams.get("uid");
  const [number, setNumber] = React.useState(1); // No of pages
  const [roomPerPage] = React.useState(20);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [q, setQ] = React.useState("");
  const [searchParam] = React.useState(["first_name", "last_name", "email"]);
  const [filterParam, setFilterParam] = React.useState("All");
  const {
    data: reservations,
    isLoading,
    error,
  }: IRoomReservation | any = useGetReservationsQuery({
    hotel_owner: uid,
    status: "pending",
  });

  if (isLoading) {
    return (
      <center>
        <LoadingSpinner />
      </center>
    );
  }
  if (error) {
    return (
      <div className="text-center alert alert-danger" role="alert">
        <h4>{error.status}</h4>
      </div>
    );
  }

  let ticketAmount = reservations.map((item: IRoomReservation) => item.room_price);

  const total_amount = ticketAmount.reduce(function (acc: number, val: number) {
    return acc + val;
  }, 0);

  function findDayDifference(date1: any, date2: any) {
    // always round down
    return Math.floor(Math.abs(date2 - date1) / (1000 * 60 * 60 * 24));
  }

  // pagination
  const lastPost = number * roomPerPage;
  const firstPost = lastPost - roomPerPage;
  const currentRooms = reservations.slice(firstPost, lastPost);
  const pageNumber = [];

  for (let i = 1; i <= Math.ceil(reservations.length / roomPerPage); i++) {
    pageNumber.push(i);
  }

  const ChangePage = (pageNumber: number) => {
    setNumber(pageNumber);
  };

  const data = Object.values(currentRooms);

  function search(rooms: any[]) {
    return rooms.filter((item) => {
      if (item.first_name === filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.date_checkin.toString() === filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (filterParam === "All") {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }

  return (
    <>
      <div className="content container-fluid">
        <WelcomeDisplay totalAmount={total_amount} reservations={reservations} />
        <div className="row">
          <div className="col px-4 py-5">
            <div className="row">
              <div className="col-md-4 mt-4">
                <h3 className="text-capitalize text-black">
                  Room Reservations
                </h3>
              </div>
              <div className="col-md-4">
                <label htmlFor="search-form">Search</label>
                <div className="form-group">
                  <input
                    type="search"
                    name="search-form"
                    id="search-form"
                    className="form-control"
                    placeholder="Search for..."
                    value={q}
                    onChange={(e) => setQ(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <label htmlFor="search-form">Search By Date</label>
                <div className="form-group">
                  <select
                    className="form-control"
                    name="search-form"
                    id="search-form"
                    onChange={(e) => setFilterParam(e.target.value)}>
                    <option value="All" selected>
                      All
                    </option>
                    {reservations.map((room: IRoomReservation, index: number) => (
                      <option key={index} value={room.date_checkin.toString()}>
                        {" "}
                        {moment(room.date_checkin).format("MMMM Do, YYYY")}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="table-responsive my-4">
              {reservations.length === 0 ? (
                <div className="alert alert-danger text-center">
                  There are no Reservations
                </div>
              ) : (
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Customer</th>
                      <th>Phone Number</th>
                      <th>Room Amount</th>
                      <th>Room</th>
                      <th colSpan={2}>Date Checkin / Checkout</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {search(data).map((room, index) => (
                      <tr key={index}>
                        <td className="text-capitalize">{index + 1}</td>
                        <td className="text-capitalize">
                          {room.first_name} {room.last_name}
                        </td>
                        <td>{room.phone_number}</td>
                        <td>ZMW {room.room_price}</td>
                        <td className="text-capitalize">
                          {room.room_type} ({room.room_number})
                        </td>
                        <td>
                          {moment(room.date_checkin).format("MMM do YYYY")} -{" "}
                          {moment(room.date_checkout).format("MMM do YYYY")},{" "}
                          <small className="text-warning">
                            {findDayDifference(
                              new Date(
                                moment().format(room.date_checkin.toString())
                              ),
                              new Date(
                                moment().format(room.date_checkout.toString())
                              )
                            )}
                            Days
                          </small>
                        </td>
                        <td>
                          {room.status === "pending" ||
                          errorMessage === "pending" ? (
                            <ConfirmBooking
                              reservationID={room.id}
                              setErrorMessage={setErrorMessage}
                            />
                          ) : (
                            <div>{room.status}</div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            {/* pagination */}
            <Pagination page={number} setPage={ChangePage} data={data} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
          <BranchDisplay uid={Number(uid)} />
          </div>
          <div className="col-md-6">
            <RoomNumberWidget />
          </div>
        </div>
        <Calendar />
      </div>
    </>
  );
};

export default HotelDashboard;
