import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { toast } from "react-toastify";
import httpRoomServices from "../services/http.room.services";

function EditAddRoomNumber({ rooms, room, branch, fetchData }) {
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [validated, setValidated] = React.useState(false);
  const [activitiesInputList, setActivitiesInputList] = React.useState([
    {
      branch: branch,
      room: room,
      roomNumber: "",
      roomReserved: false,
      underClearning: false,
    },
  ]);
  // handle Activity input change
  const handleActivityInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...activitiesInputList];
    list[index][name] = value;
    setActivitiesInputList(list);
  };

  // handle click event of the Remove button
  const handleActivityRemoveClick = (index) => {
    const list = [...activitiesInputList];
    list.splice(index, 1);
    setActivitiesInputList(list);
  };
  // handle click event of the Add button
  const handleAddActivityClick = () => {
    setActivitiesInputList([
      ...activitiesInputList,
      {
        branch: branch,
        room: room,
        roomNumber: "",
        roomReserved: false,
        underClearning: false,
      },
    ]);
  };

  const handleSubmit = async (e) => {
    const bodyFormData = new FormData();

    activitiesInputList.forEach((item) => {
      bodyFormData.append("numbers_list[]", item);
    });
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      setFormSubmitting(true);
      httpRoomServices
        .upateAddRoomNumber(JSON.stringify([...activitiesInputList]))

        .then((response) => {
          // console.log(response.data)
          setActivitiesInputList([]);
          fetchData();
          toast.success("Room Updated successfully");
          setFormSubmitting(false);
        })
        .catch((error) => {
          setFormSubmitting(false);
          toast.error(error.message);
        });
    }
    setValidated(true);
  };
  return (
    <>
      <h2>Room Numbers</h2>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        {rooms?.length === 0 ? (
          <div className="alert alert-info text-center" role="alert">
            <i className="fa fa-image"></i>
            <h2>No Room Numbers Found</h2>
          </div>
        ) : (
          <>
            <Row>
              {rooms?.map((item, index) => {
                return (
                  <>
                    <Form.Group
                      key={index}
                      as={Col}
                      md="4"
                      className="mb-3"
                      controlId="validationCustom03">
                      <Form.Label>Room Number</Form.Label>
                      <Form.Control
                        type="input"
                        disabled={formSubmitting}
                        min={1}
                        defaultValue={item.roomNumber}
                        name="roomNumber"
                        className="form-control text-capitalize"
                        // onChange={(e) => onChange(e)}
                        placeholder="Room Number"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Room Number is required
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                      <Form.Label>Room Reserved?</Form.Label>
                      <Form.Check
                        defaultChecked={item.roomReserved}
                        type="switch"
                        id="custom-switch"
                        label={item.roomReserved ? "Yes" : "No"}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                      <Form.Label>Room Under Cleaning?</Form.Label>
                      <Form.Check
                        defaultChecked={item.underClearning}
                        type="switch"
                        id="custom-switch"
                        label={item.underClearning ? "Yes" : "No"}
                      />
                    </Form.Group>
                  </>
                );
              })}
            </Row>
          </>
        )}
        <Form.Group
          as={Col}
          md="6"
          className="mb-3"
          controlId="validationCustom05">
          <button
            type="button"
            onClick={handleAddActivityClick}
            className="btn btn-sm btn-success">
            <i className="bi bi-plus-circle"></i> Add Room Number
          </button>
        </Form.Group>
        <Row>
          {activitiesInputList.map((x, i) => {
            return (
              <>
                <Form.Group as={Col} md="4" className="mb-3">
                  <Form.Label>Room Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="roomNumber"
                    value={x.roomNumber}
                    onChange={(e) => handleActivityInputChange(e, i)}
                    placeholder="Room Number"
                    disabled={formSubmitting}
                    className="form-control"
                  />
                  <Form.Control.Feedback type="invalid">
                    This Field is required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="4"
                  className="mb-3"
                  controlId="validationCustom05">
                  <Form.Label>Room Reserved?</Form.Label>
                  <Form.Select
                    onChange={(e) => handleActivityInputChange(e, i)}
                    className="form-control"
                    name="roomReserved"
                    id="ConsultantSelect">
                    <option value="">Room Reserved?</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    This Field is required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" className="mb-3">
                  <Form.Label>Room Under Clearning?</Form.Label>
                  <Form.Select
                    onChange={(e) => handleActivityInputChange(e, i)}
                    className="form-control"
                    name="underClearning"
                    id="ConsultantSelect">
                    <option value="">Room Under Clearning?</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    This Field is required
                  </Form.Control.Feedback>
                </Form.Group>
                <p className="pull-right mb-4">
                  <button
                    type="button"
                    className="btn btn-sm btn-warning"
                    onClick={() => handleActivityRemoveClick(i)}>
                    <i className="bi bi-trash text-danger"></i> Remove Room
                    Number
                  </button>
                </p>
              </>
            );
          })}
        </Row>
        <div className="form-group text-center">
          <button type="submit" className="btn btn-success">
            {formSubmitting ? (
              <span>
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ marginRight: "5px" }}
                />{" "}
                Updating...
              </span>
            ) : (
              "Update Room Numbers"
            )}
          </button>
        </div>
      </Form>
    </>
  );
}

export default EditAddRoomNumber;
