import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import LoadingSpinner from "../../widgets/spinner";
import bg1 from "../../../images/city.jpg";
import CommuterDetails from "./commuter_details";
import RoutePlaces from "./route_places";
import RouteDetails from "./route_details";

function Reservations() {
  const [fromRoutePlaces, setFromRroutePlaces] = useState([]);
  const [toRoutePlaces, setToRroutePlaces] = useState([]);

  const [results, setResults] = useState([]);
  const [commutors, setCommutors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPlaces, setIsLoadingPlaces] = useState(true);
  const dateCreate = moment().format("YYYY-MM-DD");
  const [commuterDetails, setCommuterDetails] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setCommuterDetails(null);
  };
  const handleShow = (commuter) => {
    setCommuterDetails(commuter);
    setShow(true);
  };

  const queryParams = new URLSearchParams(window.location.search);
  const from_route = queryParams.get("from_route");
  const to_route = queryParams.get("to_route");
  const traveldate = queryParams.get("travel_date");

  useEffect(() => {
    const fetchData = async () => {
      // fetch search resultstravel_date
      try {
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/my-tickets/${from_route}/${to_route}/${traveldate}/rmf_travellers`
          )
          .then((res) => {
            setResults(res.data);
            setIsLoading(false);
          });
      } catch (error) {
        setIsLoading(false);
      }

      // get commuters
      try {
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/userprofile/v1/intercity_travellers/get-commuters/rmf_travellers`
          )
          .then((com) => {
            setIsLoading(false);
            setCommutors(com.data);
          });
      } catch (error) {}

      // GET FROM ROUTE PLACE
      try {
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/route-places/${from_route}/rmf_travellers`
          )
          .then((response) => {
            setIsLoadingPlaces(false);
            setFromRroutePlaces(response.data);
          });
      } catch (error) {}
      // GET FROM ROUTE PLACE
      try {
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/route-places/${to_route}/rmf_travellers`
          )
          .then((response) => {
            setIsLoadingPlaces(false);
            setToRroutePlaces(response.data);
          });
      } catch (error) {}
    };

    fetchData();
  }, []);

  function TimeDifference(arrivalTime, departTime) {
    var diff = moment.duration(moment(arrivalTime).diff(moment(departTime)));
    var days = parseInt(diff.asDays()); //84

    var hours = parseInt(diff.asHours()); //2039 hours, but it gives total hours in given miliseconds which is not expacted.

    hours = hours - days * 24; // 23 hours

    var minutes = parseInt(diff.asMinutes()); //122360 minutes,but it gives total minutes in given miliseconds which is not expacted.

    minutes = minutes - (days * 24 * 60 + hours * 60);
    return `${hours}hrs : ${minutes}m`;
  }

  return (
    <>
      <section
        className=""
        style={{
          backgroundImage: `url(${bg1})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}>
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-center">
            <div className="col-md-9 ftco-animate pb-5 text-center">
              <p className="breadcrumbs">
                <span className="mr-2">
                  <a href="/">
                    Home <i className="fa fa-chevron-right"></i>
                  </a>
                </span>{" "}
                <span className="mr-2">
                  <a>Bus Ticket Reservation </a>
                </span>{" "}
              </p>
              <h1 className="mb-0 bread">Bus Ticket Reservation</h1>
            </div>
          </div>
        </div>
      </section>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="cl-pt-30 cl-pb-60">
          <div className="container-fluid">
            <div
              id="reservation-ticket-tab"
              className="bs-container-wrap row step-app">
              <div id="pjBrContainer" className="bs-container step-content">
                <div className="step-tab-ticket">
                  <div className="bus-tickets-area">
                    <div className="bus-ticket-top cms-e-section">
                      <h4 className="pull-left text-info mt-5">
                        Trip From:{" "}
                        {
                          <span className="text-capitalize text-dark ml-1">
                            {<span className="text-danger">{from_route} </span>}
                            to {<span className="text-danger">{to_route}</span>}
                          </span>
                        }
                      </h4>
                      <p className="pull-right mt-5">
                        <span>Date of Departure: </span>
                        <span>
                          {" "}
                          <b>{moment(traveldate).format("MMMM Do YYYY")}</b>
                        </span>
                      </p>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">
                              <b>Bus</b>
                            </th>

                            <th scope="col">
                              <b>Source</b>
                            </th>
                            <th scope="col">
                              <b>Destination</b>
                            </th>
                            <th scope="col">
                              <b>Departure Date / Time</b>
                            </th>
                            <th scope="col">
                              <b>Arrival Date / Time</b>
                            </th>
                            <th scope="col">
                              <b>Duration</b>
                            </th>
                            <th scope="col">
                              <b>Bus Fare</b>
                            </th>
                            <th scope="col">
                              <b>Action</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {results.map((res, index) => (
                            <tr key={index}>
                              <td>
                                <div className="bus-distance" data-title="Bus">
                                  <b className="text-capitalize">
                                    {res.from_route}
                                  </b>{" "}
                                  -{" "}
                                  <b className="text-capitalize">
                                    {res.to_route}
                                  </b>{" "}
                                  <br />
                                  <span className="text-capitalize text-danger">
                                    <b>{res.commutor.company_name}</b>
                                  </span>
                                  {/* <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleShow(res)}
                                    >
                                      <i className="fa fa-question-circle text-info"></i>
                                    </a> */}
                                </div>
                                {/* MODAL HERE */}
                                <CommuterDetails
                                  show={show}
                                  handleClose={handleClose}
                                  commutors={commutors}
                                  res={res}
                                />
                              </td>
                              <td>
                                <h5 className="text-capitalize">
                                  {res.from_route}
                                </h5>
                              </td>
                              <td>
                                <h5 className="text-capitalize">
                                  {res.to_route}
                                </h5>
                              </td>
                              <td>
                                <div
                                  className="bus-departure-date-time"
                                  data-title="Departure time">
                                  <span className="bus-date">
                                    {moment(traveldate).format("MMMM Do YYYY")}
                                  </span>
                                  <br />

                                  <span className="bus-time text-capitalize">
                                    {`${res.departure_time}hrs`} -{" "}
                                    <b className="text-danger">
                                      {res.going_bus.bus_role}
                                    </b>
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div
                                  className="bus-departure-date-time"
                                  data-title="Departure time">
                                  <span className="bus-date">
                                    {moment(traveldate).format("MMMM Do YYYY")}
                                  </span>
                                  <br />

                                  <span className="bus-time text-capitalize">
                                    {`${res.arrival_time}hrs`}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <b>
                                  {TimeDifference(
                                    `${traveldate} ${res.arrival_time}`,
                                    `${traveldate} ${res.departure_time}`
                                  )}
                                </b>
                              </td>
                              <td>
                                <h5>
                                  ZMW {Number(res.route_price).toLocaleString()}{" "}
                                </h5>
                              </td>
                              <td>
                                {/* <a
                                    className="btn btn-primary"
                                    href={`/zm/ticket/${urlToken}/${res.id}/passengers`}
                                  >
                                    Details
                                  </a> */}
                                <div className="col-md-4"><RouteDetails /></div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="cl-hr-line-dashed"></div>
                  </div>
                  <div className="row">
                    <h1 className="mt-5">Travel & Tour</h1>
                    <div className="col-md-6 mt-3">
                      <h2 className="text-capitalize">
                        <b>{from_route}</b>
                      </h2>
                      {/* places */}
                      {isLoadingPlaces ? (
                        <LoadingSpinner />
                      ) : (
                        <RoutePlaces places={fromRoutePlaces} />
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <h2 className="text-capitalize">
                        <b>{to_route}</b>
                      </h2>
                      {/* places */}
                      {isLoadingPlaces ? (
                        <LoadingSpinner />
                      ) : (
                        <RoutePlaces places={toRoutePlaces} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Reservations;
