export const PlaceCategories = [
  "Museum",
  "Hospital",
  "Bank",
  "Resort",
  "Shopping Complex",
  "Shopping Mall",
  "Play Park",
  "Fire Department",
  "Stadium",
  "Civic Center",
  "Game Reserve",
  "Game Park",
  "Company",
  "Hotel",
  "Lodge",
  "University",
  "College",
  "Primary School",
  "Secondary School",
  "Bus Station",
  "Market",
  "Airport",
  "Clinic",
  "Casino",
  "River",
  "Lake",
  "Road",
  "Water Falls",
  "Mountain",
  "Hill",
  "Swamp",
  "Camp Site",
  "Nature Reserve",
  "Game Ranch",
];
