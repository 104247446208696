import React, { useEffect, useRef } from "react";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import StickyBox from "react-sticky-box";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import { styles } from "../../helper";
import { motion } from "framer-motion";
import { capitalized } from "../../actions/utils";
import user from "../../images/user.png";
import PostReview from "./post_review";
import { Reveal } from "../../reveal";
import ImageGallery from "../widgets/gallery/gallery";
import { connect } from "react-redux";
import { Rating } from "react-simple-star-rating";

const HolidayDetails = ({ isAuthenticated }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [travel, setTravel] = React.useState([]);
  const [reviews, setReviews] = React.useState([]);
  const { slug, id } = useParams();
  const galleryUrl = `holidays/v1/intercity_travellers/retrieve_package_image_gallery`;
  const scrollRef = useRef(null);
  const scrowDown = () => scrollRef.current.scrollIntoView();

  const placeRef = useRef(null);
  const locationRef = useRef(null);
  const reviewRef = useRef(null);
  const overViewRef = useRef(null);

  const placeScroll = () => placeRef.current.scrollIntoView();
  const locationScroll = () => locationRef.current.scrollIntoView();
  const reviewScroll = () => reviewRef.current.scrollIntoView();
  const overviewScroll = () => overViewRef.current.scrollIntoView();

  const fetchData = async () => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/holidays/v1/intercity_travellers/get_travel/${slug}/rmf_travellers`
        )
        .then((res) => {
          setTravel(res.data);
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
    }

    // get reviews by travel
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/holidays/v1/intercity_travellers/reviews/${id}/rmf_travellers`
        )
        .then((res) => {
          setReviews(res.data);
          // console.log(res.data);
        });
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, []);

  const renderRatings = () => {
    const totalRatings = reviews.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.rating;
    }, 0);
    const averageRating = totalRatings / reviews.length;
    return averageRating.toFixed(1);
  };

  return (
    <>
      {isLoading ? (
        <center>
          <div style={{marginTop: '20%', marginBottom: '20%'}} className="p-5 text-center">
            <Spinner animation="grow" variant="danger" />
          </div>
        </center>
      ) : (
        <>
          <section
            className="hero-wrap hero-wrap-2 js-fullheight"
            style={{ backgroundImage: `url(${travel.thumbnail})` }}>
            <div className="overlay"></div>
            <div class="container">
              <div class="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
                <div class="col-md-9 ftco-animate pb-5 text-center">
                  <p class="breadcrumbs">
                    <span class="mr-2">
                      <a href="/">
                        Home <i class="fa fa-chevron-right"></i>
                      </a>
                    </span>{" "}
                    <span>
                      Holiday Destination Details{" "}
                      <i class="fa fa-chevron-right"></i>
                    </span>
                  </p>
                  <h1 class="mb-0 bread text-capitalize">
                    Holiday Destination Details
                  </h1>
                  <h1 className="text-warning text-capitalize">
                    {travel.countryName}
                  </h1>
                </div>
              </div>
            </div>
            <a className="mouse smoothscroll" onClick={scrowDown}>
              <div className="mouse-icon">
                <span className="mouse-wheel"></span>
              </div>
            </a>
          </section>
          <Reveal>
            <section ref={scrollRef}>
              <div className="container mt-5">
                <div className="row">
                  <div
                    className="col-md-7 col-lg-7 position-relative mb-5"
                    data-aos="fade-up">
                    {/* carusel down */}
                    <div ref={overViewRef}>
                      <Carousel fade indicators={true} controls={true}>
                        <Carousel.Item className="hotel-carousel">
                          <img
                            className="d-block w-100 img-fluid rounded"
                            src={travel.thumbnail}
                            alt={travel.title}
                          />
                        </Carousel.Item>
                        <Carousel.Item className="hotel-carousel">
                          <img
                            className="d-block w-100 img-fluid rounded"
                            src={travel.placeImage1}
                            alt={travel.title}
                          />
                        </Carousel.Item>
                        <Carousel.Item className="hotel-carousel">
                          <img
                            className="d-block w-100 img-fluid rounded"
                            src={travel.placeImage2}
                            alt={travel.title}
                          />
                        </Carousel.Item>
                      </Carousel>
                      <h3 className="text-capitalize">{travel.title}</h3>
                      <blockquote>{travel.description}</blockquote>
                    </div>
                    <div
                      ref={placeRef}
                      className="location js-section content-block"
                      id="places">
                      <h3 className="title text-capitalize">
                        Some Places you will see
                      </h3>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <img
                            className="rounded"
                            style={{
                              width: "100%",
                              height: "35vh",
                            }}
                            src={`${travel.placeImage1}`}
                            alt=""
                          />

                          <h4 className="text-capitalize">
                            <b>{travel.placeName1}</b>
                          </h4>
                        </div>
                        <div className="col-md-6 mb-3">
                          <img
                            className="rounded"
                            style={{
                              width: "100%",
                              height: "35vh",
                            }}
                            src={`${travel.placeImage2}`}
                            alt=""
                          />

                          <h4 className="text-capitalize">
                            <b>{travel.placeName2}</b>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div
                      ref={locationRef}
                      className="location js-section content-block"
                      id="location">
                      <h3 className="title">Location</h3>
                      <iframe
                        src={`${travel.location}`}
                        height="450"
                        style={{ border: "0", width: "100%" }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>

                      <ImageGallery imgUrl={galleryUrl} branch={id} />
                    </div>
                    <div ref={reviewRef} className="mt-5">
                      <h3
                        className="mb-5"
                        style={{ fontSize: "20px", fontWeight: "bold" }}>
                        {reviews.length === 0 ? (
                          <div></div>
                        ) : (
                          <span>
                            {reviews.length} Reviews
                            <h1>
                              <Rating
                                size={50}
                                initialValue={travel.rating_avg}
                                transition
                                allowFraction
                                showTooltip
                                tooltipDefaultText={`${travel.rating_avg}/5`}
                                allowTitleTag={false}
                                readonly
                                tooltipStyle={{
                                  paddingLeft: "2px",
                                  paddingRight: "2px",
                                  paddingBottom: "0px",
                                  paddingTop: "0px",
                                }}
                                /* Available Props */
                              />
                            </h1>
                          </span>
                        )}
                      </h3>

                      <ul className="comment-list">
                        {reviews.length < 1 ? (
                          <div className="alert alert-info text-center">
                            <i className="fa fa-comments fa-5x"></i>
                            <p>No Reviews</p>
                          </div>
                        ) : (
                          reviews.map((item, index) => (
                            <li key={index} className="comment">
                              <div className="vcard bio">
                                <img src={user} alt="Image placeholder" />
                              </div>
                              <div className="comment-body">
                                <h3 className="text-capitalize">
                                  {item.user?.first_name} {item.user?.last_name}{" "}
                                  <Rating
                                    size={18}
                                    initialValue={item.rating}
                                    transition
                                    allowFraction
                                    showTooltip
                                    tooltipDefaultText={`${item.rating}/5`}
                                    allowTitleTag={false}
                                    readonly
                                    tooltipStyle={{
                                      paddingLeft: "2px",
                                      paddingRight: "2px",
                                      paddingBottom: "0px",
                                      paddingTop: "0px",
                                    }}
                                    /* Available Props */
                                  />
                                </h3>
                                <div className="meta">
                                  {moment(item.created_at).format(
                                    "MMMM Do YYYY"
                                  )}
                                </div>
                                <p>
                                  <blockquote>
                                    {capitalized(item.review)}
                                  </blockquote>
                                </p>
                                {/* <p>
                        <a href="#" className="reply">
                          Reply
                        </a>
                      </p> */}
                              </div>
                            </li>
                          ))
                        )}
                      </ul>
                      {/* <!-- END comment-list --> */}
                      {isAuthenticated ? (
                        <div className="comment-form-wrap pt-5">
                          <h2 style={{ fontSize: "20px", fontWeight: "bold" }}>
                            Leave a Review
                          </h2>
                          {/* review form here */}
                          <PostReview
                            travelId={travel.id}
                            fetchData={fetchData}
                          />
                        </div>
                      ) : (
                        <div className="text-center">
                          <a
                            className="btn btn-info text-center"
                            href="/zm/login">
                            Login to Post Review
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-5 mb-3">
                    <StickyBox offsetTop={120} offsetBottom={0}>
                      <div className="mb-4 text-dark shadow border-0 rounded-3 py-3">
                        <div className="p-4" id="sidebar-navigation">
                          <div className="row">
                            <div className="col-md-6">
                              <li onClick={overviewScroll}>
                                <a style={{ cursor: "pointer" }}>Overview</a>
                              </li>
                            </div>
                            <div className="col-md-6">
                              <li onClick={placeScroll}>
                                <a style={{ cursor: "pointer" }}>Places</a>
                              </li>
                            </div>
                            <div className="col-md-6 mt-3">
                              <li onClick={locationScroll}>
                                <a style={{ cursor: "pointer" }}>Location</a>
                              </li>
                            </div>
                            <div className="col-md-6 mt-3">
                              <li onClick={reviewScroll}>
                                <a style={{ cursor: "pointer" }}>Reviews</a>
                              </li>
                            </div>
                          </div>
                        </div>
                        <h4 className="text-center mt-4 mb-4">
                          <p>
                            {travel.tourDays} Days {travel.tourDays - 1} Nights
                            Tour
                          </p>
                          <span>
                            <b className="text-danger">
                              {new Intl.NumberFormat("en-us", {
                                style: "currency",
                                currency: `${travel?.currency}`,
                              }).format(travel.tourPrice)}
                            </b>{" "}
                            Per Person
                          </span>
                        </h4>
                        <h4 className="text-capitalize ml-4">
                          Start in{" "}
                          <span className="text-danger">
                            {travel.placeName1}
                          </span>
                        </h4>
                        <ul>
                          <li className="text-capitalize fs-5">
                            <i className="fa fa-check-circle text-success"></i>{" "}
                            Max Group Size: <b>{travel.groupSize}</b>
                          </li>
                          <li className="text-capitalize fs-5">
                            <i className="fa fa-check-circle text-success"></i>{" "}
                            Age Range:{" "}
                            <b>
                              {travel.ageRange?.fromAge} -{" "}
                              {travel.ageRange?.toAge}
                            </b>
                          </li>
                          <li className="text-capitalize fs-5">
                            <i className="fa fa-check-circle text-success"></i>{" "}
                            Operated in:{" "}
                            <b>
                              {travel.languages?.map((lang, index) => (
                                <span key={index}>{lang}, </span>
                              ))}
                            </b>
                          </li>
                        </ul>
                        {travel.activities?.length === 0 ? (
                          <div></div>
                        ) : (
                          <>
                            <h3 className="text-center">Activities</h3>
                            <ul className="text-capitalize">
                              {travel.activities?.map((item, index) => {
                                return (
                                  <li key={index} className="text-capitalize">
                                    <span className="text-capitalize fs-6 mr-5">
                                      <i className="fa fa-check-circle text-success"></i>{" "}
                                      {item.activityName}
                                    </span>{" "}
                                    <b>
                                      {item?.currency} {item.amount}
                                    </b>
                                  </li>
                                );
                              })}
                            </ul>
                          </>
                        )}

                        <div
                          className="location js-section content-block p-4"
                          id="places">
                          <div className="row">
                            <div className="col-md-6">
                              <h3>
                                <i className="fa fa-edit"></i> <b>Personal</b>
                              </h3>
                              <p>Make your adventure more you</p>
                            </div>
                            <div className="col-md-6">
                              <h3>
                                <i className="fa fa-lock"></i> <b>Private</b>
                              </h3>
                              <p>
                                Enjoy a tour focused solely on your travel group
                              </p>
                            </div>
                            <div className="col-md-6">
                              <h3>
                                <i className="fa fa-lightbulb-o"></i>{" "}
                                <b>Professional</b>
                              </h3>
                              <p>Access our Travel Experts insider knowledge</p>
                            </div>
                            <div className="col-md-6">
                              <h3>
                                <i className="fa fa-shield"></i>{" "}
                                <b>Protected</b>
                              </h3>
                              <p>Travel within your own bubble</p>
                            </div>
                          </div>
                          <div className="text-center">
                            <motion.a
                              style={styles}
                              className="btn btn-primary"
                              href="mailto:info@intercitytravellers.com"
                              whileHover={{ scale: 1.1 }}
                              whileTap={{ scale: 0.9, x: "-5px", y: "5px" }}>
                              Make Enguiry
                            </motion.a>
                          </div>
                        </div>
                      </div>
                    </StickyBox>
                  </div>
                </div>
              </div>
            </section>
          </Reveal>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, null)(HolidayDetails);
