import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { errorHandler } from "../../../helper";
import $ from "jquery";

function AddStation({ uid, provinces }) {
  const [show, setShow] = useState(false);
  const [submitting, setSbmitting] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    province: "",
    district: "",
    stationManager: "",
    phoneNumber: "",
    stationName: "",
    email: "",
  });
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setSbmitting(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      const body = JSON.stringify({
        commuter: uid,
        province: formData.province,
        district: formData.district,
        stationManager: formData.stationManager,
        stationName: formData.stationName,
        phoneNumber: formData.phoneNumber,
        email: formData.email,
      });
      try {
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/create_station/rmf_travellers`,
            body,
            config
          )
          .then((res) => {
            console.log(res.data);

            toast.success("Station Created Successfully", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setTimeout(() => {
              window.location.reload();
              setSbmitting(false);
            }, 2000);
          })
          .catch((er) => {
            console.log(er.message);
            toast.error(`An Error Occurred! ${er.message}`, {
              position: toast.POSITION.TOP_CENTER,
            });
            setSbmitting(false);
          });
      } catch (err) {
        console.log(err.message);
        setSbmitting(false);
      }
    }

    setValidated(true);
  };
  $(document).ready(function () {
    $("#province").on("change", function () {
      var provinceID = $(this).val();
      if (provinceID) {
        $.ajax({
          url: `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/province-districts/${provinceID}/rmf_travellers`,
          type: "GET",
          dataType: "json",
          success: function (data) {
            if (data) {
              $("#district").empty();
              $("#district").focus();
              $("#district").append(
                '<option disabled selected value=""> -- Select District -- </option>'
              );
              $.each(data, function (key, value) {
                $('select[name="district"]').append(
                  '<option value="' +
                    value.id +
                    '">' +
                    value.district_name +
                    "</option>"
                );
              });
            } else {
              $("#district").empty();
            }
          },
        });
      } else {
        $("#district").empty();
      }
    });
  });
  return (
    <>
      <ToastContainer />
      <div className="pull-right mb-5">
        <Button variant="primary" onClick={handleShow}>
          Add Station
        </Button>
      </div>

      <Modal
        backdrop="static"
        keyboard={false}
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Station</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom03">
                <Form.Label>Station Province</Form.Label>
                <Form.Select
                  id="province"
                  name="province"
                  className="text-capitalize"
                  onChange={(e) => onChange(e)}
                  size="lg"
                  disabled={submitting}
                  required
                >
                  <option value="" selected>
                    Choose Province
                  </option>
                  {provinces.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.province_name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please provide station name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom03">
                <Form.Label>Station District</Form.Label>
                <Form.Select
                  id="district"
                  name="district"
                  onChange={(e) => onChange(e)}
                  size="lg"
                  disabled={submitting}
                  required
                ></Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please provide station name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom03">
                <Form.Label>Station Name</Form.Label>
                <Form.Control
                  type="text"
                  name="stationName"
                  onChange={(e) => onChange(e)}
                  disabled={submitting}
                  placeholder="station name"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide station name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom03">
                <Form.Label>Station Manager Name</Form.Label>
                <Form.Control
                  type="text"
                  name="stationManager"
                  onChange={(e) => onChange(e)}
                  disabled={submitting}
                  placeholder="station manager name"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide station manager name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom04">
                <Form.Label>Station Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  name="phoneNumber"
                  onChange={(e) => onChange(e)}
                  disabled={submitting}
                  placeholder="Station Number Phone"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide station phone number
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom04">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  onChange={(e) => onChange(e)}
                  disabled={submitting}
                  placeholder="E-mail"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="pull-right">
              <Form.Group as={Col} md="6">
                <Button disabled={submitting} type="submit">
                  {submitting ? (
                    <center>
                      <i className="fa fa-refresh fa-spin"></i> Submitting....
                    </center>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Form.Group>
              <Form.Group as={Col} md="3">
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddStation;
