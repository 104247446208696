import React, { useEffect, useRef } from "react";
import DestinationsCard from "../widgets/destinations_card";
import { Reveal } from "../../reveal";
import httpApiService from "../services/http.api.service";

const DestinationDetails = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const country_id = queryParams.get("country_id");
  const slug = queryParams.get("slug");
  const [holidays, setHolidays] = React.useState([]);
  const [country, setCountry] = React.useState(null);
  const [countries, setCountries] = React.useState([]);
  const [number, setNumber] = React.useState(1); // No of pages
  const [holidayPerPage] = React.useState(8);
  const [q, setQ] = React.useState("");
  const [searchParam] = React.useState(["country", "title", "countryName"]);
  const [filterParam, setFilterParam] = React.useState(["all"]);
  const [isLoading, setisLoading] = React.useState(true);
  const scrollRef = useRef(null);
  const scrowDown = () => scrollRef.current.scrollIntoView();

  const fetchData = async () => {
    try {
      httpApiService.getCountry(country_id).then((response) => {
        setCountry(response.data);
      });
    } catch (error) {}
    try {
      httpApiService.getCountries().then((res) => {
        setCountries(res.data);
        setisLoading(false);
      });
    } catch (error) {}
    try {
      httpApiService.getCountryDestinations(country_id).then((response) => {
        setHolidays(response.data);
        console.log(response.data);
        setisLoading(false);
      });
    } catch (error) {
      setisLoading(false);
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  // pagination
  const lastHoliday = number * holidayPerPage;
  const firstHoliday = lastHoliday - holidayPerPage;
  const currentHoliday = holidays.slice(firstHoliday, lastHoliday);
  const pageNumber = [];

  for (let i = 1; i <= Math.ceil(holidays.length / holidayPerPage); i++) {
    pageNumber.push(i);
  }

  const ChangePage = (pageNumber) => {
    setNumber(pageNumber);
  };
  const data = Object.values(currentHoliday);

  function search(list) {
    return list.filter((item) => {
      if (item.title == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.country == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (filterParam == "all") {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }
  return (
    <>
      <section
        className="hero-wrap hero-wrap-2 js-fullheight"
        style={{ backgroundImage: `url(${country?.image})` }}>
        <div className="overlay"></div>
        <div class="container">
          <div class="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
            <div class="col-md-9 ftco-animate pb-5 text-center">
              <p class="breadcrumbs">
                <span class="mr-2">
                  <a href="/">
                    Home <i class="fa fa-chevron-right"></i>
                  </a>
                </span>{" "}
                <span>
                  Holiday Destinations <i class="fa fa-chevron-right"></i>
                </span>
              </p>
              <h1 class="mb-0 bread">
                {" "}
                <span className="text-warning text-capitalize">
                  {country?.countryName}
                </span>{" "}
                Holiday Destinations{" "}
              </h1>
            </div>
          </div>
        </div>
        <a className="mouse smoothscroll" onClick={scrowDown}>
          <div className="mouse-icon">
            <span className="mouse-wheel"></span>
          </div>
        </a>
      </section>
      <Reveal>
        <section ref={scrollRef} class="ftco-section ftco-no-pb">
          <div class="container">
            <div class="search-wrap-1 ftco-animate">
              <form class="search-property-1">
                <div class="row no-gutters">
                  <div class="col-l-12 col-md-8 d-flex">
                    <div class="form-group p-4 border-0">
                      <label for="#">Destination</label>
                      <div class="form-field">
                        <div class="icon">
                          <span class="fa fa-search"></span>
                        </div>
                        <input
                          type="search"
                          class="form-control bordered"
                          placeholder="Search for holiday destinations"
                          value={q}
                          onChange={(e) => setQ(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </Reveal>
      {/* place holiday card here */}

      <DestinationsCard
        isHome={true}
        countries={countries}
        data={search(data)}
        isLoading={isLoading}
      />
      <section className="ftco-section">
        <div className="container">
          {data.length < 8 ? (
            <div></div>
          ) : (
            <div className="row">
              <div className="col text-center">
                <div className="block-27">
                  <ul>
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        disabled={number === 1}
                        onClick={() => setNumber(number - 1)}>
                        &lt;
                      </a>
                    </li>
                    {pageNumber.map((page, index) => (
                      <li
                        key={index}
                        className={`${number === page ? "active" : ""}`}>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => ChangePage(page)}>
                          <span>{page}</span>
                        </a>
                      </li>
                    ))}

                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => setNumber(number + 1)}>
                        &gt;
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};
export default DestinationDetails;
