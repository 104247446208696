import axios from "axios";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { UPDATE_URL } from "../../../urls";
import LoadingSpinner from "../../../components/widgets/spinner";
function PrintTickets() {
  const [number, setNumber] = useState(1); // No of pages
  const [ticketsPerPage] = useState(20);
  const [buses, setBuses] = useState([]);
  const [commuters, setCommuters] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [departureDate, setDepartureDate] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [printDeparture, setPrintDeparture] = useState("");
  const [userdetail, setUserDetail] = useState(null);
  const [q, setQ] = useState("");
  const [searchParam] = useState([
    "first_name",
    "last_name",
    "email",
    "ticket_no",
  ]);
  const [filterParam, setFilterParam] = useState(["All"]);

  let bus;

  const { uid } = useParams();

  const fetchData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    // get user profile
    try {
      await axios.get(`/v1/intercity_travellers/${UPDATE_URL}/rmf_travellers`, config).then((res) => {
        setUserDetail(res.data);
      });
    } catch (error) {}
    let status = "confirmed";
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/get_tickets/${uid}/${status}/rmf_travellers`,config
        )
        .then((res) => {
          setTickets(res.data);
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
      setMessage(error.message);
    }

    //  get commuter buses
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/buses/${uid}/rmf_travellers`,config)
        .then((res) => {
          setBuses(res.data);
          setIsLoading(false);
        });
    } catch (error) {}

    // get departure date
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/bus_time/${uid}/rmf_travellers`,config)
        .then((res) => {
          setDepartureDate(res.data);
          setIsLoading(false);
        });
    } catch (error) {}

    // get commuters
    // get users
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/userprofile/v1/intercity_travellers/get-commuters/rmf_travellers`,config)
        .then((users) => {
          setCommuters(users.data);
          setIsLoading(false);
        });
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, [printDeparture]);
  const printTickets = () => {
    window.print();
  };

  // pagination
  const lastTicket = number * ticketsPerPage;
  const firstTicket = lastTicket - ticketsPerPage;
  const currentTickets = tickets.slice(firstTicket, lastTicket);
  const pageNumber = [];

  for (let i = 1; i <= Math.ceil(tickets.length / ticketsPerPage); i++) {
    pageNumber.push(i);
  }

  const ChangePage = (pageNumber) => {
    setNumber(pageNumber);
  };
  const data = Object.values(currentTickets);

  function search(tickets) {
    return tickets.filter((item) => {
      if (item.departure_date == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.bus == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (filterParam == "All") {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }

  return (
    <>
      <div className="container">
        <div className="commuter_details mt-5 mb-5">
          <h3 className="text-center text-capitalize">
            {isLoading ? null : userdetail.company_name}
          </h3>
          <h5 className="text-uppercase text-center">
            {bus} - {filterParam}
          </h5>
        </div>
        <div className="row hide-on-print">
          <div className="col-md-6 form-group">
            <h6>Filter By Bus:</h6>
            <select
              onChange={(e) => {
                setFilterParam(e.target.value);
              }}
              name="bus"
              required
              className="form-control"
              aria-label="Filter Tickets By Bus"
            >
              <option value="All">Filter By Bus</option>
              {buses.map((bus, index) => (
                <option key={index} value={bus.id}>
                  {bus.driver} - {bus.number_place}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-6 form-group">
            <h6>Filter By Date:</h6>
            <select
              onChange={(e) => {
                setFilterParam(e.target.value);
              }}
              name="departure_date"
              required
              className="form-control"
              aria-label="Filter Tickets By Departure Date/Time"
            >
              <option value="All">Filter by Departure Date/Time</option>
              {departureDate.map((item, index) => (
                <option key={index} value={item.departure_date}>
                  {moment(item.departure_date).format("MMMM Do YYYY, H:m")}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <h3 className="text-capitalize text-black mt-4 mb-4 hide-on-print">
          Customer Tickets
        </h3>
        {isLoading ? (
          <LoadingSpinner />
        ) : tickets.length === 0 ? (
          <div className="alert alert-danger text-center">
            There are no Tickets
          </div>
        ) : (
          <table
            id="tickets example"
            className="table table-striped data-table table-lg"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Ticket #</th>
                <th>Name</th>
                <th>Phone</th>
                <th>N.O.K</th>
                <th>From</th>
                <th>To</th>
                <th>Bus</th>
                <th>Date of Departure</th>
                <th>Paid</th>
                <th className="hide-on-print">Status</th>
                {/* <th colSpan="2">Action</th> */}
              </tr>
            </thead>
            <tbody>
              {search(data).map((ticket, index) => (
                <tr className="text-dark bg-white" key={index}>
                  <td className="col-auto">
                    <small className="font-bold mb-0">{index + 1}</small>
                  </td>
                  <td className="col-auto">
                    <small className="font-bold mb-0">{ticket.ticket_no}</small>
                  </td>
                  <td className="col-auto">
                    <small className="font-bold mb-0 text-capitalize">
                      {ticket.first_name === null
                        ? commuters.map((prof) =>
                            prof.id === ticket.customer ? prof.first_name : null
                          )
                        : ticket.first_name}{" "}
                      {ticket.last_name === null
                        ? commuters.map((prof) =>
                            prof.id === ticket.customer ? prof.last_name : null
                          )
                        : ticket.last_name}
                    </small>
                  </td>
                  <td className="col-auto">
                    <small className="mb-0">
                      {ticket.phone_no === null
                        ? commuters.map((prof) =>
                            prof.id === ticket.customer ? prof.phone : null
                          )
                        : ticket.phone_no}
                    </small>
                  </td>
                  <td className="col-auto">
                    <small className="mb-0">
                      {ticket.phone_no === null
                        ? commuters.map((prof) =>
                            prof.id === ticket.customer ? prof.nof : null
                          )
                        : ticket.phone_no}
                    </small>
                  </td>
                  <td className="col-auto">
                    <small className="mb-0 text-capitalize">
                      {ticket.from_route}
                    </small>
                  </td>
                  <td className="col-auto">
                    <small className="mb-0 text-capitalize">
                      {ticket.to_route}
                    </small>
                  </td>
                  <td className="col-auto">
                    <small className=" mb-0">
                      {buses.map((buz) =>
                        buz.id === ticket.bus ? buz.number_place : null
                      )}
                    </small>
                  </td>
                  <td className="col-auto">
                    <small className="mb-0">
                      {moment(ticket.departure_date).format(
                        "Do MMMM, YYYY, HH:mm a"
                      )}
                    </small>
                  </td>
                  <td className="col-auto">
                    <small className="mb-0">K {ticket.amount}</small>
                  </td>
                  <td className="col-auto hide-on-print">
                    <small className="mb-0">{ticket.status}</small>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <p className="pull-right mr-4">
        <button
          onClick={printTickets}
          className="btn btn-primary hide-on-print"
        >
          <i className="fa fa-print"></i> Print Page
        </button>
      </p>
      <div className="container pull-right">
        {data.length < 50 ? null : (
          <div className="pagination">
            <div className="page-item">
              <button
                disabled={number === 1}
                className="text-center btn-primary py-0"
                onClick={() => setNumber(number - 1)}
              >
                <i className="fa fa-chevron-left"></i>
              </button>
            </div>

            {pageNumber.map((Elem, index) => {
              return (
                <div key={index} className="page-item">
                  <button
                    className={`text-center text-white ${
                      number === Elem ? "active" : ""
                    } btn-outline-dark`}
                    onClick={() => ChangePage(Elem)}
                  >
                    {Elem}
                  </button>
                </div>
              );
            })}
            <div className="page-item">
              <button
                className="text-center btn-primary"
                onClick={() => setNumber(number + 1)}
              >
                <i className="fa fa-chevron-right"></i>
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default PrintTickets;
