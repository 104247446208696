import axios from "axios";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function AddCountryModal() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [validated, setValidated] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    countryName: "",
    description: "",
  });

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setFormSubmitting(true);
      
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `JWT ${localStorage.getItem('access')}`,
        },
      };

      let form_data = new FormData();
      form_data.append("countryName", formData.countryName);
      form_data.append("description", formData.description);
      form_data.append("image", formData.image);

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/holidays/v1/intercity_travellers/create_travel/rmf_travellers`,
          form_data,
          config
        )
        .then((response) => {
          //   console.log(response.data)
          Swal.fire("Successful", "Travel Added Successfully", "success");

          setTimeout(() => {
            window.location.reload();
            setFormSubmitting(false);
          }, 2000);
        })
        .catch((error) => {
          // console.log(formData);
          setTimeout(() => {
            // window.location.reload();
            setFormSubmitting(false);
          }, 2000);
          Swal.fire("Error", `An Error occurred\n${error.message}`, "error");
          console.error("There was an error!", error.message);
        });
    }

    setValidated(true);
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <i className="fa fa-plus-circle"></i> Add Country
      </Button>

      <Modal
        show={show}
        centered
        scrollable
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Country</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Form.Group
                as={Col}
                md="6"
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Country Name</Form.Label>
                <Form.Control
                  type="file"
                  name="image"
                  disabled={formSubmitting}
                  onChange={(e) => onChange(e)}
                  required
                  placeholder="Country Name"
                />
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                as={Col}
                md="6"
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Country Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="countryName"
                  disabled={formSubmitting}
                  onChange={(e) => onChange(e)}
                  placeholder="Country Name"
                />
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Country Description</Form.Label>
              <Form.Control
                required
                as="textarea"
                name="description"
                disabled={formSubmitting}
                onChange={(e) => onChange(e)}
                rows={7}
              />
              <Form.Control.Feedback type="invalid">
                This field is required
              </Form.Control.Feedback>
            </Form.Group>
            <Modal.Footer>
              <Button
                variant="secondary"
                disabled={formSubmitting}
                type="button"
                onClick={handleClose}
              >
                Close
              </Button>
              <Button variant="primary" disabled={formSubmitting} type="submit">
                {formSubmitting ? (
                  <span className="wc-editable">
                    <i className="fa fa-refresh fa-spin" /> Submitting ....
                  </span>
                ) : (
                  "Submit"
                )}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddCountryModal;
