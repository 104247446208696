import React from "react";
import bg1 from "../assets/slider-img/slider-2.jpg";
import { Reveal } from "../reveal";
import CarCard from "./widgets/car_card";
import { ICarBrand, ICarCompany, ICarRental } from "../redux/types/car_rental.type";
import { useGetCarsQuery, useGetCompanyQuery } from "../redux/slices/car_hire_slice";
import { useGetBrandsQuery } from "../redux/slices/car_brands_apislice";

const CompanyCars = () => {
  const [number, setNumber] = React.useState(1); // No of pages
  const [dataPerPage] = React.useState(8);
  const [q, setQ] = React.useState("");
  const [filterParam, setFilterParam] = React.useState("all");
  const [searchParam] = React.useState(["gear_type", "car_model", "car_brand"]);
  const queryParams = new URLSearchParams(window.location.search);
  const company_id = queryParams.get("company_id");

  const {
    data: cars,
    isLoading: fetchingCars,
    error,
  }: ICarRental | any = useGetCarsQuery({
    company: company_id,
  });

  const {
    data: company,
    isLoading: fetchingUser,
  }: ICarCompany | any = useGetCompanyQuery({
    company: company_id,
  });
  const { data: carBrands, isLoading: fetchingBrands }: ICarBrand | any =
    useGetBrandsQuery();

  if (fetchingCars || fetchingUser) {
    return (
      <div
        style={{ marginTop: "20%", marginBottom: "20%" }}
        className="text-center">
        <i className="fa fa-spinner fa-spin fa-5x text-danger"></i>
      </div>
    );
  }
  if (error) {
    return (
      <>
        <section
          className="hero-wrap hero-wrap-2 js-fullheight"
          style={{ backgroundImage: `url(${bg1})`, height: "fit-content" }}>
          <div style={{ height: "fit-content" }} className="overlay"></div>
          <div className="container">
            <div className="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
              <div className="col-md-9 ftco-animate pb-5 text-center">
                <p className="breadcrumbs">
                  <span className="mr-2">
                    <a href="/">
                      Home <i className="fa fa-chevron-right"></i>
                    </a>
                  </span>{" "}
                  <span>
                    Company <i className="fa fa-chevron-right"></i>
                  </span>
                </p>
                <h1 className="mb-0 bread">Cars</h1>
              </div>
            </div>
          </div>
        </section>
        <div
          style={{
            marginTop: "10%",
            marginBottom: "20%",
            width: "fit-content",
          }}
          className="text-center alert alert-danger"
          role="alert">
          <h4>An Error Occurred </h4>
        </div>
      </>
    );
  }

  // pagination
  const lastData = number * dataPerPage;
  const firstData = lastData - dataPerPage;
  const currentData = cars.slice(firstData, lastData);
  const pageNumber = [];

  for (let i = 1; i <= Math.ceil(cars.length / dataPerPage); i++) {
    pageNumber.push(i);
  }

  const ChangePage = (pageNumber: number) => {
    setNumber(pageNumber);
  };
  const data = Object.values(currentData);

  function search(list: any) {
    return list.filter((item: any) => {
      if (item.car_name == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.car_model == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.car_brand.id == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (filterParam == "all") {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }
  return (
    <>
    <div className="demo-wrap">
  <img
    className="demo-bg"
    src={`${company?.company_logo}`}
    alt=""
  />
  <div className="demo-content">
  <div className="container">
    <div className="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
      <div className="col-md-9 ftco-animate pb-5 text-center">
        <p className="breadcrumbs">
          <span className="mr-2">
            <a href="/">
              Home <i className="fa fa-chevron-right"></i>
            </a>
          </span>{" "}
          <span>
            Car Rentals <i className="fa fa-chevron-right"></i>
          </span>
        </p>
        
        <h1 className="mb-0 bread text-capitalize">{company?.company_name}</h1>
      </div>
    </div>
  </div>
  </div>
</div>

      <section className="ftco-section">
        <div className="container-fluid">
          <div className="row">
            <div style={{height: '100vh'}} className="col-md-2 shadow border-right">
              <Reveal>
                <div className="form-group border-0">
                  <div className="row">
                    <div className="col-md-8">
                      <label htmlFor="search-">Search Cars</label>
                    </div>
                    <div className="col-md-3">
                      <div className="tag-widget post-tag-container">
                        <div className="tagcloud">
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => setFilterParam("all")}
                            className="tag-cloud-link btn btn-warning">
                            Clear
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-field">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search Cars for hire"
                      value={q}
                      onChange={(e) => setQ(e.target.value)}
                    />
                  </div>
                </div>
                <div className="tag-widget post-tag-container mb-5 mt-3">
                  <ul className="tagcloud">
                    {fetchingBrands ? (
                      <center>
                        <i className="fa fa-spinner fa-spin fa-5x text-danger"></i>
                      </center>
                    ) : (
                      carBrands.map((category: ICarBrand, index: number) => (
                        <li key={index}>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setFilterParam(category.id.toString())
                            }
                            className={`tag-cloud-link ${
                              category.id.toString() === filterParam
                                ? "active_category"
                                : ""
                            }`}>
                            {category.brand_name}
                            <small
                              style={{
                                background: "red",
                                color: 'white',
                                marginLeft: '10px',
                                paddingRight: "2px",
                                paddingLeft: "2px",
                                borderRadius: "50%",
                              }}>
                              {category.carmodel_count}
                            </small>
                          </a>
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              </Reveal>
            </div>
            <div className="col-md-10">
              <h1 className="mb-4" style={{fontFamily: 'serif', fontWeight: 'bolder'}}>{company?.carmodel_count} Cars Found</h1>
              <Reveal>
                <div className="row d-flex">
                  {search(data).length === 0 ? (
                    <div className="text-center mt-5">
                      <i className="fa fa-car fa-5x"></i>
                      <h3 className="mt-3 mb-5">No Cars Found</h3>
                    </div>
                  ) : (
                    search(data).map((item: ICarRental, index: number) => (
                      <CarCard is_company={true} item={item} key={index} />
                    ))
                  )}
                </div>
              </Reveal>
              {data.length < 8 ? (
                <div></div>
              ) : (
                <div className="row mt-5">
                  <div className="col text-center">
                    <div className="block-27">
                      <ul>
                        <li>
                          <a
                            style={{ cursor: "pointer" }}
                            // disabled={number === 1}
                            onClick={() => setNumber(number - 1)}>
                            &lt;
                          </a>
                        </li>
                        {pageNumber.map((page, index) => (
                          <li
                            key={index}
                            className={`${number === page ? "active" : ""}`}>
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => ChangePage(page)}>
                              <span>{page}</span>
                            </a>
                          </li>
                        ))}

                        <li>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => setNumber(number + 1)}>
                            &gt;
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CompanyCars;
