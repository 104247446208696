import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import { useForm } from "react-hook-form";
import { Modal, Spinner } from "react-bootstrap";
import moment from "moment";

const AddBusFares = ({
  provinces,
  buses,
  stations,
  uid,
  fetchFares,
  busRoutes,
}) => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bus, setBus] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [departureData, setDepartureData] = useState(
    moment().format("yyyy-mm-D")
  );
  const [routes, setRoutes] = useState([]);
  const [formData, setFormData] = useState({
    going_bus: "",
    commutor: "",
    from_route: "",
    to_route: "",
    route_price: "",
    departure_date: "",
    departure_time: "",
    arrival_time: "",
    fare_id: "",
    station: "",
  });

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleChange = async (e) => {
    setIsLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/get_bus_byid/${e.target.value}/rmf_travellers`,
          config
        )
        .then((res) => {
          setBus(res.data);
          setIsLoading(false);
        });
    } catch (error) {
      // alert(error.message);
    }
    if (bus.length !== 0) {
      bus.map(async (item) => {
        setDepartureData(item.departure_day);
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/route_byid/${item.route}/rmf_travellers`,
            config
          )
          .then((res) => {
            setRoutes(res.data);
            setIsLoading(false);
          });
      });
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handlePostData = async (data) => {
    // e.preventDefault();
    setFormSubmitting(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    const body = JSON.stringify({
      going_bus: data.going_bus,
      commutor: uid,
      from_route: data.from_route,
      to_route: data.to_route,
      route_price: data.route_price,
      departure_date: departureData,
      arrivalDay: departureData,
      departure_time: data.departure_time,
      arrival_time: data.arrival_time,
      station: data.station,
    });

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/create-departures/rmf_travellers`,
        body,
        config
      )
      .then((response) => {
        // console.log(response.data)

        Toast.fire({
          icon: "success",
          title: "new busfare added Successfully",
        });
        fetchFares();
        setFormSubmitting(false);
        setShow(false);
      })
      .catch((error) => {
        setFormSubmitting(false);
        Toast.fire({
          icon: "error",
          title: `An Error occurred try again later! ${error.message}`,
        });
      });
  };

  $(document).ready(function () {
    $("#from_province").on("change", function () {
      var provinceID = $(this).val();
      if (provinceID) {
        $.ajax({
          url:
            `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/province-districts/${provinceID}/rmf_travellers`,
          type: "GET",
          dataType: "json",
          success: function (data) {
            if (data) {
              $("#from_route").empty();
              $("#from_route").focus();
              $("#from_route").append(
                '<option disabled selected value=""> -- Select The City -- </option>'
              );
              $.each(data, function (key, value) {
                $('select[name="from_route"]').append(
                  '<option value="' +
                    value.district_name +
                    '">' +
                    value.district_name +
                    "</option>"
                );
              });
            } else {
              $("#from_route").empty();
            }
          },
        });
      } else {
        $("#from_route").empty();
      }
    });
  });

  $(document).ready(function () {
    $("#to_province").on("change", function () {
      var provinceID = $(this).val();
      if (provinceID) {
        $.ajax({
          url:
            `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/province-districts/${provinceID}/rmf_travellers`,
          type: "GET",
          dataType: "json",
          success: function (data) {
            if (data) {
              $("#to_route").empty();
              $("#to_route").focus();
              $("#to_route").append(
                '<option disabled selected value=""> -- Select City -- </option>'
              );
              $.each(data, function (key, value) {
                $('select[name="to_route"]').append(
                  '<option value="' +
                    value.district_name +
                    '">' +
                    value.district_name +
                    "</option>"
                );
              });
            } else {
              $("#to_route").empty();
            }
          },
        });
      } else {
        $("#to_route").empty();
      }
    });
  });
  return (
    <>
      <div className="form-group pull-right">
        <button type="button" className="btn btn-primary" onClick={handleShow}>
          <i className="fa fa-plus"></i> Add New Bus Fares
        </button>
      </div>
      <Modal
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
        scrollable={true}
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Bus Fares</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(handlePostData)}>
            <div className="row">
              <div className="form-group col-md-6">
                <label>Select Bus</label>
                <select
                  {...register("going_bus", { required: true })}
                  onChange={(e) => handleChange(e)}
                  disabled={formSubmitting}
                  name="going_bus"
                  className="form-control text-capitalize"
                >
                  <option selected disabled>
                    Select Bus{" "}
                  </option>
                  {buses.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.bus_role} - {item.departure_time}{" "}
                      <span>
                        {item.driver} - {item.number_place}
                      </span>
                    </option>
                  ))}
                </select>
                {errors.going_bus && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
              <div className="form-group col-md-6">
                <label>Choose Station</label>
                <select
                  onChange={(e) => onChange(e)}
                  name="station"
                  disabled={formSubmitting}
                  {...register("station", { required: true })}
                  className="form-control text-capitalize"
                >
                  <option selected disabled>
                    Choose Station
                  </option>
                  {stations.map((station, index) => (
                    <option key={index} value={station.id}>
                      {provinces.map((pro) =>
                        pro.id === station.province ? pro.province_name : null
                      )}{" "}
                      - {station.stationName}
                    </option>
                  ))}
                </select>
                {errors.station && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label>From Province</label>
                <select
                  id="from_province"
                  name="from_province"
                  className="form-control text-capitalize"
                  disabled={formSubmitting}
                >
                  <option selected disabled>
                    Change Bus Boarding Point
                  </option>
                  {provinces.map((route, index) => (
                    <option key={index} value={route.id}>
                      {route.province_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group col-md-6">
                <label>To Province</label>
                <select
                  id="to_province"
                  name="to_province"
                  className="form-control text-capitalize"
                  disabled={formSubmitting}
                >
                  <option selected disabled>
                    Change Bus Boarding Destination
                  </option>
                  {provinces.map((route, index) => (
                    <option key={index} value={route.id}>
                      {route.province_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label>From City</label>
                <input
                  onChange={(e) => onChange(e)}
                  disabled={formSubmitting}
                  type="text"
                  {...register("from_route", { required: true })}
                  name="from_route"
                  className="form-control"
                />
                {/* <select
                  onChange={(e) => onChange(e)}
                  disabled={formSubmitting}
                  id="from_route"
                  {...register("from_route", { required: true })}
                  name="from_route"
                  className="form-control text-capitalize"
                >
                  <option value="">select from city</option>
                </select> */}
                {errors.from_route && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
              <div className="form-group col-md-6">
                <label>To City</label>
                <input
                  onChange={(e) => onChange(e)}
                  disabled={formSubmitting}
                  type="text"
                  {...register("to_route", { required: true })}
                  name="to_route"
                  className="form-control"
                />
                {/* <select
                  onChange={(e) => onChange(e)}
                  disabled={formSubmitting}
                  id="to_route"
                  name="to_route"
                  {...register("to_route", { required: true })}
                  className="form-control text-capitalize"
                >
                  <option value="">select to city</option>
                </select> */}
                {errors.to_route && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label>Departure Time</label>
                <input
                  onChange={(e) => onChange(e)}
                  disabled={formSubmitting}
                  type="time"
                  {...register("departure_time", { required: true })}
                  name="departure_time"
                  className="form-control"
                />
                {errors.departure_time && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
              <div className="form-group col-md-6">
                <label>Estimated Arrival Time</label>
                <input
                  onChange={(e) => onChange(e)}
                  disabled={formSubmitting}
                  type="time"
                  name="arrival_time"
                  {...register("arrival_time", { required: true })}
                  className="form-control"
                />
                {errors.arrival_time && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
              <div className="form-group col-md-12">
                <label>Price</label>
                <input
                  type="number"
                  min="1"
                  {...register("route_price", { required: true })}
                  onChange={(e) => onChange(e)}
                  name="route_price"
                  placeholder="Route Price"
                  className="form-control"
                  disabled={formSubmitting}
                />
                {errors.route_price && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <div className="row">
                <div className="col-md-5">
                  <button
                    onClick={handleClose}
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
                <div className="col-md-7">
                  <button
                    type="submit"
                    className="btn btn-info"
                    disabled={formSubmitting}
                  >
                    {formSubmitting && (
                      <span>
                        <i
                          className="fa fa-refresh fa-spin text-danger"
                          style={{ marginRight: "5px" }}
                        />{" "}
                        Submitting ....
                      </span>
                    )}
                    {!formSubmitting && (
                      <span>
                        <i
                          className="fa fa-plus-square"
                          style={{ marginRight: "5px" }}
                        />
                        Submit
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddBusFares;
