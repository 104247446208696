import React from "react";


class GalleryModal extends React.Component {
    constructor() {
      super();
      this.handleKeyDown = this.handleKeyDown.bind(this);
    }
    componentDidMount() {
      document.body.addEventListener('keydown', this.handleKeyDown);
    }  
    componentWillUnmount() {
      document.body.removeEventListener('keydown', this.handleKeyDown);
    }
    handleKeyDown(e) {
      if (e.keyCode === 27)
        this.props.closeModal();
      if (e.keyCode === 37 && this.props.hasPrev)
        this.props.findPrev();
      if (e.keyCode === 39 && this.props.hasNext)
        this.props.findNext();
    }
    render () {
      const { closeModal, hasNext, hasPrev, findNext, findPrev, src } = this.props;
      if (!src) {
        return null;
      }
      return (
        <>
          <div id="gallery-modal-overlay" onClick={closeModal}></div>
          <div isOpen={!!src} id="gallery-modal">
            <div id='gallery-modal-body'>
              <a href="#" id='gallery-modal-close' onClick={closeModal} onKeyDown={this.handleKeyDown}>&times;</a>
              {hasPrev && <a href="#" className='gallery-modal-prev' onClick={findPrev} onKeyDown={this.handleKeyDown}>&lsaquo;</a>}
              {hasNext && <a href="#" className='gallery-modal-next' onClick={findNext} onKeyDown={this.handleKeyDown}>&rsaquo;</a>}
              <img src={src.image} />
            </div>
          </div>
        </>
      )
    }
  }

export default GalleryModal;
