import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import bg1 from "../../images/hilton.jpg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

function GetStarted() {
  const [submitting, setSbmitting] = React.useState(false);
  const [validated, setValidated] = React.useState(false);
  const [backNrc, setNrcBack] = React.useState(null);
  const [frontNrc, setNrcFront] = React.useState(null);
  const [selfieNrc, setNrcSelfie] = React.useState(null);
  const [cerfificatePDF, setCerfificate] = React.useState(null);
  const [formData, setFormData] = React.useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    companyName: "",
  });

  const handleNrcBack = (e) => {
    setNrcBack(e.target.files[0]);
  };
  const handleNrcFront = (e) => {
    setNrcFront(e.target.files[0]);
  };
  const handleNrcSelfie = (e) => {
    setNrcSelfie(e.target.files[0]);
  };
  const handleCertificate = (e) => {
    setCerfificate(e.target.files[0]);
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setSbmitting(true);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      let form_data = new FormData();
      form_data.append("phoneNumber", formData.phoneNumber);
      form_data.append("email", formData.email);
      form_data.append("firstName", formData.firstName);
      form_data.append("lastName", formData.lastName);
      form_data.append("companyName", formData.companyName);
      form_data.append("nrcFront", frontNrc);
      form_data.append("nrcBack", backNrc);
      form_data.append("nrcSelfie", selfieNrc);
      form_data.append("cerfificate", cerfificatePDF);
      console.log(`FORM DATA ${cerfificatePDF}`);
      try {
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/register_requests/rmf_travellers`,
            form_data,
            config
          )
          .then((res) => {
            console.log(res.data);

            toast.success("Registration Request sent Successfully", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setTimeout(() => {
              window.location.reload();
              setSbmitting(false);
            }, 2000);
          })
          .catch((er) => {
            console.log(er.message);
            toast.error(`An Error Occurred! ${er.message}`, {
              position: toast.POSITION.TOP_CENTER,
            });
            setSbmitting(false);
          });
      } catch (err) {
        console.log(err.message);
        setSbmitting(false);
      }
    }
    setValidated(true);
  };
  return (
    <>
      <ToastContainer />
      <section
        className=""
        style={{
          backgroundImage: `url(${bg1})`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-center">
            <div className="col-md-9 ftco-animate pb-5 text-center">
              <p className="breadcrumbs">
                <span className="mr-2">
                  <a href="/">
                    Home <i className="fa fa-chevron-right"></i>
                  </a>
                </span>{" "}
              </p>
              <h1 className="mb-0 bread">Get Started</h1>
            </div>
          </div>
        </div>
      </section>
      <div className="container mb-5">
        <div className="row">
          <div className="col-md-6">
            <h2 className="mb-5 mt-5">Register as Lodge/Hotel</h2>
            <blockquote>
              <h4>
                <b>
                  Here are some key features and benefits of our hotel/Lodge
                  management system:
                </b>
              </h4>
              {/* <p>
                <b>1.</b> Property Management System (PMS): Our PMS is a robust
                and user-friendly platform that automates front desk operations,
                reservations, check-ins, check-outs, and guest profiles. It also
                offers real-time inventory management, room availability, and
                rate management, ensuring optimal revenue management.
              </p>{" "} */}
              <p>
                <b>1.</b> Online Booking Engine: With our integrated online
                booking engine, guests can effortlessly make reservations
                directly through your website and via the Mobile Application.
                This feature enables you to increase direct bookings, reduce
                dependency on third-party channels, and enhance revenue
                generation.
              </p>{" "}
              <p>
                <b>2.</b> Housekeeping and Maintenance: Our hotel management
                system offers a comprehensive module for housekeeping and
                maintenance, enabling effective scheduling, tracking, and
                management of tasks. This feature enhances operational
                efficiency and ensures a clean and well-maintained property at
                all times.
              </p>{" "}
              <p>
                <b>3.</b> Reporting and Analytics: Our system provides detailed
                reporting, offering valuable insights into your hotel's
                performance. From financial reports to occupancy trends, you can
                make data-driven decisions to optimize your operations and
                maximize profitability.
              </p>{" "}
              <p>
                <b>4.</b> Guest Relationship Management: Our CRM module enables
                you to build personalized guest profiles, track guest
                preferences, and deliver exceptional guest experiences. By
                leveraging guest data, you can create targeted marketing
                campaigns, loyalty programs, and special offers to foster guest
                loyalty and drive repeat business. We are proud to offer a
                flexible and scalable hotel management system that can be
                integrated with the unique needs of your property, whether it is
                a small boutique hotel or a large resort at a 3.5% commission
                from every booking. Our dedicated support team will be available
                to provide training and ongoing assistance to ensure a smooth
                implementation and effective utilization of our system and
                marketing of your facility, lodge/hotel. To learn more about our
                hotel management system and how it can revolutionize your
                property's operations, <b>Register.</b>
              </p>
            </blockquote>
          </div>
          <div className="col-md-6 shadow border-0 rounded-3">
            <h2 className="mb-5 mt-5 text-center">Register Here</h2>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="validationCustom01">
                <Form.Label>First name</Form.Label>
                <Form.Control
                  required
                  name="firstName"
                  onChange={(e) => onChange(e)}
                  disabled={submitting}
                  type="text"
                  placeholder="First name"
                />
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="validationCustom02">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  required
                  name="lastName"
                  onChange={(e) => onChange(e)}
                  disabled={submitting}
                  type="text"
                  placeholder="Last name"
                />
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="validationCustom02">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  required
                  name="email"
                  onChange={(e) => onChange(e)}
                  disabled={submitting}
                  type="email"
                  placeholder="E-mail"
                />
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="validationCustom02">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  required
                  name="phoneNumber"
                  onChange={(e) => onChange(e)}
                  disabled={submitting}
                  type="number"
                  placeholder="Phone Number"
                />
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="validationCustom02">
                <Form.Label>Lodge/Hotel Name</Form.Label>
                <Form.Control
                  required
                  name="companyName"
                  onChange={(e) => onChange(e)}
                  disabled={submitting}
                  type="text"
                  placeholder="Lodge/Hotel Name"
                />
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
              <h4 className="text-center mt-3">Required Files</h4>
              <Row className="mb-3">
                <Form.Group
                  className="mb-3"
                  as={Col}
                  md="6"
                  controlId="validationCustomUsername"
                >
                  <Form.Label>NRC (Front)</Form.Label>

                  <Form.Control
                    type="file"
                    placeholder="Username"
                    name="nrcFront"
                    disabled={submitting}
                    onChange={(e) => handleNrcFront(e)}
                    aria-describedby="inputGroupPrepend"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  as={Col}
                  md="6"
                  controlId="validationCustomUsername"
                >
                  <Form.Label>NRC (Back)</Form.Label>

                  <Form.Control
                    type="file"
                    placeholder="Username"
                    name="nrcBack"
                    disabled={submitting}
                    onChange={(e) => handleNrcBack(e)}
                    aria-describedby="inputGroupPrepend"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  as={Col}
                  md="6"
                  controlId="validationCustomUsername"
                >
                  <Form.Label>Your selfie with NRC</Form.Label>

                  <Form.Control
                    type="file"
                    placeholder="Username"
                    name="nrcSelfie"
                    disabled={submitting}
                    onChange={(e) => handleNrcSelfie(e)}
                    aria-describedby="inputGroupPrepend"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  as={Col}
                  md="6"
                  controlId="validationCustomUsername"
                >
                  <Form.Label className="text-capitalize">
                    certificate of incorporation
                  </Form.Label>

                  <Form.Control
                    type="file"
                    placeholder="Username"
                    name="cerfificate"
                    accept=".pdf"
                    disabled={submitting}
                    onChange={(e) => handleCertificate(e)}
                    aria-describedby="inputGroupPrepend"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <a href="/terms" target="_blank">
                <Form.Group className="mb-3">
                  <Form.Check
                    style={{ cursor: "pointer" }}
                    required
                    label="Agree to terms and conditions"
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                  />
                </Form.Group>
              </a>
              <center>
                <Button disabled={submitting} type="submit">
                  {submitting ? (
                    <center>
                      <i className="fa fa-refresh fa-spin"></i> Submitting....
                    </center>
                  ) : (
                    "Submit Request"
                  )}
                </Button>
              </center>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default GetStarted;
