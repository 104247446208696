import React, { useEffect, useState } from "react";
import axios from "axios";

import { useParams } from "react-router-dom";
import AddStation from "./add_station";
import EditStation from "./edit_station";

function Stations() {
  const [isLoading, setIsLoading] = useState(true);
  const [stations, setStations] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [message, setMessage] = useState("");
  const { uid } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
          Accept: "application/json",
        },
      };

      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/get_stations/${uid}/rmf_travellers`,
          config
        )
        .then((res) => {
          console.log(`my error ${res.status}`);
          if (res.statusText === "Request failed with status code 500") {
            setMessage(`SERVER ERROR ${res.status}`);
            setIsLoading(false);
          } else {
            setStations(res.data);
            setIsLoading(false);
          }
        });
      //get provinces
      try {
        await axios
          .get(`${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/home/rmf_travellers`, config)
          .then((res) => {
            setProvinces(res.data);
            // setIsLoading(false);
          });
      } catch (error) {}
      // GET DISTRICTS
      try {
        await axios
          .get(`${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/districts/rmf_travellers`, config)
          .then((res) => {
            setDistricts(res.data);
            // setIsLoading(false);
          });
      } catch (error) {}
    };
    fetchData();
  }, []);

  return (
    <>
      <>
        <div className="container">
          <div className="row">
            <div className="col px-4 py-5">
              <h3 className="text-capitalize text-black mt-4">Stations</h3>

              <AddStation provinces={provinces} uid={uid} />

              {/* <!-- loading stations table --> */}
              <div className="text-center">
                <h3 className="text-center text-danger">{message}</h3>
              </div>
              <div className="table-responsive my-4">
                {isLoading ? (
                  <i
                    className="fa fa-spinner fa-spin text-center"
                    style={{ fontSize: "50px", color: "red" }}
                  ></i>
                ) : stations.length === 0 ? (
                  <div className="alert alert-danger text-center">
                    There are no Stations
                  </div>
                ) : (
                  <table id="example" className="table table-striped table-lg">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Location</th>
                        <th>Station Name</th>
                        <th>Station Manager</th>
                        <th>Phone Number</th>
                        <th>E-mail</th>
                        <th colSpan="2">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stations.map((station, index) => (
                        <tr key={index}>
                          <td className="col-auto">
                            <p className="font-bold ms-3 mb-0">{index + 1}</p>
                          </td>
                          <td className="col-auto text-capitalize">
                            <p className="mb-0">
                              {provinces.map((item) =>
                                item.id === station.province
                                  ? item.province_name
                                  : ""
                              )}
                              {", "}
                              {districts.map((item) =>
                                item.id === station.district
                                  ? item.district_name
                                  : ""
                              )}
                            </p>
                          </td>
                          <td className="col-auto text-capitalize">
                            <p className="mb-0">{station.stationName}</p>
                          </td>
                          <td className="col-auto text-capitalize">
                            <p className=" mb-0">{station.stationManager}</p>
                          </td>
                          <td className="col-auto text-capitalize">
                            <p className="mb-0">{station.phoneNumber}</p>
                          </td>
                          <td className="col-auto text-capitalize">
                            <p className="mb-0">{station.email}</p>
                          </td>
                          <td>
                            <EditStation
                              station={station}
                              uid={uid}
                              provinces={provinces}
                              districts={districts}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            {/* <!-- end of loading buses table --> */}
          </div>
        </div>
      </>
    </>
  );
}

export default Stations;
