import React, { useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { ToastContainer, toast } from "react-toastify";
import Col from "react-bootstrap/Col";
import { errorHandler } from "../../helper";

function AddHotelLodge({ show, handleClose, handleShow, fetchData }) {
  const [loading, setLoading] = useState(false);

  const [validated, setValidated] = useState(false);

  const [formData, setFormData] = useState({
    company_name: "",
    first_name: "",
    last_name: "",
    email: "",
    mechant_id: "",
    percentage_share: "",
    flutterwaveChargeType: "",
    password: "",
    re_password: "",
  });

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({
        company_name: formData.company_name,
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        user_type: "COMMUTER",
        mechant_id: formData.mechant_id,
        flutterwave_id: "56489852365",
        percentage_share: formData.percentage_share,
        flutterwaveChargeType: formData.flutterwaveChargeType,
        password: formData.password,
        re_password: formData.re_password,
      });

      try {
        await axios
          .post(`${process.env.REACT_APP_API_URL}/auth/users/`, body, config)
          .then((res) => {
            console.log(res.data);
            setLoading(false);
            fetchData();
            handleShow(false);
            toast.success("Accounted Created Successfully", {
              position: toast.POSITION.TOP_RIGHT,
            });
          })
          .catch((er) => {
            console.log(er.message);
            toast.error(`${errorHandler(er)}`, {
              position: toast.POSITION.TOP_CENTER,
            });
            setLoading(false);
          });
      } catch (err) {
        console.log(err.message);
        setLoading(false);
      }
    }
    setValidated(true);
  };
  return (
    <>
      <ToastContainer />
      <div className="pull-right">
        <Button variant="primary mb-5" onClick={handleShow}>
          Create Commuter
        </Button>
      </div>
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Create Commuter Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Company Name*"
                  name="company_name"
                  onChange={(e) => onChange(e)}
                  disabled={loading}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Company Name is required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Name*"
                  name="first_name"
                  onChange={(e) => onChange(e)}
                  disabled={loading}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  First Name is required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom03">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last Name*"
                  name="last_name"
                  onChange={(e) => onChange(e)}
                  disabled={loading}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Last Name is required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom04">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email*"
                  name="email"
                  disabled={loading}
                  onChange={(e) => onChange(e)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  E-mail is required
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="validationCustom08">
                <Form.Label>SamPay Mechant ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Sampay Mechant ID*"
                  name="mechant_id"
                  disabled={loading}
                  onChange={(e) => onChange(e)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  SamPay Mechant ID is required
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="validationCustom010">
                <Form.Label>RMF Percentage Share</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="RMF Percentage Share*"
                  name="percentage_share"
                  min="0"
                  disabled={loading}
                  onChange={(e) => onChange(e)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  RMF Percentage Share is required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom06">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password*"
                  name="password"
                  disabled={loading}
                  onChange={(e) => onChange(e)}
                  minLength="6"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Password is required
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="validationCustom07">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm Password*"
                  name="re_password"
                  disabled={loading}
                  onChange={(e) => onChange(e)}
                  minLength="6"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Confirm Password is required
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Form.Group controlId="validationCustom011">
              <Form.Label>Charge Type</Form.Label>
              <Form.Select
                name="flutterwaveChargeType"
                onChange={(e) => onChange(e)}
                size="lg"
                disabled={loading}
                required>
                <option selected value="">
                  Choose Charge Type
                </option>
                <option value="percentage">Percentage Share</option>
                <option value="flat">Flat Share</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Charge Type is required
              </Form.Control.Feedback>
            </Form.Group>

            <Modal.Footer>
              <Row>
                <Form.Group as={Col} md="6">
                  <Button
                    disabled={loading}
                    variant="secondary"
                    onClick={handleClose}>
                    Close
                  </Button>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <Button disabled={loading} type="submit" variant="primary">
                    Submit
                  </Button>
                </Form.Group>
              </Row>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default AddHotelLodge;
