import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import httpRoomServices from "../services/http.room.services";
import { DragAndDrop } from "../../widgets/DragNdrop";

function AddRoom({ uid, branch }) {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [files, setFiles] = useState([]);
  const [roomNumbers, setRoomNumbers] = useState([]);
  const [breakFast, setBreakFast] = useState(false);
  const [lunch, setLunch] = useState(false);
  const [supper, setSupper] = useState(false);
  const [room, setRoom] = useState(null);
  const [validated, setValidated] = useState(false);
  const [amenities, setAmenities] = useState([]);
  const [facility, setFacility] = useState(null);
  const [formData, setFormData] = useState({
    room_name: "",
    room_description: "",
    room_type: "",
    room_occupation: "",
    room_price: "",
    room_number: "",
    inCludeBreakFast: breakFast,
    inCludeLunch: lunch,
    inCludeSupper: supper,
    breakFastPrice: 0,
    lunchPrice: 0,
    supperPrice: 0,
  });
  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  let tagInput = useRef();
  let aminitytagInput = useRef();
  // room amenities
  const handleAminity = (e) => {
    setFacility(e.target.value);
  };

  const removeAminitiesTag = (i) => {
    const newTags = [...amenities];
    newTags.splice(i, 1);
    setAmenities(newTags);
  };

  const inputAminityKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      if (amenities.find((tag) => tag.toLowerCase() === val.toLowerCase())) {
        return;
      }
      setAmenities([...amenities, val]);
      aminitytagInput.value = null;
    }
  };

  const handleNumber = (e) => {
    setRoom(e.target.value);
  };

  const removeTag = (i) => {
    const newTags = [...roomNumbers];
    newTags.splice(i, 1);
    setRoomNumbers(newTags);
  };

  const inputKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      if (roomNumbers.find((tag) => tag.toLowerCase() === val.toLowerCase())) {
        return;
      }
      setRoomNumbers([...roomNumbers, val]);
      tagInput.value = null;
    }
  };
  /**
   * Stop enter submitting the form.
   * @param keyEvent Event triggered when the user presses a key.
   */
  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const uploadRoomImages = (room) => {
    const bodyFormData = new FormData();
    files.map(async (image) => {
      bodyFormData.append("room", room);
      bodyFormData.append("image", image);
      try {
        httpRoomServices
          .submitRoomImage(bodyFormData)
          .then((response) => {
            if (response.status === 201) {
              // reset();
              window.location.reload();
              // toast.success(response.data.message);
            } else {
              // toast.error(response.data.message);
            }
          })
          .catch((error) => {
            console.error(error);
            // toast.error(error.message);
          });
      } catch (error) {}
    });
  };

  const postData = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      // setFormSubmitting(true);

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      httpRoomServices
        .createRoom(
          formData,
          breakFast,
          lunch,
          supper,
          files,
          roomNumbers,
          amenities,
          uid,
          branch
        )
        .then((response) => {
          // uploadRoomImages(response.data.id);
          Toast.fire({
            icon: "success",
            title: "Room added Successfully",
          });
          setFormSubmitting(false);
        })
        .catch((error) => {
          setFormSubmitting(false);
          Toast.fire({
            icon: "error",
            title: `An Error occurred try again later! ${error.message}`,
          });
        });
    }
    setValidated(true);
  };

  return (
    <>
      <p className="pull-right mt-5">
        <button
          style={{ cursor: "pointer" }}
          onClick={handleShow}
          className="btn btn-primary">
          Add Room
        </button>
      </p>
      <Modal
        backdrop="static"
        keyboard={false}
        centered
        scrollable={true}
        size="xl"
        show={show}
        onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Room </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onKeyDown={onKeyDown}
            noValidate
            validated={validated}
            onSubmit={postData}>
            <Row>
              <DragAndDrop
                heading={"Room Image Gallery"}
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    for (let i = 0; i < e.target.files["length"]; i++) {
                      setFiles((prevState) => [
                        ...prevState,
                        e.target.files[i],
                      ]);
                    }
                  }
                }}
                setFiles={setFiles}
                files={files}
              />

              <Form.Group
                as={Col}
                md="6"
                className="mb-3"
                controlId="validationCustom03">
                <Form.Label>Guests</Form.Label>
                <Form.Control
                  disabled={formSubmitting}
                  type="number"
                  min={1}
                  name="room_occupation"
                  className="form-control"
                  onChange={(e) => onChange(e)}
                  placeholder="Maximum number of Guests"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Guests is required
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                md="6"
                className="mb-3"
                controlId="validationCustom03">
                <Form.Label>Room Numbers</Form.Label>
                <div className="input-tag">
                  <ul className="input-tag__tags">
                    {roomNumbers.map((tag, i) => (
                      <li key={i}>
                        {tag}
                        <button
                          type="button"
                          onClick={() => {
                            removeTag(i);
                          }}>
                          X
                        </button>
                      </li>
                    ))}
                    <li className="input-tag__tags__input">
                      <Form.Control
                        disabled={formSubmitting}
                        type="text"
                        onKeyDown={inputKeyDown}
                        onChange={(e) => handleNumber(e)}
                        name="room_number"
                        className="form-control"
                        value={room}
                        required
                        placeholder="Room Numbers, E.g A1, A2, A3 etc"
                        ref={(c) => {
                          tagInput = c;
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Room Numbers is required
                      </Form.Control.Feedback>
                    </li>
                  </ul>
                </div>
              </Form.Group>
              <Form.Group
                as={Col}
                md="6"
                className="mb-3"
                controlId="validationCustom03">
                <Form.Label>Room Price</Form.Label>
                <Form.Control
                  type="number"
                  disabled={formSubmitting}
                  min={1}
                  name="room_price"
                  className="form-control"
                  onChange={(e) => onChange(e)}
                  placeholder="Room Price"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Room Price is required
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                md="6"
                className="mb-3"
                controlId="validationCustom011">
                <Form.Label>Select Room Type</Form.Label>
                <Form.Select
                  disabled={formSubmitting}
                  className="form-control"
                  onChange={(e) => onChange(e)}
                  name="room_type"
                  id="room_type"
                  size="lg"
                  required>
                  <option value="">Select Room Type</option>
                  <option value="luxury rooms">Luxury Room</option>
                  <option value="kings room">Kings Room</option>
                  <option value="twin room">Twin Room</option>
                  <option value="single room">Single Room</option>
                  <option value="double room">Double Room</option>
                  <option value="double double room">Double-Double Room</option>
                  <option value="family room">Family Room</option>
                  <option value="queen room">Queen Room</option>
                  <option value="superior room">Superior Room</option>
                  <option value="junior suite">Junior Suite</option>
                  <option value="triple room">Triple Room</option>
                  <option value="quad room">Quad Room</option>
                  <option value="parlour room">Parlour Room</option>
                  <option value="studio room">Studio Room</option>
                  <option value="cabana room">Cabana Room</option>
                  <option value="suite room">Suite Room</option>
                  <option value="duplex room">Duplex Room</option>
                  <option value="efficiency room">Efficiency Room</option>
                  <option value="hospital room">Hospital Room</option>
                  <option value="penthouse room">Henthouse Room</option>
                  <option value="lanai room">Lanai Room</option>
                  <option value="adjacent room">Adjacent Room</option>
                  <option value="adjoining room">adjoining Room</option>
                  <option value="interconntecting room">
                    Interconntecting Room
                  </option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Charge Type is required
                </Form.Control.Feedback>
              </Form.Group>

              <div className="col-md-12">
                <div className="row">
                  <div className="mb-3 col-md-4">
                    <Form.Check // prettier-ignore
                      type="checkbox"
                      id={`breakfast-box`}
                      label={`Include Breakfast`}
                      name="inCludeBreakFast"
                      onChange={(e) => setBreakFast(!breakFast)}
                    />
                  </div>
                  <div className="mb-3 col-md-4">
                    <Form.Check // prettier-ignore
                      type="checkbox"
                      id={`lunch-box`}
                      label={`Include Lunch`}
                      name="includeLunch"
                      onChange={(e) => setLunch(!lunch)}
                    />
                  </div>
                  <div className="mb-3 col-md-4">
                    <Form.Check // prettier-ignore
                      type="checkbox"
                      id={`supper-box`}
                      label={`Include Supper`}
                      name="inCludeSupper"
                      onChange={(e) => setSupper(!supper)}
                    />
                  </div>
                  {breakFast ? (
                    <Form.Group
                      as={Col}
                      md="4"
                      className="mb-3"
                      controlId="validationCustom03">
                      <Form.Label> BreakFast Price</Form.Label>
                      <Form.Control
                        disabled={formSubmitting}
                        type="number"
                        min={1}
                        name="breakFastPrice"
                        className="form-control"
                        onChange={(e) => onChange(e)}
                        placeholder="BreakFast Price"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Breakfast Price is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  ) : (
                    <div></div>
                  )}
                  {lunch ? (
                    <Form.Group
                      as={Col}
                      md="4"
                      className="mb-3"
                      controlId="validationCustom03">
                      <Form.Label> Lunch Price</Form.Label>
                      <Form.Control
                        disabled={formSubmitting}
                        type="number"
                        min={1}
                        name="lunchPrice"
                        className="form-control"
                        onChange={(e) => onChange(e)}
                        placeholder="Lunch Price"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Lunch Price is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  ) : (
                    <div></div>
                  )}
                  {supper ? (
                    <Form.Group
                      as={Col}
                      md="4"
                      className="mb-3"
                      controlId="validationCustom03">
                      <Form.Label> Supper Price</Form.Label>
                      <Form.Control
                        disabled={formSubmitting}
                        type="number"
                        min={1}
                        name="supperPrice"
                        className="form-control"
                        onChange={(e) => onChange(e)}
                        placeholder="Supper Price"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Supper Price is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              <Form.Group className="mb-3" controlId="validationCustom03">
                <Form.Label>Amenities</Form.Label>
                <div className="input-tag">
                  <ul className="input-tag__tags">
                    {amenities.map((tag, i) => (
                      <li key={i}>
                        {tag}
                        <button
                          type="button"
                          onClick={() => {
                            removeAminitiesTag(i);
                          }}>
                          X
                        </button>
                      </li>
                    ))}
                    <li className="input-tag__tags__input">
                      <Form.Control
                        disabled={formSubmitting}
                        type="text"
                        onKeyDown={inputAminityKeyDown}
                        onChange={(e) => handleAminity(e)}
                        name="amenities"
                        required
                        className="form-control"
                        value={facility}
                        placeholder="Amenities, E.g TV, Wi-fi, Room Service etc"
                        ref={(c) => {
                          aminitytagInput = c;
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Amenities is required
                      </Form.Control.Feedback>
                    </li>
                  </ul>
                </div>
              </Form.Group>
              <Form.Group as={Col} md="12" className="mb-3">
                <Form.Label>Room Description</Form.Label>
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Room Description">
                  <Form.Control
                    as="textarea"
                    name="room_description"
                    className="form-control"
                    onChange={(e) => onChange(e)}
                    placeholder="Room Description"
                    required
                    disabled={formSubmitting}
                    rows={8}
                  />
                  <Form.Control.Feedback type="invalid">
                    Room Description is required
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Row>
            <div className="form-group pull-right mb-3">
              <Button
                type="submit"
                disabled={formSubmitting}
                className="btn btn-success">
                {formSubmitting ? (
                  <span>
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px" }}
                    />{" "}
                    Submitting...
                  </span>
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddRoom;
