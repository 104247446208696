import axios from "axios";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import Form from "react-bootstrap/Form";

function RequestDetails({
  request,
  handleClose,
  show,
  fetchRequests,
  setShow,
}) {
  const [formSubmitting, setFormSubmitting] = useState(false);

  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    status: request.status,
  });

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setFormSubmitting(true);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      const body = JSON.stringify({
        status: formData.status,
      });
      try {
        await axios
          .patch(
            `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/update_requests/${request.id}/rmf_travellers`,
            body,
            config
          )
          .then((res) => {
            Toast.fire({
              icon: "success",
              title: "Request updated successfully",
            });
            fetchRequests();
            setShow(false);
            setFormSubmitting(false);
          })
          .catch((er) => {
            Toast.fire({
              icon: "error",
              title: `${er.message}`,
            });

            setFormSubmitting(false);
          });
      } catch (err) {
        console.log(err.message);
        setFormSubmitting(true);
      }
    }

    setValidated(true);
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        scrollable
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Request Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12 mb-4 text-center">
                <h4>Hotel/Lodge Company Name</h4>
                <h5 className="text-capitalize">{request.companyName}</h5>
              </div>
              <div className="col-md-4">
                <h4>Name</h4>
                <h6 className="text-capitalize">
                  {request.firstName} {request.lastName}
                </h6>
              </div>
              <div className="col-md-4">
                <h4>Email</h4>
                <h6>{request.email}</h6>
              </div>
              <div className="col-md-4">
                <h4>Phone Number</h4>
                <h6 className="text-capitalize">{request.phoneNumber}</h6>
              </div>
              <div className="col-md-4 mt-4 mb-3">
                <h4>NRC FRONT</h4>
                <img
                  src={request.nrcFront}
                  style={{ width: "100%", height: "250px" }}
                  className="img-responsive"
                  alt=""
                />
              </div>
              <div className="col-md-4 mt-4 mb-3">
                <h4>NRC BACK</h4>
                <img
                  src={request.nrcBack}
                  style={{ width: "100%", height: "250px" }}
                  className="img-responsive"
                  alt=""
                />
              </div>
              <div className="col-md-4 mt-4 mb-3">
                <h4>NRC WITH SELFIE</h4>
                <img
                  src={request.nrcSelfie}
                  style={{ width: "100%", height: "250px" }}
                  className="img-responsive"
                  alt=""
                />
              </div>
              <div className="col-md-12">
                <h4 className="text-center">Certificate Of Incorporation</h4>
                <iframe
                  src={request.cerfificate}
                  style={{ width: "100%", height: "60vh" }}
                ></iframe>
              </div>
            </div>

            <Form.Group controlId="validationCustom01">
              <Form.Label>Request Status</Form.Label>
              <Form.Select
                name="status"
                onChange={(e) => onChange(e)}
                disabled={formSubmitting}
                required
                className="text-capitalize"
              >
                <option value={request.status}>{request.status}</option>
                <option value="approved">Approve Request</option>
                <option value="rejected">Reject Request</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Request Status is required
              </Form.Control.Feedback>
            </Form.Group>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button disabled={formSubmitting} type="submit" variant="primary">
                {formSubmitting && (
                  <span className="wc-editable">
                    <i className="fa fa-refresh fa-spin" /> Submitting ...
                  </span>
                )}
                {!formSubmitting && <span className="wc-editable">Submit</span>}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RequestDetails;
