import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { PlaceCategories } from "../../components/data/place_categories";

function AddPlace({ fetchPlaces }) {
  const [previewImage, setPreviewImage] = useState(null);
  const [provinces, setProvinces] = useState([]);
  const [image, setImage] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formSubmitting, setFormSubmitting] = useState(false);
  const cancelToken = axios.CancelToken.source();
  const fetchData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    //get provinces
    await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/home/rmf_travellers`, config).then((res) => {
      setProvinces(res.data);
    });
  };
  useEffect(() => {
    fetchData();

    return () => {
      cancelToken.cancel();
    };
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleImage = (e) => {
    setImage(e.target.files[0]);
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
  };

  // add place to the database
  const handlePostData = async (data) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    setFormSubmitting(true);

    let form_data = new FormData();
    form_data.append("image", image);
    form_data.append("province", data.province);
    form_data.append("district", data.district);
    form_data.append("place_type", data.place_type);
    form_data.append("place_name", data.place_name);
    form_data.append("facebook_url", data.facebook_url);
    form_data.append("about_place", data.about_place);
    form_data.append("address", data.address);
    form_data.append("slug", data.slug);

    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/add_place/rmf_travellers`, form_data, config)
      .then((response) => {
        // console.log(response.data)
        Toast.fire({
          icon: "success",
          title: "Place added Succesfully",
        });
        fetchPlaces();
        setFormSubmitting(false);
        setShow(false);
      })
      .catch((error) => {
        setFormSubmitting(false);
        Toast.fire({
          icon: "error",
          title: "An error occurred try again later!",
        });
      });
  };

  $(document).ready(function () {
    $("#province").on("change", function () {
      var provinceID = $(this).val();
      if (provinceID) {
        $.ajax({
          url:
            `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/province-districts/${provinceID}/rmf_travellers`,
          type: "GET",
          dataType: "json",
          success: function (data) {
            if (data) {
              $("#district").empty();
              $("#district").focus();
              $("#district").append(
                '<option disabled selected value=""> -- Select District -- </option>'
              );
              $.each(data, function (key, value) {
                $('select[name="district"]').append(
                  '<option value="' +
                    value.id +
                    '">' +
                    value.district_name +
                    "</option>"
                );
              });
            } else {
              $("#district").empty();
            }
          },
        });
      } else {
        $("#district").empty();
      }
    });
  });
  return (
    <>
      <div className="mt-5 pull-right">
        <button onClick={handleShow} className="btn btn-info" type="button">
          Add Place
        </button>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="xl"
        scrollable={true}
        keyboard={false}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Place</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(handlePostData)}>
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="form-group">
                  <label>
                    <span className="wc-editable">
                      <h3 style={{ fontSize: "18px" }}>
                        {" "}
                        <i className="fa fa-map-marker"></i> Province
                      </h3>
                    </span>
                  </label>

                  <div className="form-group mb-3">
                    <select
                      disabled={formSubmitting}
                      id="province"
                      name="province"
                      {...register("province", { required: true })}
                      className="form-control"
                    >
                      <option value="">Select Province</option>
                      {provinces.map((prov, index) => (
                        <option key={index} value={prov.id}>
                          {prov.province_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors.province && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="form-group search-field">
                  <label>
                    <span className="wc-editable">
                      <h3 style={{ fontSize: "18px" }}>
                        {" "}
                        <i className="fa fa-map-marker"></i> Select City
                      </h3>
                    </span>
                  </label>

                  <div className="form-group mb-3">
                    <select
                      {...register("district", { required: true })}
                      id="district"
                      disabled={formSubmitting}
                      name="district"
                      className="form-control"
                    >
                      <option value="">Select District</option>
                    </select>
                  </div>
                  {errors.district && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className={`${
                  previewImage === null ? "" : "col-md-2"
                } form-group`}
              >
                {previewImage && (
                  <img
                    width={150}
                    height={100}
                    src={previewImage}
                    alt="intercity travellers"
                    srcset=""
                  />
                )}
              </div>
              <div
                className={`${
                  previewImage === null ? "col-md-6" : "col-md-4"
                } form-group`}
              >
                <div className="form-group">
                  <label>
                    <span className="wc-editable">
                      <h3 style={{ fontSize: "18px" }}>Image</h3>
                    </span>
                  </label>

                  <div className="form-group mb-3">
                    <input
                      type="file"
                      {...register("image", {
                        required: true,
                        onChange: handleImage,
                      })}
                      disabled={formSubmitting}
                      name="image"
                      className="form-control"
                    />
                  </div>
                </div>
                {errors.image && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>

              <div className="col-md-6 col-xs-12">
                <div className="form-group">
                  <label>
                    <span className="wc-editable">
                      <h3 style={{ fontSize: "18px" }}>Place Name</h3>
                    </span>
                  </label>

                  <div className="form-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="place_name"
                      disabled={formSubmitting}
                      {...register("place_name", { required: true })}
                      placeholder="place name"
                    />
                  </div>
                </div>
                {errors.place_name && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>

              <div className="col-md-6 form-group">
                <label htmlFor="place_type">
                  Place Type <span className="text-danger">*</span>
                </label>
                <select
                  {...register("place_type", { required: true })}
                  className="text-capitalize form-control"
                  name="place_type"
                >
                  <option selected disabled>
                    Select Place Type
                  </option>
                  {PlaceCategories.map((item, index) => (
                    <option key={index} value={item.toLowerCase()}>
                      {item}
                    </option>
                  ))}
                </select>
                {errors.place_type && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>

              <div className="col-md-6 col-xs-12">
                <div className="form-group">
                  <label>
                    <span className="wc-editable">
                      <h3 style={{ fontSize: "18px" }}>Address</h3>
                    </span>
                  </label>

                  <div className="form-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      disabled={formSubmitting}
                      name="address"
                      {...register("address", { required: true })}
                      placeholder="place Address"
                    />
                  </div>
                </div>
                {errors.address && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>

              <div className="col-md-6 col-xs-12">
                <div className="form-group">
                  <label>
                    <span className="wc-editable">
                      <h3 style={{ fontSize: "18px" }}>Name of the District</h3>
                    </span>
                  </label>

                  <div className="form-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      disabled={formSubmitting}
                      name="slug"
                      {...register("slug", {
                        required: true,
                      })}
                      placeholder="Type the Name of the District you have selected in lowercase e.g lusaka"
                    />
                  </div>
                </div>
                {errors.slug && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>

              <div className="col-md-6 col-xs-12">
                <div className="form-group">
                  <label>
                    <span className="wc-editable">
                      <h3 style={{ fontSize: "18px" }}>Facebook Page Name</h3>
                    </span>
                  </label>

                  <div className="form-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      disabled={formSubmitting}
                      name="facebook_url"
                      {...register("facebook_url", {
                        required: true,
                      })}
                      placeholder="facebook page name e.g https://facebook.com/intercitytravellers"
                    />
                  </div>
                </div>
                {errors.facebook_url && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>

              <div className="col-md-12 col-xs-12">
                <div className="form-group">
                  <label>
                    <span className="wc-editable">
                      <h3 style={{ fontSize: "18px" }}>About The Place</h3>
                    </span>
                  </label>

                  <div className="form-group mb-3">
                    <textarea
                      type="text"
                      cols="12"
                      rows="8"
                      disabled={formSubmitting}
                      className="form-control"
                      name="about_place"
                      {...register("about_place", { required: true })}
                      placeholder="about the place"
                    ></textarea>
                  </div>
                </div>
                {errors.about_place && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>
            <div className="form-group pull-right">
              <button
                className="btn btn-primary form-control"
                disabled={formSubmitting}
              >
                {formSubmitting && (
                  <span className="wc-editable">
                    <i className="fa fa-refresh fa-spin" /> Submitting...
                  </span>
                )}
                {!formSubmitting && <span className="wc-editable">Submit</span>}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddPlace;
