import React from "react";
import dashboard from "../../../images/logo.png";
import product from "../../../assets/img/icons/product.svg";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "../../../hooks/redux-hooks";

const SideBar: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const location = useLocation();
  return (
    <>
      <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li className={`${location.pathname === "/zm/hotel_dashboard/" ? "active" : ""}`}>
                <Link to={`/zm/hotel_dashboard/?uid=${user?.id}`}>
                  <img src={dashboard} alt="img" />
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className="submenu">
                <a href="javascript:void(0);">
                  <img src={product} alt="img" />
                  <span>Branches</span>
                  <span className="menu-arrow"></span>
                </a>
                <ul>
                  <li>
                    <Link className={`${location.pathname === "/zm/hotel_dashboard/view_hotels/" ? "active" : ""}`} to={`/zm/hotel_dashboard/view_hotels/?hotel=${user?.company_name}`}>View Branches</Link>
                  </li>
                </ul>
              </li>
              <li className="submenu">
                <a href="javascript:void(0);">
                  <i className="fa fa-hotel"></i>
                  <span>Rooms</span>
                  <span className="menu-arrow"></span>
                </a>
                <ul>
                  <li>
                    <Link className={`${location.pathname === "/zm/hotel_dashboard/view_hotel_rooms" ? "active" : ""}`} to={`/zm/hotel_dashboard/view_hotel_rooms`}>View Rooms</Link>
                  </li>
                </ul>
              </li>
              <li className="submenu">
                <a href="javascript:void(0);">
                  <i className="fa fa-book"></i>
                  <span>Reservations</span>
                  <span className="menu-arrow"></span>
                </a>
                <ul>
                  <li>
                    <Link className={`${location.pathname === "/zm/hotel_dashboard/hotel_reservations" ? "active" : ""}`} to={`/zm/hotel_dashboard/hotel_reservations`}>View Reservations</Link>
                  </li>
                </ul>
              </li>
              <li className="submenu">
                <a href="javascript:void(0);">
                  <i className="fa fa-users"></i>
                  <span>Customers</span>
                  <span className="menu-arrow"></span>
                </a>
                <ul>
                  <li>
                    <Link className={`${location.pathname === "/zm/hotel_dashboard/customers" ? "active" : ""}`} to={`/zm/hotel_dashboard/customers/`}>View Customers</Link>
                  </li>
                </ul>
              </li>
              <li className="submenu">
                <a href="javascript:void(0);">
                  <i className="fa fa-book"></i>
                  <span>Terms & Privacy</span>
                  <span className="menu-arrow"></span>
                </a>
                <ul>
                  <li>
                    <Link className={`${location.pathname === "/zm/hotel_dashboard/terms_of_service" ? "active" : ""}`} to={`/zm/hotel_dashboard/terms_of_service`}>View Terms of Service</Link>
                  </li>
                  <li>
                    <Link className={`${location.pathname === "/zm/hotel_dashboard/privacy_policy" ? "active" : ""}`} to={`/zm/hotel_dashboard/privacy_policy`}>View Privacy Policy</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
