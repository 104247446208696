import React, { useState } from "react";
import { toast } from "react-toastify";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { IRoomNumber } from "../../../redux/types/room_numbers.type";
import { useUpdateRoomNumberMutation } from "../../../redux/slices/room_numbers_slice";
import { useForm } from "react-hook-form";
import { Modal, Row } from "react-bootstrap";

interface IRoomNumberData {
  roomData: IRoomNumber;
}

function UpdateRoomNumber({ roomData }: IRoomNumberData) {
  const [updateRoom, { isLoading }] = useUpdateRoomNumberMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleUpdateRoom = (formData: any) => {    
    updateRoom({ id: roomData.id, ...formData })
      .unwrap()
      .then((res) => {
        reset();
        setShow(false);
        toast.success("Room Details Updated Successfully");
      })
      .catch((err) => {
        toast.error("Failed to Update:" + err.error);
      });
  };
  return (
    <>
      <button
        onClick={handleShow}
        data-bs-toggle="modal"
        data-bs-target="#confirmBookModal"
        className="btn btn-secondary border-0 btn-sm text-white">
        <i className="fa fa-pencil-square"></i>
      </button>
      <Modal
        backdrop="static"
        keyboard={false}
        centered
        scrollable={true}
        show={show}
        onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Room Number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(handleUpdateRoom)}>
            <Form.Group className="mb-3" controlId="validationCustom03">
              <Form.Label>Room Number</Form.Label>
              <Form.Control
                type="input"
                disabled={isLoading}
                min={1}
                defaultValue={roomData.roomNumber}
                {...register("roomNumber", { required: true })}
                className={`form-control ${
                  errors.roomNumber ? "border-danger" : ""
                }`}
                placeholder="Room Number"
              />
              {errors.roomNumber && (
                <p className="text-danger">This is required.</p>
              )}
            </Form.Group>
            <Row>
              <Form.Group as={Col} md="6">
                <Form.Label>Room Reserved?</Form.Label>
                <Form.Check
                  defaultChecked={roomData.roomReserved}
                  {...register("roomReserved", { required: true })}
                  type="switch"
                  id="custom-switch"
                  label={roomData.roomReserved ? "Yes" : "No"}
                />
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label>Room Under Cleaning?</Form.Label>
                <Form.Check
                  defaultChecked={roomData.underClearning}
                  {...register("underClearning", { required: true })}
                  type="switch"
                  id="custom-switch"
                  label={roomData.underClearning ? "Yes" : "No"}
                />
              </Form.Group>
            </Row>
            <button type="submit" className="btn btn-submit me-2">
              {isLoading && (
                <div className="spinner-border spinner-border-sm text-danger"></div>
              )}{" "}
              Update Room
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default UpdateRoomNumber;
