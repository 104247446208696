import React from "react";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { useGetUserCarRentalsQuery } from "../../../redux/slices/user_slice";
import { ICarBooking } from "../../../redux/types/car_booking.type";

const CarRentals: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [status, setStatus] = React.useState<string>("");
  const {
    data: cars,
    isLoading,
    isFetching,
    error,
  }: ICarBooking | any = useGetUserCarRentalsQuery({
    client: user?.id,
    status: status,
  });

  if (isLoading || isFetching) {
    return (
      <div className="text-center">
        <i className="fa fa-spinner fa-spin fa-2x text-danger"></i>
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger text-center mt-5" role="alert">
        {error.status}
      </div>
    );
  }

  return (
    <>
      <h3 className="text-center mt-5">My Car Rentals</h3>
      <div className="row mt-5">
        {cars.map((item: ICarBooking, index: number) => {
          return (
            <div key={index} className="col-md-4 rounded shadow">
              <img
                style={{ width: "100%", height: "fit-content" }}
                className="rounded"
                src={`${process.env.REACT_APP_API_URL}/${item.car.car_images[0].image}`}
                alt={item.car.car_model}
              />
              <h5 className="text-capitalize">
                Car: <b>{item.car.car_model},</b>{" "}
                <span className="text-warning">
                  {item.company.company_name}
                </span>{" "}
              </h5>
              <h5 className="text-capitalize">
                Transmission: <b>{item.car.gear_type}</b>
              </h5>
              <h5 className="text-capitalize">
                Rental Price: <b>ZMW {item.rental_price.toLocaleString()}</b>
              </h5>
              <div className="row mb-3">
                <div className="col-md-8">
                  <h5 className="text-capitalize">
                    Days: <b>{item.days}</b>
                  </h5>
                </div>
                <div className="col-md-4">
                  <h6 className="badges bg-lightred text-white">
                    {item.status}
                  </h6>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CarRentals;
