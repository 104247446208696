export const container = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { staggerChildren: 0.5 } },
    visible: { opacity: 1, scale: 4, transition: { duration: 1.5 } },
    hidden: { opacity: 0, scale: 0 },
    exit: {
      x: "-12rem",
      opacity: 0,
      transition: {
        staggerChildren: 0.25,
        staggerDirection: -1,
        when: "afterChildren",
      },
    },
  };

  export  const capitalized = (word) => {
    if (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }

    return "";
  };