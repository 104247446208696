import React, { useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Swal from "sweetalert2";
import Clock from "./clock";
import bg1 from "../../images/bus.jpg";
import { Helmet } from "react-helmet";

function SearchBus() {
  const currentDate = moment().format("MMMM Do, YYYY");
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [search, setSearch] = React.useState([]);
  const [toRoutes, setToRoutes] = React.useState([]);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handlePostData = async (data) => {
    // event.preventDefault();
    setFormSubmitting(true);
    console.log(data);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/my-tickets/${data.from_route}/${data.to_route}/${data.travel_date}/rmf_travellers`
      )
      .then((response) => {
        const res = response.data;
        res.map((results) => {
          console.log(data.trave_date, results.departure_date);
          if (
            data.from_route === results.from_route &&
            data.to_route === results.to_route
          ) {
            setTimeout(() => {
              window.location.href = `/zm/reservations/?from_route=${results.from_route}&to_route=${results.to_route}&travel_date=${data.travel_date}`;
              setFormSubmitting(false);
            }, 100);
          }
        });
        if (res.length === 0) {
          Toast.fire({
            icon: "error",
            title: "No Results found, try a different search",
          });
        }
      })
      .catch((e) => {
        console.log(e);
        Toast.fire({
          icon: "error",
          title: "No Results found, try a different search",
        });
      });

    //Faking API call here
    setTimeout(() => {
      setFormSubmitting(false);
    }, 2000);
  };

  const fetchData = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/get-search/rmf_travellers`)
        .then((sear) => {
          setSearch(sear.data);
        });
    } catch (error) {}
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/get-to-search/rmf_travellers`)
        .then((sear) => {
          setToRoutes(sear.data);
        });
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, [currentDate]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Intercity Travellers | Buses</title>
      </Helmet>
      <section
        style={{
          background: `url(${bg1})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        id="hero"
        className="d-flex py-5 align-items-center"
      >
        <div
          className="container position-relative text-lg-start"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div className="row">
            <div className="col-lg-12 mt-5">
              <h1 className="text-uppercase text-center mt-5 text-white">
                Welcome to Intercity Travellers
              </h1>
              <p className="text-white"></p>
              <h2 className="text-center text-white mb-2">
                Choose Your Destinations And Date To Reserve A Ticket
              </h2>
              <div className="mb-5">
                <div className="container p-5">
                  <form onSubmit={handleSubmit(handlePostData)}>
                    {/*districts*/}
                    <div className="row">
                      <div className="col-md-6 col-xs-12">
                        <div className="form-group search-field">
                          <label>
                            <span
                              style={{ color: "#ffffff" }}
                              className="wc-editable"
                            >
                              <h3 style={{ fontSize: "18px" }}>
                                {" "}
                                <i className="fa fa-map-marker"></i> From City
                              </h3>
                            </span>
                          </label>

                          <div className="form-group mb-3">
                            <select
                              {...register("from_route", { required: true })}
                              disabled={formSubmitting}
                              name="from_route"
                              className="form-control text-capitalize"
                              style={{
                                background: "rgba(204, 206, 206, 0.25)",
                                border: "none",
                                color: "white",
                              }}
                            >
                              <option value="">
                                select the city where you are coming from
                              </option>
                              {search.map((item, index) => (
                                <option
                                  key={index}
                                  className="text-dark"
                                  value={item.from_route}
                                >
                                  {item.from_route}
                                </option>
                              ))}
                            </select>
                            {errors.from_route && (
                              <p className="text-danger">
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-xs-12 cl-col-2of2">
                        <div className="form-group search-field">
                          <label style={{ color: "#ffffff" }}>
                            <span className="wc-editable">
                              <h3 style={{ fontSize: "18px" }}>
                                {" "}
                                <i className="fa fa-map-marker"></i> To City
                              </h3>
                            </span>
                          </label>

                          <div className="form-group mb-5">
                            <select
                              {...register("to_route", { required: true })}
                              disabled={formSubmitting}
                              name="to_route"
                              className="form-control border-none text-capitalize"
                              style={{
                                background: "rgba(204, 206, 206, 0.25)",
                                border: "none",
                                color: "white",
                              }}
                            >
                              <option value="">
                                select the city where you are going
                              </option>
                              {toRoutes.map((item, index) => (
                                <option
                                  key={index}
                                  className="text-dark"
                                  value={item.to_route}
                                >
                                  {item.to_route}
                                </option>
                              ))}
                            </select>
                            {errors.to_route && (
                              <p className="text-danger">
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>
                        <label style={{ color: "#ffffff" }}>
                          <span className="wc-editable">
                            <h3 style={{ fontSize: "18px" }}>
                              {" "}
                              <i className="fa fa-calendar"></i> Date of Travel
                            </h3>
                          </span>
                        </label>
                        <h4 className="text-warning text-bold mb-3">
                          <Clock /> - {currentDate}
                        </h4>

                        <input
                          {...register("travel_date", { required: true })}
                          name="travel_date"
                          disabled={formSubmitting}
                          type="date"
                          id="datepicker"
                          placeholder="date of travel"
                          className="form-control"
                          style={{
                            background: "rgba(204, 206, 206, 0.25)",
                            border: "none",
                            color: "white",
                          }}
                        />
                        {errors.travel_date && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>

                      <center>
                        <div className="text-center from-group mt-4 mb-5">
                          <div className="col-md-6 pull-right">
                            <button
                              className="btn btn-primary form-control"
                              disabled={formSubmitting}
                            >
                              {formSubmitting && (
                                <span className="wc-editable">
                                  <i
                                    className="fa fa-refresh fa-spin"
                                    style={{ marginRight: "5px" }}
                                  />{" "}
                                  Searching ....
                                </span>
                              )}
                              {!formSubmitting && (
                                <span className="wc-editable">
                                  <i
                                    className="fa fa-search"
                                    style={{ marginRight: "5px" }}
                                  />
                                  Check Availability
                                </span>
                              )}
                            </button>
                          </div>
                        </div>
                      </center>
                    </div>
                    {/*districts*/}
                  </form>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 d-flex align-items-center justify-content-center position-relative" data-aos="zoom-in" data-aos-delay="200">
      
      <a style={{ cursor: 'pointer' }} data-bs-toggle="modal" data-bs-target="#exampleModal" className="glightbox play-btn"></a>
    </div> */}
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-fullscreen">
            <div
              style={{ background: "rgba(0,0,0,0.7)" }}
              className="modal-content"
            >
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  How to Travel With Intercity Travellers
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  style={{ color: "white" }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  X
                </button>
              </div>
              <div className="modal-body">
                <div className="centerAll">
                  {/* <video width="600" height="500" controls src={batman}></video> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SearchBus;
