import React from "react";
import axios from "axios";
import ReactHtmlParser from "html-react-parser";

import LoadingSpinner from "../../components/widgets/spinner";

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      provinces: [],
      formSubmitting: false,
      isSubmitting: false,
      isLoading: true,
    };
  }
  componentDidMount = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/home/rmf_travellers`,
        config
      )
      .then((res) => {
        const provinces = res.data;
        this.setState({
          provinces,
          isLoading: false,
        });
      });
  };

  render() {
    return (
      <>
        
        <div className="d-flex px-3">
          {this.state.isLoading ? (
            <>
              <LoadingSpinner />
            </>
          ) : (
            <div id="content">
              <h1 className="text-center mb-4">Provinces</h1>
              <div className="row">
                {this.state.provinces.map((province, index) => (
                  <div
                    className="col-md-4 col-sm-4 col-lg-4 mb-4 display-img"
                    key={index}>
                    <img
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                        height: "200px",
                      }}
                      src={province.thumbnail}
                      className="tour-img"
                      alt={province.province_name}
                    />
                    <div className="">
                      <a
                        className="text-capitalize"
                        href={`/zm/intercity_travellers_admin/pro/${province.slug}/${province.id}`}>
                        {province.province_name}
                      </a>
                    </div>
                    <div className="text-capitalize">
                      {ReactHtmlParser(
                        province.about_province.substring(0, 150)
                      )}
                      <a
                        className="text-capitalize"
                        href={`/zm/intercity_travellers_admin/pro/${province.slug}/${province.id}`}>
                        {" "}
                        Read More
                      </a>
                      <br />
                      <a
                        className="btn btn-sm btn-info pull-right"
                        href={`/zm/intercity_travellers_admin/edit/${province.slug}/${province.id}`}>
                        {" "}
                        <i className="fa fa-edit"></i> Edit
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default Admin;
