import React from "react";
import bg1 from "../../images/hero.jpg";
import tree from "../../images/tree.jpg";
import WeAre from "../widgets/weAre";
import { Reveal } from "../../reveal";

const AboutUs = () => {
  return (
    <>
      <section
        className="hero-wrap hero-wrap-2 js-fullheight"
        style={{ backgroundImage: `url(${bg1})`, height: 'fit-content' }}
      >
        <div style={{height: 'fit-content'}} className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
            <div className="col-md-9 ftco-animate pb-5 text-center">
              <p className="breadcrumbs">
                <span className="mr-2">
                  <a href="/">
                    Home <i className="fa fa-chevron-right"></i>
                  </a>
                </span>{" "}
                <span>
                  About Us <i className="fa fa-chevron-right"></i>
                </span>
              </p>
              <h1 className="mb-3 bread">About Us</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="ftco-section mt-5 ftco-about ftco-no-pt img">
        <div className="container">
          <div className="row d-flex">
            <div className="col-md-12 about-intro">
              <div className="row">
                <div className="col-md-6 d-flex align-items-stretch">
                  <div
                    className="img d-flex w-100 align-items-center justify-content-center"
                    style={{ backgroundImage: `url(${bg1})` }}
                  ></div>
                </div>
                <div className="col-md-6 pl-md-5 py-5">
                  <div className="row justify-content-start pb-3">
                    <div className="col-md-12 heading-section ftco-animate">
                      <span className="subheading">About Us</span>
                      <h2 className="mb-4">Convenience at it's Best</h2>
                      <p>
                        Intercity Travelers is Zambia's best online bus search
                        engine which provides the best Real-Time, Cloud Based,
                        Integrated Bus Ticketing and Hotel Management System
                        developed by REUMAIFAB TECH. The Technology has been
                        built with a vision to revolutionize the intercity bus
                        market in Zambia and around Central and Southern Africa.
                        Moving forward, we plan to grow and expand our network
                        to help users travel no matter where they are or want to
                        go. Our vision is to become Africa's leading bus search
                        engine and change the way people travel using public
                        buses. The ticketing system allows the Bus Service
                        provider to carry out online ticket sales using portable
                        devices such as tablets and also extends an easy to use
                        and safe online platform for travelers to purchase their
                        tickets from the comfort of their homes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Reveal>
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div
                className="col-lg-6 order-1 order-lg-2"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div className="about-img">
                  <img src={tree} alt="" />
                </div>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                <h3 className="mb-4">
                  Enjoy the following exclusive features while setting up your
                  trip or on the go;
                </h3>
                <ul>
                  <li>
                    <i className="fa fa-check-circle"></i>{" "}
                    <small>
                      Explore your desired travel destination, see what is
                      amazing about it.
                    </small>
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>{" "}
                    <small>
                      Choose the bus passing from your nearest boarding location
                      and book in a simple easy process.
                    </small>
                  </li>
                  {/* <li>
                    <i className="fa fa-check-circle"></i>{" "}
                    <small>Book an Ulendo Taxi Using your Dashboard.</small>
                  </li> */}
                  <li>
                    <i className="fa fa-check-circle"></i>{" "}
                    <small>
                      Make a Hotel, Lodge and Guest House reservations within
                      your dashboard.
                    </small>
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>{" "}
                    <small>
                      Make all payments online using Visa, Airtel, MTN and
                      Zamtel.
                    </small>
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>{" "}
                    <small>
                      Keep Track of all New and past Bus Booking receipts.
                    </small>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </Reveal>

      <Reveal>
        <section id="why-us" className="why-us">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Why Us</h2>
              <p>Why Choose IntercityTravel</p>
            </div>

            <div className="row">
              <div className="col-lg-12 mb-3">
                <div className="box" data-aos="zoom-in" data-aos-delay="100">
                  <h4>Why Choose Intercity Travellers?</h4>
                  <p>
                    Book bus travel at anytime, anywhere and be able to compare
                    the schedules of the Bus tickets online for your next trip.
                    For the best price, shortest travel time duration or a
                    specific route intercity Travelers gives you the best
                    options.
                  </p>
                  <p>
                    Plus be able to customize your own trip/trips. Whether you
                    are looking for the best price, the shortest travel
                    duration, or a specific route book intercity bus tickets in
                    one place
                  </p>
                </div>
              </div>

              <div className="col-lg-4 mt-4 mt-lg-0">
                <div className="box" data-aos="zoom-in" data-aos-delay="200">
                  <h4>Time Save</h4>
                  <p>
                    Save time when making a booking online, you can buy multiple
                    tickets & you’re picked from your nearest pickup point
                  </p>
                </div>
              </div>

              <div className="col-lg-4 mt-4 mb-3 mt-lg-0">
                <div className="box" data-aos="zoom-in" data-aos-delay="300">
                  <h4>Smart Travel</h4>
                  <p>
                    Know your destination before you get there, travel smart
                    like a VIP and have fun on the go with the best Luxury bus
                    operators in the country.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 mt-4 mt-lg-0">
                <div className="box" data-aos="zoom-in" data-aos-delay="300">
                  <h4>Travel Safe</h4>
                  <p>
                    Know your pickup personnel even before arrival and know your
                    reserved Hotel, Lodge or Gust House booked. We take your
                    security serious
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Reveal>
      <WeAre />
    </>
  );
};
export default AboutUs;
