import React from "react";
import dashboard from "../../../images/logo.png";
import product from "../../../assets/img/icons/product.svg";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { urlToken } from "../../../helper";
import sidebarNav from "../configs/sidebarNav";

const SideBar: React.FC = () => {
  const location = useLocation();
  const user = useAppSelector((state) => state.auth.user);
  function randomString(length: number, chars: string) {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  var token = randomString(100, `${localStorage.getItem("access")}`);
  return (
    <>
      <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li className={`${location.pathname === "/zm/dashboard/" ? "active" : ""}`}>
                <Link to={`/zm/dashboard/?uid=${user?.id}`}>
                  <img src={dashboard} alt="img" />
                  <span>Dashboard</span>
                </Link>
              </li>
              
                {sidebarNav.map((nav, index) => {
                  return <li className={`${location.pathname === `${nav.link}/${urlToken}/${user?.id}` ? "active" : ""}`}>
                  <Link to={`${nav.link}/${urlToken}/${user?.id}`}>
                  {nav.icon}
                    <span>{nav.text}</span>
                    <span className="menu-arrow"><i className="fa fa-arrow-right"></i></span>
                  </Link>
                  </li>
                })}
                
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
