import React, { useState } from "react";

import axios from "axios";
import Swal from "sweetalert2";
import { Modal, Spinner } from "react-bootstrap";
import { PlaceCategories } from "../../components/data/place_categories";

function UpdatePlace({ show, handleClose, places }) {
  const [previewImage, setPreviewImage] = useState(null);
  const [image, setImage] = useState(null);
  const [formsubmitting, setFormSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    place_name: places.place_name,
    place_type: places.place_type,
    about_place: places.about_place,
    image: places.image,
    facebook_url: places.facebook_url,
    address: places.address,
  });

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImage = (e) => {
    setImage(e.target.files[0]);
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
  };

  const HandleUpdatePlace = async (e) => {
    e.preventDefault();
    setFormSubmitting(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    let form_data = new FormData();
    // form_data.append("image", formData.image);
    form_data.append("place_type", formData.place_type);
    form_data.append("place_name", formData.place_name);
    form_data.append("facebook_url", formData.facebook_url);
    form_data.append("about_place", formData.about_place);
    form_data.append("address", formData.address);

    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/update_place/${places.id}/rmf_travellers`,
        form_data,
        config
      )
      .then((response) => {
        Swal.fire("Successful", "Place Updated Successfully!", "success");
        setFormSubmitting(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setFormSubmitting(false);
        Swal.fire("Error", `${error.message}`, "error");
      });
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="xl"
        scrollable={true}
        keyboard={false}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Place</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={HandleUpdatePlace}>
            <div className="container">
              <div className="row">
                <div
                  className={`${
                    previewImage === null ? "" : "col-md-2"
                  } form-group`}
                >
                  {previewImage && (
                    <img
                      width={150}
                      height={100}
                      src={previewImage}
                      alt="intercity travellers"
                      srcset=""
                    />
                  )}
                </div>
                <div
                  className={`${
                    previewImage === null ? "col-md-6" : "col-md-4"
                  } form-group`}
                >
                  <div className="form-group">
                    <label>
                      <span className="wc-editable">
                        <h3 style={{ fontSize: "18px" }}>Image</h3>
                      </span>
                    </label>

                    <div className="form-group mb-3">
                      <input
                        type="file"
                        onChange={handleImage}
                        disabled={formsubmitting}
                        name="image"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-xs-12">
                  <div className="form-group">
                    <label>
                      <span className="wc-editable text-dark">
                        <h3 style={{ fontSize: "18px" }}>Place Name</h3>
                      </span>
                    </label>

                    <div className="form-group mb-3">
                      <input
                        type="text"
                        name="place_name"
                        defaultValue={places.place_name}
                        className="form-control"
                        onChange={(e) => onChange(e)}
                        placeholder="place name"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-xs-12">
                  <div className="form-group">
                    <label>
                      <span className="wc-editable text-dark">
                        <h3 style={{ fontSize: "18px" }}>Place Type</h3>
                      </span>
                    </label>

                    <div className="form-group mb-3">
                      <select
                        required
                        className="text-capitalize form-control"
                        name="place_type"
                        onChange={(e) => onChange(e)}
                      >
                        <option defaultValue={places.place_type} selected>
                          {places.place_type}
                        </option>
                        {PlaceCategories.map((item, index) => (
                          <option key={index} value={item.toLowerCase()}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-xs-12">
                  <div className="form-group">
                    <label>
                      <span className="wc-editable text-dark">
                        <h3 style={{ fontSize: "18px" }}>Address</h3>
                      </span>
                    </label>

                    <div className="form-group mb-3">
                      <input
                        type="text"
                        name="address"
                        className="form-control"
                        defaultValue={places.address}
                        onChange={(e) => onChange(e)}
                        placeholder="place Address"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-12 col-xs-12">
                  <div className="form-group">
                    <label>
                      <span className="wc-editable text-dark">
                        <h3 style={{ fontSize: "18px" }}>Facebook Page Name</h3>
                      </span>
                    </label>

                    <div className="form-group mb-3">
                      <input
                        type="text"
                        name="facebook_url"
                        className="form-control"
                        defaultValue={places.facebook_url}
                        onChange={(e) => onChange(e)}
                        placeholder="facebook page name e.g https://facebook.com/intercitytravellers"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-12 col-xs-12">
                  <div className="form-group">
                    <label>
                      <span className="wc-editable text-dark">
                        <h3 style={{ fontSize: "18px" }}>About The Place</h3>
                      </span>
                    </label>

                    <div className="form-group mb-3">
                      <textarea
                        type="text"
                        name="about_place"
                        cols="12"
                        rows="8"
                        className="form-control"
                        value={formData.about_place}
                        onChange={(e) => onChange(e)}
                        placeholder="about the place"
                      >
                        {places.about_place}
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group pull-right mb-5">
                <button
                  type="submit"
                  disabled={formsubmitting}
                  className="btn btn-success"
                >
                  {formsubmitting ? (
                    <center>
                      <Spinner /> Updating ...
                    </center>
                  ) : (
                    "Update Place"
                  )}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UpdatePlace;
