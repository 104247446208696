import React from "react";
import bg2 from "../../images/city.jpg";

const WeAre = () => {
  return (
    <section className="ftco-intro ftco-section ftco-no-pt">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <div className="img" style={{ backgroundImage: `url(${bg2}` }}>
              <div className="overlay"></div>
              <h2>We Are Intercity Travellers</h2>
              <p>Let's take you there</p>
              {/* <p className="mb-0">
                <a href="#" className="btn btn-primary px-4 py-3">
                  Ask For A Quote
                </a>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default WeAre;
