import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import appleStore from "../../../images/app-store.png";
import google from "../../../images/google-play.png";
import LaunchingSoon from "../../widgets/launching_soon";

function RouteDetails() {
  const [showApple, setShowApple] = useState(false);

  const handleCloseApple = () => setShowApple(false);
  const handleShowApple = () => setShowApple(true);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="col-md-4">
      <Button className="btn btn-primary" onClick={handleShow}>
       <i className="fa fa-info"></i> Book Now
      </Button>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Intercity Travellers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>The website isn't accepting payments currently.</p>
          <p>Download the App to enjoy the most of Intercity Travellers</p>{" "}
          <br />
          <div className="row">
            <div className="col-md-6">
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.reumaifab.intercitytravellers"
                className="py-2 d-block"
              >
                <img
                  className="text-warning"
                  src={google}
                  width={150}
                  alt=""
                  srcset=""
                />
              </a>
            </div>
            <div className="col-md-6">
              <a
                onClick={handleShowApple}
                style={{ cursor: "pointer" }}
                className="py-2 d-block"
              >
                <img
                  className="text-warning"
                  src={appleStore}
                  width={150}
                  alt=""
                  srcset=""
                />
              </a>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary">Download App</Button> */}
        </Modal.Footer>
      </Modal>
      <LaunchingSoon show={showApple} handleClose={handleCloseApple} />
    </>
  );
}

export default RouteDetails;
