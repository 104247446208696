import React from "react";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../hooks/redux-hooks";

const ProtectedLayout = () => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  
  React.useEffect(() => {

  }, [isAuthenticated, localStorage.getItem("access")])

  if (localStorage.getItem("access") === null) {
    return <Navigate replace to={"/zm/login"} />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default ProtectedLayout;