import React, { useEffect, useState } from "react";
import axios from "axios";
import LoadingSpinner from "../../components/widgets/spinner";
import { capitalized } from "../../actions/utils";
import AddPlace from "./add_place";
import PlaceDetailsModal from "../../components/tourism/place_details_modal";
import UpdatePlace from "./UpdatePlace";

function MaPlaces() {
  const [showEdit, setShowEdit] = useState(false);
  const [editEdetails, setEditDetails] = useState(null);
  const handleShowEdit = (place) => {
    setShowEdit(true);
    setEditDetails(place);
  };
  const handleCloseEdit = () => {
    setShowEdit(false);
    setEditDetails(null);
  };

  const [show, setShow] = useState(false);
  const [details, setDetails] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (place) => {
    setShow(true);
    setDetails(place);
  };
  const [isLoading, setIsLoading] = useState(true);
  const [provinces, setProvinces] = useState([]);
  const [places, setPlaces] = useState([]);
  const cancelToken = axios.CancelToken.source();
  const fetchData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/places/rmf_travellers`, config)
      .then((res) => {
        setIsLoading(false);
        setPlaces(res.data);
      });
    //get provinces
    await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/home/rmf_travellers`, config).then((res) => {
      setProvinces(res.data);
    });
  };
  useEffect(() => {
    fetchData();

    return () => {
      cancelToken.cancel();
    };
  }, []);

  return (
    <>
      <section>
        <div className="py-4 align-items-stretch">
          <div id="content" className="p-2 p-md-3 pt-3">
            {/* place modal */}
            <AddPlace fetchPlaces={fetchData} />
            <div className="text-center">
              <h1 className="text-center">Tourism Places </h1>
            </div>
            <div className="container-fluid mt-5">
              <div className="row">
                {isLoading ? (
                  <>
                    <LoadingSpinner />
                  </>
                ) : (
                  places.map((place, index) => (
                    <div
                      key={index}
                      className="col-md-4 col-sm-4 col-lg-4 mb-3 display-img"
                    >
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => handleShow(place)}
                      >
                        <img
                          style={{
                            borderRadius: "10px",
                            width: "100%",
                            height: "200px",
                          }}
                          src={place.image}
                          alt=""
                          className="img-fluid tour-img"
                        />
                        <h4 className="text-capitalize">
                          <b>{place.place_name}</b> <br />
                        </h4>
                      </a>
                      <h6 className="text-capitalize">
                        <small className="text-info text-center">
                          <i className="fa fa-map-marker"></i>{" "}
                          <span>{place.place_type}</span>, {place.address}
                        </small>
                      </h6>
                      <a
                        className="text-capitalize"
                        href={`https://facebook.com/${place.facebook_url}`}
                      >
                        {" "}
                        <i className="fa fa-facebook"></i> Facebook
                      </a>
                      <blockquote>
                        <i>
                          {capitalized(place.about_place.substring(0, 150))}
                          ...{" "}
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => handleShow(place)}
                            className="btn btn-sm btn-info text-white"
                          >
                            Read More
                          </a>
                        </i>
                      </blockquote>
                      <a
                        className="btn btn-sm btn-primary pull-right"
                        onClick={() => handleShowEdit(place)}
                      >
                        <i className="fa fa-edit"></i> Edit
                      </a>
                      <UpdatePlace
                        show={showEdit}
                        handleClose={handleCloseEdit}
                        places={editEdetails === null ? place : editEdetails}
                      />
                      {/* view place details modal */}
                      <PlaceDetailsModal
                        show={show}
                        handleClose={handleClose}
                        place={details === null ? place : details}
                      />
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MaPlaces;
