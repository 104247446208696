import React from "react";
import Modal from "react-bootstrap/Modal";

function DetailsModal({ show, handleClose, data }) {
  return (
    <>
      <Modal size="xl" scrollable centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="text-uppercase">{data.placeName}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-5">
              <img
                style={{ borderRadius: "8px", width: "100%", height: "400px" }}
                src={data.image}
                alt={data.placeName}
                srcset=""
              />
            </div>
            <div className="col-md-7">
              <h3 className="text-capitalize">{data.placeName}</h3>
              <p>{data.aboutPlace}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DetailsModal;
