import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../components/widgets/spinner";

const EditmaBlogs = () => {
  const [isLoading, setisLoading] = useState(true);
  const [formSubmiting, setformSubmiting] = useState(false);
  const [blog, setBlog] = useState([]);
  const [formData, setFormData] = useState({
    blog_title: "",
    blog_author: "",
    blog_body: "",
    image: null,
  });
  const { id, slug } = useParams();
  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  const handleBlogSubmit = (e) => {
    e.preventDefault();
    setformSubmiting(true);
    

    let form_data = new FormData();
    form_data.append("image", blog.image);
    form_data.append("blog_title", blog.blog_title);
    form_data.append("blog_author", blog.blog_author);
    form_data.append("blog_body", blog.blog_body);

    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/update_blog/${id}/rmf_travellers`,
        form_data,
        config
      )
      .then((response) => {
        // console.log(response.data)
        Swal.fire("Successful", "Blog Updated Successfully", "success");

        setTimeout(() => {
          window.location.href = "/reuben-blogs";
          setformSubmiting(false);
        }, 2000);
      })
      .catch((error) => {
        setformSubmiting(false);
        Swal.fire("Error", "An Error occurred try again later!", "error");
        console.error("There was an error!", error);
      });
  };

  const fetchData = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/grab_blog/${id}/rmf_travellers`, config)
      .then((res) => {
        setBlog(res.data);
        setisLoading(false);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="wrapper py-4 align-items-stretch">
      {isLoading ? (
        <div className="centerAll">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="p-2 p-md-3 pt-3">
          <div className="container">
            <h3 className="text-center text-dark mt-5 mb-5 text-capitalize">
              {" "}
              {blog.blog_title}{" "}
            </h3>

            <form onSubmit={handleBlogSubmit}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 form-group">
                    <input
                      type="text"
                      name="blog_title"
                      defaultValue={blog.blog_title}
                      className="form-control"
                      onChange={(e) =>
                        (blog.blog_title = e.target.defaultValue)
                      }
                      placeholder="Blog Title"
                    />
                  </div>
                  <div className="col-md-4 form-group">
                    <input
                      type="text"
                      name="blog_author"
                      defaultValue={blog.blog_author}
                      className="form-control"
                      onChange={(e) =>
                        (blog.blog_author = e.target.defaultValue)
                      }
                      placeholder="Blog Author"
                    />
                  </div>
                  <div className="col-md-4 form-group">
                    <input
                      type="file"
                      name="image"
                      className="form-control"
                      onChange={(e) => (blog.image = e.target.files[0])}
                      placeholder="Blog Image"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="blog_body"
                    defaultValue={blog.blog_body}
                    className="form-control"
                    cols="12"
                    rows="12"
                    onChange={(e) => (blog.blog_body = e.target.defaultValue)}
                  ></textarea>
                </div>
              </div>
              <div className="modal-footer">
                <div className="row">
                  <div className="col-md-12">
                    <button
                      className="btn btn-primary form-control"
                      disabled={formSubmiting}
                    >
                      {formSubmiting && (
                        <span className="wc-editable">
                          <i className="fa fa-refresh fa-spin" />
                          Updating ....
                        </span>
                      )}
                      {!formSubmiting && (
                        <span className="wc-editable">Update</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditmaBlogs;
