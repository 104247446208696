import React from "react";
import { IRoomReservation } from "../../../redux/types/room_reservation.type";
import LoadingSpinner from "../../../components/widgets/spinner";
import { useGetCustomerReservationQuery } from "../../../redux/slices/room_reservation_slice";
import moment from "moment";

const CustomerProfile = () => {
    const queryParams = new URLSearchParams(window.location.search);
  const customer_id = queryParams.get("customer_id");
  const {
    data: reservations,
    isLoading,
    error,
  }: IRoomReservation | any = useGetCustomerReservationQuery({customer: customer_id});

  if (isLoading) {
    return (
      <center>
        <LoadingSpinner />
      </center>
    );
  }
  if (error) {
    return (
      <div className="text-center alert alert-danger mt-5" role="alert">
        <h4>Error: {error.status}</h4>
      </div>
    );
  }

  function findDayDifference(date1: any, date2: any) {
    // always round down
    return Math.floor(Math.abs(date2 - date1) / (1000 * 60 * 60 * 24));
  }
  return (
    <>
      <section style={{ backgroundColor: "#eee" }}>
        <div className="content py-5">
          <div className="row">
            <div className="col-lg-4">
              <div className="card mb-4">
                <div className="card-body text-center">
                  <img
                    src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
                    alt="avatar"
                    className="rounded-circle img-fluid"
                    style={{ width: "150px" }}
                  />
                  <h5 className="my-3 text-capitalize">{reservations[0].user.first_name} {reservations[0].user.last_name}</h5>
                  <p className="text-muted mb-1">Full Stack Developer</p>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Full Name</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0 text-capitalize">{reservations[0].user.first_name} {reservations[0].user.last_name}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Email</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{reservations[0].user.email}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Phone</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">(097) 234-5678</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Address</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">
                        chilenje str, Lusaka, Zambia
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="card mb-4 mb-md-0">
              <div className="card-body table-responsive">
              <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>E-mail</th>
                      <th>Phone Number</th>
                      <th>Paid</th>
                      <th>Room</th>
                      <th>Date Checkin / Checkout</th>
                      <th>Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {reservations.map(
                      (room: IRoomReservation, index: number) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="text-capitalize">
                            {room.first_name} {room.last_name}
                          </td>

                          <td>{room.email}</td>
                          <td>{room.phone_number}</td>
                          <td>ZMW {room.room_price}</td>
                          <td className="text-capitalize">
                            {room.room_type} ({room.room_number})
                          </td>
                          <td>
                            {moment(room.date_checkin).format("MMM do YYYY")} -{" "}
                            {moment(room.date_checkout).format("MMM do YYYY")},{" "}
                            <small className="text-warning">
                              {findDayDifference(
                                new Date(
                                  moment().format(room.date_checkin.toString())
                                ),
                                new Date(
                                  moment().format(room.date_checkout.toString())
                                )
                              )}
                              Days
                            </small>
                          </td>
                          <td>
                            <div className="badges bg-lightred text-white text-capitalize">
                              <b>{room.status}</b>
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CustomerProfile;
