import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { signup } from "../../actions/auth";
import axios from "axios";
import { useParams } from "react-router-dom";
import { errorHandler } from "../../helper";
import Swal from "sweetalert2";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Spinner } from "react-bootstrap";

function EditCompanies() {
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const { uid } = useParams();

  useEffect(() => {
    const fetchUser = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/userprofile/v1/intercity_travellers/update_user/${uid}/rmf_travellers`)
        .then((res) => {
          setUser(res.data);
          setIsLoading(false);
        });
    };

    fetchUser();
  }, []);

  const [formData, setFormData] = useState({
    company_name: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    mechant_id: "",
    flutterwave_id: "",
    percentage_share: "",
    flutterwaveChargeType: "",
  });

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    const body = JSON.stringify({
      company_name:
        formData.company_name === "" ? user.first_name : formData.company_name,
      first_name:
        formData.first_name === "" ? user.first_name : formData.first_name,
      last_name:
        formData.last_name === "" ? user.last_name : formData.last_name,
      email: user.email,
      phone: formData.phone === "" ? user.phone : formData.phone,
      mechant_id:
        formData.mechant_id === "" ? user.mechant_id : formData.mechant_id,
      flutterwave_id:
        formData.flutterwave_id === ""
          ? user.flutterwave_id
          : formData.flutterwave_id,
      percentage_share:
        formData.percentage_share === ""
          ? user.percentage_share
          : formData.percentage_share,
      flutterwaveChargeType:
        formData.flutterwaveChargeType === ""
          ? user.flutterwaveChargeType
          : formData.flutterwaveChargeType,
    });

    try {
      await axios
        .patch(
          `${process.env.REACT_APP_API_URL}/userprofile/v1/intercity_travellers/update_user/${uid}/rmf_travellers`,
          body,
          config
        )
        .then((res) => {
          console.log(res.data);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Accounted Update Successfully",
          });
        })
        .catch((er) => {
          console.log(er.message);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: errorHandler(er),
          });
        });
    } catch (err) {
      console.log(err.message);
    }
    setLoading(false);
    setValidated(true);
  };

  return (
    <>
      <div className="container">
        <div className="inner p-2 p-md-3 pt-3">
          <h4 className="text-center mt-5 mb-3">Update Account</h4>
          {error && (
            <div className="alert alert-warning">
              <i className="fa fa-warning"></i> {error}
            </div>
          )}
          {isLoading ? (
            <center>
              <Spinner />
            </center>
          ) : (
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => onSubmit(e)}
            >
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Company Name*"
                    name="company_name"
                    defaultValue={user.company_name}
                    onChange={(e) => onChange(e)}
                    disabled={loading}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Company Name is required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom02">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First Name*"
                    name="first_name"
                    defaultValue={user.first_name}
                    onChange={(e) => onChange(e)}
                    disabled={loading}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    First Name is required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Last Name*"
                    name="last_name"
                    defaultValue={user.last_name}
                    onChange={(e) => onChange(e)}
                    disabled={loading}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Last Name is required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email*"
                    readOnly
                    name="email"
                    defaultValue={user.email}
                    disabled={loading}
                    onChange={(e) => onChange(e)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    E-mail is required
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="validationCustom05">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Phone Number *"
                    name="phone"
                    defaultValue={user.phone}
                    disabled={loading}
                    onChange={(e) => onChange(e)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Phone Number is required
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="validationCustom08">
                  <Form.Label>SamPay Mechant ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Sampay Mechant ID *"
                    name="mechant_id"
                    disabled={loading}
                    defaultValue={user.mechant_id}
                    onChange={(e) => onChange(e)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    SamPay Mechant ID is required
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="validationCustom09">
                  <Form.Label>Flutterwave ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Flutterwave Mechant ID *"
                    name="flutterwave_id"
                    defaultValue={user.flutterwave_id}
                    disabled={loading}
                    onChange={(e) => onChange(e)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Flutterwave ID is required
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="validationCustom010">
                  <Form.Label>RMF Percentage Share</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="RMF Percentage Share *"
                    name="percentage_share"
                    min="0"
                    disabled={loading}
                    defaultValue={user.percentage_share}
                    onChange={(e) => onChange(e)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Percentage Share is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Form.Group controlId="validationCustom011">
                <Form.Label>Charge Type</Form.Label>
                <Form.Select
                  name="flutterwaveChargeType"
                  onChange={(e) => onChange(e)}
                  size="lg"
                  disabled={loading}
                  required
                >
                  <option defaultValue={user.flutterwaveChargeType}>
                    {user.flutterwaveChargeType}
                  </option>
                  <option value="percentage">Percentage Share</option>
                  <option value="flat">Flat Share</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Percentage Share
                </Form.Control.Feedback>
                <div className="form-group mt-3 col-md-4 pull-right">
                  <button
                    disabled={loading}
                    className="btn btn-primary form-control"
                    type="submit"
                  >
                    {loading ? (
                      <center>
                        <i className="fa fa-refresh fa-spin"></i> Updating
                        Account....
                      </center>
                    ) : (
                      "Update Company"
                    )}
                  </button>
                </div>
              </Form.Group>
            </Form>
          )}
          {/* <div className='row'>
                <div className='col-md-6'>
                    <button className='btn btn-danger mt-3' onClick={continueWithGoogle}>
                <i className='fa fa-google'></i> Continue With Google
                    </button>
                </div>
                <div className='col-md-6'>
                    
            <button className='btn btn-primary mt-3' onClick={continueWithFacebook}>
            <i className='fa fa-facebook'></i> Continue With Facebook
            </button>
                </div>
            </div> */}
          {/* <p className='mt-3'>
                Already have an account? <Link to='/login'>Sign In</Link>
            </p> */}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { signup })(EditCompanies);
