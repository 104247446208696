import React from "react";
import Calendar from "../../calendar/calendar";
import { ICarBooking } from "../../../redux/types/car_booking.type";
import WelcomeDisplay from "../widgets/welcome_display";
import { useAppSelector } from "../../../hooks/redux-hooks";
import {
  useGetAdminCarsQuery,
  useGetAdminConfirmedBookingsQuery,
  useGetAdminPendingBookingsQuery,
} from "../../../redux/slices/admin_hire_slice";
import { useGetCarCustomersQuery } from "../../../redux/slices/car_booking_slice";
import { ICarRental } from "../../../redux/types/car_rental.type";
import moment from "moment";

const CarHomePage: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const {
    data: reservations,
    isLoading,
    error,
  }: ICarBooking | any = useGetAdminPendingBookingsQuery();
  const {
    data: confirmedReservations,
    isLoading: fetchingConfirmed,
  }: ICarBooking | any = useGetAdminConfirmedBookingsQuery();
  const { data: customers, isLoading: fetchingCustomers }: ICarBooking | any =
    useGetCarCustomersQuery({
      company: user?.id,
    });

  const { data: cars, isLoading: fetchingCars }: ICarRental | any =
    useGetAdminCarsQuery({
      company: user?.id,
    });

  if (isLoading || fetchingCustomers || fetchingConfirmed || fetchingCars) {
    return (
      <div className="text-center mt-5">
        <i className="fa fa-spinner fa-spin fa-5x text-danger"></i>
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger text-center mt-5" role="alert">
        An error occurred while loading the application
      </div>
    );
  }

  let ticketAmount = reservations.map((item: ICarBooking) => item.rental_price);

  const total_amount = ticketAmount.reduce(function (acc: number, val: number) {
    return acc + val;
  }, 0);

  let confirmedAmount = confirmedReservations.map(
    (item: ICarBooking) => item.rental_price
  );

  const totalConirmedAmount = confirmedAmount.reduce(function (
    acc: number,
    val: number
  ) {
    return acc + val;
  },
  0);

  return (
    <>
      <center>
        <h2
          style={{ fontFamily: "monospace", fontWeight: "bolder" }}
          className="text-capitalize mt-4">
          Welcome: {user?.company_name}
        </h2>
      </center>
      <WelcomeDisplay
        totalAmount={total_amount}
        reservations={reservations}
        customers={customers}
        cars={cars}
        confirmedReservations={confirmedReservations}
        totalConfirmed={totalConirmedAmount}
      />
      <div className="col-md-12">
        <div className="card mb-4 mb-md-0">
          {reservations.length === 0 ? (
            <center>
              <div
                className="text-center alert alert-info"
                role="alert"
                style={{ width: "fit-content" }}>
                <h1>NO RENTALS FOUND</h1>
              </div>
            </center>
          ) : (
            <div className="card-body table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>E-mail</th>
                    <th>Phone Number</th>
                    <th>Paid</th>
                    <th>Pick Up Date</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {reservations
                    .slice(0, 10)
                    .map((item: ICarBooking, index: number) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="text-capitalize">
                          {item.client.first_name} {item.client.last_name}
                        </td>
                        <td>{item.client.email}</td>
                        <td>{item.phone_number}</td>
                        <td>ZMW {item.rental_price.toLocaleString()}</td>
                        <td>
                          {moment(item.client_pickup_date_time).format(
                            "MMM do YYYY"
                          )}
                          Days
                        </td>
                        <td>
                          <div className="badges bg-lightred text-white text-capitalize">
                            <b>{item.status}</b>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <Calendar />
    </>
  );
};

export default CarHomePage;
