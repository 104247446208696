import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import LoadingSpinner from "../../components/widgets/spinner";

const ReferalUserDetails = () => {
  const [number, setNumber] = useState(1); // No of pages
  const [ticketsPerPage] = useState(20);
  const [commuters, setCommuters] = useState([]);
  const [confirming, setConfirming] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { uid } = useParams();

  const fetchData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/get_customer_tickets/${uid}/rmf_travellers`, config
        )
        .then((res) => {
          setTickets(res.data);
          setIsLoading(false);
        });
    } catch (error) {}
    // get users
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/userprofile/v1/intercity_travellers/get-commuters/rmf_travellers`, config)
        .then((users) => {
          setCommuters(users.data);
          // setIsLoading(false);
        });
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, []);

  let ticketAmount = tickets.map((item) => parseInt(item.amount));

  const total_amount = ticketAmount.reduce(function (acc, val) {
    return acc + val;
  }, 0);

  let commision = tickets.map((item) => parseInt(item.user_commission));

  const totalCommission = commision.reduce(function (acc, val) {
    return acc + val;
  }, 0);

  // pagination
  const lastTicket = number * ticketsPerPage;
  const firstTicket = lastTicket - ticketsPerPage;
  const currentTickets = tickets.slice(firstTicket, lastTicket);
  const pageNumber = [];

  for (let i = 1; i <= Math.ceil(tickets.length / ticketsPerPage); i++) {
    pageNumber.push(i);
  }

  const ChangePage = (pageNumber) => {
    setNumber(pageNumber);
  };

  const handleClearance = async (e) => {
    setConfirming(true);
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    const body = {
      pending_commission: 0,
    };

    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}/userprofile/v1/intercity_travellers/clear_commission/${uid}/rmf_travellers`,
        body,
        config
      )
      .then((response) => {
        setTimeout(() => {
          setConfirming(false);
        }, 2000);
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: "Commission Cleared Successfully!",
        });
      })
      .catch((error) => {
        setConfirming(false);
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "An Error occurred try again later!",
        });

        // setErrorMessage(error.message);
        // console.error('There was an error!', error);
      });
  };

  return (
    <>
      <div className="wrapper  d-flex align-items-stretch">
        <div id="content" className="p-2 p-md-3 pt-3">
          <h1 className="text-center mt-5">Referal User</h1>
          <h5 className="text-center text-capitalize text-danger">
            {commuters.map((item, index) =>
              item.id == uid ? (
                <span key={index}>
                  <i className="fa fa-user"></i> {item.first_name}{" "}
                  {item.last_name}, <i className="fa fa-phone"></i> {item.phone}
                </span>
              ) : null
            )}
          </h5>
          {/* <!-- summary analytics --> */}
          <div className="row my-3 mt-5">
            <div className="col-md-4 mb-3">
              <div className="card shadow border-0 rounded-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h6 className="card-title text-muted">
                        Total Tickets Sold
                      </h6>
                    </div>

                    <div className="col-auto">
                      <div className="stat text-primary">
                        <i
                          className="bi bi-ticket-detailed-fill"
                          style={{
                            fontSize: "2rem",
                            color: "rgb(192, 52, 52)",
                          }}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <h5 className="mt-1 mb-3">{tickets.length}</h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <div className="card shadow border-0 rounded-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h6 className="card-title text-muted">
                        Total Tickets Revenue
                      </h6>
                    </div>

                    <div className="col-auto">
                      <div className="stat text-primary">
                        <i
                          className="bi bi-cash-coin"
                          style={{
                            fontSize: "2rem",
                            color: "rgb(7, 145, 7)",
                          }}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <h5 className="mt-1 mb-3">ZMW {total_amount}</h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <div className="card shadow border-0 rounded-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col mt-0">
                      <h6 className="card-title text-muted text-capitalize">
                        Total Commission Earned
                      </h6>
                    </div>

                    <div className="col-auto">
                      <div className="stat text-primary">
                        <i
                          className="bi bi-cash-coin"
                          style={{
                            fontSize: "2rem",
                            color: "orangered",
                          }}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <h5 className="mt-1 mb-3">
                    Total Commission: ZMW {totalCommission.toLocaleString()}
                    <br />
                    Pending Balance: ZMW{" "}
                    <b>
                      {commuters.map((item) =>
                        item.id == uid ? item.pending_commission : null
                      )}
                    </b>
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-none">
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <div className="table-responsive">
                <table className="table table-hover text-dark">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Ticket #</th>
                      <th>Name</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Paid</th>
                      <th>Phone</th>
                      <th>Date/Time Of Departure</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tickets.map((ticket, index) => (
                      <tr key={index}>
                        <td className="col-auto">
                          <small className="font-bold mb-0">{index + 1}</small>
                        </td>
                        <td className="col-auto">
                          <small className="font-bold mb-0">
                            #{ticket.ticket_no}
                          </small>
                        </td>
                        <td className="col-auto">
                          <small className="font-bold mb-0 text-capitalize">
                            {" "}
                            {ticket.first_name} {ticket.last_name}
                          </small>
                        </td>
                        <td className="col-auto">
                          <small className="mb-0 text-capitalize">
                            {ticket.from_route}
                          </small>
                        </td>
                        <td className="col-auto">
                          <small className="mb-0 text-capitalize">
                            {ticket.to_route}
                          </small>
                        </td>
                        <td className="col-auto">
                          <small className="mb-0">K {ticket.amount}</small>
                        </td>
                        <td className="col-auto">
                          <small className="mb-0">{ticket.phone_no}</small>
                        </td>
                        <td className="col-auto">
                          <small className="mb-0">
                            {moment(ticket.departure_date).format(
                              "Do MMMM, YYYY, HH:mm a"
                            )}
                          </small>
                        </td>
                        <td className="col-auto">
                          {ticket.status === "pending" ? (
                            <small className="mb-0 text-info">
                              <i>{ticket.status}</i>
                            </small>
                          ) : (
                            <small className="mb-0 text-success text-uppercase">
                              <b>{ticket.status}</b>
                            </small>
                          )}
                        </td>
                        {/* <a href={ticket.ticket_no} data-bs-toggle="modal" data-bs-target={`#exampleModal${ticket.ticket_no}`} className="btn btn-info">Details</a> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="container ">
                  {tickets.length === 0 ? null : (
                    <div className="pagination">
                      <div className="page-item">
                        <button
                          disabled={number === 1}
                          className="text-center btn-primary py-0"
                          onClick={() => setNumber(number - 1)}
                        >
                          <i className="fa fa-chevron-left"></i>
                        </button>
                      </div>

                      {pageNumber.map((Elem, index) => {
                        return (
                          <div key={index} className="page-item">
                            <button
                              className={`text-center text-white ${
                                number === Elem ? "active" : ""
                              } btn-outline-dark`}
                              onClick={() => ChangePage(Elem)}
                            >
                              {Elem}
                            </button>
                          </div>
                        );
                      })}
                      <div className="page-item">
                        <button
                          className="text-center btn-primary"
                          onClick={() => setNumber(number + 1)}
                        >
                          <i className="fa fa-chevron-right"></i>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="pull-right">
            <p>
              {confirming && <i className="fa fa-refresh fa-spin" />}
              {confirming && (
                <span className="wc-editable text-warning">
                  Clearing Commission ....
                </span>
              )}
            </p>
            <button
              disabled={confirming}
              onClick={(e) => handleClearance(e)}
              className="btn btn-primary"
            >
              Clear Commission
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferalUserDetails;
