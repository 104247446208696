import React from "react";
import Modal from "react-bootstrap/Modal";
import { capitalized } from "../../actions/utils";

function PlaceDetailsModal({ show, handleClose, place }) {
  return (
    <>
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        keyboard={false}
        backdrop="static"
        scrollable
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="text-uppercase">{place.place_name}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <img
                style={{
                  borderRadius: "10px",
                  width: "100%",
                  height: "400px",
                }}
                src={place.image}
                alt={place.place_name}
                className="img-fluid"
              />
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <h2 className="text-capitalize text-center">
                  {place.place_name}
                </h2>
                <small className="text-center text-capitalize">
                  <i className="fa fa-map"></i> Category: {place.place_type}
                </small>
                <h6 className="text-capitalize">
                  <i className="fa fa-map-marker"></i> Location:{" "}
                  <b>{place.address}</b>
                </h6>
                <a
                  target="_blank"
                  className="text-capitalize"
                  href={`${place.facebook_url}`}
                >
                  {" "}
                  <i className="fa fa-facebook"></i> Facebook
                </a>
              </div>
              <p>{capitalized(place.about_place)}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PlaceDetailsModal;
