export const blogCategories = [
  "Business",
  "Cars",
  "Education",
  "Entertainment",
  "Food",
  "Health",
  "Home",
  "Lifestyle",
  "Music",
  "News",
  "Science",
  "Sports",
  "Technology",
  "Travel",
  "World",
  "Religion",
];
