import React, { Component } from "react";
import axios from "axios";
import Swal from "sweetalert2";

import { Editor } from "@tinymce/tinymce-react";

import { useParams } from "react-router-dom";
export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class EditDistrict extends Component {
  constructor(props) {
    super(props);

    this.state = {
      details: [],
      isLoading: true,
      image: null,
      district_name: "",
      discription: "",
      isSubmitting: false,
      image_preview: null,
      district_id: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.input = React.createRef();
  }

  handleEditorChange(discription) {
    this.setState({ discription });
  }

  handleImage(e) {
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    this.setState({
      image_preview: image_as_base64,
      image: e.target.files[0],
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.setState({
      isSubmitting: true,
    });
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    let form_data = new FormData();
    // form_data.append('image', this.state.image);
    form_data.append("district_name", this.state.district_name);
    form_data.append("discription", this.state.discription);

   await axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/update-district/${this.props.match.params.id}/rmf_travellers`,
        form_data,
        config
      )
      .then((response) => {
        // console.log(response.data)
        Swal.fire(
          "Successful",
          "District updated Successfully....Redirecting!",
          "success"
        );
        setTimeout(() => {
          window.location.href = `/zm/intercity_travellers_admin/?uid=1`;
          this.setState({
            isSubmitting: false,
          });
        }, 2000);
      })
      .catch((error) => {
        this.setState({
          isSubmitting: false,
        });
        Swal.fire("Error", "An Error occurred try again later!", "error");
        console.error("There was an error!", error);
      });
  }

  async componentDidMount() {
    const district_id = this.props.match.params.id;
    const slug = this.props.match.params.slug;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/district-details/${slug}/${district_id}/rmf_travellers`, config
      )
      .then((res) => {
        const details = res.data;
        this.setState({
          details,
        });
      });
  }
  render() {
    return (
      <>
        <div className="py-5 align-items-stretch">
          <form className="mt-5" onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="form-group col-md-6">
                <label className="text-dark">District Name</label>

                <input
                  type="text"
                  required
                  placeholder="District Name"
                  name="seat_capacity"
                  className="form-control bg-transparent text-dark"
                  ref={this.input}
                  value={
                    (this.state.district_name =
                      this.state.details.district_name)
                  }
                  onChange={() =>
                    (this.state.district_name =
                      this.state.details.district_name)
                  }
                />
              </div>
              <div className="form-group col-md-6">
                <label className="text-dark">Image</label>

                <img
                  width="70"
                  height="50"
                  src={this.state.image_preview}
                  alt=""
                />
                <input
                  type="file"
                  name="image"
                  placeholder="image"
                  ref={this.input}
                  className="form-control bg-transparent"
                  onChange={this.handleImage}
                />
              </div>
            </div>
            <div className="form-group">
              <Editor
                init={{
                  height: 350,
                  width: "100%",
                }}
                ref={this.input}
                initialValue={this.state.details.discription}
                onEditorChange={this.handleEditorChange}
              />
            </div>
            <div className="modal-footer">
              <div className="row">
                <button
                  className="btn btn-primary form-control"
                  disabled={this.state.isSubmitting}
                >
                  {this.state.isSubmitting && (
                    <span className="wc-editable">
                      <i className="fa fa-refresh fa-spin" /> Updating ....
                    </span>
                  )}
                  {!this.state.isSubmitting && (
                    <span className="wc-editable">
                      <i className="fa fa-upload" />
                      Update
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}
export default withRouter(EditDistrict);
