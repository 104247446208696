import React from "react";
import { motion } from "framer-motion";
import { urlToken } from "../../helper";
import { Reveal } from "../../reveal";
import DestinationsLoading from "../loading/destinations_loading";
import { buttonStyles } from "../../utils/motion_button_style";
import { Rating } from "react-simple-star-rating";

const DestinationsCard = ({ data, isLoading }: any) => {
  return (
    <>
      <Reveal>
        <section className="ftco-section">
          <div className="container">
            <div className="row justify-content-center pb-4">
              <div className="col-md-12 heading-section text-center ftco-animate">
                <span className="subheading">Destinations</span>
                <h2 className="mb-4">Tour Destinations</h2>
              </div>
            </div>
            <div className="row">
              {isLoading ? (
                <DestinationsLoading />
              ) : data.length === 0 ? (
                <div className="alert alert-danger text-center">
                  <i style={{ fontSize: "100px" }} className="fa fa-globe"></i>
                  <h5>No Holiday Destinations Found</h5>
                </div>
              ) : (
                data.map((item: any, index: number) => (
                  <div
                    data-aos="fade-up"
                    data-aos-delay={index * 250}
                    key={index}
                    className="col-md-4 ftco-animate mb-4">
                    <div className="project-wrap mb-5">
                      <a
                        href={`/zm/holiday-details/${item.slug}/${item.id}/${urlToken}`}
                        className="img"
                        style={{
                          backgroundImage: `url(${item.thumbnail})`,
                        }}>
                        <span className="price">
                          {new Intl.NumberFormat("en-us", {
                            style: "currency",
                            currency: `${item.currency}`,
                          }).format(item.tourPrice)}{" "}
                          Per Person
                        </span>
                      </a>
                      <div className="text rounded-top p-2">
                        <span className="days">
                          {item.tourDays} Days {item.tourDays - 1} Nights
                        </span>
                        <h3 className="text-capitalize">
                          <a
                            href={`/zm/holiday-details/${item.slug}/${item.id}/${urlToken}`}>
                            {item.title.substring(0, 30)}...
                          </a>
                        </h3>

                        <div className="row">
                          <div className="col-md-4">
                            <ul>
                              <li>
                                <span className="flaticon-mountains"></span>
                                <b className="text-capitalize text-mute">
                                  {item.countryName}
                                </b>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-8">
                            <Rating
                              size={18}
                              initialValue={item.rating_avg}
                              transition
                              allowFraction
                              showTooltip
                              tooltipDefaultText={`${item.rating_avg}`}
                              allowTitleTag={false}
                              readonly
                              tooltipStyle={{
                                paddingLeft: "2px",
                                paddingRight: "2px",
                                paddingBottom: "0px",
                                paddingTop: "0px",
                              }}
                              /* Available Props */
                            />
                          </div>
                        </div>
                        <motion.a
                          href={`/zm/holiday-details/${item.slug}/${item.id}/${urlToken}`}
                          style={buttonStyles}
                          className="pull-right mt-2"
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9, x: "-5px", y: "5px" }}>
                          Details
                        </motion.a>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </section>
      </Reveal>
    </>
  );
};

export default DestinationsCard;
