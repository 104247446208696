import React, { useEffect, useRef } from "react";
import bg1 from "../../images/banner.png";
import axios from "axios";
import { Reveal } from "../../reveal";
import BranchCard from "./widgets/branchCard";

const Hotels = () => {
  const [hotels, setHotels] = React.useState([]);
  const [number, setNumber] = React.useState(1); // No of pages
  const [hotelPerPage] = React.useState(8);
  const [q, setQ] = React.useState("");
  const [searchParam] = React.useState(["address", "hotel_name", "branchType"]);
  const [filterParam, setFilterParam] = React.useState(["all"]);
  const [isLoading, setisLoading] = React.useState(true);
  const scrollRef = useRef(null);
  const scrowDown = () => scrollRef.current.scrollIntoView();
  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/hotels/v1/intercity_travellers/get_hotels/rmf_travellers`
          )
          .then((response) => {
            setHotels(response.data);
            setisLoading(false);
          });
      } catch (error) {
        setisLoading(false);
        console.log(error.message);
      }
    };
    fetchData();
  }, []);
  // pagination
  const lastHotel = number * hotelPerPage;
  const firstHotel = lastHotel - hotelPerPage;
  const currentBlogs = hotels.slice(firstHotel, lastHotel);
  const pageNumber = [];

  for (let i = 1; i <= Math.ceil(hotels.length / hotelPerPage); i++) {
    pageNumber.push(i);
  }

  const ChangePage = (pageNumber) => {
    setNumber(pageNumber);
  };
  const data = Object.values(currentBlogs);

  function search(list) {
    return list.filter((item) => {
      if (item.hotel_name === filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.branchType === filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (filterParam === "all") {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }
  return (
    <>
      <section
        class="hero-wrap hero-wrap-2 js-fullheight"
        style={{ backgroundImage: `url(${bg1})` }}>
        <div class="overlay"></div>
        <div class="container">
          <div class="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
            <div class="col-md-9 ftco-animate move-text pb-5 text-center">
              <p class="breadcrumbs">
                <span class="mr-2">
                  <a href="/">
                    Home <i class="fa fa-chevron-right"></i>
                  </a>
                </span>{" "}
                <span>
                  Hotel & Lodges <i class="fa fa-chevron-right"></i>
                </span>
              </p>
              <h1 class="mb-0 bread">Hotels & Lodges</h1>
            </div>
          </div>
        </div>
        <a className="mouse smoothscroll" onClick={scrowDown}>
          <div className="mouse-icon">
            <span className="mouse-wheel"></span>
          </div>
        </a>
      </section>

      <Reveal>
        <section ref={scrollRef} class="ftco-section ftco-no-pb">
          <div class="container">
            <div class="search-wrap-1 ftco-animate">
              <form action="#" class="search-property-1">
                <div class="row no-gutters">
                  <div class="col-l-12 col-md-8 d-flex">
                    <div class="form-group p-4 border-0">
                      <label htmlFor="#">Search</label>
                      <div class="form-field">
                        <div class="icon">
                          <span class="fa fa-search"></span>
                        </div>
                        <input
                          type="search"
                          class="form-control"
                          placeholder="Search for a place to stay"
                          value={q}
                          onChange={(e) => setQ(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg d-flex">
                    <div className="form-group p-4">
                      <label htmlFor="#">Filter</label>
                      <div className="form-field">
                        <div className="select-wrap">
                          <div className="icon">
                            <span className="fa fa-chevron-down"></span>
                          </div>
                          <select
                            name="branchType"
                            id=""
                            className="form-control"
                            onChange={(e) => setFilterParam(e.target.value)}>
                            <option value="all">Filter By: ALL</option>
                            <option value="motel">Motel</option>
                            <option value="apartment">Apartment</option>
                            <option value="lodge">Lodge</option>
                            <option value="hotel">Hotel</option>
                            <option value="guest_house">Guest House</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </Reveal>

      <section class="ftco-section">
        <Reveal>
          <div class="container">
            <div class="row">
              {<BranchCard data={data} isLoading={isLoading} />}
            </div>
            {data.length < 8 ? (
              <div></div>
            ) : (
              <div className="row mt-5">
                <div className="col text-center">
                  <div className="block-27">
                    <ul>
                      <li>
                        <a
                          style={{ cursor: "pointer" }}
                          disabled={number === 1}
                          onClick={() => setNumber(number - 1)}>
                          &lt;
                        </a>
                      </li>
                      {pageNumber.map((page, index) => (
                        <li
                          key={index}
                          className={`${number === page ? "active" : ""}`}>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => ChangePage(page)}>
                            <span>{page}</span>
                          </a>
                        </li>
                      ))}

                      <li>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => setNumber(number + 1)}>
                          &gt;
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Reveal>
      </section>
    </>
  );
};
export default Hotels;
