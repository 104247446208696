import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import OwlCarousel from "react-owl-carousel";

const HolidaySkeleton = ({ options }) => {
  return (
    <>
      <SkeletonTheme baseColor="#f3f3f3" highlightColor="#ecebeb">
        <div className="col-md-12">
          <div className="carousel-destination ftco-animate">
            <OwlCarousel {...options} className="owl-theme">
              {Array(6)
                .fill()
                .map((item, index) => (
                    <Skeleton key={index} height={250} width={`100%`} />
                  
                ))}
            </OwlCarousel>
          </div>
        </div>
      </SkeletonTheme>
    </>
  );
};

export default HolidaySkeleton;
