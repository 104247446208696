import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

export const Reveal = ({ children }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });
  const mainControls = useAnimation();
  const slideControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      // fire the animation
      mainControls.start("visible");
      slideControls.start("visible");
    }
  }, [isInView]);

  return (
    <div ref={ref}>
      <motion.div
        // whileInView={{ scale: [1.0, 1.1, 1.0] }}
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{
          type: "spring",
          duration: 0.5,
          delay: 0.25,
          bounce: 0.5,
        }}
      >
        {children}
      </motion.div>
      {/* TODO: ADD SLID DIV THINGY */}
      <motion.div
        variants={{
          hidden: { left: 0 },
          visible: { left: "100%" },
        }}
        animate={slideControls}
        transition={{
          type: "spring",
          duration: 0.5,
          ease: "easeIn",
          bounce: 0.5,
        }}
        style={{
          top: 4,
          bottom: 4,
          right: 0,
          left: 0,
          background: "green",
        }}
      ></motion.div>
    </div>
  );
};
