import React from "react";
import OwlCarousel from "react-owl-carousel";
import { Reveal } from "../../../reveal";

interface ICarCompany{
  data: any[]
}

const CarHireCompanies: React.FC<ICarCompany> = ({data}) => {
  //Owl Carousel Settings
  const options = {
    loop: true,
    center: true,
    items: 3,
    margin: 10,
    autoplay: true,
    dots: true,
    autoplayTimeout: 4500,
    smartSpeed: 450,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <>
      <Reveal>
        <section className="ftco-section img ftco-select-destination">
          <div className="container">
            <div className="row justify-content-center pb-4">
              <div className="col-md-12 heading-section text-center ftco-animate">
                <span className="subheading">
                  Intercity Travellers Car Hire
                </span>
                <h2 className="mb-4">Car Hire Companies</h2>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {data.length === 0 ? (
                <div className="alert alert-info text-center" role="alert">
                  <h1>
                    <i className="fa fa-globe"></i>
                  </h1>
                  <h3> No Car Hire Companies Found</h3>
                </div>
              ) : (
                <div className="col-md-12">
                  <div className="carousel-destination ftco-animate">
                    <OwlCarousel {...options} className="owl-theme">
                      {data.map((item, index) => (
                        <div
                          data-aos="fade-up"
                          data-aos-delay={index * 250}
                          key={index}
                          className="item">
                          <div className="project-destination">
                            <a
                              href={`/zm/company_cars?company_id=${item.id}`}
                              className="img"
                              style={{ backgroundImage: `url(${item.company_logo})` }}>
                              <div className="text">
                                <h3 className="text-uppercase">
                                  {item.company_name}
                                </h3>
                                <span>{item.carmodel_count} Cars</span>
                              </div>
                            </a>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </Reveal>
    </>
  );
};
export default CarHireCompanies;
