import React, { useState, useEffect, useRef } from "react";
import bg1 from "../../images/blog.jpg";
import moment from "moment";
import axios from "axios";
import { motion } from "framer-motion";
import { blogCategories } from "../data/blog_categories";
import { Reveal } from "../../reveal";
import { urlToken } from "../../helper";
import BlogSkeleton from "../loading/blog_loading";
import BlogCard from "./widgets/blog_card";

const Blogs = () => {
  const [number, setNumber] = useState(1); // No of pages
  const [blogPerPage] = useState(8);
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [q, setQ] = useState("");
  const [filterParam, setFilterParam] = useState(["all"]);
  const [searchParam] = useState(["blog_title", "blog_author", "category"]);
  const scrollRef = useRef(null);
  const scrowDown = () => scrollRef.current.scrollIntoView();

  const FetchData = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/blog/v1/intercity_travellers/blogs/rmf_travellers`
      )
      .then((res) => {
        setBlogs(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    FetchData();
  }, []);
  // pagination
  const lastBlog = number * blogPerPage;
  const firstBlog = lastBlog - blogPerPage;
  const currentBlogs = blogs.slice(firstBlog, lastBlog);
  const pageNumber = [];

  for (let i = 1; i <= Math.ceil(blogs.length / blogPerPage); i++) {
    pageNumber.push(i);
  }

  const ChangePage = (pageNumber) => {
    setNumber(pageNumber);
  };
  const data = Object.values(currentBlogs);

  function search(list) {
    return list.filter((item) => {
      if (item.blog_title == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.category == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (filterParam == "all") {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }
  return (
    <>
      <div className="demo-wrap">
        <img className="demo-bg" src={bg1} alt="" />
        <div className="demo-content">
          <div className="container">
            <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-center">
              <div className="col-md-9 ftco-animate pb-5 text-center">
                <p className="breadcrumbs">
                  <span className="mr-2">
                    <a href="/">
                      Home <i className="fa fa-chevron-right"></i>
                    </a>
                  </span>{" "}
                  <span className="mr-2">
                      Blogs
                  </span>
                </p>
                <h1 className="mb-0 bread">Blogs</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section ref={scrollRef} className="ftco-section">
        <div className="container-fluid">
          {/* <div className="row justify-content-center">
            <div className="col-md-12 heading-section text-center ftco-animate">
              <span className="subheading">Intercity Blogs</span>
              <h2>Blogs</h2>
            </div>
          </div> */}
          <div className="row">
            <div className="col-md-2 shadow border-right">
              <Reveal>
                <div class="form-group border-0">
                  <div className="row">
                    <div className="col-md-8">
                      <label for="#">Search Blogs</label>
                    </div>
                    <div className="col-md-3">
                      <div className="tag-widget post-tag-container">
                        <div className="tagcloud">
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => setFilterParam("all")}
                            className="tag-cloud-link btn btn-warning">
                            Clear
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-field">
                    <input
                      type="search"
                      class="form-control"
                      placeholder="Search Blogs"
                      value={q}
                      onChange={(e) => setQ(e.target.value)}
                    />
                  </div>
                </div>
                <div className="tag-widget post-tag-container mb-5 mt-3">
                  <ul className="tagcloud">
                    {blogCategories.map((category, index) => (
                      <li key={index}>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setFilterParam(category.toLocaleLowerCase())
                          }
                          className={`tag-cloud-link ${
                            category.toLocaleLowerCase() === filterParam
                              ? "active_category"
                              : ""
                          }`}>
                          {category}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </Reveal>
            </div>
            <div className="col-md-10">
              {isLoading ? (
                <>
                  <BlogSkeleton />
                </>
              ) : (
                <Reveal>
                  <div className="row d-flex">
                    {search(data).length === 0 ? (
                      <div className="text-center mt-5">
                        <i className="fa fa-blog fa-5x"></i>
                        <h3 className="mt-3 mb-5">No Blogs Found</h3>
                      </div>
                    ) : (
                      search(data).map((item, index) => (
                        <BlogCard item={item} index={index} key={index} />
                      ))
                    )}
                  </div>
                </Reveal>
              )}
              {data.length < 8 ? (
                <div></div>
              ) : (
                <div className="row mt-5">
                  <div className="col text-center">
                    <div className="block-27">
                      <ul>
                        <li>
                          <a
                            style={{ cursor: "pointer" }}
                            disabled={number === 1}
                            onClick={() => setNumber(number - 1)}>
                            &lt;
                          </a>
                        </li>
                        {pageNumber.map((page, index) => (
                          <li
                            key={index}
                            className={`${number === page ? "active" : ""}`}>
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => ChangePage(page)}>
                              <span>{page}</span>
                            </a>
                          </li>
                        ))}

                        <li>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => setNumber(number + 1)}>
                            &gt;
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blogs;
