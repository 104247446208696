import React from "react";
import { urlToken } from "../../../helper";
import moment from "moment";
import { motion } from "framer-motion";
import { IBlog } from "../../../redux/types/blog.type";

interface IBlogCard {
  item: IBlog;
  index: number;
}

const BlogCard: React.FC<IBlogCard> = ({ item, index }) => {
  return (
    <>
      <motion.div
        transition={{ delay: 0.1 * index }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        whileHover={{ scale: 1.1 }}
        key={index}
        className="col-md-4 d-flex ftco-animate">
        <div className="blog-entry justify-content-end">
          <a
            href={`/zm/blog-details/?token=${urlToken}&blog_id=${item.id}&slug=${item.slug}`}
            className="block-20"
            style={{
              backgroundImage: `url(${item.image})`,
            }}></a>
          <div className="text">
            <div className="d-flex align-items-center mb-4 topp">
              <div className="one">
                <span className="day">
                  {moment(item.created_aat).format("DD")}
                </span>
              </div>
              <div className="two">
                <span className="yr">
                  {moment(item.created_aat).format("YYYY")}
                </span>
                <span className="mos">
                  {moment(item.created_aat).format("MMMM")}
                </span>
              </div>
              <br />
              <div className="text-white border-left shadow rounded">
                <i className="fa fa-comment"></i>{" "}
                {item.comments.length}
                <i className="fa fa-thumbs-up"></i> {item.likes}
              </div>
            </div>
            <span className="text-capitalize">
              <a
                href={`/zm/blog-details/?token=${urlToken}&blog_id=${item.id}&slug=${item.slug}`}>
                <h6>{item.blog_title}</h6>
              </a>
            </span>
            <p>{item.blog_body.substring(0, 130)}...</p>
            <div className="row">
              <div className="col-md-6">
                <div className="tag-widget post-tag-container">
                  <div className="tagcloud">
                    <button className="tag-cloud-link btn btn-info text-capitalize">
                      {item.category}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <p>
                  <a
                    href={`/zm/blog-details/?token=${urlToken}&blog_id=${item.id}&slug=${item.slug}`}
                    className="btn btn-primary pull-right">
                    Read more
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default BlogCard;
