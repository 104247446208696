import React, { useEffect } from "react";
import bg1 from "../../images/lv.jpg";
import axios from "axios";
import LoadingSpinner from "../../components/widgets/spinner";
import { capitalized } from "../../actions/utils";
import PlaceDetailsModal from "../../components/tourism/place_details_modal";
import { Reveal } from "../../reveal";

const AllPlaces = () => {
  const [show, setShow] = React.useState(false);
  const [places, setPlaces] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [details, setDetails] = React.useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (place) => {
    setShow(true);
    setDetails(place);
  };

  const fetchData = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/places/rmf_travellers`
      )
      .then((res) => {
        const places = res.data;
        setIsLoading(false);
        setPlaces(places);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <section
        className=""
        style={{
          backgroundImage: `url(${bg1})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}>
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-center">
            <div className="col-md-9 ftco-animate pb-5 text-center">
              <p className="breadcrumbs">
                <span className="mr-2">
                  <a href="/">
                    Home <i className="fa fa-chevron-right"></i>
                  </a>
                </span>{" "}
                <span className="mr-2">
                  <a>Discover</a>
                </span>{" "}
              </p>
              <h1 className="mb-0 bread">Lets Discover Together</h1>
            </div>
          </div>
        </div>
      </section>
      <div className="p-4 p-md-5">
        <div className="row justify-content-center">
          <div className="col-md-12 heading-section text-center ftco-animate">
            <span className="subheading">Places</span>
            <h2>Discover Places</h2>
          </div>
        </div>
        <Reveal>
          <div className="container mt-3">
            <div className="row">
              {isLoading ? (
                <>
                  <LoadingSpinner />
                </>
              ) : (
                places.map((place, index) => (
                  <div
                    data-aos="fade-up"
                    data-aos-delay={index * 250}
                    key={index}
                    className="col-md-4 col-sm-4 col-lg-4 mb-3">
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => handleShow(place)}>
                      <img
                        style={{
                          borderRadius: "10px",
                          width: "100%",
                          height: "200px",
                        }}
                        src={place.image}
                        alt=""
                        className="img-fluid"
                      />
                      <h4 className="text-capitalize">
                        <b>{place.place_name}</b> <br />
                      </h4>
                    </a>
                    <h6 className="text-capitalize">
                      <small className="text-info text-center">
                        <i className="fa fa-map-marker"></i>{" "}
                        <span>{place.place_type}</span>,{" "}
                        {place.address.substring(0, 25)}
                      </small>
                    </h6>
                    <a
                      className="text-capitalize"
                      href={`${place.facebook_url}`}>
                      {" "}
                      <i className="fa fa-facebook"></i> Facebook
                    </a>
                    <blockquote>
                      <small>
                        {capitalized(place.about_place.substring(0, 150))}
                        ...{" "}
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => handleShow(place)}
                          className="btn btn-sm btn-info text-white">
                          Read More
                        </a>
                      </small>
                    </blockquote>
                    {/* modal here */}
                    <PlaceDetailsModal
                      show={show}
                      handleClose={handleClose}
                      place={details === null ? place : details}
                    />
                  </div>
                ))
              )}
            </div>
          </div>
        </Reveal>
      </div>
    </>
  );
};

export default AllPlaces;
