import React from "react";
import service1 from "../../../images/city.jpg";
import service2 from "../../../images/hotel-resto-5.jpg";
import service3 from "../../../images/bg_5.jpg";
import service4 from "../../../images/ele.jpg";
import { Reveal } from "../../../reveal";

const Activities = () => {
  return (
    <>
      <Reveal>
        <section className="ftco-section services-section">
          <div className="container">
            <div className="row d-flex">
              <div className="col-md-6 order-md-last heading-section pl-md-5 ftco-animate d-flex align-items-center">
                <div className="w-100">
                  <span className="subheading">
                    Welcome to Intercity Travellers
                  </span>
                  <h2 className="mb-4">It's time to start your adventure</h2>
                  <p>
                    Imagine starting your adventure from your phone having to
                    look at all the amazing places found in Zambia all at the
                    same time and in at a click of a button, a platform giving
                    you a wide range of Hotels, Lodges, Holyday places and Bus
                    booking options.
                  </p>
                  <p>
                    Zambia is a Land linked country regarded to as one of the
                    most beautiful, friendly, diverse countries on the entire
                    African continent. Aside from the majestic Victoria Falls
                    located in the southern province of the country, Zambia has
                    more natural water resources than any other Southern African
                    country, including several other falls dotted across the
                    country, not to mention the well-known Zambezi River and
                    many national parks that offer great opportunities for
                    observing Africa’s plains game and predators, while bustling
                    urban areas offer a taste of Zambia’s beautiful culture.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12 col-lg-6 d-flex align-self-stretch ftco-animate">
                    <div
                      className="services services-1 color-1 d-block img"
                      style={{
                        backgroundImage: `url(${service1})`,
                      }}
                    >
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="flaticon-route"></span>
                      </div>
                      <div className="media-body">
                        <h3 className="heading mb-3">
                          <b>Bus Ticket Reservation</b>
                        </h3>
                        <p>
                          Booking a bus has now been easy, you can make a
                          reservation at any time just from a click of a button.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6 d-flex align-self-stretch ftco-animate">
                    <div
                      className="services services-1 color-2 d-block img"
                      style={{ backgroundImage: `url(${service2})` }}
                    >
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-bed"></span>
                      </div>
                      <div className="media-body">
                        <h3 className="heading mb-3">
                          <b>Room Reservation</b>
                        </h3>
                        <p>
                          Make reservations based on your test. Make a lodge or
                          Hotel reservation at your connivance.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6 d-flex align-self-stretch ftco-animate">
                    <div
                      className="services services-1 color-3 d-block img"
                      style={{ backgroundImage: `url(${service3})` }}
                    >
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-compass"></span>
                      </div>
                      <div className="media-body">
                        <h3 className="heading mb-3">
                          <b>Holiday Destinations</b>
                        </h3>
                        <p>
                          Find your perfect holiday destinations, suitable for
                          individuals, couples and families in Zambia.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6 d-flex align-self-stretch ftco-animate">
                    <div
                      className="services services-1 color-4 d-block img"
                      style={{ backgroundImage: `url(${service4})` }}
                    >
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="flaticon-map"></span>
                      </div>
                      <div className="media-body">
                        <h3 className="heading mb-3">
                          <b>Zambia Tourism</b>
                        </h3>
                        <p>
                          Experience the real Africa with the best tourism
                          destinations around Zambia the real Africa.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Reveal>
    </>
  );
};
export default Activities;
