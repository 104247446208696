import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

import LoadingSpinner from "../../components/widgets/spinner";
import { useAppSelector } from "../../hooks/redux-hooks";

const CustomerHomePage = ()=> {
  const authenticatedUser = useAppSelector((state) => state.auth.user);
  const [number, setNumber] = React.useState(1); // No of pages
  const [ticketsPerPage] = useState(20);
  const [user, setUser] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [commuters, setCommuters] = useState([]);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
      Accept: "application/json",
    },
  };
  const fetchData = async () => {
    // fetch user
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/auth/users/me/`, config)
        .then((response) => {
          setUser(response.data);
          setIsLoading(false);
        });
    } catch (error) {}
    //get user tickets
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/get_customer_tickets/${authenticatedUser?.id}/rmf_travellers`,
          config
        )
        .then((res) => {
          setTickets(res.data);
          setIsLoading(false);
        });
    } catch (error) {}

    // get users
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/userprofile/v1/intercity_travellers/get-commuters/rmf_travellers`,
          config
        )
        .then((users) => {
          setCommuters(users.data);
          // setIsLoading(false);
        });
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, []);

  let ticketAmount = tickets.map((item) => parseInt(item.amount));

  const total_amount = ticketAmount.reduce(function (acc, val) {
    return acc + val;
  }, 0);

  let commision = tickets.map((item) => parseInt(item.user_commission));

  const totalCommission = commision.reduce(function (acc, val) {
    return acc + val;
  }, 0);

  // pagination
  const lastTicket = number * ticketsPerPage;
  const firstTicket = lastTicket - ticketsPerPage;
  const currentTickets = tickets.slice(firstTicket, lastTicket);
  const pageNumber = [];

  for (let i = 1; i <= Math.ceil(tickets.length / ticketsPerPage); i++) {
    pageNumber.push(i);
  }

  const ChangePage = (pageNumber) => {
    setNumber(pageNumber);
  };

  return (
    <>
      <h1 className="text-center">Bus Tickets</h1>
      {/* <!-- summary analytics --> */}
      <div className="row my-3 mt-5">
        <div
          className={`${
            user.user_type === "REFERAL" ? "col-md-4" : "col-md-6"
          } mb-3`}>
          <div className="card shadow border-0 rounded-3">
            <div className="card-body">
              <div className="row">
                <div className="col mt-0">
                  <h6 className="card-title text-muted">Total Tickets</h6>
                </div>

                <div className="col-auto">
                  <div className="stat text-primary">
                    <i
                      className="bi bi-ticket-detailed-fill"
                      style={{
                        fontSize: "2rem",
                        color: "rgb(192, 52, 52)",
                      }}></i>
                  </div>
                </div>
              </div>
              <h5 className="mt-1 mb-3">{tickets.length}</h5>
            </div>
          </div>
        </div>

        <div
          className={`${
            user.user_type === "REFERAL" ? "col-md-4" : "col-md-6"
          } mb-3`}>
          <div className="card shadow border-0 rounded-3">
            <div className="card-body">
              <div className="row">
                <div className="col mt-0">
                  <h6 className="card-title text-muted">
                    Total Tickets Revenue
                  </h6>
                </div>

                <div className="col-auto">
                  <div className="stat text-primary">
                    <i
                      className="bi bi-cash-coin"
                      style={{
                        fontSize: "2rem",
                        color: "rgb(7, 145, 7)",
                      }}></i>
                  </div>
                </div>
              </div>
              <h5 className="mt-1 mb-3">
                ZMW <b>{total_amount.toLocaleString()}</b>
              </h5>
            </div>
          </div>
        </div>

        {user?.user_type === "REFERAL" ? (
          <div className="col-md-4 mb-3">
            <div className="card shadow border-0 rounded-3">
              <div className="card-body">
                <div className="row">
                  <div className="col mt-0">
                    <h6 className="card-title text-muted text-capitalize">
                      Total Commission Earned
                    </h6>
                  </div>

                  <div className="col-auto">
                    <div className="stat text-primary">
                      <i
                        className="bi bi-cash-coin"
                        style={{
                          fontSize: "2rem",
                          color: "orangered",
                        }}></i>
                    </div>
                  </div>
                </div>
                <h5 className="mt-1 mb-3">
                  Total Commission: ZMW{" "}
                  <b>{totalCommission.toLocaleString()}</b>
                  <br />
                  Pending Balance: ZMW{" "}
                  <b>
                    {commuters.map((item) =>
                      item.id == user.id ? item.pending_commission : null
                    )}
                  </b>
                </h5>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="container-fluid mt-5">
        <div className="row">
          {isLoading ? (
            <div className="text-center">
              <LoadingSpinner />
            </div>
          ) : (
            <div className="mb-3">
              <div className="table-responsive">
                <table id="example" className="table table-striped table-lg">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Ticket #</th>
                      <th>Name</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Paid</th>
                      <th>Phone</th>
                      <th>Date/Time Of Departure</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentTickets.map((ticket, index) => (
                      <tr key={index}>
                        <td className="col-auto">
                          <small className="font-bold mb-0">{index + 1}</small>
                        </td>
                        <td className="col-auto">
                          <small className="font-bold mb-0">
                            {ticket.ticket_no}
                          </small>
                        </td>
                        <td className="col-auto">
                          <small className="font-bold mb-0 text-capitalize">
                            {" "}
                            {user.first_name} {user.last_name}
                          </small>
                        </td>
                        <td className="col-auto">
                          <small className="mb-0 text-capitalize">
                            {ticket.from_route}
                          </small>
                        </td>
                        <td className="col-auto">
                          <small className="mb-0 text-capitalize">
                            {ticket.to_route}
                          </small>
                        </td>
                        <td className="col-auto">
                          <small className="mb-0">K {ticket.amount}</small>
                        </td>
                        <td className="col-auto">
                          <small className="mb-0">{ticket.phone_no}</small>
                        </td>
                        <td className="col-auto">
                          <small className="mb-0">
                            {moment(ticket.departure_date).format(
                              "Do MMMM, YYYY, HH:mm a"
                            )}
                          </small>
                        </td>
                        <td className="col-auto">
                          {ticket.status === "pending" ? (
                            <small className="mb-0 text-info">
                              <b>{ticket.status}</b>
                            </small>
                          ) : ticket.status === "cancelled" ? (
                            <small className="mb-0 text-danger text-uppercase">
                              <b>{ticket.status}</b>
                            </small>
                          ) : (
                            <small className="mb-0 text-success text-uppercase">
                              <b>{ticket.status}</b>
                            </small>
                          )}
                        </td>
                        {/* <a href={ticket.ticket_no} data-bs-toggle="modal" data-bs-target={`#exampleModal${ticket.ticket_no}`} className="btn btn-info">Details</a> */}
                        <div
                          className="modal fade"
                          id={`exampleModal${ticket.ticket_no}`}
                          tabIndex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true">
                          <div className="modal-dialog modal-fullscreen">
                            <div
                              style={{ background: "rgba(0,0,0,0.7)" }}
                              className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id="exampleModalLabel">
                                  My Tickets
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  style={{ color: "white" }}
                                  data-bs-dismiss="modal"
                                  aria-label="Close">
                                  X
                                </button>
                              </div>
                              <div className="modal-body">
                                <div className="table-responsive">
                                  <table className="table table-hover table-lg">
                                    <thead>
                                      <tr>
                                        <th>Ticket</th>
                                        <th>Name</th>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>Paid</th>
                                        <th>Status</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr className="text-success">
                                        <td className="col-auto">
                                          <p className="font-bold mb-0">
                                            {ticket.ticket_no}
                                          </p>
                                        </td>
                                        <td className="col-auto">
                                          <p className="font-bold mb-0 text-capitalize">
                                            {" "}
                                            {user.first_name}
                                          </p>
                                        </td>
                                        <td className="col-auto">
                                          <p className="mb-0 text-capitalize">
                                            {ticket.from_route}
                                          </p>
                                        </td>
                                        <td className="col-auto">
                                          <p className="mb-0 text-capitalize">
                                            {ticket.to_route}
                                          </p>
                                        </td>
                                        <td className="col-auto">
                                          <p className="mb-0">
                                            K {ticket.amount}
                                          </p>
                                        </td>
                                        <td className="col-auto">
                                          <p className="mb-0">
                                            {ticket.status}
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* <a href={ticket.ticket_no} data-bs-toggle="modal" data-bs-target={`#exampleModal${ticket.ticket_no}`}>
                                    <img style={{borderRadius: '10px', width: '100%', height: '200px', border: '1px solid #fff'}} src={logo} alt="" className="img-fluid" />
                                    <h6 className="text-capitalize">
                                      {districts.map(district => district.id == ticket.from_route ? district.district_name : null)} - {districts.map(district => district.id == ticket.to_route ? district.district_name : null)} <br /> 
                                      <small className="text-warning text-center">{ticket.status}, <span className="text-success">Ticket #{ticket.ticket_no}</span> </small>
                                       </h6>
                                      
                                </a> */}
              <div className="container pull-right">
                {tickets.length < 50 ? null : (
                  <div className="pagination">
                    <div className="page-item">
                      <button
                        disabled={number === 1}
                        className="text-center btn-primary py-0"
                        onClick={() => setNumber(number - 1)}>
                        <i className="fa fa-chevron-left"></i>
                      </button>
                    </div>

                    {pageNumber.map((Elem, index) => {
                      return (
                        <div key={index} className="page-item">
                          <button
                            className={`text-center text-white ${
                              number === Elem ? "active" : ""
                            } btn-outline-dark`}
                            onClick={() => ChangePage(Elem)}>
                            {Elem}
                          </button>
                        </div>
                      );
                    })}
                    <div className="page-item">
                      <button
                        className="text-center btn-primary"
                        onClick={() => setNumber(number + 1)}>
                        <i className="fa fa-chevron-right"></i>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CustomerHomePage;
