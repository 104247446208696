import React, { useEffect } from "react";
import ImageGallery from "../widgets/gallery/gallery";
import { Modal, Button } from "react-bootstrap";
import { Carousel } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import LoadingSpinner from "../widgets/spinner";
import placeHolder from "../../images/user.png";
import moment from "moment";
import { Rating } from "react-simple-star-rating";
import RelatedRooms from "./widgets/related_rooms";

export default function RoomDetails() {
  const [room, setRooms] = React.useState([]);
  const [hotel, setHotel] = React.useState([]);
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [token, setToken] = React.useState(null);
  const [user, setUser] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [isRegistering, setIsRegistering] = React.useState(false);
  const [formData, setFormData] = React.useState({
    user: user.id,
    first_name: "",
    last_name: "",
    room_type: "",
    room_location: "",
    room_occupation: "",
    room_number: "",
    phone_number: "",
    date_checkin: "",
    date_checkout: "",
    email: "",
    optNumber: "",
  });
  const [isLoading, setisLoading] = React.useState(true);
  const [formValues, setFormValues] = React.useState(formData);
  const [formErrors, setFormErrors] = React.useState({});
  const queryParams = new URLSearchParams(window.location.search);
  const hotelID = queryParams.get("hotel");
  const roomID = queryParams.get("room");
  const ImgUrl = "hotels/v1/intercity_travellers/retrieve_room_images";
  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/hotels/v1/intercity_travellers/room_details/${roomID}/rmf_travellers`
          )
          .then((response) => {
            setRooms(response.data);
            setisLoading(false);
          });
      } catch (error) {
        setisLoading(false);
        console.log(error.message);
      }
      // get rooms

      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/hotels/v1/intercity_travellers/hotel_details/${hotelID}/rmf_travellers`
        )
        .then((response) => {
          setHotel(response.data);
        })
        .catch((error) => {
          console.log(error.message);
        });

      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
            Accept: "application/json",
          },
        };

        const userProfile = await axios.get(
          `${process.env.REACT_APP_API_URL}/auth/users/me/`,
          config
        );
        if (userProfile) {
          setUser(userProfile.data);
        }
      } catch (error) {}
    };
    fetchData();
    console.log("form errors" + Object.keys(formErrors).length);
    if (Object.keys(formErrors).length === 0 && formSubmitting) {
      setIsRegistering(true);
    }
  }, [formErrors]);
  const capitalized = (word) => {
    if (word) {
      return word.charAt(0).toUpperCase() + word.slice([1]);
    }

    return "";
  };

  // const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value },validate(e));

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const bookRoom = async (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    setFormSubmitting(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    let form_data = new FormData();
    form_data.append("user", formData.user);
    form_data.append("first_name", formData.first_name);
    form_data.append("last_name", formData.last_name);
    form_data.append("room_type", formData.room_type);
    form_data.append("room_location", formData.room_location);
    form_data.append("room_occupation", formData.room_occupation);
    form_data.append("room_number", formData.room_number);
    form_data.append("phone_number", formData.phone_number);
    form_data.append("email", formData.email);
    form_data.append("date_checkin", formData.date_checkin);
    form_data.append("date_checkout", formData.date_checkout);

    if (formErrors) {
      setFormSubmitting(false);
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/hotels/v1/intercity_travellers/create_booking/rmf_travellers`,
          form_data,
          config
        )
        .then((response) => {
          console.log(response.data);
          Swal.fire(
            "Successful",
            "Room has been Booked Successfully....",
            "success"
          );
          setFormSubmitting(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          setFormSubmitting(false);
          handleClose();
          Swal.fire("Error", `An Error occurred ${error.message}`, "error");
          console.error("There was an error!", error);
        });
    }
  };

  function randomString(length, chars) {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  var orderId = randomString(
    10,
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
  );
  var appKey =
    "1D682C567D64488136399E6218FD3C2954EA5DC56D4785C85EFBE15A7A96A1583493B830AE2B1EE8139C5A84C0E3DEE7";
  var authKey =
    "4A818A870763B4B7C6ECE48F6096EEED3493B830AE2B1EE8139C5A84C0E3DEE7F33CBE16D88D75F06ECCF65B4F7F937787C7F9A8";

  const registerTransaction = async (event) => {
    setIsRegistering(true);
    event.preventDefault();
    setFormErrors(validate(formValues));
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    var reqbody = {
      app_key: appKey,
      auth_key: appKey,
      key_type: "business",
      request_id: new Date().toString(),
      order_id: orderId,
      order_details: "Ticket Payment",
      method: "mobile_money",
      amount: hotel.room_price,
      currency: "ZMW",
      account: `+26${user.phone}`,
      preauthflag: "0",
      service: "Airtel Money",
    };

    var jsonBody = JSON.stringify(reqbody);

    await axios
      .post(
        "https://samafricaonline.com/sam_pay/public/ra_register",
        jsonBody,
        config
      )
      .then((response) => {
        console.log(response.data);
        setIsRegistering(false);
        setToken(response.data["token"]);
        setShow(true);
      })
      .catch((error) => {
        setIsRegistering(false);

        Swal.fire("Error", `${error.message}`, "error");
        console.error("There was an error!", error);
      });
  };

  const finalizeTransaction = async (event) => {
    event.preventDefault();
    setFormSubmitting(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    var finalRequestbody = {
      app_key: appKey,
      auth_key: authKey,
      key_type: "business",
      request_id: new Date().toString(),
      method: "mobile_money",
      token: token,
      otp: formData.optNumber,
    };

    await axios
      .post(
        "https://samafricaonline.com/sam_pay/public/ra_mmpayrequest",
        JSON.stringify(finalRequestbody),
        config
      )
      .then((response) => {
        console.log(response.data);
        setFormSubmitting(false);
        bookRoom();
        Swal.fire(
          "Success",
          "Your Room has been reserved succesfully!",
          "success"
        );
      })
      .catch((error) => {
        setFormSubmitting(false);

        Swal.fire("Error", `${error.message}`, "error");
        console.error("There was an error!", error);
      });
  };
  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.first_name) {
      errors.first_name = "First Name is required!";
    }
    if (!values.last_name) {
      errors.last_name = "last Name is required";
    }
    if (!values.phone_number) {
      errors.phone_number = "Phone Number is required";
    }
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }

    if (!values.date_checkin) {
      errors.date_checkin = "This field is required";
    }
    if (!values.date_checkout) {
      errors.date_checkout = "This field is required";
    }
    if (!values.room_occupation) {
      errors.room_occupation = "This field is required";
    }
    return errors;
  };

  // $(document).ready(function(){
  //   $( "#datepicker" ).datepicker();
  // })
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={true}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Phone Number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="otp">Enter Phone Number</label>
            <input
              type="text"
              name="phone_number"
              placeholder="Enter Phone Number"
              className="form-control"
              required
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-md-5">
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </div>
            <div className="col-md-7">
              <Button
                disabled={isRegistering}
                onClick={registerTransaction}
                variant="primary">
                {isRegistering && (
                  <span className="wc-editable text-white">
                    <i className="fa fa-refresh fa-spin" /> Please Wait...
                  </span>
                )}
                {!isRegistering && (
                  <span className="wc-editable text-white">Pay</span>
                )}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {isLoading ? (
        <center>
          <LoadingSpinner />
        </center>
      ) : (
        <section>
          <div className="room-backend">
            <div className="room-hero">
              <div className="background mt-5">
                <h1 className="text-center text-white text-uppercase mt-5">
                  {room.room_name}
                </h1>
                <h4 className="text-center text-white text-uppercase mt-2 mb-5">
                  Room Details{" "}
                </h4>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 mt-5 mb-5">
                  <Carousel fade indicators={false} controls={false}>
                    <Carousel.Item className="hotel-carousel">
                      {room.room_images?.map((item, index) => {
                        return (
                          <img
                            key={index}
                            className="d-block w-100"
                            src={item.image}
                            alt={room.room_name}
                          />
                        );
                      })}
                    </Carousel.Item>
                  </Carousel>
                </div>
                <div className="col-md-6">
                  <h1 className="text-white text-center"> Room Booking Form</h1>
                  <div className="bg-agile">
                    <div className="book-appointment">
                      <h4 className="text-center text-warning">
                        <b>ZMW {room.room_price} / Per Night</b>
                      </h4>
                      <h2 className="text-white text-center">Book Now</h2>
                      <div className="book-form agileits-login">
                        <form onSubmit={bookRoom}>
                          <div className="agileits_reservation_grid row">
                            <div className="phone_email col-md-6">
                              <label htmlFor="" className="text-white">
                                First Name
                              </label>
                              <div className="form-text">
                                <input
                                  type="text"
                                  name="first_name"
                                  className="form-control bg-transparent"
                                  onChange={(e) => onChange(e)}
                                  value={formValues.first_name}
                                  placeholder="First name"
                                />
                              </div>
                              <p className="text-danger">
                                {formErrors.first_name}
                              </p>
                            </div>
                            <div className="phone_email phone_email1 col-md-6">
                              <label htmlFor="" className="text-white">
                                Last Name
                              </label>
                              <div className="form-text">
                                <input
                                  type="text"
                                  className="form-control bg-transparent"
                                  name="last_name"
                                  onChange={(e) => onChange(e)}
                                  value={formValues.last_name}
                                  placeholder="Last name"
                                />
                              </div>
                              <p className="text-danger">
                                {formErrors.last_name}
                              </p>
                            </div>

                            <div className="phone_email col-md-6">
                              <label htmlFor="" className="text-white">
                                Phone Number
                              </label>
                              <div className="form-text">
                                <input
                                  type="text"
                                  name="phone_number"
                                  placeholder="Phone number"
                                  className="form-control bg-transparent"
                                  onChange={(e) => onChange(e)}
                                  value={formValues.phone_number}
                                />
                              </div>
                              <p className="text-danger">
                                {formErrors.phone_number}
                              </p>
                            </div>
                            <div className="phone_email phone_email1 col-md-6">
                              <label htmlFor="" className="text-white">
                                E-mail
                              </label>
                              <div className="form-text">
                                <input
                                  type="email"
                                  className="form-control bg-transparent"
                                  onChange={(e) => onChange(e)}
                                  name="email"
                                  placeholder="Email"
                                  value={formValues.email}
                                />
                              </div>
                              <p className="text-danger">{formErrors.email}</p>
                            </div>

                            <div className="phone_email d-none">
                              <div className="form-text">
                                <input
                                  type="text"
                                  name="room_location"
                                  className="form-control bg-transparent"
                                  defaultValue={room.room_location}
                                  onChange={(e) =>
                                    (formData.room_location =
                                      e.target.defaultValue)
                                  }
                                />
                              </div>
                            </div>
                            <div className="phone_email phone_email1 d-none">
                              <div className="form-text">
                                <input
                                  type="text"
                                  className="form-control bg-transparent"
                                  onChange={(e) =>
                                    (formData.room_type = e.target.defaultValue)
                                  }
                                  name="room_type"
                                  defaultValue={room.room_type}
                                />
                              </div>
                            </div>

                            <div className="span1_of_1 phone_email1 col-md-6">
                              <label htmlFor="" className="text-white">
                                Date Checkin
                              </label>
                              <div className="book_date">
                                <input
                                  id="datepicker"
                                  name="date_checkin"
                                  className="form-control bg-transparent"
                                  type="date"
                                  onChange={(e) => onChange(e)}
                                  value={formValues.date_checkin}
                                  placeholder="Checkout Date"
                                />
                              </div>
                              <p className="text-danger">
                                {formErrors.date_checkin}
                              </p>
                            </div>
                            <div className="span1_of_1 phone_email col-md-6">
                              <label htmlFor="" className="text-white">
                                Date Checkin
                              </label>
                              <div className="book_date">
                                <input
                                  id="datepicker1"
                                  name="date_checkout"
                                  className="form-control bg-transparent"
                                  type="date"
                                  onChange={(e) => onChange(e)}
                                  value={formValues.date_checkout}
                                  placeholder="Checkin Date"
                                />
                              </div>
                              <p className="text-danger">
                                {formErrors.date_checkout}
                              </p>
                            </div>
                            <div className="span1_of_1">
                              <label htmlFor="" className="text-white">
                                No.of Guests
                              </label>
                              {/* <!-- start_section_room --> */}
                              <div className="section_room">
                                <select
                                  id="country"
                                  onChange={(e) => onChange(e)}
                                  name="room_occupation"
                                  className="frm-field required form-control">
                                  <option selected disabled>
                                    No.of guests
                                  </option>
                                  <option value="1">1</option>
                                  <option value="2">2 </option>
                                  <option value="3">3</option>
                                </select>
                              </div>
                              <p className="text-danger">
                                {formErrors.room_occupation}
                              </p>
                            </div>
                            <div className="clear"></div>
                            <div className="row bg-warning mb-4">
                              <h3>Available Rooms</h3>
                              {room.room?.map((item, index) => {
                                return (
                                  <div key={index} className="col-md-2">
                                    {item.roomNumber}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="form-group col-md-8 pull-right">
                            {formErrors ? (
                              <Button
                                type="submit"
                                className="btn btn-success form-control"
                                disabled={formSubmitting}>
                                {formSubmitting && (
                                  <span className="wc-editable ">
                                    Sending request ....
                                  </span>
                                )}
                                {!formSubmitting && (
                                  <span className="wc-editable ">
                                    Proceed with Mobile Money
                                  </span>
                                )}
                              </Button>
                            ) : (
                              <Button
                                onClick={handleShow}
                                className="btn btn-success form-control"
                                disabled={formSubmitting}>
                                {formSubmitting && (
                                  <span className="wc-editable ">
                                    Sending request ....
                                  </span>
                                )}
                                {!formSubmitting && (
                                  <span className="wc-editable ">
                                    Proceed with Mobile Money
                                  </span>
                                )}
                              </Button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h3 className="text-white">Room Description</h3>
                  <p className="text-white">
                    {capitalized(room.room_description)}
                  </p>
                </div>
                <div className="col-md-6">
                  <h1 className="text-white mt-4">Amenities</h1>
                  <hr className="text-white" />
                  <div className="row">
                    {room.amenities.map((item, index) => (
                      <div
                        key={index}
                        className="col-md-4 text-capitalize text-white">
                        <i className="fa fa-check-square text-success"></i>{" "}
                        {item}
                      </div>
                    ))}
                  </div>
                  <h1 className="text-white">Contact Information</h1>
                  <hr className="text-white" />
                  <div className="row">
                    {/* {hotel.hotel_name} */}
                    <div className="col-md-6 text-white">
                      <h5 className="text-white">Phone Number</h5>
                      <a href={`tel:${hotel.hotel_phone}`}>
                        <i className="fa fa-phone"></i> {hotel.hotel_phone}
                      </a>
                    </div>
                    <div className="col-md-6 text-white">
                      <h5 className="text-white">E-mail</h5>
                      <a href={`mailto:${hotel.hotel_email}`}>
                        <i className="fa fa-envelope"></i> {hotel.hotel_email}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <section className="text-white">
                <ImageGallery branch={roomID} imgUrl={ImgUrl} />
              </section>
            </div>
          </div>
          <section className="mt-4 container">
            <div className="mt-3 mb-5">
              <h3
                className="mb-3"
                style={{ fontSize: "20px", fontWeight: "bold" }}>
                {room.room_reviews?.length === 0 ? (
                  <div></div>
                ) : (
                  <span>
                    <h3>{room.room_reviews?.length} Reviews</h3>
                    <h1>
                      <Rating
                        size={50}
                        initialValue={room.rating_avg}
                        transition
                        allowFraction
                        showTooltip
                        tooltipDefaultText={`${room.rating_avg}/5`}
                        allowTitleTag={false}
                        readonly
                        tooltipStyle={{
                          paddingLeft: "2px",
                          paddingRight: "2px",
                          paddingBottom: "0px",
                          paddingTop: "0px",
                        }}
                        /* Available Props */
                      />
                    </h1>
                  </span>
                )}
              </h3>

              <ul className="comment-list">
                {hotel.room_reviews?.length < 1 ? (
                  <div className="alert alert-info text-center">
                    <i className="fa fa-comments fa-5x"></i>
                    <p>No Reviews</p>
                  </div>
                ) : (
                  room.room_reviews?.map((item, index) => (
                    <li key={index} className="comment">
                      <div className="vcard bio">
                        <img src={placeHolder} alt="Image placeholder" />
                      </div>
                      <div className="comment-body">
                        <h3 className="text-capitalize">
                          {item.user?.first_name} {item.user?.last_name}{" "}
                          <Rating
                            size={18}
                            initialValue={item.rating}
                            transition
                            allowFraction
                            showTooltip
                            tooltipDefaultText={`${item.rating}`}
                            allowTitleTag={false}
                            readonly
                            tooltipStyle={{
                              paddingLeft: "3px",
                              paddingRight: "3px",
                              paddingBottom: "0px",
                              paddingTop: "0px",
                            }}
                            /* Available Props */
                          />
                        </h3>
                        <div className="">
                          {moment(item.created_at).format("MMMM Do YYYY")}
                        </div>
                        <p>
                          <blockquote>{capitalized(item.review)}</blockquote>
                        </p>
                        {/* <p>
                        <a href="#" className="reply">
                          Reply
                        </a>
                      </p> */}
                      </div>
                    </li>
                  ))
                )}
              </ul>
            </div>
          </section>
          <RelatedRooms />
        </section>
      )}
    </>
  );
}
