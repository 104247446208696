import React from "react";
import dashboard from "../../../images/logo.png";
import product from "../../../assets/img/icons/product.svg";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "../../../hooks/redux-hooks";

const SideBar: React.FC = () => {
  const location = useLocation();
  const user = useAppSelector((state) => state.auth.user);
  function randomString(length: number, chars: string) {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  var token = randomString(100, `${localStorage.getItem("access")}`);
  return (
    <>
      <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li className={`${location.pathname === "/zm/car_dashboard/" ? "active" : ""}`}>
                <Link to={`/zm/car_dashboard/?uid=${user?.id}`}>
                  <img src={dashboard} alt="img" />
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className={`${location.pathname === "/zm/car_dashboard/cars" ? "active" : ""}`}>
                <Link to={`/zm/car_dashboard/cars`}>
                  <img src={product} alt="img" />
                  <span>Cars</span>
                  <span className="menu-arrow"><i className="fa fa-arrow-right"></i></span>
                </Link>
                
              </li>
              <li className={`${location.pathname === "/zm/car_dashboard/customer_bookings" ? "active" : ""}`}>
                <Link to="/zm/car_dashboard/customer_bookings">
                  <i className="fa fa-car"></i>
                  <span>Reservations</span>
                  <span className="menu-arrow"><i className="fa fa-arrow-right"></i></span>
                </Link>
              </li>
              <li className={`${location.pathname === "/zm/car_dashboard/customers" ? "active" : ""}`}>
                <a href="/zm/car_dashboard/customers">
                  <i className="fa fa-users"></i>
                  <span>Customers</span>
                  <span className="menu-arrow"><i className="fa fa-arrow-right"></i></span>
                </a>
              </li>
              <li>
                <a href={`/zm/car_dashboard/terms_of_service/${token}/${user?.id}`}>
                  <i className="fa fa-book"></i>
                  <span>Terms & Conditions</span>
                  <span className="menu-arrow"><i className="fa fa-arrow-right"></i></span>
                </a>
              </li>
              <li>
                <a href={`/zm/car_dashboard/privacy_policy/${token}/${user?.id}`}>
                  <i className="fa fa-book"></i>
                  <span>View Privacy Policy</span>
                  <span className="menu-arrow"><i className="fa fa-arrow-right"></i></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
