import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

import { useParams } from "react-router-dom";
import LoadingSpinner from "../../../components/widgets/spinner";

const BusRoutes = () => {
  const [routes, setRoutes] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { uid } = useParams();

  function randomString(length, chars) {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  var token = randomString(100, `${localStorage.getItem("access")}`);

  const fetchData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    await axios
      .get(`${process.env.REACT_APP_API_URL}/auth/users/me/`, config)
      .then((res) => {
        setUser(res.data);
        // setIsLoading(false);
      });

    //get company routes
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/bus-routes/${uid}/rmf_travellers`,config)
        .then((res) => {
          setRoutes(res.data);
          setIsLoading(false);
        });
    } catch (error) {}

    //get provinces
    await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/home/rmf_travellers`,config).then((res) => {
      setProvinces(res.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div>
        <div className="App">
          <div className="main-content ">
            <div className="container">
              <div className="row">
                <div className="col px-4 py-5">
                  <h3 className="text-capitalize mt-4">my routes</h3>
                  <p className="text-muted">
                    This shows the list of routes assigned to you.{" "}
                    <span className="text-primary">
                      You can now add fares to the various stoppages of a route
                      from{" "}
                    </span>
                    <span>
                      <a
                        href={`/dashboard/busfares/${token}/${uid}`}
                        className="text-danger"
                      >
                        here
                      </a>
                    </span>
                  </p>

                  {/* down here */}
                  <div className="table-responsive">
                    {isLoading ? (
                      <LoadingSpinner />
                    ) : (
                      <table
                        id="example"
                        className="table table-striped table-lg"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Created On</th>
                          </tr>
                        </thead>
                        <tbody>
                          {routes.map((route, index) => (
                            <tr key={index}>
                              <td className="col-auto">
                                <p className="font-bold ms-3 mb-0">
                                  {index + 1}
                                </p>
                              </td>
                              <td className="col-auto">
                                <p className=" mb-0 text-capitalize">
                                  {provinces.map((pro) =>
                                    pro.id === route.from_province
                                      ? pro.province_name
                                      : null
                                  )}
                                </p>
                              </td>
                              <td className="col-auto">
                                <p className=" mb-0 text-capitalize">
                                  {provinces.map((pro) =>
                                    pro.id === route.to_province
                                      ? pro.province_name
                                      : null
                                  )}
                                </p>
                              </td>

                              <td>
                                {moment(route.created_at).format(
                                  "MMMM Do YYYY hh:m a"
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusRoutes;
