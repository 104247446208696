import React from "react";
import { Navigate } from "react-router-dom";
import logo from "../assets/img/trans_logo.png";
import { User } from "../redux/types/user";
import { profile } from "../redux/features/authSlice";
import { useRetrieveUserQuery } from "../redux/slices/authApiSlice";
import { useAppDispatch } from "../hooks/redux-hooks";

const UserRedirect = () => {
  const dispatch = useAppDispatch();
  const {
    data: user,
    isLoading,
    isSuccess,
    error,
  }: User | any = useRetrieveUserQuery();

  if (isLoading) {
    return (
      <>
        <center>
        <div className="mt-5">
          <img
            src={logo}
            alt=""
            className="img-responsive"
            style={{ height: "200px" }}
          />
        </div>
        <div className="mb-5">
          <i className="fa fa-spinner fa-spin fa-4x text-danger"></i>
          <h3 className="text-primary">Redirecting....</h3>
        </div>
      </center>
      </>
    );
  }

  if (error) {
    console.log(error.status);
    return (
      <center>
        <div style={{ marginTop: "15%", width: '30%' }} className="alert alert-danger text-center" role="alert">
        <div className="mt-2">
          <img
            src={logo}
            alt=""
            className="img-responsive"
            style={{ height: "200px" }}
          />
        </div>
        An Error occured {error.status}
        </div>
        <a href="/" className="btn btn-primary">Back Home</a>
      </center>
    );
  }
  // React.useEffect(() => {
  //   if (isSuccess && user) {
  //     dispatch(profile(user));
  //   }
  // }, [isSuccess, user]);

  if (isSuccess && user) {
    localStorage.setItem("userInfo", JSON.stringify(user));
    dispatch(profile(user));
    // window.location.reload();
    if (user?.user_type === "HOTEL") {
      return <Navigate replace={true} to={`/zm/hotel_dashboard/?uid=${user.id}`} />;
    } else if (user?.user_type === "COMMUTER") {
      return <Navigate replace={true} to={`/zm/dashboard/?uid=${user.id}`} />;
    } else if (user?.user_type === "REFERAL" || user?.user_type === "CUSTOMER") {
      return <Navigate replace={true} to={`/zm/profile/?uid=${user.id}`} />;
    } else if (user?.user_type === "CARHIRE") {
      return <Navigate replace={true} to={`/zm/car_dashboard/?uid=${user.id}`} />;
    } else if (user?.user_type === "ADMIN") {
      return <Navigate replace={true} to={`/zm/intercity_travellers_admin/?uid=${user.id}`} />;
    }
  }
  return (
    <>
      <center>
        <div className="mt-5">
          <img
            src={logo}
            alt=""
            className="img-responsive"
            style={{ height: "200px" }}
          />
        </div>
        <div className="mb-5">
          <i className="fa fa-spinner fa-spin fa-4x text-danger"></i>
          <h3 className="text-primary">Redirecting....</h3>
        </div>
      </center>
    </>
  );
};

export default UserRedirect;
