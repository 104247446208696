import React from "react";
import user from "../assets/img/icons/users1.svg";
import emailIcon from "../assets/img/icons/mail.svg";
import { Link } from "react-router-dom";
import { useRegister } from "../hooks";

const RegisterUser: React.FC = () => {
  const [view, setView] = React.useState<boolean>(false);
  const [countries, setCountries] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState("");
  const [formData, setFormData] = React.useState({
    nationality: "",
  });
  React.useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        setSelectedCountry(data.userSelectValue);
      });
  }, []);

  const onNationalityChange = (event: any) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };
  const {
    first_name,
    last_name,
    email,
    password,
    re_password,
    isLoading,
    onChange,
    onSubmit,
  } = useRegister({nationality: formData.nationality});
  return (
    <div className="account-page">
      <div className="main-wrapper">
        <div className="account-content">
          <div className="login-wrapper">
            <div className="login-content">
              <div className="login-userset">
                <center>
                  <div className="login-logo">
                    <img src={require("../assets/img/logo.png")} alt="img" />
                  </div>
                </center>
                <div className="login-userheading">
                  <h3>Create an Account</h3>
                  {/* <h4>Continue where you left off</h4> */}
                </div>
                <form onSubmit={onSubmit}>
                <div className="form-login">
                    <label>Email</label>
                    <div className="form-addons">
                      <input
                        type="email"
                        name="email"
                        required
                        onChange={onChange}
                        value={email}
                        disabled={isLoading}
                        placeholder="Enter your email address"
                      />
                      <img src={emailIcon} alt="img" />
                    </div>
                  </div>
                  <div className="form-login">
                    <label>First Name</label>
                    <div className="form-addons">
                      <input
                        type="text"
                        name="first_name"
                        required
                        onChange={onChange}
                        value={first_name}
                        disabled={isLoading}
                        placeholder="Enter your first name"
                      />
                      <img src={user} alt="img" />
                    </div>
                  </div>
                  <div className="form-login">
                    <label>Last Name</label>
                    <div className="form-addons">
                      <input
                        type="text"
                        required
                        onChange={onChange}
                        name="last_name"
                        value={last_name}
                        disabled={isLoading}
                        placeholder="Enter your last name"
                      />
                      <img src={user} alt="img" />
                    </div>
                  </div>
                  <div className="form-login">
                    <label htmlFor="nationality">Nationality</label>
                    <div className="pass-group">
                    <select required className="form-control" name="nationality" onChange={(e) => onNationalityChange(e)}>
                      <option selected value="">Select Nationality</option>
                      {countries.map((item: any, index) => (
                        <option key={index} value={item.label.substring(5, 20)}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                    {/* <span className="fa fa-flag"></span> */}
                    </div>
                                        
                  </div>
                  <div className="form-login">
                    <label>Password</label>
                    <div className="pass-group">
                      <input
                        type={view ? "text" : "password"}
                        name="password"
                        required
                        onChange={onChange}
                        value={password}
                        disabled={isLoading}
                        className="pass-input"
                        placeholder="Enter your password"
                      />
                      <span
                        onClick={() => setView(() => !view)}
                        className={view ? "fa toggle-password fa-eye": "fa toggle-password fa-eye-slash"}></span>
                    </div>
                  </div>
                  <div className="form-login">
                    <label>Password</label>
                    <div className="pass-group">
                      <input
                        type={view ? "text" : "password"}
                        required
                        onChange={onChange}
                        name="re_password"
                        value={re_password}
                        disabled={isLoading}
                        className="pass-input"
                        placeholder="Enter your password"
                      />
                      <span
                        onClick={() => setView(() => !view)}
                        className={view ? "fa toggle-password fa-eye": "fa toggle-password fa-eye-slash"}></span>
                    </div>
                    {password.length !== 0 && re_password.length !== 0 && password !== re_password ? <p className="text-danger">Passwords do not match</p>: null}
                  </div>
                  <div className="form-login">
                    <button disabled={isLoading || password !== re_password} className="btn btn-login">
                      {isLoading && (
                        <div className="spinner-border spinner-border-sm text-danger"></div>
                      )}
                      Sign Up
                    </button>
                  </div>
                </form>
                <div className="signinform text-center">
                  <h4>
                    Already a user?
                    <Link to="/zm/auth/login" className="hover-a">
                      Sign In
                    </Link>
                  </h4>
                </div>
                {/* <div className="form-setlogin">
                  <h4>Or sign up with</h4>
                </div> */}
                {/* <div className="form-sociallink">
                  <ul>
                    <li>
                      <a href="javascript:void(0);">
                        <img
                          src={require("../assets/img/icons/google.png")}
                          className="me-2"
                          alt="google"
                        />
                        Sign Up using Google
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <img
                          src={require("../assets/img/icons/facebook.png")}
                          className="me-2"
                          alt="google"
                        />
                        Sign Up using Facebook
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="login-img shadow">
              <img src={require("../assets/img/auth.jpg")} alt="img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterUser;
