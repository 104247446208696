import React from "react";

interface IPagination{
    page: number;
    setPage: (page: any)=> void;
    data: any[];
}

function Pagination({page, setPage, data}: IPagination) {
  return (
    <>
      <div className="proclinic-widget">
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <button
                disabled={page <= 1}
                onClick={() => setPage((prev: number) => prev - 1)}
                className="page-link"
                aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only">Previous</span>
              </button>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                1
              </a>
            </li>

            <li className="page-item">
              <button
                disabled={data.length < 10}
                onClick={() => setPage((prev: number) => prev + 1)}
                className="page-link"
                aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
                <span className="sr-only">Next</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default Pagination;
