import React from "react";
import moment from "moment";
import { ICarBooking } from "../../../../redux/types/car_booking.type";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { useGetCustomerBookingQuery } from "../../../../redux/slices/car_booking_slice";

const CustomerProfile: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const customer_id = queryParams.get("customer_id");
  const {
    data: reservations,
    isLoading,
    error,
  }: ICarBooking | any = useGetCustomerBookingQuery({
    customer: customer_id,
  });

  if (isLoading) {
    return (
      <center>
        <LoadingSpinner />
      </center>
    );
  }
  if (error) {
    return (
      <div className="text-center alert alert-danger mt-5" role="alert">
        <h4>Error: {error.status}</h4>
      </div>
    );
  }

  return (
    <>
      <section style={{ backgroundColor: "#eee" }}>
        <div className="content py-5">
          <div className="row">
            <div className="col-lg-4">
              <div className="card mb-4">
                <div className="card-body text-center">
                  <img
                    src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
                    alt="avatar"
                    className="rounded-circle img-fluid"
                    style={{ width: "150px" }}
                  />
                  <h5 className="my-3 text-capitalize">
                    {reservations[0].client.first_name}{" "}
                    {reservations[0].client.last_name}
                  </h5>
                  {/* <p className="text-muted mb-1">Full Stack Developer</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Full Name</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0 text-capitalize">
                        {reservations[0].client.first_name}{" "}
                        {reservations[0].client.last_name}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Email</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">
                        {reservations[0].client.email}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Phone</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{reservations[0]?.phone_number}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Address</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">
                      {reservations[0]?.client_address}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="card mb-4 mb-md-0">
              <div className="card-body table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>E-mail</th>
                      <th>Phone Number</th>
                      <th>Paid</th>
                      <th>Pick Up Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {reservations.map((item: ICarBooking, index: number) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="text-capitalize">
                          {item.client.first_name} {item.client.last_name}
                        </td>
                        <td>{item.client.email}</td>
                        <td>{item.phone_number}</td>
                        <td>ZMW {item.rental_price.toLocaleString()}</td>
                        <td>
                          {moment(item.client_pickup_date_time).format("MMM do YYYY")}
                          Days
                        </td>
                        <td>
                          <div className="badges bg-lightred text-white text-capitalize">
                            <b>{item.status}</b>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CustomerProfile;
