import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import axios from "axios";

function AddCommuterRoute({ fetchRoutes, company, provinces }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handlePostData = async (data) => {
    // e.preventDefault();
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    setFormSubmitting(true);
    const body = {
      commutor: data.commutor,
      from_province: data.from_route,
      to_province: data.to_route,
    };

    await axios
      .post(`${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/assign-route/rmf_travellers`, body, config)
      .then((response) => {
        // console.log(response.data)
        fetchRoutes();
        setShow(false);
        setFormSubmitting(false);
        Toast.fire({
          icon: "success",
          title: "Route assigned Succesfully",
        });
      })
      .catch((error) => {
        setFormSubmitting(false);
        Toast.fire({
          icon: "error",
          title: `An error occurred try again later! ${error.message}`,
        });
        // console.error('There was an error!', error);
      });
  };

  return (
    <>
      <div className="col-md-5 pull-right">
        <button className="btn btn-primary" onClick={handleShow}>
          Assign Route
        </button>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign Route to a Commuter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(handlePostData)}>
            <div className="form-group">
              <label>Select Commutor</label>
              <select
                {...register("commutor", { required: true })}
                className="form-control text-capitalize"
                disabled={formSubmitting}
                name="commutor"
              >
                <option value="">Select Commutor</option>
                {company.map((com, index) =>
                  com.is_commutor === true ? (
                    <option key={index} value={com.id}>
                      {com.company_name}
                    </option>
                  ) : null
                )}
              </select>
              {errors.commutor && (
                <p className="text-danger">This field is required</p>
              )}
            </div>
            <div className="form-group">
              <label>Select From</label>
              <select
                {...register("from_route", { required: true })}
                className="form-control text-capitalize"
                disabled={formSubmitting}
                name="from_route"
              >
                <option value="">Select From</option>
                {provinces.map((pro, index) => (
                  <option key={index} value={pro.id}>
                    {pro.province_name}
                  </option>
                ))}
              </select>
              {errors.from_route && (
                <p className="text-danger">This field is required</p>
              )}
            </div>
            <div className="form-group">
              <label>Select To</label>
              <select
                {...register("to_route", { required: true })}
                className="form-control text-capitalize"
                disabled={formSubmitting}
                name="to_route"
              >
                <option value="">Select To</option>
                {provinces.map((pro, index) => (
                  <option key={index} value={pro.id}>
                    {pro.province_name}
                  </option>
                ))}
              </select>
              {errors.to_route && (
                <p className="text-danger">This field is required</p>
              )}
            </div>
            <Modal.Footer>
              <div className="row">
                <div className="col-md-7">
                  <button
                    type="submit"
                    disabled={formSubmitting}
                    className="btn btn-outline-success"
                  >
                    {formSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </div>
                <div className="col-md-5">
                  <button
                    type="button"
                    disabled={formSubmitting}
                    className="btn btn-outline-warning"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default AddCommuterRoute;
