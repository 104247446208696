import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useParams } from "react-router-dom";

import AddBusFares from "./add_bus_fare";
import LoadingSpinner from "../../../components/widgets/spinner";
import EditBusFares from "./editBusFares";

const BusFares = () => {
  const [routes, setRoutes] = useState([]);
  const [buses, setBuses] = useState([]);
  const [busfares, setBusfares] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [stations, setStations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { uid } = useParams();
  const [editBus, setEditBus] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (room) => {
    setEditBus(room);
    setShow(true);
  };

  const fetchData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    // get stations
    await axios
      .get(`${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/get_stations/${uid}/rmf_travellers`, config)
      .then((res) => {
        setStations(res.data);
      });
    //get company buses
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/buses/${uid}/rmf_travellers`)
        .then((res) => {
          setBuses(res.data);
          // setIsLoading(false);
        });
    } catch (error) {
      // alert(error.message);
    }

    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/bus-routes/${uid}/rmf_travellers`)
        .then((res) => {
          setRoutes(res.data);
          // setIsLoading(false);
        });
    } catch (error) {}

    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/get_busfares/${uid}/rmf_travellers`)
        .then((sear) => {
          setBusfares(sear.data);
          setIsLoading(false);
        });
    } catch (error) {}

    //get provinces
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/api/v1/intercity_travellers/home/rmf_travellers`)
        .then((res) => {
          setProvinces(res.data);
          // setIsLoading(false);
        });
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="App">
        <div className="main-content">
          <div className="col-lg-12 mt-4 mt-lg-0">
            <div className="row">
              <div className="col-12 col-md-12 mt-3">
                <h2 className="mt-5">My Bus Fares</h2>

                {/* modal here */}
                <AddBusFares
                  provinces={provinces}
                  buses={buses}
                  stations={stations}
                  uid={uid}
                  fetchFares={fetchData}
                  busRoutes={routes}
                />

                <div className="card-body">
                  <div className="table-responsive">
                    {isLoading ? (
                      <LoadingSpinner />
                    ) : busfares.length === 0 ? (
                      <div className="alert alert-danger text-center">
                        There are no BusFares
                      </div>
                    ) : (
                      <table
                        id="example"
                        className="table table-striped data-table table-lg"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Bus</th>
                            <th>Source</th>
                            <th>Destination</th>
                            <th>Departure Date / Time</th>
                            <th>Arrival Date / Time</th>
                            <th>Price</th>
                            <th>Created On</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {busfares.map((bus, index) => (
                            <tr key={index}>
                              <td className="col-auto">
                                <p className="font-bold ms-3 mb-0">
                                  {index + 1}
                                </p>
                              </td>
                              <td className="col-auto">
                                <p className="text-capitalize mb-0">
                                  {buses.map((roading) =>
                                    roading.id === bus.going_bus ? (
                                      <span className="text-uppercase">
                                        {roading.number_place} -{" "}
                                        {roading.bus_role}{" "}
                                      </span>
                                    ) : null
                                  )}
                                </p>
                              </td>

                              <td className="col-auto">
                                <p className="text-capitalize mb-0">
                                  {bus.from_route}
                                </p>
                              </td>
                              <td className="col-auto">
                                <p className="text-capitalize mb-0">
                                  {bus.to_route}
                                </p>
                              </td>
                              <td className="col-auto">
                                <p className="text-capitalize mb-0">
                                  {moment(bus.departure_date).format(
                                    "MMMM Do, YYYY"
                                  )}{" "}
                                  {`${bus.departure_time}hrs`}
                                </p>
                              </td>
                              <td className="col-auto">
                                <p className="text-capitalize mb-0">
                                  {moment(bus.arrivalDay).format(
                                    "MMMM Do, YYYY"
                                  )}
                                  {", "}
                                  {`${bus.arrival_time}hrs`}
                                </p>
                              </td>
                              <td className="col-auto">
                                <p className="text-capitalize mb-0">
                                  K {bus.route_price}
                                </p>
                              </td>
                              <td className="col-auto">
                                <p className="text-capitalize mb-0">
                                  {moment(bus.created_at).format(
                                    "MMMM Do, YYYY, HH:mm a"
                                  )}
                                </p>
                              </td>
                              <td>
                                <button
                                  className="btn btn-sm btn-info"
                                  onClick={() => handleShow(bus)}
                                >
                                  <i className="fa fa-edit"></i> Edit
                                </button>
                              </td>
                              <EditBusFares
                                routes={routes}
                                buses={buses}
                                busfares={editBus === null ? bus : editBus}
                                provinces={provinces}
                                uid={uid}
                                stations={stations}
                                show={show}
                                handleClose={handleClose}
                              />
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusFares;
