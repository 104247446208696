import React from "react";
import { Outlet } from "react-router-dom";
import SidebarNav from "../navigation/SideNav";
import TopNav from "../navigation/top_nav";

const UserMainLayout = () => {
  return (
    <>
    <div className="main-wrapper">
          <TopNav />
          <SidebarNav />
          <div className="page-wrapper">
            <Outlet />
        </div>
      </div>
    </>
  );
};

export default UserMainLayout;
