import React from 'react'
import dash1 from "../../../assets/img/icons/dash1.svg";
import dash2 from "../../../assets/img/icons/dash2.svg";
import dash3 from "../../../assets/img/icons/dash3.svg";
import dash4 from "../../../assets/img/icons/dash4.svg";
import { IRoomReservation } from '../../../redux/types/room_reservation.type';

interface IWelcomeDisplay{
  totalAmount: number;
  reservations: IRoomReservation[];
}

const WelcomeDisplay = ({totalAmount, reservations}: IWelcomeDisplay) => {
  return (
    <>
      <div className="row">
          <div className="col-lg-4 col-sm-6 col-12 shadow border-0 rounded-3 mb-4">
            <div className="dash-widget">
              <div className="dash-widgetimg">
                <span>
                  <img src={dash1} alt="img" />
                </span>
              </div>
              <div className="dash-widgetcontent">
                <h5>
                  <span className="counters" data-count={reservations.length}>
                    {reservations.length}
                  </span>
                </h5>
                <h6>Pending Reservations</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12 shadow border-0 rounded-3 mb-4">
            <div className="dash-widget dash1">
              <div className="dash-widgetimg">
                <span>
                  <img src={dash2} alt="img" />
                </span>
              </div>
              <div className="dash-widgetcontent">
                <h5> 
                  <span className="counters" data-count={totalAmount}>
                   ZMW {totalAmount}
                  </span>
                </h5>
                <h6>Total Sales Due</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12 shadow border-0 rounded-3 mb-4">
            <div className="dash-widget dash3">
              <div className="dash-widgetimg">
                <span>
                  <img src={dash4} alt="img" />
                </span>
              </div>
              <div className="dash-widgetcontent">
                <h5>
                  $
                  <span className="counters" data-count="40000.00">
                    400.00
                  </span>
                </h5>
                <h6>Total Sale Amount</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count">
              <div className="dash-counts">
                <h4>100</h4>
                <h5>Customers</h5>
              </div>
              <div className="dash-imgs">
                <i data-feather="user"></i>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count das1">
              <div className="dash-counts">
                <h4>5</h4>
                <h5>Branches</h5>
              </div>
              <div className="dash-imgs">
                <i data-feather="user-check"></i>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count das2">
              <div className="dash-counts">
                <h4>100</h4>
                <h5>Rooms</h5>
              </div>
              <div className="dash-imgs">
                <i data-feather="file-text"></i>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count das3">
              <div className="dash-counts">
                <h4>105</h4>
                <h5>Total Confirmed Reservations</h5>
              </div>
              <div className="dash-imgs">
                <i data-feather="file"></i>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default WelcomeDisplay
