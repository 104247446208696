import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import axios from "axios";

function UpdateRoute({ show, handleClose, handleShow, provinces, details }) {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handlePostData = async (data) => {
    // e.preventDefault();
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    setFormSubmitting(true);
    const body = {
      commutor: data.commutor,
      from_province: data.from_route,
      to_province: data.to_route,
    };

    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/update-route/${details.id}/rmf_travellers`,
        body,
        config
      )
      .then((response) => {
        // console.log(response.data)
        setTimeout(() => {
          setFormSubmitting(false);
          window.location.reload();
        }, 2000);
        Toast.fire({
          icon: "success",
          title: "Route Updated Succesfully",
        });
      })
      .catch((error) => {
        setFormSubmitting(false);
        Toast.fire({
          icon: "error",
          title: `An error occurred ${error.message}`,
        });
        // console.error('There was an error!', error);
      });
  };

  return (
    <>
      <button className="btn btn-sm btn-warning" onClick={handleShow}>
        {" "}
        <i className="fa fa-edit"></i> Edit
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <spand>Edit Commuter Route</spand>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(handlePostData)}>
            <div className="form-group">
              <label>Select From</label>
              <select
                {...register("from_route", { required: true })}
                className="form-control text-capitalize"
                disabled={formSubmitting}
                name="from_route"
              >
                <option selected value={details.from_province}>
                  Current:{" "}
                  {provinces.map((item) =>
                    item.id === details.from_province ? item.province_name : ""
                  )}
                </option>
                {provinces.map((pro, index) => (
                  <option key={index} value={pro.id}>
                    {pro.province_name}
                  </option>
                ))}
              </select>
              {errors.from_route && (
                <p className="text-danger">This field is required</p>
              )}
            </div>
            <div className="form-group">
              <label>Select To</label>
              <select
                {...register("to_route", { required: true })}
                className="form-control text-capitalize"
                disabled={formSubmitting}
                name="to_route"
              >
                <option selected value={details.to_province}>
                  Current:{" "}
                  {provinces.map((item) =>
                    item.id === details.to_province ? item.province_name : ""
                  )}
                </option>
                {provinces.map((pro, index) => (
                  <option key={index} value={pro.id}>
                    {pro.province_name}
                  </option>
                ))}
              </select>
              {errors.to_route && (
                <p className="text-danger">This field is required</p>
              )}
            </div>
            <Modal.Footer>
              <div className="row">
                <div className="col-md-7">
                  <button
                    type="submit"
                    disabled={formSubmitting}
                    className="btn btn-success"
                  >
                    {formSubmitting ? "Updating Route..." : "Submit"}
                  </button>
                </div>
                <div className="col-md-5">
                  <button
                    type="button"
                    disabled={formSubmitting}
                    className="btn btn-outline-warning"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default UpdateRoute;
