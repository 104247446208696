import React, { useState, useEffect } from "react";
import httpBranchServices from "../../services/http.branch.services";
import { TotalAmount } from "../../../utils/add_numbers";


// interface IReport{
//   branch: IBranch,
//   groupBy: string;
// }


function MonthlyReports({ branch }) {
  const [monthlyReport, setMonthlyReport] = useState([]);
  const [branchData, setBranchData] = useState([]);

  const fetchReports = () => {
    try {
      httpBranchServices
        .getReports(branch.id, "month")
        .then((reports) => {
          setMonthlyReport(reports.data.monthly_report);
          setBranchData(reports.data);
        })
        .catch((error) => {});
    } catch (error) {}
  };

  useEffect(() => {
    fetchReports();
  }, []);


  return (
    <>
        <div className="mb-3 text-center text-white">
          <div className="card bg-success">
            <div className="card-body">
              <div
                className="rounded shadow"
                style={{
                  background: "#00162b",
                  width: "80px",
                  height: "40px",
                  float: "right",
                  margin: "auto",
                  display: "flex",
                }}>
                <h6 className="text-white" style={{ margin: "auto" }}>
                  Sales {Number(monthlyReport[0]?.count).toLocaleString()}
                </h6>
              </div>
              <i className="fa fa-line-chart fa-3x"></i>
              <h4 className="text-white text-capitalize mt-3">
                monthly bookings
              </h4>
              <h5 className="text-white">
                ZMW <TotalAmount data={monthlyReport} />
              </h5>
            </div>
          </div>
        </div>
    </>
  );
}

export default MonthlyReports;
