export const TotalAmount = ({ data }) => {
  let amount = data.map((item) => parseInt(item.room_price));

  const total_amount = amount.reduce(function (acc, val) {
    return acc + val;
  }, 0);
  return Number(total_amount).toLocaleString();
};

export const ReportTotalAmount = ({ data }) => {
  let amount = data.map((item) => parseInt(item.room_price));

  const total_amount = amount.reduce(function (acc, val) {
    return acc + val;
  }, 0);
  return Number(total_amount).toLocaleString();
};
