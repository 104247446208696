import React from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../../../components/widgets/spinner";
import { urlToken } from "../../../../helper";
import { ICarBooking } from "../../../../redux/types/car_booking.type";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { useGetCarCustomersQuery } from "../../../../redux/slices/car_booking_slice";

const Customers = () => {
  const user = useAppSelector((state) => state.auth.user);
  const {
    data: reservations,
    isLoading,
    error,
  }: ICarBooking | any = useGetCarCustomersQuery({
    company: user?.id,
  });

  if (isLoading) {
    return (
      <center>
        <LoadingSpinner />
      </center>
    );
  }
  if (error) {
    return (
      <div className="text-center alert alert-danger" role="alert">
        <h4>{error.status}</h4>
      </div>
    );
  }
  return (
    <div className="content">
      <section>
        <div className="container-fluid py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            {reservations.length === 0 ? (
              <center>
                <div
                  className="alert alert-info text-center"
                  style={{ width: "fit-content" }}>
                  <i className="fa fa-users fa-5x"></i>
                  <h1>Not Data Found</h1>
                </div>
              </center>
            ) : (
              reservations.map((item: ICarBooking, index: number) => {
                return (
                  <div key={index} className="col-md-3 col-sm-2 col-xl-3">
                    <div className="card" style={{ borderRadius: "15px" }}>
                      <div className="card-body text-center">
                        <div className="mt-3 mb-4">
                          <img
                            src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava2-bg.webp"
                            className="rounded-circle img-fluid"
                            style={{ width: "100px" }}
                          />
                        </div>
                        <h4 className="mb-2 text-capitalize">
                          {item.client.first_name} {item.client.last_name}
                        </h4>
                        <p className="text-muted mb-4">{item.client.email}</p>
                        <Link
                          to={`/zm/car_dashboard/customer_profile/${urlToken}/?customer_id=${item.client.id}`}
                          className="btn btn-primary btn-rounded btn-lg">
                          Details
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Customers;
