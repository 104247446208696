import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { Link, useParams } from "react-router-dom";
import LoadingSpinner from "../../../components/widgets/spinner";

const TicketDetails = () => {
  const [tickets, setTickets] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [confirming, setConfirming] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [buses, setBuses] = useState([]);
  const [commuters, setCommuters] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [user, setUser] = useState(null);

  const { ticket_no, uid } = useParams();

  const fetchData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    // get user
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/auth/users/me/`, config)
        .then((res) => {
          setUser(res.data);
          // setIsLoading(false);
        });
    } catch (err) {}

    //  get commuter buses
    try {
      // console.log(`==== printing user ${uid} ======`);
      await axios
        .get(`${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/buses/${uid}/rmf_travellers`, config)
        .then((res) => {
          setBuses(res.data);
          // setIsLoading(false);
        });
    } catch (error) {}

    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/view_ticket_no/${uid}/${ticket_no}/rmf_travellers`, config
      )
      .then((res) => {
        setTickets(res.data);
        setErrorMessage(res.data["status"]);
        setIsLoading(false);
      });
    // get commuters
    // get users
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/userprofile/v1/intercity_travellers/get-commuters/rmf_travellers`, config)
        .then((users) => {
          setCommuters(users.data);
          // setIsLoading(false);
        });
    } catch (error) {}
  };

  useEffect(() => {
    setOpenModal(true);
    fetchData();
  }, [errorMessage]);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const handleSubmit = async (e, ticketID) => {
    setConfirming(true);
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = {
      status: "confirmed",
    };

   await axios
      .patch(
        `${process.env.REACT_APP_API_URL}/buses/v1/intercity_travellers/update-ticket/${ticketID}/rmf_travellers`,
        body,
        config
      )
      .then((response) => {
        setErrorMessage(response.data["status"]);
        // console.log(response.data["status"]);
        setTimeout(() => {
          // this.props.history.push(`/admin-dashboard`)
          setConfirming(false);
        }, 2000);

        Toast.fire({
          icon: "success",
          title: "Ticket Confirmed Successful!",
        });
      })
      .catch((error) => {
        setConfirming(false);

        Toast.fire({
          icon: "error",
          title: "An Error occurred try again later!",
        });

        // setErrorMessage(error.message);
        // console.error('There was an error!', error);
      });
  };

  return (
    <>
      <div className="main-content">
        <div className="col-lg-12 mt-4 mt-lg-0">
          <div className="col-12 col-md-12">
            <div className="card-body">
              <Modal
                size="xl"
                show={openModal}
                onHide={() => setOpenModal(false)}
                animation={false}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header>
                  <Modal.Title>Ticket Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="table-responsive">
                    {isLoading ? (
                      <center>{<LoadingSpinner />}</center>
                    ) : (
                      <table className="table table-lg">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Ticket</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>N.O.K</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Seat</th>
                            <th>Paid</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tickets.map((item, index) => (
                            <tr key={index}>
                              <td className="col-auto">
                                <small className="font-bold mb-0">
                                  {index + 1}
                                </small>
                              </td>
                              <td className="col-auto">
                                <small className="font-bold mb-0">
                                  {item.ticket_no}
                                </small>
                              </td>
                              <td className="col-auto">
                                <small className="font-bold mb-0 text-capitalize">
                                  {item.first_name === ""
                                    ? commuters.map((prof) =>
                                        prof.id === item.customer
                                          ? prof.first_name
                                          : null
                                      )
                                    : item.first_name}{" "}
                                  {item.last_name === ""
                                    ? commuters.map((prof) =>
                                        prof.id === item.customer
                                          ? prof.last_name
                                          : null
                                      )
                                    : item.last_name}
                                </small>
                              </td>
                              <td className="col-auto">
                                <small className="font-bold mb-0 text-capitalize">
                                  {item.phone_no === ""
                                    ? commuters.map((prof) =>
                                        prof.user === item.customer
                                          ? prof.phone
                                          : null
                                      )
                                    : item.phone_no}
                                </small>
                              </td>
                              <td className="col-auto">
                                <small className="mb-0 text-capitalize">
                                  {item.phone_no === ""
                                    ? commuters.map((prof) =>
                                        prof.user === item.customer
                                          ? prof.nof
                                          : null
                                      )
                                    : item.phone_no}
                                </small>
                              </td>

                              <td className="col-auto">
                                <small className="mb-0 text-capitalize">
                                  {item.from_route}
                                </small>
                              </td>
                              <td className="col-auto">
                                <small className="mb-0 text-capitalize">
                                  {item.to_route}
                                </small>
                              </td>

                              <td className="col-auto">
                                <small className="mb-0">{item.seat_no}</small>
                              </td>
                              <td className="col-auto">
                                <small className="mb-0">K {item.amount}</small>
                              </td>
                              <td>
                                {item.status === "confirmed" &&
                                errorMessage != "pending" ? (
                                  <span className="text-success text-capitalize">
                                    {item.status}
                                  </span>
                                ) : item.status === "pending" ? (
                                  <button
                                    onClick={(e) => handleSubmit(e, item.id)}
                                    type="submit"
                                    className="btn btn-sm btn-primary"
                                    disabled={confirming}
                                  >
                                    <span className="wc-editable">
                                      {confirming ? (
                                        <span>
                                          <i className="fa fa-refresh fa-spin" />{" "}
                                          Confirming..
                                        </span>
                                      ) : (
                                        "Confirm"
                                      )}
                                    </span>
                                  </button>
                                ) : null}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                  <Modal.Footer>
                    <div className="row">
                      <div className="col-md-6">
                        <Link
                          to={`/zm/dashboard/?uid=${uid}`}
                          className="btn btn-outline-warning"
                        >
                          Close
                        </Link>
                      </div>
                    </div>
                  </Modal.Footer>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketDetails;
