import tour1 from "../../images/ele.jpg";
import tour2 from "../../images/tour1.png";
import tour3 from "../../images/tour2.jpg";
import tour4 from "../../images/holiday.jpg";
import home1 from "../../images/hero.jpg";
import home2 from "../../images/bg_4.jpg";
import home3 from "../../images/city.jpg";
import home4 from "../../images/banner.png";
import home5 from "../../images/ele.jpg";
import home6 from "../../assets/slider-img/slider-3.jpg";
import holiday1 from "../../images/bg_5.jpg";
import holiday2 from "../../images/bg_2.jpg";

export const tourSlides = [
  {
    eachSlide: `url(${tour1})`,
  },
  {
    eachSlide: `url(${tour2})`,
  },
  {
    eachSlide: `url(${tour3})`,
  },
  {
    eachSlide: `url(${tour4})`,
  },
];

export const homeSlides = [
  {
    eachSlide: `url(${home1})`,
    title: "Discover Your Favorite Places with Us",
  },
  {
    eachSlide: `url(${home2})`,
    title: "Holiday Reservation",
  },
  {
    eachSlide: `url(${home6})`,
    title: "Intercity Travellers Car Rentals",
  },
  {
    eachSlide: `url(${home3})`,
    title: "Bus Ticket Reservation",
  },
  {
    eachSlide: `url(${home4})`,
    title: "Hotel/Lodge Room Reservation",
  },
  {
    eachSlide: `url(${home5})`,
    title: "Lets Explore Together",
  },
];

export const holidaySlides = [
  {
    eachSlide: `url(${home2})`,
    title: "Adventure",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit\
    Repellat, expedita ducimus. Vero obcaecati enim quo. Error\
    reprehenderit iste, at eaque inventore nihil dignissimos vitae\
    cumque laudantium eos, molestiae iure et?",
  },
  {
    eachSlide: `url(${holiday1})`,
    title: "Experience",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit\
    Repellat, expedita ducimus. Vero obcaecati enim quo",
  },
  {
    eachSlide: `url(${holiday2})`,
    title: "Explore",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit\
    Repellat, expedita ducimus. Vero obcaecati enim quo. Error\
    reprehenderit iste.",
  },
];
