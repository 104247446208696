import React from "react";
import DeleteAccountModal from "./delete_account_modal";

const DeleteAccount: React.FC = () => {
  return (
    <div className="container p-5">
      <div className="col-lg-9 col-md-8 col-12">
        {/* <!-- Card --> */}
        <div className="card">
          {/* <!-- Card header --> */}
          <div className="card-header">
            <h3 className="mb-0">Delete your account</h3>
            <p className="mb-0">Delete or Close your account permanently.</p>
          </div>
          {/* <!-- Card body --> */}
          <div className="card-body p-4">
            <span className="text-danger h4">Warning</span>
            <p>
              If you delete your account, all of your data will be permanently deleted, and you will lose access forever.
            </p>
            <DeleteAccountModal />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
