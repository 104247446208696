import { useState, ChangeEvent, FormEvent } from "react";
import { toast } from "react-toastify";
import { useRegisterMutation } from "../redux/slices/authApiSlice";
import { useNavigate } from "react-router-dom";

interface IRegister{
  nationality: string
}

const useRegister = ({nationality}: IRegister) =>{
  const router = useNavigate();
  const [register, { isLoading }] = useRegisterMutation();

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    user_type: "CUSTOMER",
    password: "",
    re_password: "",
  });

  const { first_name, last_name, email, user_type, password, re_password } = formData;

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    register({ first_name, last_name, email, user_type, nationality, password, re_password })
      .unwrap()
      .then(() => {
        toast.success("Account Created Successfully");
        router("/zm/auth/login");
      })
      .catch((error: any) => {
          if(error.data['email']){
            toast.error(`${error.data['email']}`);
           }else if (error.data['password']){
            toast.error(`${error.data['password']}`);
           }else{
            toast.error(`An error occurred`);
           }
        
      });
  };

  return {
    first_name,
    last_name,
    email,
    password,
    re_password,
    isLoading,
    onChange,
    onSubmit,
  };
}
export default useRegister;