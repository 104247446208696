import React from "react";
import { Link } from "react-router-dom";
import { urlToken } from "../../../helper";
import LoadingSpinner from "../../../components/widgets/spinner";
import { IRoomReservation } from "../../../redux/types/room_reservation.type";
import { useGetCustomerReservationsQuery } from "../../../redux/slices/room_reservation_slice";

const Customers = () => {
  const {
    data: reservations,
    isLoading,
    error,
  }: IRoomReservation | any = useGetCustomerReservationsQuery({
    hotel_owner: 44,
  });

  if (isLoading) {
    return (
      <center>
        <LoadingSpinner />
      </center>
    );
  }
  if (error) {
    return (
      <div className="text-center alert alert-danger" role="alert">
        <h4>{error.status}</h4>
      </div>
    );
  }
  return (
    <div className="content" style={{ background: "#dbdbdb" }}>
      <section>
        <div className="container-fluid py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            {reservations.map((item: IRoomReservation, index: number) => {
              return (
                <div key={index} className="col-md-3 col-sm-2 col-xl-3">
                  <div className="card" style={{ borderRadius: "15px" }}>
                    <div className="card-body text-center">
                      <div className="mt-3 mb-4">
                        <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava2-bg.webp"
                          className="rounded-circle img-fluid"
                          style={{ width: "100px" }}
                        />
                      </div>
                      <h4 className="mb-2 text-capitalize">
                        {item.first_name} {item.last_name}
                      </h4>
                      <p className="text-muted mb-4">
                        {item.email}
                      </p>
                      <Link
                        to={`/zm/hotel_dashboard/customer_profile/${urlToken}/?customer_id=${item.user.id}`}
                        className="btn btn-primary btn-rounded btn-lg">
                        Details
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Customers;
