import { CreateUserResponse, SocialAuthArgs, User } from "../types/user";
import { apiSlice } from "../services/apiSlice";

const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    retrieveUser: builder.query<User, void>({
      query: () => "/auth/users/me/",
    }),

    socialAuthenticate: builder.mutation<CreateUserResponse, SocialAuthArgs>({
      query: ({ provider, state, code }) => ({
        url: `/o/${provider}/?state=${encodeURIComponent(
          state
        )}&code=${encodeURIComponent(code)}`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }),
    }),

    login: builder.mutation<User, any>({
      query: ({ email, password }) => ({
        url: "/userprofile/v1/intercity_travellers/auth/jwt/token/rmf_travellers",
        method: "POST",
        body: { email, password },
      }),
    }),

    deleteUser: builder.mutation<User, any>({
      query: ({ current_password }) => ({
        url: "/auth/users/me/",
        method: "DELETE",
        body: { current_password },
      }),
    }),

    register: builder.mutation({
      query: ({ first_name, last_name, email, user_type, nationality, password, re_password }) => ({
        url: "/auth/users/",
        method: "POST",
        body: { first_name, last_name, email, user_type, nationality, password, re_password },
      }),
    }),

    verify: builder.mutation({
      query: ({token}) => ({
        url: "/auth/jwt/verify/",
        method: "POST",
        body: token,
      }),
    }),
    refresh: builder.mutation({
      query({token}) {
        return {
          url: "/auth/jwt/refresh/",
          method: "POST",
          body: token,
        }
      },
    }),
    logout: builder.mutation({
      query: () => ({
        url: "/logout/",
        method: "POST",
      }),
    }),

    activation: builder.mutation({
      query: ({ uid, token }) => ({
        url: "/users/activation/",
        method: "POST",
        body: { uid, token },
      }),
    }),

    resetPassword: builder.mutation({
      query: (email) => ({
        url: "/users/reset_password/",
        method: "POST",
        body: { email },
      }),
    }),
    resetPasswordConfirm: builder.mutation({
      query: ({ uid, token, new_password, re_new_password }) => ({
        url: "/users/reset_password_confirm/",
        method: "POST",
        body: { uid, token, new_password, re_new_password },
      }),
    }),
  }),
});

export const {
  useRetrieveUserQuery,
  useSocialAuthenticateMutation,
  useLoginMutation,
  useRegisterMutation,
  useVerifyMutation,
  useRefreshMutation,
  useLogoutMutation,
  useActivationMutation,
  useResetPasswordMutation,
  useResetPasswordConfirmMutation,
  useDeleteUserMutation,
} = authApiSlice;
